import React, {Fragment} from 'react';
import { useTable, useSortBy, useFilters, useExpanded, usePagination } from "react-table"
import { DefaultColumnFilter } from '../../modules/authenticated/Management/components/filters';

import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import "../../stylesheets/components/responsiveTable.css"

const TableContainer = ({ columns, data }) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows, //-> we change 'rows' to 'page'
        prepareRow,
        visibleColumns,
    } = useTable(
        {
            columns,
            data,
            defaultColumn: { Filter: DefaultColumnFilter },
        },
        usePagination,
    );

    const Center = ({children}) => {
        return <div style={{height: "100%"}} className="d-flex justify-content-center align-items-center">
            <div>
                {children}
            </div>
        </div>
    }


    return (
        <Fragment>
            <Table bordered {...getTableProps()}>
                <Thead>
                        <Tr>
                            {headerGroups[0].headers.map((column, key) => {
                                return (
                                        <Th key={key}>
                                            {column.Header}
                                        </Th>

                                );
                            })}
                        </Tr>
                </Thead>

                <Tbody {...getTableBodyProps()}>
                {rows.map(row => {
                    prepareRow(row);
                    return (
                        <Fragment key={row.getRowProps().key} >
                            <Tr>
                                {row.cells.map((cell) => {
                                    return (
                                        <Td {...cell.getCellProps()}>{cell.render('Cell')}</Td>
                                    );
                                })}
                            </Tr>

                        </Fragment>
                    );
                })}
                </Tbody>
            </Table>
        </Fragment>
    );
};

export default TableContainer;