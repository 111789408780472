import React, {useEffect, useMemo, useState} from "react";
import { useSelector } from "react-redux";
import {getFirstPackagePage, getPackagePage} from "../../../../../redux/actions/packageOverviewActions";
import PaginatedTable from "../../../../../components/PaginatedTable/PaginatedTable";
import {dateFormatter} from "../../../../../utils/formattingHelpers";
import PackageDropdown from "./PackageDropdown";
import { Modal, ModalBody, Button } from "reactstrap";
import { Link } from "react-router-dom";
import {GET_PACKAGE_PAGE_SUCCESS} from "../../../../../redux/constants";
import {fetchPackagePage, packagePageBaseUrl} from "../../../../../features/package/packageSlice";

//Returns a packageoverview
const SalesPackageOverview = () => {
    const packagePageState = useSelector((state) => state["userPackages"]);

    const error = packagePageState.error;
    const message = packagePageState.editReturnMessage;
    const edit = packagePageState.edited;
    const newOrderconfirmation = packagePageState.newOrderconfirmation;


    const [messageIsOpen, setMessageIsOpen] = useState(false);

    useEffect(() => {
        if(edit){
            setMessageIsOpen(true);
        }
    }, [edit]);

    const renderRowSubComponent = (row) => {
        return (
            <PackageDropdown pack={row.original} />
        )
    }

    const columns = useMemo(
        () => [
            {
                Header: () => null,
                id: 'expandedButton',
                Cell: ({ row }) => (
                    <span>
            {row.isExpanded ? '△' : '▽'}
          </span>
                )
            },
            {
                id: "name",
                Header: "Pakke",
                accessor: "name"
            },
            {
                id: "students",
                Header: "Elever",
                accessor: d => {
                    return <>{d.students.map((student, key) => {
                            return <p key={key}>{student.first_name} {student.last_name}</p>
                        })}</>

                }
            },
            {
                id: "remaining_hours",
                Header: "Gjenst. timer",
                accessor: "remaining_hours"
            },
            {
                id: "date_sold",
                Header: "Dato solgt",
                accessor: "date_sold"
            },
            {
                id: "invoice_receiver",
                Header: "Fakturamottaker",
                accessor: "invoice_receiver_name"
            },
            {
                id: "invoice_sent_date",
                Header: "Faktura sendt",
                accessor: d => {
                    return <>{d.invoice_sent_date ? `${dateFormatter(new Date(d.invoice_sent_date))}` : "Ikke sendt"}</>;
                }
            },
            {
                id: "order_confirmations",
                Header: "Ordrebekreftelser",
                accessor: d => {
                    return <>{d.order_confirmations.map((order_confirmation, key) => {
                        return <p key={key}>{order_confirmation.order_confirmation_purpose === "NEW_PACKAGE" ? "Ny" : "Redigert"} - {order_confirmation.order_confirmation_sent_date ? 'Sendt ' + `${dateFormatter(new Date(order_confirmation.order_confirmation_sent_date))}` : "Ikke sendt"}</p>
                    })}</>
                }
            }
        ],
        []
    )


    return (
        <>
            <Modal isOpen={messageIsOpen} onClick={() => setMessageIsOpen(false)}>
                <div
                    id={error ? "failModalHeader" : "modalHeader"}
                    className="fullFlexCentre"
                >
                    <div className="title whiteText">Melding</div>
                </div>
                <ModalBody>
                    <p>

                        {message}
                    </p>
                    <p>
                        {newOrderconfirmation ?
                            <Link
                                to={{ pathname: "/sales/order-confirmations" }}
                                className="darkColorText"
                            >Gå til ordrebekreftelser</Link>
                            :
                            <></>
                        }
                    </p>

                    <Button className="button darkButton" style={{float: "center", margin: "auto", marginTop: "20px"}} onClick={() => {
                        setMessageIsOpen(false);
                    }}>Ok</Button>
                </ModalBody>
            </Modal>
            <div className="title mb-3" >Pakkeoversikt</div>
                <PaginatedTable
                    className="tableContainer"
                    columns={columns}
                    renderRowSubComponent={renderRowSubComponent}
                    reducer='package'
                    baseEndpoint={packagePageBaseUrl}
                    api={fetchPackagePage}
                    constant={GET_PACKAGE_PAGE_SUCCESS}
                />
        </>
    );
};

export default SalesPackageOverview;
