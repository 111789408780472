import axiosInstance from "../../axios";
import {
    GET_PACKAGE_PAGE_REQUEST,
    GET_PACKAGE_PAGE_SUCCESS,
    GET_PACKAGE_PAGE_FAIL,
    DELETE_PACKAGE_REQUEST,
    DELETE_PACKAGE_SUCCESS,
    DELETE_PACKAGE_FAIL,
    EDIT_PACKAGE_FAIL,
    EDIT_PACKAGE_SUCCESS,
    EDIT_PACKAGE_REQUEST,
    SET_ERROR,
} from "../constants";

export const getFirstPackagePage = (search, pageSize) => async (dispatch) => {
    try {
        dispatch({ type: GET_PACKAGE_PAGE_REQUEST });
        const { data } = await axiosInstance.get(
            "/package/packages/?search=" + search + "&limit=" + pageSize
        );
        dispatch({type: GET_PACKAGE_PAGE_SUCCESS, payload: data, page: 0});
    } catch (error) {
        dispatch({
            type: GET_PACKAGE_PAGE_FAIL,
            error: error.response && error.response.data.message ?
                error.response.data.message :
                error.message,
        });

    }
}

export const getPackagePage = (search, link, pageSize, page) => async (dispatch) => {
    try {
        dispatch({ type: GET_PACKAGE_PAGE_REQUEST });
        if(link) {
            const { data } = await axiosInstance.get(
                "/package/packages/" + link + search
            );
            dispatch({type: GET_PACKAGE_PAGE_SUCCESS, payload: data, page: page});
        }else{
            const { data } = await axiosInstance.get(
                "/package/packages/?limit=" + pageSize + "&offset=" + pageSize*page + "&search=" + search
            );
            dispatch({type: GET_PACKAGE_PAGE_SUCCESS, payload: data, page: page});
        }
    } catch (error) {
        dispatch({
            type: SET_ERROR,
            error: error.response && error.response.data ?
                error.response.data :
                error.message,
        });

    }
}

export const deletePackage = (id) => async (dispatch) => {
    try {
        dispatch({ type: DELETE_PACKAGE_REQUEST });

        const { data } = await axiosInstance.delete(
            "/package/package/" + id + "/"
        );
        dispatch({type: DELETE_PACKAGE_SUCCESS, payload: {id: id}});

    } catch (error) {
        dispatch({
            type: DELETE_PACKAGE_FAIL,
            error: error.response && error.response.data.message ?
                error.response.data.message :
                error.message,
        });

    }
}

export const editPackage = (id, patch, newOrderconfirmation) => async (dispatch) => {
    try {
        dispatch({ type: EDIT_PACKAGE_REQUEST });

        const { data } = await axiosInstance.patch(
            "/package/package/" + id + "/", patch
        );
        dispatch({type: EDIT_PACKAGE_SUCCESS, payload: data, newOrderconfirmation: newOrderconfirmation});
        //dispatch(getPackagePage(search, link, pageSize, page))

    } catch (error) {
        dispatch({
            type: EDIT_PACKAGE_FAIL,
            error: error.response && error.response.data.message ?
                error.response.data.message :
                error.message,
        });

    }
}