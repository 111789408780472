import {
  GET_TEACHER_SALARIES_FAIL,
  GET_TEACHER_SALARIES_REQUEST,
  GET_TEACHER_SALARIES_SUCCESS,
  GET_TEACHER_SALARY_FAIL,
  GET_TEACHER_SALARY_REQUEST,
  GET_TEACHER_SALARY_SUCCESS,
  MARK_TEACHERS_AS_PAID_FAIL,
  MARK_TEACHERS_AS_PAID_REQUEST,
  MARK_TEACHERS_AS_PAID_SUCCESS,
  GET_SALARIES_FAIL,
  GET_SALARIES_REQUEST,
  GET_SALARIES_SUCCESS,
  ADD_SALARY_FAIL,
  ADD_SALARY_SUCCESS,
  ADD_SALARY_REQUEST,
  GET_SALARY_LEVEL_TYPE_DETAILS_FAIL,
  GET_SALARY_LEVEL_TYPE_DETAILS_REQUEST,
  GET_SALARY_LEVEL_TYPE_DETAILS_SUCCESS,
} from "../constants";

const initialState = {
  loading: false,
  teachers: [],
  currentSalary: {},
  markAsPaidLoading: false,
  allSalaries: [],
  salaryLevelTypeDetails: {history:[{}]},
};

export const salaryReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TEACHER_SALARIES_REQUEST:
      return { ...state, loading: true };

    case GET_TEACHER_SALARIES_SUCCESS:
      return { ...state, loading: false, teachers: action.payload };

    case GET_TEACHER_SALARIES_FAIL:
      return { ...state, loading: false };

    case GET_TEACHER_SALARY_REQUEST:
      return { ...state, loading: true };

    case GET_TEACHER_SALARY_SUCCESS:
      return { ...state, loading: false, currentSalary: action.payload };

    case GET_TEACHER_SALARY_FAIL:
      return { ...state, loading: false };

    case MARK_TEACHERS_AS_PAID_REQUEST:
      return { ...state, markAsPaidLoading: true };

    case MARK_TEACHERS_AS_PAID_SUCCESS:
      return { ...state, markAsPaidLoading: false, actionSucceeded: true };

    case MARK_TEACHERS_AS_PAID_FAIL:
      return { ...state, markAsPaidLoading: false, actionSucceeded: false };

    case GET_SALARIES_REQUEST:
      return { ...state, loading: true, allSalaries: [] };

    case GET_SALARIES_SUCCESS:
      return { ...state, loading: false, allSalaries: action.payload };

    case GET_SALARIES_FAIL:
      return {...state, loading: false, error: action.payload };
    
      case GET_SALARY_LEVEL_TYPE_DETAILS_REQUEST:
      return {...state, loading: true};

    case GET_SALARY_LEVEL_TYPE_DETAILS_SUCCESS:
      return {...state, loading: false, salaryLevelTypeDetails: action.payload};

    case GET_SALARY_LEVEL_TYPE_DETAILS_FAIL:
      return { ...state, loading: false };

    case ADD_SALARY_REQUEST:
      return { ...state, loading: true };

    case ADD_SALARY_SUCCESS:
      return { ...state, loading: false, actionSucceeded: true };

    case ADD_SALARY_FAIL:
      return { ...state, loading: false, actionSucceeded: false };

    default:
      return state;
  }
};
