import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "reactstrap";
import { getSubjectTree } from "../../../../redux/actions/getSubjectTree";
import CheckButton from "./CheckButton";
import Rad from "./RadioWithInnerText";

function SubjectSelector(
  commonCourses,
  studentCourses,
  teacherCourses,
  returnSubjects
) {
  /* HANDLE INTERNAL STATE */
  const initialState = {
    fields: {
      level: { children: [] },
      category: { children: [] },
      selected: [],
    },
    errors: {
      level: "",
      category: "",
      selected: "",
    },
  };
  const [state, setState] = useState(initialState);

  const stateHandler = (field, value) => {
    setState((prevState) => ({
      ...prevState,
      fields: {
        ...prevState.fields,
        [field]: value,
      },
      errors: {
        ...prevState.errors,
        [field]: "",
      },
    }));
  };

  const [modal, setModal] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => setIsOpen(!isOpen);

  const toggle = () => setModal(!modal);

  /* Return subject list to parent on change */
  useEffect(() => {
    returnSubjects(state.fields.selected);
  }, [state.fields.selected]);

  /* Add and remove new subjects */
  const addSelectedSubject = (subject) => {
    stateHandler(
      "selected",
      state.fields.selected.includes(subject)
        ? state.fields.selected
        : [...state.fields.selected, subject]
    );
  };

  const removeSelectedSubject = (subject) => {
    stateHandler(
      "selected",
      state.fields.selected.filter((selected) => selected !== subject)
    );
  };

  /* REDUX */
  const dispatch = useDispatch();

  const subjectTree = useSelector((state) => state.subjects.tree);

  /* Fetches tree of educational level, subject categories and subjects */
  useEffect(() => {
    dispatch(getSubjectTree());
  }, [dispatch]);

  /* RENDERING TO THE PAGE */

  /* Renders buttons to choose educational level */
  function getEducationLevelButtons() {
    return (
      <Row>
        {subjectTree.map((level, key) => (
          <Col
            key={key}
            xs="12"
            sm="6"
            md="4"
            lg="4"
            xl="3"
            className="fullFlexCentre"
          >
            {Rad(
              key,
              "eduLevel",
              level.name,
              level.id,
              false,
              () => stateHandler("level", level),
              false,
              state.fields.level.id,
              "medium"
            )}
          </Col>
        ))}
      </Row>
    );
  }

  /* Renders buttons to choose subject category */
  const getSubjectCategoryButtons = () => {
    return (
      <Row>
        {state.fields.level.children.map((subjectCategory, key) => (
          <Col
            key={key}
            xs="12"
            sm="6"
            md="4"
            lg="4"
            xl="3"
            className="fullFlexCentre"
          >
            {Rad(
              key,
              "mainSub",
              subjectCategory.name,
              subjectCategory.id,
              subjectCategory.id === state.fields.category.id,
              (e) => stateHandler("category", subjectCategory),
              false,
              state.fields.category.id,
              "under"
            )}
          </Col>
        ))}
      </Row>
    );
  };

  /* Renders buttons to choose subjects */
  const getSubjectButtons = () => {
    let cols = state.fields.category.children
      .filter(
        (subject) => !studentCourses.some((course) => course.id === subject.id)
      )
      .filter(
        (subject) =>
          !state.fields.selected.some((course) => course.id === subject.id)
      )
      .map((subject, key) => (
        <Col
          key={key}
          xs="12"
          sm="6"
          md="4"
          lg="4"
          xl="3"
          className="fullFlexCentre"
        >
          {CheckButton(
            key,
            subject.subject_name,
            subject.id,
            false,
            false,
            () => addSelectedSubject(subject),
            "light"
          )}
        </Col>
      ));

    return (
      <Row>
        {cols.length > 0 ? (
          cols
        ) : state.fields.category.children.length > 0 ? (
          <Col style={{ margin: 10 }}>
            Det er ingen flere relevante fag i denne kategorien
          </Col>
        ) : (
          ""
        )}
      </Row>
    );
  };

  /* Renders labels and buttons for subjects for the student, both old and new */
  const getSelectedSubjects = () => {
    return (
      <Row>
        {typeof commonCourses !== "undefined" ? (
          commonCourses.map((subject, key) => (
            <Col
              key={key}
              xs="12"
              sm="6"
              md="4"
              lg="4"
              xl="3"
              className="fullFlexCentre"
              style={{ paddingBottom: "8px" }}
            >
              <div className="button lightLabel">{subject.subject_name}</div>
            </Col>
          ))
        ) : (
          <></>
        )}
        {state.fields.selected
          .filter((subject) =>
            teacherCourses.some((course) => course.id === subject.id)
          )
          .map((subject, key) => (
            <Col
              key={key}
              xs="12"
              sm="6"
              md="4"
              lg="4"
              xl="3"
              className="fullFlexCentre"
              style={{ paddingBottom: "8px" }}
            >
              {CheckButton(
                key,
                subject.subject_name,
                subject.id,
                true,
                true,
                () => {
                  removeSelectedSubject(subject);
                },
                false
              )}
            </Col>
          ))}

        {typeof studentCourses !== "undefined" ? (
          studentCourses
            .filter(
              (subject) =>
                !teacherCourses.some((course) => course.id === subject.id)
            )
            .map((subject, key) => (
              <Col
                key={key}
                xs="12"
                sm="6"
                md="4"
                lg="4"
                xl="3"
                className="fullFlexCentre"
                style={{ paddingBottom: "8px" }}
              >
                <div className="button greyButton">{subject.subject_name}</div>
              </Col>
            ))
        ) : (
          <></>
        )}
        {state.fields.selected
          .filter(
            (subject) =>
              !teacherCourses.some((course) => course.id === subject.id)
          )
          .map((subject, key) => (
            <Col
              key={key}
              xs="12"
              sm="6"
              md="4"
              lg="4"
              xl="3"
              className="fullFlexCentre"
              style={{ paddingBottom: "8px" }}
            >
              {CheckButton(
                key,
                subject.subject_name,
                subject.id,
                true,
                true,
                () => {
                  removeSelectedSubject(subject);
                },
                "grey"
              )}
            </Col>
          ))}
      </Row>
    );
  };

  /* Renders options for activity level in drop-down */
  const listLevelOptions = () => {
    return subjectTree.map((level, key) => (
      <option key={key} value={level.id}>
        {level.name}
      </option>
    ));
  };

  /* Renders options for subject category in drop-down */
  const listCategoryOptions = () => {
    return state.fields.level.children.map((category, key) => (
      <option key={key} value={category.id}>
        {category.name}
      </option>
    ));
  };

  /* Lets HR know which course, that does not exist, a teacher wishes to add to a student */
  const saveNewCourse = () => {
    toggle();
    toggleDropdown();
  };

  return (
    <div>
      <Row>
        <Col>Velg undervisningsnivå</Col>
      </Row>
      {getEducationLevelButtons()}
      {state.fields.level.children.length === 0 ? (
        ""
      ) : (
        <Row>
          <Col>Velg fagkategori</Col>
        </Row>
      )}
      {getSubjectCategoryButtons()}
      {state.fields.category.children.length === 0 ? (
        ""
      ) : (
        <Row>
          <Col>Velg fag</Col>
        </Row>
      )}
      {getSubjectButtons()}
      <Row>
        <Col>Elevens fag:</Col>
      </Row>
      {getSelectedSubjects()}
      <Row style={{ margin: "10px"}}>
        <Col >
          Finner du ikke faget? Ta kontakt med drift så registrerer vi det for
          deg.
        </Col>
      </Row>
    </div>
  );
}
/* TODO: Decide what to do with this button
<Col xs="12" sm="5" md="5" lg="5" xl="5">
          <FormGroup>
            <Label>Finner du ikke faget?</Label>
            <Button style={{ width: "100%" }} className="button darkButton" onClick={toggleDropdown}>
              Registrer et nytt fag
            </Button>
            <Collapse isOpen={isOpen}>
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <Label>Undervisningsnivå</Label>
                      <Input
                        type="select"
                        onChange={(e) => {
                          stateHandler(
                            "level",
                            subjectTree.find((x) => x.id == e.target.value)
                          );
                        }}
                        defaultValue="Velg nivå"
                        className="inputField"
                        required
                      >
                        <option value={""}>Velg nivå</option>
                        {listLevelOptions()}
                      </Input>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Label>Underkategori</Label>
                      <Input
                        type="select"
                        onChange={(e) => {
                          stateHandler(
                            "category",
                            state.fields.level.children.find((x) => x.id == e.target.value)
                          );
                        }}
                        defaultValue="Velg kategori"
                        className="inputField"
                        required
                      >
                        <option value={""}>Velg kategori</option>
                        {listCategoryOptions()}
                      </Input>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Label>Navn på fag:</Label>
                      <Input outline="true" onChange={(e) => console.log(e.target.value)} className="inputField" />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Button className="button mediumButton" style={{ marginTop: "20px" }} onClick={toggle}>
                        Legg til
                      </Button>
                      <Modal isOpen={modal} toggle={toggle}>
                        <ModalHeader toggle={toggle} id="modalHeader">
                          <Label className="title whiteText">Varsel</Label>
                        </ModalHeader>
                        <ModalBody>
                          <p>Er du sikker på at du vil legge til et nytt fag? Ta kontakt med HR for å få faget godkjent til eleven.</p>
                          <hr />
                          <ButtonToolbar style={{ float: "right" }}>
                            <Button className="button mediumButton" onClick={toggle} style={{ marginRight: "5px" }}>
                              Avbryt
                            </Button>
                            <Button className="button mediumButton" onClick={saveNewCourse}>
                              Lagre
                            </Button>
                          </ButtonToolbar>
                        </ModalBody>
                      </Modal>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Collapse>
          </FormGroup>
        </Col>
 */

export default SubjectSelector;
