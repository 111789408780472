import {
    GET_SUBJECTS_SUCCESS,
    GET_TEACHER_MATCHING_SUCCESS,
} from "../constants";
import {act} from "react-dom/test-utils";

const initialState = {
    loading: false,
    teacherMatching: [],
    subjects: [],
};

export const teacherMatchingReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_TEACHER_MATCHING_SUCCESS:
            return { ...state, loading: false, teacherMatching: action.payload, actionSucceeded: true };

        case GET_SUBJECTS_SUCCESS:
            return { ...state, loading: false, subjects: action.payload, actionSucceeded: true };
        default:
            return state;
    }
};
