import React from "react";
import { Settings, User } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router";
import { isTeacher } from "../utils/authHelpers";
import { Link, useHistory } from "react-router-dom";
import {
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  UncontrolledDropdown,
} from "reactstrap";
import { logOut } from "../redux/actions/auth";
import { toggleSidebar } from "../redux/actions/sidebarActions";

// const NavbarDropdown = ({
//   children,
//   count,
//   showBadge,
//   header,
//   footer,
//   icon: Icon,
// }) => (
//   <UncontrolledDropdown nav inNavbar className="mr-2">
//     <DropdownToggle nav className="nav-icon dropdown-toggle">
//       <div className="position-relative">
//         <Icon className="align-middle" size={18} />
//         {showBadge ? <span className="indicator">{count}</span> : null}
//       </div>
//     </DropdownToggle>
//     <DropdownMenu right className="dropdown-menu-lg py-0">
//       <div className="dropdown-menu-header position-relative">
//         {count} {header}
//       </div>
//       <ListGroup>{children}</ListGroup>
//       <DropdownItem header className="dropdown-menu-footer">
//         <span className="text-muted">{footer}</span>
//       </DropdownItem>
//     </DropdownMenu>
//   </UncontrolledDropdown>
// );

// const NavbarDropdownItem = ({ icon, title, description, time, spacing }) => (
//   <ListGroupItem>
//     <Row noGutters className="align-items-center">
//       <Col xs={2}>{icon}</Col>
//       <Col xs={10} className={spacing ? "pl-2" : null}>
//         <div className="text-dark">{title}</div>
//         <div className="text-muted small mt-1">{description}</div>
//         <div className="text-muted small mt-1">{time}</div>
//       </Col>
//     </Row>
//   </ListGroupItem>
// );

const NavbarComponent = () => {
  const dispatch = useDispatch();
  const currentUserState = useSelector((state) => state.currentUser);
  const { error, currentUser } = currentUserState;
  const history = useHistory();

  const handleSubmit = (e) => {
    //window.location.href = "/";
    history.push("/sign-in/");
    e.preventDefault();
    dispatch(logOut());
  };

  if (!currentUser && !error) {
    return <Redirect to="/sign-in/" />;
  }

  return (
    <Navbar color="white" light expand sticky="top">
      <span
        className="sidebar-toggle d-flex mr-2"
        onClick={() => {
          dispatch(toggleSidebar());
        }}
      >
        <i className="hamburger align-self-center" />
      </span>
      <Collapse navbar>
        <Nav className="ml-auto" navbar>
          <UncontrolledDropdown nav inNavbar>
            <span className="d-inline-block d-sm-none">
              <DropdownToggle nav caret>
                <Settings size={18} className="align-middle" />
              </DropdownToggle>
            </span>
            <span className="d-none d-sm-inline-block">
              <DropdownToggle nav caret>
                <span className="text-dark">
                  {currentUser.user.first_name} {currentUser.user.last_name}
                </span>
              </DropdownToggle>
            </span>
            {isTeacher() ? (
              <DropdownMenu right>
                <DropdownItem>
                  <Link
                    to={{ pathname: "/teacher/profile" }}
                    className="darkColorText"
                  >
                    <User
                      style={{ marginTop: "-5px" }}
                      size={18}
                      className="align-middle mr-2"
                    />
                    Profil
                  </Link>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={handleSubmit} className="darkColorText">
                  Logg ut
                </DropdownItem>
              </DropdownMenu>
            ) : (
              <DropdownMenu right>
                <DropdownItem onClick={handleSubmit} className="darkColorText">
                  Logg ut
                </DropdownItem>
              </DropdownMenu>
            )}
          </UncontrolledDropdown>
        </Nav>
      </Collapse>
    </Navbar>
  );
};

export default NavbarComponent;
