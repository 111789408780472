import React from "react";
import {
  Col, Row
} from "reactstrap";

//Returns grid of objects
const RadioGrid = (radios) => {
  return (
    <Row>
      {radios.map((x, index) => (
        <Col
          xs="12"
          sm="6"
          md="4"
          lg="4"
          xl="3"
          key={index}
          className="fullFlexCentre"
        >
          {x}
        </Col>
      ))}
    </Row>
  );
};

export default RadioGrid;
