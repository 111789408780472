import axiosInstance from "../../axios";
import {
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAIL,
} from "../constants";

export const changePassword =
  (oldPassword, newPassword) => async (dispatch) => {
    dispatch({ type: CHANGE_PASSWORD_REQUEST, clear: true });

    const { data } = await axiosInstance.put("user/change-password/", {
      old_password: oldPassword,
      new_password: newPassword,
    });

    return data;
  };
