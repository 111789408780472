import axiosInstance from "../../axios";
import { GET_TEACHER_SALARY_FAIL, GET_TEACHER_SALARY_REQUEST, GET_TEACHER_SALARY_SUCCESS } from "../constants";
export const getTeacherSalary = (payload) => async (dispatch) => {
  try {
    dispatch({ type: GET_TEACHER_SALARY_REQUEST });

    const { data } = await axiosInstance.post("/salary/next-payment/", payload);

    dispatch({ type: GET_TEACHER_SALARY_SUCCESS, payload: data });
  } catch (error) {

    dispatch({ type: GET_TEACHER_SALARY_FAIL,
      error: error.response && error.response.data.message ?
          error.response.data.message :
          error.message
    });
  }
};
