import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Card, CardBody, FormGroup, Input, Label } from "reactstrap";
import { useDispatch } from "react-redux";
import { confirmResetPassword } from "../../../redux/actions/confirmResetPassword";
import viewIcon from "../../../assets/img/RH/view_icon.png";

const ConfirmResetPassword = () => {
  const dispatch = useDispatch();
  const [password, setPassword] = useState("");

  const [errorMessage, setErrorMessage] = useState([]);

  const [backToSignin, setBackToSignin] = useState(false);

  const [viewPass, setViewPass] = useState(false);

  const [successful, setSuccessful] = useState(false);

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const changeHandler = (e) => {
    setPassword(e.target.value);
  };

  //const { currentTeacher } = currentTeacherObject;

  const handleReset = () => {
    dispatch(
      confirmResetPassword({
        password: password,
        token: urlParams.get("token"),
      })
    )
      .then((response) => {
        // Handles different responses and errors
        if (response.password) {
          setErrorMessage(response.password);
        } else if (response.detail) {
          setErrorMessage(["Token utløpt, spør om ny epost ved å trykke på glemt passord på innloggingsiden."]);
          setBackToSignin(true);
        } else if (response.token) {
          setErrorMessage(["Token mangler. Trykk på link i epost."]);
          setBackToSignin(true);
        } else {
          setErrorMessage(["Nytt passord har blitt opprettet!"]);
          setSuccessful(true);
          setBackToSignin(true);
        }
      })
      .catch(function (error) {});

    return false;
  };
  return (
    <React.Fragment>
      <div className="text-center mt-4">
        <h1 className="h2"  style={{paddingBottom: "22px"}}>Lag nytt passord</h1>
      </div>

      <Card>
        <CardBody>
          <div className="m-sm-4">
            <div>
              <FormGroup>
                <Label>Nytt passord</Label>

                {viewPass ? (
                  <div className={"passInputContainer"}>
                    <Input
                      className={"passInputField"}
                      bsSize="lg"
                      name="password"
                      placeholder="Enter your new password"
                      onChange={changeHandler}
                      onKeyPress={(event) => {
                        if (event.key === "Enter") {
                          handleReset();
                        }
                      }}
                    />
                    <button
                      className={"showOnlyButtonContent"}
                      style={{ marginLeft: "5px" }}
                      onClick={() => {
                        setViewPass(!viewPass);
                      }}
                    >
                      <img
                        src={viewIcon}
                        alt="editPng"
                        style={{ width: 30, cursor: "pointer" }}
                      />
                    </button>
                  </div>
                ) : (
                  <div className={"passInputContainer"}>
                    <Input
                      className={"passInputField"}
                      bsSize="lg"
                      type="password"
                      name="password"
                      placeholder="Enter your new password"
                      onChange={changeHandler}
                      onKeyPress={(event) => {
                        if (event.key === "Enter") {
                          handleReset();
                        }
                      }}
                    />
                    <button
                      className={"showOnlyButtonContent"}
                      style={{ marginLeft: "5px" }}
                      onClick={() => {
                        setViewPass(!viewPass);
                      }}
                    >
                      <img
                        src={viewIcon}
                        alt="editPng"
                        style={{ width: 30, cursor: "pointer" }}
                      />
                    </button>
                  </div>
                )}
              </FormGroup>
            </div>

            <div className="text-center mt-3">
              <Button
                color="primary"
                size="lg"
                id="submit"
                onClick={handleReset}
              >
                Lagre passord
              </Button>
            </div>
            <ul style={{ marginTop: "12px", color: (successful ? "#71bf44" : "red") }}>
              {errorMessage.map((item) => {
                return <li>{item}</li>;
              })}
            </ul>
          </div>
          <div>
            {backToSignin ? (
              <div>
                <Link to="/sign-in">Gå tilbake til innlogging.</Link>
              </div>
            ) : (
              <div />
            )}
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default ConfirmResetPassword;
