import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import Loader from "../../../../../components/Loader";
import { getActivities } from "../../../../../redux/actions/getActivities";
import { getTeacherSalary } from "../../../../../redux/actions/getTeacherSalary";
import { getMonth, getMonthNumber, getYear } from "../../../../../utils/timeUtils";
import NextActivityInfo from "../../../../../features/activity/NextActivityInfo";
import TeacherLessonTable from "../../components/TeacherLessonTable.jsx";
import {TeacherPaths} from "../../teacherConstants";
import {Link} from "react-router-dom";

const monthNames = [
  "Januar",
  "Februar",
  "Mars",
  "April",
  "Mai",
  "Juni",
  "Juli",
  "August",
  "September",
  "Oktober",
  "November",
  "Desember",
];

const TeacherDashboard = () => {
  const dispatch = useDispatch();

  // gets the activity info from the store
  const userActivities = useSelector(
    (state) => state.userActivities.userActivities
  );

  const salaryObject = useSelector((state) => state.salary);
  const { currentSalary, loading, error } = salaryObject;

  const now = new Date();

  useEffect(() => {
    dispatch(
      getTeacherSalary({
        year: getYear(),
        month: getMonthNumber(),
        month_is_unpaid: true,
      })
    );
  }, [dispatch]);

  // Returns the next payout amount of the user
  const getNextTotalPayout = () => {
    if (currentSalary.next_payment) {
      return Object.values(currentSalary.next_payment).reduce((a,b ) => a+b);
    }
  };

  // Returns next salary of a specific type (e.g travel)
  const getNextPayout = (key) => {
    if (currentSalary.next_payment) {
      return currentSalary.next_payment[key]
    }
  }

  // Returns the total unpaid salary of the user
  const getTotalUnpaidSalary = () => {
    if (currentSalary.total_unpaid) {
      return Object.values(currentSalary.total_unpaid).reduce((a,b ) => a+b);
    }
  }

  const getUnpaidSalary = (key) => {
    if (currentSalary.total_unpaid) {
      return currentSalary.total_unpaid[key]
    }
  }

  // Returns the number of planned lessons
  const getPlannedLessons = () => {
    if (userActivities === "No activities found") {
      return []
    }
    return userActivities.filter(
      (activity) =>
        !activity.summary_date &&
        new Date(activity.start_date_time) >= new Date()
    ).length;
  };

  // Returns the number of overdue lessons
  const getOverdueLessons = () => {
     if (userActivities === "No activities found") {
       return []
     }
    return userActivities.filter(
      (activity) =>
        !activity.summary_date &&
        new Date(activity.start_date_time) < new Date()
    ).length;
  };

  //Returns the new payout date
  const getNextPayoutDate = () => {
    return "15." + getMonth();
  };

  const getGranularSalary  = (salarySelector) => {
    return <>
               <Row>Basis: {salarySelector("base")}</Row>
               <Row> + Bonus: {salarySelector("incentive_bonus")}</Row>
               {salarySelector("special_bonus") > 0 ?
                   <Row>
                     + Spesialbonus: {salarySelector("special_bonus")}
                   </Row> :
                   <></>
               }
               <Row>+ Reise: {salarySelector("travel_salary")}</Row>
             </>

  }

  if (loading) return <Loader/>;

  return (
    <div className="page-container">
      <h1 className="title mb-3">MINE AKTIVITETER</h1>
        <Row>
          <Col style={{flex: 2, flexDirection: "row"}} >
            <Card className="dashboard-card m-1">
              <CardBody className="d-flex" style={{flexDirection: "column", flex: 2}}>
                 <Row>
                  <div style={{color: "#3B3A3D"}}><b>Lønn</b></div>
                 </Row>
                <Row style={{flex: 1}} className="d-flex">
                  <Col className="d-flex justify-center align-items-center">Neste lønning:<b>&nbsp;&nbsp;{getNextPayoutDate()}</b></Col>
                </Row>
                <Row style={{flex: 1}} className="d-flex">
                  <Col>Forfalte aktiviteter uten oppsummering:<b>&nbsp;&nbsp;{getOverdueLessons()}</b></Col>
                </Row>
                <Row style={{flex: 7}}>
                    <Col className="m-1">
                      <Row><b>Neste utbetaling (kr):</b></Row>
                      {getGranularSalary((key) => getNextPayout(key))}
                      <Row><b>Totalt:   {getNextTotalPayout()}</b></Row>
                    </Col>
                     <Col className="m-1">
                       <Row><b>Totalt ubetalt lønn (kr):</b></Row>
                        {getGranularSalary((key) => getUnpaidSalary(key))}
                       <Row><b>Totalt: {getTotalUnpaidSalary()}</b></Row>
                     </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col style={{flex: 2, flexDirection: "row"}}>
             <Card className="dashboard-card m-1" >
               <NextActivityInfo type="teacher"/>
            </Card>
          </Col>
          <Col style={{flex: 2, flexDirection: "row"}}>
            <Card className="dashboard-card m-1">
               <CardBody className="d-flex" style={{flexDirection: "column", flex: 2}}>
                 <Row>
                  <div style={{color: "#3B3A3D"}}><b>Husk</b></div>
                 </Row>
                <Row style={{flex: 1, flexDirection: "column"}} className="d-flex">
                  <p>Du må oppsummere aktivitetene før du får betalt</p>
                  <Link to={TeacherPaths.schedule}>
                    Fyll inn tidspunktene du er tilgjengelig.
                  </Link>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <TeacherLessonTable />
    </div>
  );
};

export default TeacherDashboard;
