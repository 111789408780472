import axiosInstance from "../../axios";
import {
  GET_ORDER_CONFIRMATIONS_FAIL,
  GET_ORDER_CONFIRMATIONS_REQUEST,
  GET_ORDER_CONFIRMATIONS_SUCCESS,
} from "../constants";
export const getOrderConfirmations = () => async (dispatch) => {
  try {
    dispatch({ type: GET_ORDER_CONFIRMATIONS_REQUEST, clear: true });

    const { data } = await axiosInstance.get("/package/order-confirmations/");

    dispatch({ type: GET_ORDER_CONFIRMATIONS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_ORDER_CONFIRMATIONS_FAIL,
      error:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const deleteOrderConfirmation = (key) => async (dispatch) => {
  try {
    const { res } = await axiosInstance.delete(
      "/package/order-confirmation/" + key
    );
  } catch (error) {}
};

export const sendOrderConfirmation = (key) => async (dispatch) => {
  try {
    const { res } = await axiosInstance.post(
      "/package/order-confirmation/" + key
    );

    dispatch(getOrderConfirmations());
  } catch (error) {}
};
