import axiosInstance from "../../axios";
import {
  GET_SUBJECTS_FAIL,
  GET_SUBJECTS_REQUEST,
  GET_SUBJECTS_SUCCESS,
} from "../constants";
export const getTeacherSubjects = () => async (dispatch) => {
  try {
    dispatch({ type: GET_SUBJECTS_REQUEST, clear: true });
    const { data } = await axiosInstance.get("/user/teacher/get-subjects/");
    dispatch({ type: GET_SUBJECTS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_SUBJECTS_FAIL,
      error:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
