import axiosInstance from "../../axios";
import {
  GET_COMMON_STUDENT_SUBJECT_FAIL,
  GET_COMMON_STUDENT_SUBJECT_REQUEST,
  GET_COMMON_STUDENT_SUBJECT_SUCCESS,
} from "../constants";
export const getCommonStudentSubject = (props) => async (dispatch) => {
  try {
    dispatch({ type: GET_COMMON_STUDENT_SUBJECT_REQUEST, clear: true });

    const { data } = await axiosInstance.get(
      "/user/teacher/student-subjects-in-common/" + props.id
    );
    console.log(
      "Trying to get data from: " +
        "/user/teacher/student-subjects-in-common/" +
        props.id
    );

    dispatch({ type: GET_COMMON_STUDENT_SUBJECT_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_COMMON_STUDENT_SUBJECT_FAIL,
      error:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
