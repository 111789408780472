import React, {useState} from "react";
import { Row } from "reactstrap";
import OrderConfirmationRow from "./OrderConfirmationRow";
import {
  dateFormatter,
} from "../../../../../utils/formattingHelpers";

// Returns a table of existing order confirmations.

const OrderConfirmationTable = (
  retrievedOrderConfirmations,
  handleDelete,
  handleView,
  handleDeletePackage
) => {

    const [sortBy, setSortBy] = useState("name");
    const [isAsc, setIsAsc] = useState(true);
    const toggleIsAsc = () => setIsAsc(!isAsc);

    const sortedOrderConfirmations = () => {
        let orderConfirmations = [...retrievedOrderConfirmations];

        switch (sortBy) {
            case "name":
                orderConfirmations.sort((oc1, oc2) => oc1.package.invoice_receiver.first_name
                    .localeCompare(oc2.package.invoice_receiver.first_name)
                );
                break;
            case "date":
                orderConfirmations.sort((oc1, oc2) =>
                    new Date(oc1.package.date_sold) - new Date(oc2.package.date_sold)
                );
        }

        return isAsc ? orderConfirmations : orderConfirmations.reverse();

    }

    const getSortingTableHead = (tableHeadName, sortByStat) => {
        return (
          <th
            onClick={() => sortBy === sortByStat ? toggleIsAsc() : setSortBy(sortByStat)}
            style={{cursor: "pointer"}}
            >
            <div style={{display: "flex"}}>
              <div style={{alignSelf: "center"}}>
                  {tableHeadName}
              </div>
              <div style={{marginLeft: "5px"}}>
                  <div style={sortBy === sortByStat ? (isAsc ? {} : {visibility: "hidden"}) : {}}>▲</div>
                  <div style={sortBy === sortByStat ? (isAsc ? {visibility: "hidden"} : {}) : {}}>▼</div>
              </div>
            </div>
          </th>
        );
    }

  const getOrderConfirmationRows = () => {

    return sortedOrderConfirmations().map((orderConfirmation, key) => {
      let date = new Date(orderConfirmation.package.date_sold);
      return OrderConfirmationRow(
        {
          invoice_receiver: orderConfirmation.package.invoice_receiver
            ? orderConfirmation.package.invoice_receiver.first_name +
              " " +
              orderConfirmation.package.invoice_receiver.last_name
            : "-",
          students: orderConfirmation.package.students.map(
              (student) => student.first_name + " " + student.last_name
          ),
          date_sold: `${dateFormatter(date)}`,
          package: orderConfirmation.package.name,
          packageID: orderConfirmation.package.id,
          new:
            orderConfirmation.order_confirmation_purpose === "NEW_PACKAGE"
              ? "Ny Pakke"
              : "Redigert Pakke",
          key: orderConfirmation.id,
        },
        handleDelete,
        handleView,
        handleDeletePackage
      );
    });
  };

  // returns the component
  return (
      <div>
        <table className="lessonTable overflow-table" style={{ width: "100%" }}>
          <thead>
            <tr>
              {getSortingTableHead("Fakturamottaker", "name")}
              <th>Elev(er)</th>
              {getSortingTableHead("Salgsdato", "date")}
              <th>Pakke</th>
              <th>Type Ordre</th>
              <th/>
            </tr>
          </thead>
          <tbody>{getOrderConfirmationRows()}</tbody>
        </table>
      </div>
  );
};

export default OrderConfirmationTable;
