import {
  GET_TEACHER_FAIL,
  GET_TEACHER_REQUEST,
  GET_TEACHER_SUCCESS,
  EDIT_TEACHER_DETAILS_FAIL,
  EDIT_TEACHER_DETAILS_REQUEST,
  EDIT_TEACHER_DETAILS_SUCCESS,
  EDIT_TEACHER_PREFERENCE_FAIL,
  EDIT_TEACHER_PREFERENCE_REQUEST,
  EDIT_TEACHER_PREFERENCE_SUCCESS,
  EDIT_TEACHER_PREFERRED_SUBJECT_REQUEST,
  EDIT_TEACHER_PREFERRED_SUBJECT_SUCCESS,
  EDIT_TEACHER_PREFERRED_SUBJECT_FAIL,
} from "../constants";
import store from "../store";
const initialState = {
  loading: true,
  currentTeacher: { teacher: {}, subjects: [] },
};

export const teacherReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TEACHER_REQUEST:
      return {
        ...state,
        loading: true,
        currentTeacher: initialState.currentTeacher,
      };

    case GET_TEACHER_SUCCESS:
      return { ...state, loading: false, currentTeacher: action.payload };

    case GET_TEACHER_FAIL:
      return { ...state, loading: false };

    case EDIT_TEACHER_DETAILS_REQUEST:
      return { ...state, loading: true };

    case EDIT_TEACHER_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        currentTeacher: {
          ...state.currentTeacher,
          teacher: { ...state.currentTeacher.teacher, ...action.payload },
        },
      };

    case EDIT_TEACHER_DETAILS_FAIL:
      return { ...state, loading: false };

    case EDIT_TEACHER_PREFERENCE_REQUEST:
      return { ...state, loading: false };

    case EDIT_TEACHER_PREFERENCE_SUCCESS:
      return { ...state, loading: false, currentTeacher: {
          ...state.currentTeacher,
          teacher: { ...state.currentTeacher.teacher, ...action.payload },
        } };

    case EDIT_TEACHER_PREFERENCE_FAIL:
      return { ...state, loading: false };

    case EDIT_TEACHER_PREFERRED_SUBJECT_REQUEST:
      return { ...state, loading: false};

    case EDIT_TEACHER_PREFERRED_SUBJECT_SUCCESS:
      return { ...state, loading: false };

    case EDIT_TEACHER_PREFERRED_SUBJECT_FAIL:
      return { ...state, loading: false};
    default:
      return state;
  }
};
