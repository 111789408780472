import React from "react";
import { Col, Container, Row } from "reactstrap";
import Main from "../../components/Main";
import AlertComponent from "../../features/error/AlertComponent";

export default ({ children }) => {
  return (
    <React.Fragment>
      <AlertComponent />
      <Main className="d-flex w-100 justify-content-center">
        <Container className="d-flex flex-column">
          <Row className="h-100">
            <Col sm="10" md="8" lg="6" className="mx-auto d-table h-100">
              <div className="d-table-cell align-middle">{children}</div>
            </Col>
          </Row>
        </Container>
      </Main>
    </React.Fragment>
  );
};

