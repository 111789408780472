import axiosInstance from "../../axios";
import {
  GET_FIELDSOFSTUDY_FAIL,
  GET_FIELDSOFSTUDY_REQUEST,
  GET_FIELDSOFSTUDY_SUCCESS,
} from "../constants";
export const getFieldsOfStudy = () => async (dispatch) => {
  try {
    dispatch({ type: GET_FIELDSOFSTUDY_REQUEST, clear: true });

    const { data } = await axiosInstance.get("/school/get-fields-of-study/");

    dispatch({ type: GET_FIELDSOFSTUDY_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_FIELDSOFSTUDY_FAIL,
      error:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
