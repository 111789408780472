import axiosInstance from "../../axios";
import {
  ADD_ACTIVITY_FAIL,
  ADD_ACTIVITY_REQUEST,
  ADD_ACTIVITY_SUCCESS
} from "../constants";
export const postActivity = (props) => async (dispatch) => {
  try {
    dispatch({ type: ADD_ACTIVITY_REQUEST, clear: true });
    const { data } = await axiosInstance.post("/activity/create-activity/", props);

    dispatch({ type: ADD_ACTIVITY_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: ADD_ACTIVITY_FAIL,
      error: error.response && error.response.data.message ? error.response.data.message : error.message,
    });
  }
};
