import React from "react";

import {
  Col,
  Row,
  Modal,
  ModalBody,
  Button,
} from "reactstrap";

const ConfirmDeleteModal = (props) => {

return (
    <Modal isOpen={props.show}>
        <ModalBody style={{align: "middle"}}>
            <Row style={{align: "middle", marginTop: "20px"}}>
                <h3 style={{textAlign: "middle", marginLeft: "15px"}}>
                    {props.text}
                </h3>
            </Row>
            <Row style={{align: "middle", marginTop: "20px"}}>
                <Col>
                    <Button
                        style={{marginLeft: "auto", marginRight: "auto"}}
                        className="button redButton"
                        onClick={() => {
                            props.handleConfirmDelete();
                        }}
                    >
                        Bekreft og slett
                    </Button>
                </Col>
                <Col style={{justifyContent: "center", alignItems: "center"}}>
                    <Button
                        style={{marginLeft: "auto", marginRight: "auto"}}
                        className="button darkButton"
                        onClick={() => {
                            props.setShowConfirmDeleteModal(false);
                        }}
                    >
                        Avbryt
                    </Button>
                </Col>
            </Row>
        </ModalBody>
    </Modal>

);
}
export default ConfirmDeleteModal;