import React, {useState} from "react"
import { useSelector } from "react-redux";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Progress,
    Row,
} from "reactstrap";
import PackageProgress from "./PackageProgress";
import {isInvoiceReceiver, isStudent} from "../../../../../utils/authHelpers";
import "./style.css"
import PackageDropdown from "../../../Management/pages/SalesPackageOverview/PackageDropdown";
import {MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowUp} from "react-icons/md"

const PackageInfoCard = (packageDetails) => {

    const [expanded, setExpanded] = useState(false);

    const user = useSelector(
        (state) => state.currentUser.currentUser.user
    );

    const progress = () => {
        if (packageDetails.packageDetails.remaining_hours == 0 || packageDetails.packageDetails.remaining_hours == null) return {progress: "0%", color: "#4EA8E9"};
        if (packageDetails.packageDetails.is_expired) return {progress: "0%", color: "#CECECEB2"};

        const percentage = packageDetails.packageDetails.remaining_hours / packageDetails.packageDetails.hours_in_package;
        const progress = 94 - (64 * percentage);


        if (percentage <= 0.25) return {progress: progress + "%", color: "#E43C3CB2"};
        if (percentage <= 0.5) return {progress: progress + "%", color: "#E1E43C"};
        return {progress: progress + "%", color: "#4CC029B2"}
    }

    const DropdownStudent = () => {
        return (
            <div style={{marginTop: "20px"}}>
                <Row>
                    <span><b>Dato kjøpt: </b>{new Date(packageDetails.packageDetails.date_sold).toLocaleDateString('en-GB')}</span>
                </Row>
                {
                    packageDetails.packageDetails.is_expired && packageDetails.packageDetails.remaining_hours > 0 ?
                        <Row>
                            <span><b>Dato utløpt: </b>{new Date(packageDetails.packageDetails.expiration_date).toLocaleDateString('en-GB')}</span>
                        </Row>
                        :
                        <></>
                }
                <Row>
                    <span><b>Pris: </b>{packageDetails.packageDetails.sold_for_price} kr</span>
                </Row>
                <Row>
                    <span><b>Fakturamottaker: </b>{packageDetails.packageDetails.invoice_receiver_name}</span>
                </Row>
                <Row>
                    <span><b>Kommentar: </b>{packageDetails.packageDetails.comment_for_invoice}</span>
                </Row>

            </div>
        )
    }

    const DropdownForInvoice = () => {
        return (
            <div style={{marginTop: "20px"}}>
                <Row>
                    <span><b>Dato kjøpt: </b>{new Date(packageDetails.packageDetails.date_sold).toLocaleDateString('en-GB')}</span>
                </Row>
                {
                    packageDetails.packageDetails.is_expired && packageDetails.packageDetails.remaining_hours > 0 ?
                        <Row>
                            <span><b>Dato utløpt: </b>{new Date(packageDetails.packageDetails.expiration_date).toLocaleDateString('en-GB')}</span>
                        </Row>
                        :
                        <></>
                }
                <Row>
                    <span><b>Pris: </b>{packageDetails.packageDetails.sold_for_price} kr</span>
                </Row>
                <Row>
                    <span><b>Elev: </b>{(packageDetails.packageDetails.students.map(s => s.first_name + " " + s.last_name).join(', '))}</span>
                </Row>
                <Row>
                    <span><b>Kommentar: </b>{packageDetails.packageDetails.comment_for_invoice}</span>
                </Row>

            </div>
        )
    }

    const DropdownForInvoiceAndStudent = () => {
        return (
            <div style={{marginTop: "20px"}}>
                <Row>
                    <span><b>Dato kjøpt: </b>{new Date(packageDetails.packageDetails.date_sold).toLocaleDateString('en-GB')}</span>
                </Row>
                {
                    packageDetails.packageDetails.is_expired  && packageDetails.packageDetails.remaining_hours > 0 ?
                        <Row>
                            <span><b>Dato utløpt: </b>{new Date(packageDetails.packageDetails.expiration_date).toLocaleDateString('en-GB')}</span>
                        </Row>
                        :
                        <></>
                }
                <Row>
                    <span><b>Pris: </b>{packageDetails.packageDetails.sold_for_price} kr</span>
                </Row>
                <Row>
                    <span><b>Elev: </b>{(packageDetails.packageDetails.students.map(s => s.first_name + " " + s.last_name).join(', '))}</span>
                </Row>
                <Row>
                    <span><b>Fakturamottaker: </b>{packageDetails.packageDetails.invoice_receiver_name}</span>
                </Row>
                <Row>
                    <span><b>Kommentar: </b>{packageDetails.packageDetails.comment_for_invoice}</span>
                </Row>

            </div>
        )
    }

    const Dropdown = () => {
        if (isInvoiceReceiver(user) && isStudent(user)) return DropdownForInvoiceAndStudent();
        if (isInvoiceReceiver(user)) return DropdownForInvoice();
        return DropdownStudent();
    }


    return (
      <div className="package-info-card" onClick={() => setExpanded(!expanded)}>
          <Row className="flex" >
              <div className="package-info-card-icon">
                  <PackageProgress props={progress()}></PackageProgress>
              </div>

              <div className="package-info-card-header">
                  {
                      packageDetails.packageDetails.is_expired && packageDetails.packageDetails.remaining_hours > 0 ?
                          <span><b>{packageDetails.packageDetails.name}</b>: {packageDetails.packageDetails.hours_in_package} {packageDetails.packageDetails.hours_in_package == 1 ? "time" : "timer"} - Utløpt pakke</span>
                          :
                          <span><b>{packageDetails.packageDetails.name}</b>: {packageDetails.packageDetails.remaining_hours} resterende timer av totalt {packageDetails.packageDetails.hours_in_package}</span>
                  }
              </div>

              <div className="package-info-card-arrow">
                  {
                      expanded ?
                          <MdOutlineKeyboardArrowUp></MdOutlineKeyboardArrowUp>
                          :
                          <MdOutlineKeyboardArrowLeft></MdOutlineKeyboardArrowLeft>
                  }
              </div>
          </Row>
          {
              expanded ?
                  Dropdown()
                  :
                  <></>
          }
      </div>
    );
}

export default PackageInfoCard;