import React, {useMemo} from "react";
import { Row } from "reactstrap";
import LessonRow from "./LessonRow";
import {dateFormatter, timeFormatter} from "../../../../utils/formattingHelpers";
import {GET_PACKAGE_PAGE_SUCCESS, GET_USER_ACTIVITIES_PAGE_SUCCESS} from "../../../../redux/constants";
import PaginatedTable from "../../../../components/PaginatedTable/PaginatedTable";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Link } from "react-router-dom";

import { faEdit, faCircle } from '@fortawesome/free-solid-svg-icons';

import "./styles.css"
import {activityPageBaseUrl, fetchActivityPage} from "../../../../features/activity/activitySlice";

// Returns the a table displaying activities from the perspective of a teacher
// given a list of activities

const TeacherLessonTable = () => {

  const columns = useMemo(
      () => [
          {
              id: "time",
              Header: "Tidspunkt",
              accessor: d => {
                  const date = new Date(d.start_date_time);
                  return `${dateFormatter(date)}, kl: ${timeFormatter(date)}`
              }
          },
        {
          id: "subjects",
          Header: "Fag",
          accessor: "subject_details"
        },
          {
              id: "duration",
              Header: "Varighet",
              accessor: "duration"
          },
          {
              id: "student",
              Header: "Elev",
              accessor: d => d.student_details[0] ? d.student_details[0].name : "Ingen elev deltok"
          },
          {
              id: "status",
              Header: "Status",
              accessor: d => d.summary_date ? <>
                  <FontAwesomeIcon icon={faCircle} color={
                          "#2BA501"
                  }/> Fullført
              </> : new Date(d.start_date_time) > new Date() ?
                  <>
                      <FontAwesomeIcon icon={faCircle} color={
                          "#F87F0F"
                      }/> Planlagt
                  </> : <>
                      <FontAwesomeIcon icon={faCircle} color={
                          "#FC4040"
                      }/> Mangler oppsummering
                  </>
          },
          {
              id: "salary",
              Header: "Grunnlønn (+ reiselønn)",
              accessor: d => d.base_salary + d.travel_salary
          },
          {
              id: "edit",
              Header: "Rediger",
              accessor: d => <Link
                  to={{
                      pathname: "/teacher/activity/" + d.id,
                  }}
              >
                  <FontAwesomeIcon
                      size="lg"
                      icon={faEdit}
                      color={"#3B3A3D"}/>
              </Link>
          }
      ],
      []
  )


  return (
      <div className="nested-table" style={{marginTop: "30px"}}>
          <PaginatedTable
              className="tableContainer"
              columns={columns}
              reducer='activity'
              baseEndpoint={activityPageBaseUrl}
              api={fetchActivityPage}
          />
      </div>
  );
};

export default TeacherLessonTable;
