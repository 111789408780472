import PackageOverview from "./pages/PackageOverview";
import ContactInfo from "./pages/ContactInfo";
import CustomerDashboard from "./pages/CustomerDashboard";
import {STUDENT, CUSTOMER_TYPE, CustomerPaths, INVOICE_RECEIVER} from "./customerConstants";
import { AiFillDashboard } from 'react-icons/ai';
import { RiContactsLine } from "react-icons/ri";
import { FiPackage } from 'react-icons/fi';
import { BsPersonPlusFill, BsFillPersonLinesFill } from 'react-icons/bs';
import {MdPermContactCalendar} from "react-icons/md"
import { faBox, faPen } from '@fortawesome/free-solid-svg-icons';

import StudentOverview from "./pages/StudentOverview";

export const customerHomePath = "/customer/dashboard";

export const customerRoutes = [
    {
        path: CustomerPaths.home,
        name: "Dashboard",
        showOnSidebar: true,
        icon: AiFillDashboard,
        component: CustomerDashboard,
        authCheck: (userState) => 
            userState.type === CUSTOMER_TYPE
    },
    {
        path: CustomerPaths.packageOverview,
        name: "Mine pakker",
        showOnSidebar: true,
        icon: FiPackage,
        component: PackageOverview,
        authCheck: (userState) =>
            userState.type === CUSTOMER_TYPE
    },
    {
        path: CustomerPaths.studentOverview,
        name: "Mine elever",
        showOnSidebar: true,
        icon: BsFillPersonLinesFill,
        component: StudentOverview,
        authCheck: (userState) =>
            userState.user.groups.includes(INVOICE_RECEIVER)
    },
    {
        path: "/customer/contact",
        name: "Kontaktpersoner",
        showOnSidebar: true,
        icon: MdPermContactCalendar,
        component: ContactInfo,
        authCheck: (userState) =>
            userState.user.groups.includes(STUDENT)
    },
];
