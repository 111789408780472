import axiosInstance from "../../axios";
import axios from "axios";
import {
  RESET_PASSWORD_FAIL,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_REQUEST,
} from "../constants";

export const resetPassword = (props) => async (dispatch) => {
  dispatch({ type: RESET_PASSWORD_REQUEST, clear: true });
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}user/password-reset/`,
      {
        email: props.email,
      }
    );

    dispatch({
      type: RESET_PASSWORD_SUCCESS,
      payload: "",
    });
  } catch (error) {
    dispatch({
      type: RESET_PASSWORD_FAIL,
      error:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
