import React, {useMemo} from "react";
import {
    addExpandedPageRowId,
    fetchInvoiceReceiverPage,
    invoiceReceiverPageBaseUrl, removeExpandedPageRowId
} from "./invoiceReceiverSlice";
import PaginatedTable from "../../components/PaginatedTable/PaginatedTable";
import InvoiceReceiverForm from "./InvoiceReceiverForm";

const InvoiceReceiverPaginatedOverview = () => {

    // the dropdown element for the table
    const renderRowSubComponent = (row) => {
        const {
            id,
        } = row.original
        return (
            <>
                <InvoiceReceiverForm invoiceReceiverId={id}/>
            </>
        )
    }

    const columns = useMemo(
        () => [
            {
                Header: () => null,
                id: 'expandedButton', // 'id' is required
                Cell: ({ row }) => (
                    <span>
            {row.isExpanded ? '△' : '▽'}
          </span>
                )
            },
            {
                id: "name",
                Header: "Invoice Receiver",
                accessor: d => d.first_name + " " + d.last_name,
            },
            {
                id: "email",
                Header: "E-post",
                accessor: "email"
            },
            {
                id: "phone_number",
                Header: "Mobilnummer",
                accessor: "phone_number"
            },
        ],
        []
    )

    // render component  reducer : students
    return (
        <>
            <PaginatedTable
                className="tableContainer"
                columns={columns}
                reducer='invoiceReceiver'
                baseEndpoint={invoiceReceiverPageBaseUrl}
                api={fetchInvoiceReceiverPage}
                renderRowSubComponent={renderRowSubComponent}
                addExpandedId={addExpandedPageRowId}
                removeExpandedId={removeExpandedPageRowId}
            />
        </>
    );
};

export default InvoiceReceiverPaginatedOverview
