import {createSlice, isRejectedWithValue} from '@reduxjs/toolkit'

const initialState = {
    title: null,
    message: null,
    status: null,
    showAlert: false
}

export const errorSlice = createSlice({
    name: 'error',
    initialState,
    reducers: {
        add(state, action) {
            state.title = action.payload.title
            state.message = action.payload.message
            if (action.payload.status) {
                state.status = action.payload.status
            }
            state.showAlert = true
        },
        clear(state){
            state.showAlert = false
            state.title = null
            state.message = null
            state.status = null
        }
    },
    extraReducers: (builder) => {
        builder.addMatcher(isRejectedWithValue, (state, action) => {
            state.title = "Error"

            // In some backend error messages, an object is returned
            if (action.payload.message instanceof Object) {
                let s = "";
                for (const key in action.payload.message) {
                    s += action.payload.message[key].toString()
                }
                state.message = s
            } else {
                state.message = action.payload.message
            }
            state.status = action.payload.status
            state.showAlert = true
        })
    }
})

export const { add, clear } = errorSlice.actions
export default errorSlice.reducer