import React from "react";
import { Button } from 'react-bootstrap';

//Returns a row for the tables that display students in teacher profile,
const OrderConfirmationRow = (props, handleDelete, handleView, handleDeletePackage) => {
    return (
        <tr key={props.key}>
            <td>{props.invoice_receiver}</td>
            <td>{props.students.map((student, key) =>
                <div key={key}>
                    {props.students.indexOf(student) + 1 === props.students.length ? student : student + ","}
                </div>
                )}
            </td>
            <td>{props.date_sold}</td>
            <td>{props.package}</td>
            <td>{props.new}</td>
            <td>
              <div className="p-1">
                <Button onClick={() => handleView(props.key)}
                        className="button darkButton m-1"
                        style={{width: "240px", height: "25px"}}>
                    Se Ordrebekreftelse
                </Button>
                <Button onClick={() => handleDelete(props.key)}
                        className="button redButton m-1"
                        style={{width: "240px", height: "25px"}}>
                    Slett Ordrebekreftelse
                </Button>
                <Button onClick={() => handleDeletePackage(props.packageID)}
                        className="button redButton m-1"
                        style={{width: "240px", height: "25px"}}>
                    Slett Pakke
                </Button>
              </div>
            </td>
        </tr>
    );
};

export default OrderConfirmationRow;
