export default (userState) => {
    for (let key in userState.user) {
        if (userState.user.hasOwnProperty(key)) {
            const requiredFields = [
                "first_name",
                "last_name",
                "birth_date",
                "phone_number",
                "address",
                "post_code",
                "post_code_area",
                "bank_account_number",
                "heard_about_rh",
                "social_security"

            ]
            if (!userState.user[key] && requiredFields.includes(key)) {
                return true;
            }
        }
    }
    return false;
}