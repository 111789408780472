import React from "react"
import {useDispatch} from "react-redux";
import {Scheduler} from "./lib";
import {deleteUserEvent, getUserEvents, patchUserEvent} from "./scheduleSlice";
import UserScheduleEditor from "./UserScheduleEditor";
import Event from "./Event"
import "./schedulerStyle.css"


const UserSchedule = ({userId, canChange}) => {
    const timeInterval = {startHour: 8, endHour: 21}
    const dispatch = useDispatch()

    return (
        <>
            <Scheduler
                view={"week"}
                month={timeInterval}
                week={timeInterval}
                day={{...timeInterval, navigation: true}}
                translations={{
                    navigation: { month: "Måned", week: "Uke", day: "Dag", today: "I dag"},
                    event: {title: "Tittel", start: "Start", end: "Slutt", allDay: "Heldags"}, loading: "Laster..."
                }}
                hourFormat="24"
                eventRenderer={(event) => <Event event={event} />}
                getRemoteEvents={async ({start, end}) => {
                    try {
                        return await dispatch(getUserEvents({
                            id: userId, start: start, end: end, canChange: canChange
                        })).unwrap()
                    } catch (e) {
                        return []
                    }
                }}
                customEditor={(scheduler) => <UserScheduleEditor userId={userId} scheduler={scheduler} />}
                onDelete={ async (
                    recurringEvent, deletedParentId, deleteDecision,
                    newRecurrenceString
                ) => {
                    try {
                        if (recurringEvent === true) {
                            if (deleteDecision !== "ALL_EVENTS") {
                                await dispatch(patchUserEvent({
                                    eventId: deletedParentId,
                                    eventPatch: {recurrences: newRecurrenceString}
                                }))
                                return true
                            }
                        }
                        await dispatch(deleteUserEvent({eventId: deletedParentId}))
                        return true
                    } catch (e) {
                        return false
                    }
                }}
                onEventDrop={async (droppedOn, updatedEvent, originalEvent) => {
                    try {
                        await dispatch(patchUserEvent({
                            eventId: updatedEvent.event_id,
                            eventPatch: {start: updatedEvent.start, end: updatedEvent.end}
                        })).unwrap()
                        return updatedEvent
                    } catch (e) {
                        return originalEvent
                    }
                }}
            />
        </>
    )
}

export default UserSchedule;
