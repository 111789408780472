import React from "react";

import Wrapper from "../../components/Wrapper";
import Sidebar from "../../components/Sidebar";
import Main from "../../components/Main";
import Navbar from "../../components/Navbar";
import Content from "../../components/Content";
import Footer from "../../components/Footer";
import { useSelector } from "react-redux";
import AlertComponent from "../../features/error/AlertComponent";

export default ({ children }) => {

  return (
    <React.Fragment>
      <Wrapper>
        <Sidebar />
        <Main>
          <Navbar />
          <AlertComponent />
          <div>
            <Content>{children}</Content>
          </div>
          <Footer />
        </Main>
      </Wrapper>
    </React.Fragment>
  );
};
