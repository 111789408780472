// Type
export const CUSTOMER_TYPE = "customer";

// Auth groups
export const STUDENT = "User Conducts Activities (Student)";
export const INVOICE_RECEIVER = "User Receives Invoices (Invoice Receiver)"

// Customer Paths
export const CustomerPaths = {
    home: "/customer/dashboard",
    packageOverview: "/customer/package-overview",
    studentOverview: "/customer/student-overview",
    contactInfo: "/customer/contact"
}
