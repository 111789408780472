import React from "react";
import DoughnutChart from "./Doughnut";
const DoughnutWithInnerText = (labels, categories, text, subtext) => {
  return (
    <div style={{ width: "100%", height: "fitToContent", margin: "0" }}>
      <div className="fullFlexCentre" style={{ height: "100%" }}>
        {DoughnutChart(labels, categories)}
      </div>
      <div className="fullFlexCentre" style={{flexDirection:"column"}} id="doughnutTextPos" >
        <div className="doughnutText" style={{ color:text.color}}>{text.text}</div>
        <div className="smallText">{subtext?subtext:<br></br>}</div>
      </div>
    </div>
  );
};

export default DoughnutWithInnerText;
