import React from "react";
import cross from "../../../../assets/img/RH/cross.png";

// Returns a checkbox that looks like a button
const CheckButton = (key, text, value, checked, crossBool, func, preferred) => {
  const className =
    "button darkButton "
  return (
    <button
      key={key}
      value={value}
      className={className}
      //selected={false}
      onClick={func}
      style={{
        width: "150px",
        margin: 10,
        backgroundColor: preferred ? "#6FA65D" : "#CFDEF3",
        color: preferred ? "white" : "black",
        borderColor:  preferred ? "#6FA65D" : "#CFDEF3"
      }}
    >
      {text}
      {/*crossBool ? <img src={cross} style={{ marginLeft: "20px" }}></img> : ""*/}
    </button>
  );
};

export default CheckButton;
