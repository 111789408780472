import {
  GET_CONTACT_FAIL,
  GET_CONTACT_REQUEST,
  GET_CONTACT_SUCCESS,
} from "../constants";
import store from "../store";

const initialState = {
  loading: false,
  supervisor: null,
  allTeachersForStudent: [],
};

export const employeeReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CONTACT_REQUEST:
      return { ...state, loading: true, allTeachersForStudent: [] };

    case GET_CONTACT_SUCCESS:
      return {
        ...state,
        loading: false,
        supervisor: action.payload.contact_person,
        allTeachersForStudent: action.payload.teachers,
      };

    case GET_CONTACT_FAIL:
      return { ...state, loading: false };

    default:
      return state;
  }
};