import React from "react";
import { Form, Input, Row } from "reactstrap";
import arrow from "../assets/img/RH/Pil_ned.png";

function TimeInput(
  starttime,
  updateField,
  increment,
  decrement,
  format,
  clear,
  disabled,
  inValid = false
) {
  return (
    <div
      className="input_container"
      style={{ display: "flex", flexDirection: "row" }}
    >
      <Input
        type="number"
        name="timeinput"
        rows="1"
        value={starttime}
        className="inputField timeinput"
        onChange={updateField}
        onBlur={format}
        onClick={disabled ? null : clear}
        invalid={inValid}
        disabled={disabled}
        required
      />
      <Form className="input_img">
        <Row>
          <img
            src={arrow}
            onClick={disabled ? null : increment}
            className="rotate180"
            alt="arrow"
          />
        </Row>
        <Row>
          <img
            style={{ marginTop: "8px" }}
            src={arrow}
            onClick={disabled ? null : decrement}
            alt="arrow"
          />
        </Row>
      </Form>
    </div>
  );
}

export default TimeInput;
