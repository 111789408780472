import { createSlice, createAsyncThunk} from "@reduxjs/toolkit"
import axiosInstance from "../../axios";
import PaginatedService from "../../services/PaginatedService";

const initialState = {
    loading: 'idle',    //  'idle' | 'pending' | 'succeeded' | 'failed'
    currentStudent: null,
    entities: [],
    count: null,
    page: [],
    pageIndex: 0,
    nextPage: null,
    previousPage: null,
}

export const studentPageBaseUrl = "user/list-students/"
export const fetchStudentPage = createAsyncThunk(
    "student/fetchStudentPage", PaginatedService.getPage
)

// ToDo: turn into standard fetch thunk like that we have in
//  invoice receivers and activities. Should probably
//  create pagination in the student overview on teacher
//  and invoice receiver dashboards
export const fetchStudentsWithSearch = createAsyncThunk(
    "student/fetchWithSearch",
    async (arg, thunkAPI) => {
        try {
            const response = await axiosInstance.get(
                "/user/list-students/?search=" + arg.search + (arg.filter ? "&" + arg.filter : "")
            )
            return response.data
        } catch (err) {
            if (err.response) {
                return thunkAPI.rejectWithValue({status: err.response.status, message: err.response.data})
            }
            return thunkAPI.rejectWithValue({status: "", message: err.message})
        }
    }
)

export const studentSlice = createSlice({
    name: "student",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchStudentsWithSearch.pending, (state, action) => {
            state.loading = 'pending'
        }).addCase(fetchStudentsWithSearch.fulfilled, (state, action) => {
            state.loading = 'success'
            state.entities = action.payload
        }).addCase(fetchStudentsWithSearch.rejected, (state, action) => {
            state.loading = 'failed'
        }).addCase(fetchStudentPage.pending, (state, action) => {
            state.loading = "pending"
        }).addCase(fetchStudentPage.fulfilled, (state, action) => {
            state.loading = "success"
            PaginatedService.renderFulfilled(state, action)
        }).addCase(fetchStudentPage.rejected, (state, action) => {
            state.loading = "failed"
        })
    }
})

export default studentSlice.reducer