import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, Link, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import validator from "validator";
import axiosInstance from "../../../../../axios.js";
import TimeInput from "../../../../../components/TimeInput";
import { postActivity } from "../../../../../redux/actions/addActivity";
import { getActivities } from "../../../../../redux/actions/getActivities.js";
import { patchActivity } from "../../../../../redux/actions/editActivity.js";
import { deleteActivity } from "../../../../../redux/actions/deleteActivity.js";
import { setError } from "../../../../../redux/actions/errorActions";
import { getStudents } from "../../../../../redux/actions/getStudents";
import Rad from "../../components/RadioWithInnerText";
import ModalButton from "../../components/RegisterNewCourseModal";
import Loader from "../../../../../components/Loader";
import { Multiselect } from "multiselect-react-dropdown";
import {
  getCommonSubjects,
  getSubjectIntersection,
} from "../../utils/subjectHelper";
import {destroy} from "../../../../../features/activity/activitySlice";

const ActivityForm = () => {
  const initialActivityState = {
    fields: {
      category: "",
      students: [],
      subject: "",
      date: "",
      start_time_hr: "00",
      start_time_min: "00",
      duration_hr: "00",
      duration_min: "00",
      travel_time: "",
      place: "",
      plan: "",
      summary_teacher: "",
      summary_student: "",
      homework: "",
      notes: null,
    },

    errors: {
      category: "",
      students: "",
      subjects: "",
      subject: "",
      date: "",
      start_time: "",
      duration: "",
      travel_time: "",
      place: "",
      plan: "",
      summary_student: "",
      summary_teacher: "",
      homework: "",
      notes: "",
    },

    edited: {
      category: false,
      students: false,
      subjects: false,
      subject: false,
      time: false,
      duration: false,
      travel_time: false,
      place: false,
      plan: false,
      summary_teacher: false,
      summary_student: false,
      homework: false,
      notes: false,
    },
  };

  /* MODES */

  /* Possible modes */
  const NEW = "NEW";
  const EDIT = "EDIT";

  const [mode, setMode] = useState({ title_text: "Ny", mode: NEW });

  /* HANDLE LOCAL STATE */
  const activityID = useParams().id;

  /* Message status */
  const SAVED = "SAVED";
  const PATCHED = "PATCHED";
  const DELETED = "DELETED";

  const [message_status, setMessage] = useState("");

  /* Decides whether fields should be disabled when teacher has been paid */
  const [disabledFields, setDisabled] = useState(false);
  const [disabledMessage, setDisabledMessage] = useState("");

  const [activity, setActivity] = useState(initialActivityState);

  const activityHandler = (field, value) => {
    /* Exception cases: */
    let errorField = field;
    if (field === "start_time_hr" || field === "start_time_min") {
      errorField = "start_time";
    } else if (field === "duration_hr" || field === "duration_min") {
      errorField = "duration";
    }
    let editField = field;
    if (
      field === "start_time_hr" ||
      field === "start_time_min" ||
      field === "date"
    ) {
      editField = "time";
    } else if (field === "duration_hr" || field === "duration_min") {
      editField = "duration";
    }

    // Date and time resets summary error
    if (
      field === "start_time_hr" ||
      field === "start_time_min" ||
      field === "date"
    ) {
      setActivity((prevState) => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          summary_student: "",
          summary_teacher: "",
        },
      }));
    }

    setActivity((prevState) => ({
      ...prevState,
      fields: {
        ...prevState.fields,
        [field]: value,
      },
      errors: {
        ...prevState.errors,
        [errorField]: "",
      },
      edited: {
        ...prevState.edited,
        [editField]: true,
      },
    }));
  };

  /* Set state to the same, except for error.
  There you set the field to the error message. */
  const errorHandler = (field, errormsg) => {
    setActivity((prevState) => ({
      ...prevState,
      errors: {
        ...prevState.errors,
        [field]: errormsg,
      },
    }));
  };

  /* Decides whether modal with message should be visible to user */
  const [infoOpen, setOpen] = useState(false);

  const toggle = () => {
    setOpen(!infoOpen);
    if (mode.mode === NEW) {
      clearPage();
    }
  };

  /* Set when form is submitted to know if modal should be shown */
  const [submitted, setSubmitted] = useState(false);
  const [deleted, setDeleted] = useState(false);

  /* Was summarized is true if the activity has previously saved a summary,
   and should therefore not set a summary date */
  const [wasSummarized, setSummarized] = useState(false);

  /* Adds fields from the loaded activity to local state */
  const updateActivityFromDB = () => {
    let act = activities.find((s) => s.id.toString() === activityID);

    let dateObj = new Date(act.start_date_time);
    let date = act.start_date_time.split("T")[0];
    let starthr = dateObj.getHours().toString();
    let startmin = (dateObj.getMinutes() < 10 ? "0" : "") +  dateObj.getMinutes().toString()

    let durhr = Math.floor(act.duration);
    let min = ((act.duration - durhr) * 60).toString();
    let durmin = "" + min === "0" ? "00" : min;

    // Update both ref and state with student data
    activityHandler("students", act.students);
    selectedStudents.current = {
      students: act.students,
      student_details: act.student_details,
    };

    activityHandler("category", act.activity_category);
    activityHandler("subject", act.subject);
    activityHandler("date", date);
    activityHandler("start_time_hr", starthr);
    activityHandler("start_time_min", startmin);
    activityHandler("duration_hr", durhr);
    activityHandler("duration_min", durmin);
    activityHandler("travel_time", act.travel_time);
    activityHandler("place", act.activity_location);
    activityHandler("plan", act.activity_plan);
    activityHandler("summary_teacher", act.summary_teacher);
    activityHandler("summary_student", act.summary_student);
    activityHandler("homework", act.homework);
    activityHandler("notes", act.notes);
    setAllEditedFalse();

    // This Activity has previously been summarized:
    if (act.summary_date != null) {
      setSummarized(true);
    }
    // Teacher has been paid or activity invoice have been sent
    // and not all fields can be edited:
    if (act.teacher_payment_date != null) {
      disableFields();
      setDisabledMessage(
        "NB: Kun enkelte felter kan redigeres fordi " +
          "du allerede har fått utbetalt lønn for denne aktiviteten." +
          " Aktiviteten kan ikke slettes"
      );
    } else if (
      act.activity_invoices.find((invoice) => invoice.invoice_sent_date)
    ) {
      disableFields();
      setDisabledMessage(
        "NB: Kun enkelte felter kan redigeres fordi " +
          "aktiviteten allerede er fakturert." +
          " Aktiviteten kan ikke slettes"
      );
    }
    disableFields("category");
    disableFields("students_input");
  };

  /* REDUX */

  const dispatch = useDispatch();

  /* Stores locally what is collected from redux */
  const studentsList = useSelector((state) => state.students);

  const [studentsLoading, students] = [
    studentsList.loading,
    studentsList.allStudentsForUser,
  ];

  const initialSelectedStudentState = {
    students: [], // List of ids
    student_details: [], // List of objects {id: 1, name: "First Last"}
  };

  /* We need both ref and state to update multi select properly
   *   if we only use ref, the page will not rerender when students are selected
   *   if we only use state, we cannot update selectedValues in Multi select
   *   properly
   * */
  const selectedStudents = useRef(initialSelectedStudentState);
  const disableStudents = useRef(false);

  const currentUser = useSelector((state) => state.currentUser.currentUser);
  const [teacherSubjects] = [currentUser.user.subjects];

  const activitiesList = useSelector((state) => state.userActivities);
  const [activityLoading, activities] = [
    activitiesList.loading,
    activitiesList.userActivities,
  ];

  const [categories, setCategories] = useState([]);
  const [categoriesLoading, setCategoriesLoading] = useState(false);

  const [places, setPlaces] = useState([]);
  const [placesLoading, setPlacesLoading] = useState(false);

  const location = useLocation();

  /* Used to see if dispatches have been run for the first time.
  Not started fetching: null,
  Started fetching but no result: false,
  done loading: true */
  // TODO: this should be cleaned up and moved to redux
  const [categoriesHaveLoaded, setCategoriesLoaded] = useState(null);
  const [placesHaveLoaded, setPlacesLoaded] = useState(null);
  const [studentsHaveLoaded, setStudentsLoaded] = useState(null);
  const [activitiesHaveLoaded, setActivitiesLoaded] = useState(null);
  const [doneInitialLoading, setDoneInitialLoading] = useState(false);

  /* Variables for student id and student name for
  when you create an activity from a student */
  const { id, name } = location.state ? location.state : { id: null, name: "" };

  /* Makes api calls */
  // TODO: these api calls should be made by redux
  async function getCategories() {
    try {
      setCategoriesLoading(true);
      const { data } = await axiosInstance.get("/activity/activity-categories");
      setCategoriesLoading(false);
      setCategories(data);
    } catch (err) {
      dispatch(setError(err.message));
    }
  }

  async function getPlaces() {
    try {
      setPlacesLoading(true);
      const { data } = await axiosInstance.get("/activity/activity-locations");
      setPlacesLoading(false);
      setPlaces(data);
    } catch (err) {
      dispatch(setError(err.message));
    }
  }

  /* Functions that are called as you load the page */
  useEffect(() => {
    getCategories();
    dispatch(getStudents());

    getPlaces();
    if (typeof activityID == "undefined") {
      setMode({ title_text: "Ny", mode: NEW });
      clearPage();
      if (id != null && id != "") {
        activityHandler("student", id);
        activityHandler("student_name", name);
      }
    } else {
      /* If activities in redux is in its initial state: */
      if (activities[0]) {
        dispatch(getActivities());
      }

      setMode({ title_text: "Rediger", mode: EDIT });
    }
  }, []);

  /* Functions that are called after successful submit */
  useEffect(() => {
    if (!activityLoading && submitted) {
      setSubmitted(false);
      setOpen(true);
      dispatch(getActivities());
    }
  }, [!activityLoading && submitted]);

  /* Functions that are called after activities are loaded */
  useEffect(() => {
    if (!activityLoading && mode.mode === EDIT) {
      updateActivityFromDB();
    }
  }, [activityLoading]);

  /* Functions that are called after successful delete */
  useEffect(() => {
    if (!activityLoading && deleted) {
      setDeleted(false);
      setOpen(true);
    }
  }, [!activityLoading && deleted]);

  /* Checks that initial loading is done before 1st render. */
  useEffect(() => {
    if (doneInitialLoading) {
      return;
    }
    if (mode.mode === NEW) {
      setDoneInitialLoading(true);
      return;
    }
    if (
      placesHaveLoaded &&
      activitiesHaveLoaded &&
      studentsHaveLoaded &&
      categoriesHaveLoaded
    ) {
      setDoneInitialLoading(true);
      return;
    }

    if (placesHaveLoaded == null && placesLoading) {
      setPlacesLoaded(false);
    }
    if (placesHaveLoaded === false && !placesLoading) {
      setPlacesLoaded(true);
    }

    if (activitiesHaveLoaded == null && activityLoading) {
      setActivitiesLoaded(false);
    }
    if (activitiesHaveLoaded === false && !activityLoading) {
      setActivitiesLoaded(true);
    }

    if (studentsHaveLoaded == null && studentsLoading) {
      setStudentsLoaded(false);
    }
    if (studentsHaveLoaded === false && !studentsLoading) {
      setStudentsLoaded(true);
    }

    if (categoriesHaveLoaded == null && categoriesLoading) {
      setCategoriesLoaded(false);
    }
    if (categoriesHaveLoaded === false && !categoriesLoading) {
      setCategoriesLoaded(true);
    }
  }, [placesLoading, activityLoading, studentsLoading, categoriesLoading]);

  /* Send Activity to backend */
  const registerActivity = () => {
    let valid = validateInput();

    if (!valid) window.scroll(0, 0);
    else {
      let startdatetime =
        "" +
        activity.fields.date +
        "T" +
        activity.fields.start_time_hr +
        ":" +
        activity.fields.start_time_min;
      let duration =
        parseFloat(activity.fields.duration_hr) +
        parseFloat(activity.fields.duration_min) / 60;
      let travel_time = activity.fields.travel_time;
      if (travel_time === "") {
        travel_time = 0;
      }
      travel_time = parseInt(travel_time);
      let a = {};
      if (mode.mode === NEW) {
        a = {
          activity_category: activity.fields.category,
          students: activity.fields.students,
          start_date_time: new Date(startdatetime).toISOString(),
          duration: duration,
          activity_plan: activity.fields.plan,
          subject: activity.fields.subject,
          travel_time: travel_time,
          activity_location: activity.fields.place,
          summary_teacher: activity.fields.summary_teacher,
          summary_student: activity.fields.summary_student,
          homework: activity.fields.homework,
          notes: activity.fields.notes,
        };
        if (shouldSetDate()) {
          a.summary_date = new Date().toJSON().slice(0, 10);
        }

        dispatch(postActivity(a));
        setMessage(SAVED);
      } else if (mode.mode === EDIT) {
        if (activity.edited.category)
          activity.activity_category = activity.fields.category;
        if (activity.edited.students) a.students = activity.fields.students;
        if (activity.edited.time) a.start_date_time = new Date(startdatetime).toISOString();
        if (activity.edited.duration) a.duration = duration;
        if (activity.edited.travel_time) a.travel_time = travel_time;
        if (activity.edited.plan) a.activity_plan = activity.fields.plan;
        if (activity.edited.subject) a.subject = activity.fields.subject;
        if (activity.edited.location)
          a.activity_location = activity.fields.place;
        if (activity.edited.summary_teacher)
          a.summary_teacher = activity.fields.summary_teacher;
        if (activity.edited.summary_student)
          a.summary_student = activity.fields.summary_student;
        if (activity.edited.homework) a.homework = activity.fields.homework;
        if (activity.edited.notes) a.notes = activity.fields.notes;
        if (shouldSetDate()) {
          a.summary_date = new Date().toJSON().slice(0, 10);
        }

        dispatch(patchActivity(a, activityID));
        setMessage(PATCHED);
      } else {
      }
      setSubmitted(true);
    }
  };

  /* Deletes Activity */
  const removeActivity = () => {
    dispatch(destroy({id: activityID}));
    setMessage(DELETED);
    setDeleted(true);
  };

  /*  VALIDATION AND ERROR HANDLING */

  /* Print error message */
  const printErrormsg = (field) => {
    return activity.errors[field] === "" ? (
      ""
    ) : (
      <p style={{ color: "red" }}>{activity.errors[field]}</p>
    );
  };

  /* Checks if a string is an integer */
  function isInt(str) {
    return !isNaN(str) && Number.isInteger(parseFloat(str));
  }

  /* Validates input */
  const validateInput = () => {
    let valid = true;

    /* Category */
    if (
      activity.fields.category === "" ||
      activity.fields.category === undefined
    ) {
      errorHandler("category", "Kategori ikke valgt");
      valid = false;
    }

    /* Students:
    *   Activities should have students, unless the activity category has
    *   connected_to_student = false
    *  */
    if (activity.fields.students.length <= 0 ) {
      let currentCategory = getCurrentCategory()

      if (currentCategory) {
        if (currentCategory.connected_to_student) {
          errorHandler("students", "Elev ikke valgt");
          valid = false;
        }
      }
    }

    // Group activity
    if (activity.fields.students.length > 1) {
      let package_ids = [];

      // Finding all packages
      activity.fields.students.forEach((id) =>
        package_ids.push(students.find((s) => s.id === id).packages)
      );
      // Finding intersection
      package_ids = package_ids.reduce((p1, p2) =>
        p1.filter((p) => p2.includes(p))
      );
      if (package_ids.length <= 0) {
        errorHandler("students", "Elevene har ingen felles gruppetime pakke");
        valid = false;
      }
    }

    /* Subject */
    if (
      activity.fields.subject === "" ||
      activity.fields.subject === undefined
    ) {
      errorHandler("subject", "Fag ikke valgt");
      valid = false;
    }

    /* Date */
    if (activity.fields.date === "" || activity.fields.date === undefined) {
      errorHandler("date", "Dato ikke valgt");
      valid = false;
    }

    if (!validator.isDate(activity.fields.date)) {
      errorHandler("date", "Valgt dato er ikke gyldig");
      valid = false;
    }

    /* Time */
    if (
      activity.fields.start_time_hr === "" ||
      activity.fields.start_time_min === "" ||
      activity.fields.start_time_hr === undefined ||
      activity.fields.start_time_min === undefined
    ) {
      errorHandler("start_time", "Ugyldig klokkeslett");
      valid = false;
    }

    if (
      activity.fields.start_time_hr < 0 ||
      activity.fields.start_time_hr > 23 ||
      activity.fields.start_time_min < 0 ||
      activity.fields.start_time_min > 59
    ) {
      errorHandler("start_time", "Ugyldig klokkeslett");
      valid = false;
    }

    if (
      !isInt(activity.fields.start_time_hr) ||
      !isInt(activity.fields.start_time_min)
    ) {
      errorHandler("start_time", "Klokkeslett må bestå av heltall");
      valid = false;
    }

    /* Duration */
    if (
      activity.fields.duration_hr === "" ||
      activity.fields.duration_min === "" ||
      activity.fields.duration_hr === undefined ||
      activity.fields.duration_min === undefined
    ) {
      errorHandler("duration", "Ugyldig varighet");
      valid = false;
    }

    if (
      activity.fields.duration_hr < 0 ||
      activity.fields.duration_hr > 99 ||
      activity.fields.duration_min < 0 ||
      activity.fields.duration_min > 59
    ) {
      errorHandler("duration", "Ugyldig varighet");
      valid = false;
    }

    if (
      !isInt(activity.fields.duration_hr) ||
      !isInt(activity.fields.duration_min)
    ) {
      errorHandler("duration", "Varighet må bestå av heltall");
      valid = false;
    }

    let validMins = ["00", "15", "30", "45"];
    if (!validMins.includes(activity.fields.duration_min)) {
      errorHandler("duration", "Varighet kan kun velges til nærmeste kvarter");
      valid = false;
    }

    if (
      activity.fields.duration_hr === "00" &&
      activity.fields.duration_min === "00"
    ) {
      errorHandler("duration", "Varighet kan ikke være null");
      valid = false;
    }

    /* Travel */
    if (
      activity.fields.travel_time < 0 ||
      activity.fields.travel_time === undefined ||
      (!isInt(activity.fields.travel_time) &&
        activity.fields.travel_time !== "")
    ) {
      errorHandler("travel_time", "Reisevei kan bare være positive heltall");
    }

    /* Place */
    if (activity.fields.place === "" || activity.fields.place === undefined) {
      errorHandler("place", "Sted ikke valgt");
      valid = false;
    }

    /* Plan */
    if (activity.fields.plan === "" || activity.fields.plan === undefined) {
      errorHandler("plan", "Plan må fylles inn");
      valid = false;
    }

    /* Summaries:
    *   Checks if you try to set the summary field to empty after it has been
    *   stored with a value previously.
    * */
    if (
      (activity.fields.summary_student == null ||
        activity.fields.summary_student === "") &&
      wasSummarized
    ) {
      errorHandler(
        "summary_student",
        "Du kan ikke ha en tom oppsummering når den tidligere har vært fylt inn."
      );
      valid = false;
    }
    if (
      (activity.fields.summary_teacher == null ||
        activity.fields.summary_teacher === "") &&
      wasSummarized
    ) {
      errorHandler(
        "summary_teacher",
        "Du kan ikke ha en tom oppsummering når den tidligere har vært fylt inn."
      );
      valid = false;
    }

    // Checks if you try to summarize an activity that has not happened yet.
    let startdatetime =
      "" +
      activity.fields.date +
      "T" +
      activity.fields.start_time_hr +
      ":" +
      activity.fields.start_time_min;
    if (
      activity.fields.summary_student != null &&
      activity.fields.summary_student !== "" &&
      new Date(startdatetime) > new Date()
    ) {
      errorHandler(
        "summary_student",
        "Du kan ikke sette oppsummering på en økt som er frem i tid."
      );
      valid = false;
    }
    if (
      activity.fields.summary_teacher != null &&
      activity.fields.summary_teacher !== "" &&
      new Date(startdatetime) > new Date()
    ) {
      errorHandler(
        "summary_teacher",
        "Du kan ikke sette oppsummering på en økt som er frem i tid."
      );
      valid = false;
    }

    return valid;
  };

  /* Checks if summary_date should be set */
  function shouldSetDate() {
    /* Case 1 New Activity with summary should set summary_date */
    if (mode.mode === NEW) {
      if (
        activity.fields.summary_student != null &&
        activity.fields.summary_student !== "" &&
        activity.fields.summary_teacher != null &&
        activity.fields.summary_teacher !== ""
      ) {
        return true;
      }
      /* Case 2 New Activity without summary should not set summary_date */
      return false;
    } else if (mode.mode === EDIT) {
      /* Case 3 Edit Activity that has previously been summarized should not set summary_date */
      if (wasSummarized) {
        return false;
      }
      /* Case 4 Edit Activity has at least one of the summary fields empty. It should not set summary_date */
      if (
        activity.fields.summary_student == null ||
        activity.fields.summary_student === "" ||
        activity.fields.summary_teacher == null ||
        activity.fields.summary_teacher == ""
      ) {
        return false;
      }
      /* Case 5 Edit Activity with both summaries set and not previously been summarized */
      return true;
    }

    return false;
  }

  /* HELP FUNCTIONS */

  /* When you press arrow up, you go up to closest 15 min above */
  const incrementMinute = (field) => {
    let oldMin = Number(activity.fields[field]);
    let newValue = (oldMin + 15 - (oldMin % 15)) % 60;
    activityHandler(field, newValue < 10 ? "0" + newValue : String(newValue));
  };

  /* When you press arrow down, you go down to closest 15 min below */
  const decrementMinute = (field) => {
    let oldMin = Number(activity.fields[field]);
    let newValue =
      oldMin == 0
        ? 45
        : (oldMin % 15 == 0 ? oldMin - 15 : oldMin - (oldMin % 15)) % 60;
    activityHandler(field, newValue < 10 ? "0" + newValue : String(newValue));
  };

  /* Stores minute in correct format */
  const formatMinute = (field) => {
    let minute = Number(activity.fields[field]);
    activityHandler(field, minute < 10 ? "0" + minute : String(minute));
  };

  /* When you press arrow up, you increment hour up to 23 */
  const incrementHour = (field) => {
    let oldHr = Number(activity.fields[field]);
    let maxvalue = field == "duration_hr" ? 100 : 24;
    let newValue = (oldHr + 1) % maxvalue;
    activityHandler(field, newValue < 10 ? "0" + newValue : String(newValue));
  };

  /* When you press arrow down, you decrement hour down to 0 */
  const decrementHour = (field) => {
    let oldHr = Number(activity.fields[field]);
    let maxvalue = field == "duration_hr" ? 99 : 23;
    let newValue = oldHr == 0 ? maxvalue : oldHr - 1;
    activityHandler(field, newValue < 10 ? "0" + newValue : String(newValue));
  };

  /* Stores hour in correct format */
  const formatHour = (field) => {
    let hr = Number(activity.fields[field]);
    activityHandler(field, hr < 10 ? "0" + hr : String(hr));
  };

  // Stores student in the right format for multi select
  const formatStudent = (student) => {
    if (student === null) {
      return { id: "", name: "" };
    }
    return {
      id: student.id,
      name: student.first_name + " " + student.last_name,
    };
  };

  /*
  Disables fields that should not be able to edit when teacher has been paid
  or the activity has been already invoiced
   */

  const disableFields = (field = null, disable = true) => {
    if (field === "students_input") {
      document.getElementById("students_input").disabled = disable;
      disableStudents.current = disable;
    } else if (field !== null) {
      document.getElementById(field).disabled = disable;
    } else {
      setDisabled(disable);
      document.getElementById("category").disabled = disable;
      disableStudents.current = disable;
      document.getElementById("date").disabled = disable;
      document.getElementById("travel").disabled = disable;
    }
  };

  /* Sets location. If it is set to Campus or Online tutoring travel time is forced to be 0 and disabled. */
  const handleLocationChange = (place) => {
    activityHandler("place", place);
    if (place === "INTERNET" || place === "CAMPUS") {
      activityHandler("travel_time", 0);
      document.getElementById("travel").disabled = true;
    } else {
      document.getElementById("travel").disabled = false;
    }
  };

  /* A help function to set all edit fields to false after an activity has been
   loaded from db, but before user had changed anything */
  function setAllEditedFalse() {
    let newEdited = {};
    Object.keys(activity.edited).forEach((key) => (newEdited[key] = false));
    setActivity((prevState) => ({
      ...prevState,
      edited: newEdited,
    }));
  }

  /* FUNCTIONS THAT RENDER SOMETHING ON THE PAGE */

  /* Clears everything from form and renders new form */
  function clearPage() {
    Array.from(document.querySelectorAll("input")).forEach(
      (input) => (input.value = "")
    );
    Array.from(document.querySelectorAll("textarea")).forEach(
      (input) => (input.value = input.placeholder)
    );
    Array.from(document.querySelectorAll("select")).forEach(
      (input) => (input.value = input.defaultValue)
    );
    setActivity(initialActivityState);
    selectedStudents.current = initialSelectedStudentState;
    disableStudents.current = false;
  }

  /* Renders an input field for hour or minute */
  const getTime = (name, increment, decrement, format) => {
    return TimeInput(
      activity.fields[name],
      (e) => {
        activityHandler(name, e.target.value);
      },
      increment,
      decrement,
      format,
      (e) => {
        activityHandler(name, "");
      },
      disabledFields,
      activity.errors.start_time !== ""
    );
  };


  /* Finds detailed info about:
  *  - the activity category that is currently selected (default)
  *  - a specific activity category
  * */
  const getCurrentCategory = (category_id = null) => {
    if (category_id == null) {
      return categories.find(
          (category) => parseInt(category.id) === parseInt(activity.fields.category)
      );
    }
    return categories.find(
      (category) => parseInt(category.id) === parseInt(category_id)
    );
  }

  /* Renders a message modal */
  function message() {
    let msg = "";
    let header = "";
    let buttonMsg = "";
    let button = (buttonMsg) => (
      <div style={{ justifyContent: "center", display: "flex" }}>
        <Button
          className="button mediumButton"
          onClick={toggle}
          style={{ marginRight: "25px", float: "right" }}
        >
          {buttonMsg}
        </Button>
        <Link to="/teacher/dashboard">
          <Button
            className="button mediumButton"
            onClick={toggle}
            style={{ marginRight: "5px", float: "right" }}
          >
            Tilbake til dashboard
          </Button>
        </Link>
      </div>
    );

    if (message_status === DELETED) {
      msg = "Økten er slettet.";
      header = "Slettet!";
      buttonMsg = "Tilbake til dashboard";
      button = (buttonMsg) => (
        <Link to="/teacher/dashboard">
          <Button
            className="button mediumButton"
            onClick={toggle}
            style={{ marginRight: "5px", float: "right" }}
          >
            {buttonMsg}
          </Button>
        </Link>
      );
    } else if (message_status === PATCHED) {
      msg =
        'Aktiviteten din er endret. Du kan se alle aktivitetene dine under "Mine aktiviteter".';
      header = "Oppdatert!";
      buttonMsg = "Fortsett å redigere";
    } else if (message_status === SAVED) {
      msg =
        'Den nye aktiviteten din er lagret. Du kan se alle aktivitetene dine under "Mine aktiviteter".';
      header = "Lagret!";
      buttonMsg = "Opprett ny aktivitet";
    } else {
      msg = "Det har skjedd en feil. Ta kontakt med IT.";
      header = "Advarsel";
      buttonMsg = "OK";
    }

    return (
      <Modal isOpen={infoOpen} toggle={toggle}>
        <ModalHeader toggle={toggle} id="modalHeader">
          <Label className="title whiteText">{header}</Label>
        </ModalHeader>
        <ModalBody>
          <p>{msg}</p>
          {button(buttonMsg)}
        </ModalBody>
      </Modal>
    );
  }

  /* Renders all the subjects that teacher and students have in common
   *   - intersection of subjects for the selected students and the teacher
   *   - a button to add subjects for a single student, if only one is selected
   * */
  const subjects = () => {
    let subjectList = [];
    let message_if_empty = "Du må velge en elev for å kunne velge fag";
    let currentCategory = getCurrentCategory()

    if (mode.mode === NEW && activity.fields.students.length > 0) {
      /* If one or more students is selected, then the intersection between
      *  the teacher and the students is rendered
      * */
      subjectList = getSubjectIntersection(
        teacherSubjects,
        students,
        activity.fields.students
      );
      message_if_empty = "Ingen felles fag. Sjekk at du og eleven(e) har minst" +
          " ett fag som er felles.";
    }

    if (mode.mode === NEW && currentCategory) {
      if(!currentCategory.connected_to_student) {
        subjectList = teacherSubjects
      }
    }

    if (mode.mode === EDIT) {
      subjectList = teacherSubjects.filter(
        (subject) =>
          activity.fields.subject.toString() === subject.id.toString()
      );
    }

    return (
      <Row>
        {subjectList.length > 0 ? (
          subjectList.map((x, key) => (
            <Col
              key={key}
              xs="12"
              sm="6"
              md="4"
              lg="4"
              xl="3"
              className="fullFlexCentre"
            >
              {Rad(
                x.subject_name,
                "subject",
                x.subject_name,
                x.id,
                false,
                (e) => activityHandler("subject", x.id),
                disabledFields,
                activity.fields.subject,
                "light"
              )}
            </Col>
          ))
        ) : (
          <Col xs="12" sm="6" md="4" lg="4" xl="3">
            {message_if_empty}
          </Col>
        )}
        <Col
          xs="12"
          sm="6"
          md="4"
          lg="4"
          xl="3"
          className="fullFlexCentre"
          id="chooseCourseCol"
        >
          {ModalButton(
            selectedStudents.current.student_details.length > 0
              ? selectedStudents.current.student_details[0]
              : { id: "", name: "" },
            activity.fields.students.length === 1 &&
            students.length  >= 1
              ? getCommonSubjects(
                  teacherSubjects,
                  students.find((s) => s.id === activity.fields.students[0])
                    .subjects
                )
              : [],
            activity.fields.students[0] &&
                students.length >= 1
              ? students.find((s) => s.id === activity.fields.students[0])
                  .subjects
              : [],
            teacherSubjects,
            // Disabled if fields are disabled, no student is selected
            // or we are in edit mode
            disabledFields ||
              activity.fields.students.length !== 1 ||
              mode.mode === EDIT
          )}
        </Col>
      </Row>
    );
  };

  /* Renders delete button if in EDIT mode */
  const deleteButton = () => {
    return mode.mode === EDIT ? (
      <Col>
        <button
          disabled={disabledFields}
          className={
            disabledFields ? "button saveButton" : "button saveButton redButton"
          }
          onClick={(e) => removeActivity()}
        >
          Slett
        </button>
      </Col>
    ) : (
      ""
    );
  };

  /* Shows if the current category chosen is a custom category */
  const [isCustomCategory, setIsCustomCategory] = useState(false);

  const getStudentList = () => {
    const studentList = !isCustomCategory
      ? students.filter((student) => !student.is_custom_price_customer)
      : students;

    return studentList.map((student) => formatStudent(student));
  };

  const addSelectedStudent = (value, item) => {
    let old = selectedStudents.current.students;
    old.push(item.id);
    selectedStudents.current.students = old;
    selectedStudents.current.student_details = value;

    activityHandler("students", old);
  };

  const removeSelectedStudent = (value, item) => {
    let old = selectedStudents.current.students;
    old.splice(old.indexOf(item.id), 1);
    selectedStudents.current.students = old;
    selectedStudents.current.student_details = value;

    activityHandler("students", old);
  };

  /* Renders the dropdown menu for choosing Activity Categories */
  const listActivityCategories = () => {
    return categories.map((ac, key) => (
      <option value={ac.id} key={key}>
        {ac.name}
      </option>
    ));
  };

  /* Renders the radio buttons for choosing location */
  const getLocations = () => {
    return (
      <div>
        {places.map((pl, key) => (
          <label key={key} style={{ display: "flex" }}>
            <input
              type="radio"
              value="Campus"
              checked={activity.fields.place === pl[0]}
              onChange={
                disabledFields ? null : (e) => handleLocationChange(pl[0])
              }
              className="radioButtons"
            />
            <p className="radioLabel">{pl[1]}</p>
          </label>
        ))}
        {printErrormsg("place")}
      </div>
    );
  };

  const chooseCategory = (chosenField, chosenValue) => {
    activityHandler(chosenField, chosenValue);

    if (chosenValue === "") {
      // The value of the "Velg"-field
      selectedStudents.current = initialSelectedStudentState;
      activityHandler("students", []);
      disableFields("students_input", false);
      return;
    }

    const currentCategory = getCurrentCategory(chosenValue)

    if (!currentCategory.connected_to_student) {
      selectedStudents.current = initialSelectedStudentState;
      activityHandler("students", []);
      disableFields("students_input");
      return;
    }
    if (currentCategory.hasOwnProperty("customer")) {
      setIsCustomCategory(true);
      let customer = students.find(
        (student) => student.id === currentCategory.customer
      );
      customer = formatStudent(customer);
      selectedStudents.current = initialSelectedStudentState;
      addSelectedStudent([customer], customer);
      disableFields("students_input");
    } else {
      // We just want to remove the custom activity customer in this case
      let non_customers = students.filter(
        (s) =>
          selectedStudents.current.students.includes(s.id) &&
          !s.is_custom_price_customer
      );
      selectedStudents.current = initialSelectedStudentState;
      non_customers.forEach((s) =>
        addSelectedStudent([formatStudent(s)], formatStudent(s))
      );
      setIsCustomCategory(false);
      activityHandler(
        "students",
        non_customers.map((s) => s.id)
      );
      disableFields("students_input", false);
    }
  };

  return (
    <div>
      {placesLoading ||
      activityLoading ||
      studentsLoading ||
      categoriesLoading ||
      !doneInitialLoading ? (
        <Loader />
      ) : (
        ""
      )}
      <Card
        style={{
          display:
            placesLoading ||
            activityLoading ||
            studentsLoading ||
            categoriesLoading ||
            !doneInitialLoading
              ? "none"
              : "block",
        }}
      >
        <CardHeader>
          <div className="title">{mode.title_text} aktivitet</div>
          <p>{disabledMessage != null ? disabledMessage : ""}</p>
        </CardHeader>
        <CardBody>
          <Row>
            <Col xs="12">
              <FormGroup>
                <Label>Kategori</Label>
                <Input
                  type="select"
                  value={activity.fields.category}
                  onChange={(e) => chooseCategory("category", e.target.value)}
                  invalid={activity.errors.category !== ""}
                  className="inputField"
                  required
                  id="category"
                >
                  <option value={""}>Velg</option>
                  {listActivityCategories()}
                </Input>
                {printErrormsg("category")}
              </FormGroup>
            </Col>
          </Row>
          <Row></Row>
          <Row>
            <Col>
              <FormGroup>
                <Label>Velg elev(er)</Label>
                <Multiselect
                  id="students"
                  onSelect={addSelectedStudent}
                  onRemove={removeSelectedStudent}
                  className="inputField"
                  options={getStudentList()}
                  displayValue="name"
                  placeholder="Søk.."
                  closeIcon="cancel"
                  disablePreSelectedValues={disableStudents.current}
                  selectedValues={selectedStudents.current.student_details}
                  emptyRecordMsg="Ingen flere elever"
                  hidePlaceholder="true"
                  style={{
                    multiselectContainer: {
                      // To change input field position or margin
                      minHeight: "38px",
                      background: "rgba(196, 196, 196, 0.24)",
                      border: "1px solid rgba(196, 196, 196, 0.8)",
                      borderRadius: "10px",
                      lineHeight: "22px",
                    },
                    searchBox: {
                      // To change search box element look
                      border: "none",
                    },
                    inputField: {
                      // To change input field position or margin
                      fontSize: "15px",
                      fontFamily: "Montserrat",
                      fontStyle: "normal",
                      fontWeight: "300",
                      marginLeft: "5px",
                    },
                    chips: {
                      // To change css chips(Selected options)
                      background: "rgba(38, 70, 83, 1)",
                      marginTop: "-5px",
                    },
                    option: {
                      // To change css for dropdown options
                      color: "rgba(38, 70, 83, 1)",
                      fontSize: "15px",
                      fontFamily: "Montserrat",
                      fontStyle: "normal",
                      fontWeight: "300",
                    },
                  }}
                />
                {printErrormsg("students")}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label>Fag</Label>
                {subjects()}
                {printErrormsg("subject")}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs="12" sm="12" md="5" lg="6" xl="6">
              <FormGroup>
                <Label>Dato</Label>
                <Input
                  type="date"
                  value={activity.fields.date}
                  name="date_of_session"
                  onChange={(e) => activityHandler("date", e.target.value)}
                  invalid={activity.errors.date !== ""}
                  required
                  className="inputField"
                  id="date"
                />
                {printErrormsg("date")}
              </FormGroup>
            </Col>
            <Col xs="12" sm="12" md="7" lg="6" xl="6">
              <FormGroup>
                <Row style={{ margin: "0px" }}>
                  <Label>Starttidspunkt</Label>
                </Row>
                <Row style={{ margin: "0px" }}>
                  <div className="dateContainer">
                    <div id="starthr">
                      {getTime(
                        "start_time_hr",
                        () => incrementHour("start_time_hr"),
                        () => decrementHour("start_time_hr"),
                        () => formatHour("start_time_hr")
                      )}
                    </div>
                    {getTime(
                      "start_time_min",
                      () => incrementMinute("start_time_min"),
                      () => decrementMinute("start_time_min"),
                      () => formatMinute("start_time_min")
                    )}
                  </div>
                </Row>
                <Row style={{ margin: "0px" }}>
                  {printErrormsg("start_time")}
                </Row>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Row style={{ margin: "0px" }}>
                  <Label>Planlagt varighet</Label>
                </Row>
                <Row style={{ margin: "0px" }}>
                  <div className="dateContainer">
                    <div id="starthr">
                      <Label className="smallText timeLabel">Timer</Label>
                      {getTime(
                        "duration_hr",
                        () => incrementHour("duration_hr"),
                        () => decrementHour("duration_hr"),
                        () => formatHour("duration_hr")
                      )}
                    </div>
                    <div style={{ marginTop: "0px" }}>
                      <Label className="smallText timeLabel">Minutter</Label>
                      {getTime(
                        "duration_min",
                        () => incrementMinute("duration_min"),
                        () => decrementMinute("duration_min"),
                        () => formatMinute("duration_min")
                      )}
                    </div>
                  </div>
                </Row>
                <Row style={{ margin: "0px" }}>{printErrormsg("duration")}</Row>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label>Reisevei (minutter, én vei)</Label>
                <Input
                  type="number"
                  value={activity.fields.travel_time}
                  name="travel_time"
                  rows="1"
                  onChange={(e) =>
                    activityHandler("travel_time", e.target.value)
                  }
                  invalid={activity.errors.travel_time !== ""}
                  required
                  className="inputField"
                  id="travel"
                />
                {printErrormsg("travel_time")}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <Label>Sted</Label>
              <FormGroup>{getLocations()}</FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label>Plan for økten</Label>
                <Input
                  name="plan"
                  value={activity.fields.plan}
                  type="textarea"
                  outline="true"
                  style={{ height: "100px" }}
                  onChange={(e) => activityHandler("plan", e.target.value)}
                  className="inputField"
                  invalid={activity.errors.plan !== ""}
                />
                {printErrormsg("plan")}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <Label className="secondTitle">
                For økter som allerede er gjennomført
              </Label>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label>Oppsummering av økten</Label>
                <Input
                  name="summary_teacher"
                  value={activity.fields.summary_teacher}
                  type="textarea"
                  outline="true"
                  style={{ height: "100px" }}
                  placeholder="Ikke synlig for eleven"
                  onChange={(e) =>
                    activityHandler("summary_teacher", e.target.value)
                  }
                  required
                  className="inputField"
                  invalid={activity.errors.summary_teacher !== ""}
                />
                {printErrormsg("summary_teacher")}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label>Oppsummering av økten</Label>
                <Input
                  name="summary_student"
                  value={activity.fields.summary_student}
                  type="textarea"
                  outline="true"
                  style={{ height: "100px" }}
                  placeholder="Synlig for eleven"
                  onChange={(e) =>
                    activityHandler("summary_student", e.target.value)
                  }
                  required
                  className="inputField"
                  invalid={activity.errors.summary_student !== ""}
                />
                {printErrormsg("summary_student")}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label>Lekser</Label>
                <Input
                  name="homework"
                  value={activity.fields.homework}
                  type="textarea"
                  outline="true"
                  style={{ height: "100px" }}
                  placeholder="Synlig for eleven"
                  onChange={(e) => activityHandler("homework", e.target.value)}
                  required
                  className="inputField"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            {deleteButton()}
            <Col>
              <button
                type="submit"
                className="button darkButton saveButton"
                style={{ marginRight: "0px", float: "right" }}
                onClick={(e) => registerActivity()}
              >
                Lagre
              </button>
            </Col>
            {message()}
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};

/* Commented out unfinished code
  <Row>
    <Col>
      <Label> Notater</Label>
      <Input type="file"
             name="notes"
             onChange={(e) => lessonHandler("notes", e.target.files[0])}
      />
    </Col>
  </Row>
 */
export default ActivityForm;
