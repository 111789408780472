import axiosInstance from "../../axios";
import {
  ADD_PACKAGE_STUDENT_FAIL,
  ADD_PACKAGE_STUDENT_REQUEST,
  ADD_PACKAGE_STUDENT_SUCCESS,
} from "../constants";

export const addPackageStudent = (props) => async (dispatch) => {
  try {
    dispatch({ type: ADD_PACKAGE_STUDENT_REQUEST, clear: true });
    const { data } = await axiosInstance.post("/package/package/", {
      students: props.students,
      package_type: props.package_type,
      date_sold: props.date_sold,
      discount_percentage: props.discount_percentage,
      extra_price: props.extra_price,
      additional_hours_added: props.additional_hours_added,
      description: props.description,
      number_of_payments: props.number_of_payments,
      comment_for_invoice: props.comment_for_invoice,
      invoice_receiver: props.invoice_receiver,
    });
    dispatch({ type: ADD_PACKAGE_STUDENT_SUCCESS });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ADD_PACKAGE_STUDENT_FAIL,
      error:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
