export const monthNames = ["Januar", "Februar", "Mars", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Desember"];
export const getMonth = () => monthNames[getMonthNumber() - 1];
export const getMonthNumber = (date = new Date()) =>{
    var month_number = (date.getMonth() + (date.getDate() > 15 ? 2 : 1)) % 12;
    if (month_number === 0) {
      month_number = 12
    }
    return month_number
  }
    
export const getYear = (date = new Date()) => date.getFullYear() + (date.getMonth == 11 && date.getDate() > 15 ? 1 : 0);
export const getDbDate = (date = new Date()) => date.toJSON().slice(0, 10)

export const getMinuteDiff = (end, start) => {
    return ( Math.abs(end?.getTime() - start?.getTime()) / 1000 )/ 60
}