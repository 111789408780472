import {
  APPLICANTS_LIST_FAIL,
  APPLICANTS_LIST_REQUEST,
  APPLICANTS_LIST_SUCCESS,
} from "../constants";

const initialState = {
  applicants: [],
};

export const listApplicantsReducer = (state = initialState, action) => {
  switch (action.type) {
    case APPLICANTS_LIST_REQUEST:
      return { loading: true, applicants: [] };

    case APPLICANTS_LIST_SUCCESS:
      return { loading: false, applicants: action.payload };

    case APPLICANTS_LIST_FAIL:
      return { loading: false };

    default:
      return state;
  };
};