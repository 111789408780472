import React, {useState, useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import {clear} from "./errorSlice";
import {
    Alert
} from "reactstrap";

const AlertComponent = () => {
    const {title, message, status, showAlert} = useSelector((state) => state.errors);
    const dispatch = useDispatch();

    if (showAlert) {
        window.scroll(0, 0);
    }

    const render = () => {
        let msg = ""
        if (title) {
            msg += title + ": "
        }
        if (status) {
            msg += status + " "
        }
        if (message) {
            msg += message
        }
        return msg
    }

    return (
        <Alert color={"danger"} isOpen={showAlert} toggle={() => dispatch(clear())}>
            <div className="alert-message">
                <strong>{render()}</strong>
            </div>
        </Alert>
  );
};

export default AlertComponent;
