import React, {Component} from "react";
import {Route, Switch} from "react-router-dom";
import routesConfig from "./routesConfig"
import Layout from "./Layout";

export default () => {
    return (
        routesConfig.map(({path, component: Component}, index) =>
             <Route key={index} path={path} exact>
                 <Layout>
                     <Component />
                 </Layout>
             </Route>
        )
    )
};