import {
  TABLETS_LIST_FAIL,
  TABLETS_LIST_REQUEST,
  TABLETS_LIST_SUCCESS,
} from "../constants";

const initialState = {
  tablets: [],
};

export const listTabletsReducer = (state = initialState, action) => {
  switch (action.type) {
    case TABLETS_LIST_REQUEST:
      return { ...state, loading: true, tablets: [] };

    case TABLETS_LIST_SUCCESS:
      return { ...state, loading: false, tablets: action.payload };

    case TABLETS_LIST_FAIL:
      return { ...state, loading: false };

    default:
      return state;
  }
};
