import { createSlice, createAsyncThunk} from "@reduxjs/toolkit"
import PaginatedService from "../../services/PaginatedService";

const initialState = {
    loading: 'idle',    //  'idle' | 'pending' | 'succeeded' | 'failed'
    count: null,
    page: [],
    pageIndex: 0,
    nextPage: null,
    previousPage: null,
}

export const teacherPageBaseUrl = "user/list-teachers/"
export const fetchTeacherPage = createAsyncThunk(
    "teacher/fetchTeacherPage", PaginatedService.getPage
)

const teacherSlice = createSlice({
    name: "teacher",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchTeacherPage.pending, (state, action) => {
            state.loading = "pending"
        }).addCase(fetchTeacherPage.fulfilled, (state, action) => {
            state.loading = "success"
            PaginatedService.renderFulfilled(state, action)
        }).addCase(fetchTeacherPage.rejected, (state, action) => {
            state.loading = "failed"
        })
    }
})

export default teacherSlice.reducer
