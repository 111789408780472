import axiosInstance from "../../axios";
import {
    EDIT_TEACHER_PREFERRED_SUBJECT_REQUEST ,
    EDIT_TEACHER_PREFERRED_SUBJECT_SUCCESS,
    EDIT_TEACHER_PREFERRED_SUBJECT_FAIL,
} from "../constants";

export const editTeacherPreferredSubjects = (updatedData) => async (dispatch) => {
    try {
        dispatch({ type: EDIT_TEACHER_PREFERRED_SUBJECT_REQUEST, clear: true });

        const { data } = await axiosInstance.post(
            "user/teacher/update/preferred-subjects/",
            {
                subject: updatedData.subject,
                preferred_subject: updatedData.preferred_subject
            }
        );

        dispatch({
            type: EDIT_TEACHER_PREFERRED_SUBJECT_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: EDIT_TEACHER_PREFERRED_SUBJECT_FAIL,
            error:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};
