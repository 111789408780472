import React, {useEffect, useMemo, useState} from 'react'
import { Spinner } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { Form, Field } from 'react-final-form'
import {getInvoiceReceiver, patchInvoiceReceiver} from "./invoiceReceiverSlice";
import {composeValidators, required, mustBeNumber, correctEmail} from "../../utils/formFieldValidators"
import Styles from "./InvoiceReceiverFormStyle"

const InvoiceReceiverForm = ({invoiceReceiverId}) => {
    const dispatch = useDispatch()
    const {pageDetail} = useSelector((state) => state.invoiceReceiver)

    const thisInvoiceReceiver = () => {
        if (pageDetail.filter(ir => ir.id === invoiceReceiverId).length <= 0) {
            return null;
        }
        return pageDetail.filter(ir => ir.id === invoiceReceiverId)[0]
    }

    useEffect(() => {
        if (thisInvoiceReceiver() === null) {
            dispatch(getInvoiceReceiver({id: invoiceReceiverId}))
        }
    }, [dispatch])

    const onSubmit = (values, form) => {
        // Finding the fields that have been edited
        let dirtyValues = {}
        let empty = true
        for (const key in values) {
            if (Object.hasOwn(form.getState().dirtyFields, key )) {
                dirtyValues[key] = values[key]
                empty = false
            }
        }
        if (!empty) {
            dispatch(patchInvoiceReceiver({id: invoiceReceiverId, data: dirtyValues}))
        }
    }

    const render = () => {
        if (thisInvoiceReceiver() === null) {
            return <Spinner color="primary" />
        }
        return (
            <Styles>
                <Form
                    onSubmit={onSubmit}
                    initialValues={thisInvoiceReceiver()}
                    render={({ handleSubmit, form, submitting, pristine, values }) => (
                        <form onSubmit={handleSubmit}>
                            <Field name="first_name" validate={required}>
                                {({ input, meta }) => (
                                    <div>
                                        <label>First Name</label>
                                        <input {...input} type="text" placeholder="First Name" />
                                        {meta.error && meta.touched && <span>{meta.error}</span>}
                                    </div>
                                )}
                            </Field>
                            <Field name="last_name" validate={required}>
                                {({ input, meta }) => (
                                    <div>
                                        <label>Last Name</label>
                                        <input {...input} type="text" placeholder="Last Name" />
                                        {meta.error && meta.touched && <span>{meta.error}</span>}
                                    </div>
                                )}
                            </Field>
                            <Field
                                name="email"
                                validate={composeValidators(required, correctEmail)}
                            >
                                {({ input, meta }) => (
                                    <div>
                                        <label>Epost</label>
                                        <input {...input} type="text" placeholder="Email" />
                                        {meta.error && meta.touched && <span>{meta.error}</span>}
                                    </div>
                                )}
                            </Field>
                            <Field
                                name="phone_number"
                                validate={composeValidators(required, mustBeNumber)}
                            >
                                {({ input, meta }) => (
                                    <div>
                                        <label>Mobilnummer</label>
                                        <input {...input} type="text" placeholder="Mobilnummer" />
                                        {meta.error && meta.touched && <span>{meta.error}</span>}
                                    </div>
                                )}
                            </Field>
                            <div className="buttons">
                                <button type="submit" disabled={submitting}>
                                    Submit
                                </button>
                                <button
                                    type="button"
                                    onClick={form.reset}
                                    disabled={submitting || pristine}
                                >
                                    Reset
                                </button>
                            </div>
                            {/* <pre>{JSON.stringify(values, 0, 2)}</pre>*/}
                        </form>
                    )}
                />

            </Styles>
        )
    }

    return render()
}

export default InvoiceReceiverForm
