import React, {useState} from "react";

const InvoiceTable = (props) => {

    const packages = "packages";
    const custom = "custom";
    const travel = "travel";

    //Sets the correct packages for the correct invoice receiver
    const setVariables = (item) => {

        let packageType;

            switch (props.view) {
                case packages:
                    packageType = item.packages
                    break;
                case custom:
                    packageType = item.revenue_levels
                    break;
                case travel:
                    packageType = item.travel_salary_levels
                    break;
            }

        return packageType === undefined ? [] : packageType;
    }

    //package_id, activities, activities
    //Forces rerender when you check of a checkbox, in order to get the correct checked value
    const [render, setRender] = useState(0);
    const rerender = (item) => {
        props.toggle(item.invoice_receiver.email, setVariables(item) === item.packages ? setVariables(item).map(i => i.package_id) : [].concat.apply([], setVariables(item).map(i => i.activities)));
        setRender(render + 1)
    }

return (

    <table className="lessonTable" style={{width: "100%"}}>
        <thead>
            <tr>
                <th>Fakturamottaker</th>
                <th>Produkter</th>
                <th>Velg</th>
            </tr>
        </thead>
        <tbody>
            {props.invoiceList.length !== 0 ? props.invoiceList.map((item, key) => (
                <tr key={key}>
                    <td style={{whiteSpace: "pre", textAlign:"left"}}>

                        {
                            "Navn: " + item.invoice_receiver.first_name + " " + item.invoice_receiver.last_name
                            + "\nEpost: " + item.invoice_receiver.email
                            + "\nTlf: " + item.invoice_receiver.phone_number
                            + "\nAdresse: " + item.invoice_receiver.address
                            + "\nPostnummer: " + item.invoice_receiver.post_code
                            + "\nPoststed: " + item.invoice_receiver.post_code_area
                        }
                    </td>
                    <td>
                        <table>
                            <thead style={{position: "inherit"}}>
                                <tr>
                                    <th style={{backgroundColor: "#b3b3b3", color: "#070707"}}>Produktinfo</th>
                                    <th style={{backgroundColor: "#b3b3b3", color: "#070707"}}>Antall</th>
                                    <th style={{backgroundColor: "#b3b3b3", color: "#070707"}}>Produktpris</th>
                                    <th style={{backgroundColor: "#b3b3b3", color: "#070707"}}>Antall betalinger</th>
                                    <th style={{backgroundColor: "#b3b3b3", color: "#070707"}}>Pris per betaling</th>
                                    <th style={{backgroundColor: "#b3b3b3", color: "#070707"}}>Totalpris</th>
                                </tr>
                            </thead>
                            <tbody>
                            {setVariables(item).length !== 0 ? setVariables(item).map((item, key) => (
                                <tr key={key} style={item.number_of_payments === 1 ? {backgroundColor: "#e0ffa4"}
                                    : {backgroundColor: "#c4ecff"}} >
                                    <td>
                                        {item.product_info} <br/>
                                        {item.date_sold ? 'Solgt: ' + item.date_sold : ""}
                                    </td>
                                    <td>{item.quantity}</td>
                                    <td>{item.product_price}</td>
                                    <td>{item.number_of_payments}</td>
                                    <td>{Math.round((item.price_per_payment + Number.EPSILON) * 100) / 100}</td>
                                    <td>{item.total_price}</td>

                                </tr>
                            )) : <tr></tr>}
                            </tbody>
                        </table>
                    </td>
                    <td>
                        <input type="checkBox" style={{zoom: "1.5"}}
                               checked={props.chosen[props.view].hasOwnProperty(item.invoice_receiver.email)}
                               onChange={() => rerender(item)}
                        />
                    </td>

                </tr>
            )) : <tr></tr>}
        </tbody>
    </table>
);
}

export default InvoiceTable;