import {
    CREATE_INVOICE_RECEIVER_FAIL,
    CREATE_INVOICE_RECEIVER_SUCCESS,
    CREATE_INVOICE_RECEIVER_REQUEST, ADD_STUDENT_FAIL,
} from "../constants"

import axiosInstance from "../../axios";

export const createInvoiceReceiver = (props) => async (dispatch) => {
    try {
        dispatch({ type: CREATE_INVOICE_RECEIVER_REQUEST, clear: true });
        const invoiceReceiver = props.invoice.fields

        const { data } = await axiosInstance.post("/user/invoice-receiver/", {
            user_info: {
                first_name: invoiceReceiver.billing_first_name,
                last_name: invoiceReceiver.billing_last_name,
                phone_number: invoiceReceiver.billing_phone_number,
                password: "ThisIsChangedInTheBackend90",
                email: invoiceReceiver.billing_email,
                address: invoiceReceiver.billing_address,
                post_code: invoiceReceiver.billing_post_code,
                post_code_area: invoiceReceiver.billing_post_code_area,
            },
            self_as_student: invoiceReceiver.self_as_student,
            pays_for_students: invoiceReceiver.pays_for_students
        });

        dispatch({ type: CREATE_INVOICE_RECEIVER_SUCCESS, payload: data.email });
    } catch (error) {
     if (typeof error.response.data === "string") {
          dispatch({
            type: ADD_STUDENT_FAIL,
            error:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
          });
        }
        else if ("email" in error.response.data) {
            dispatch({
                type: CREATE_INVOICE_RECEIVER_FAIL,
                emailError:
                    error.response.data.email[0]
            });
        } else {
            dispatch({
                type: CREATE_INVOICE_RECEIVER_FAIL,
                error:
                    error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
            });
        }
    }

}