import axiosInstance from "../../axios";
import {
  GET_ACTIVITY_FAIL,
  GET_ACTIVITY_REQUEST,
  GET_ACTIVITY_SUCCESS,
} from "../constants";
export const getActivities = (props) => async (dispatch) => {
  try {
    dispatch({ type: GET_ACTIVITY_REQUEST, clear: true });

    const { data } = await axiosInstance.get("/activity/user-activities/");

    dispatch({ type: GET_ACTIVITY_SUCCESS, payload: data });
  } catch (error) {

    dispatch({
      type: GET_ACTIVITY_FAIL,
      error:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
