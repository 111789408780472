import React from "react";
import TokenService from "../../services/TokenService";
import { Route, Redirect} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {NonAuthRoutes} from "../unauthenticated/routesConfig";
import {homePath} from "../../utils/authHelpers";
import {add} from "../../features/error/errorSlice"

const PrivateRoute = ({component: Component, layout: Layout, path, exact, authCheck, ...rest}) => {
    const isAuthenticated = !!TokenService.getAccessToken();

    const currentUser = useSelector(
        (state) => state.currentUser
    );
    const dispatch = useDispatch();

    const render = () => {
        if (isAuthenticated) {
            if (path === "/") {
                // Redirect to home path of user
                return (
                    <Redirect
                        to={{
                            pathname: homePath(currentUser.currentUser.type)}}
                    />
                );
            }
            const userPassesAuthCheck = authCheck(currentUser.currentUser);
            if (userPassesAuthCheck) {
                return (
                    <Route path={path} exact>
                        <Layout>
                            <Component/>
                        </Layout>
                    </Route>
                );
            }
            dispatch(add({title: "Autentiserings Error", message: "Du har ikke tilgang til denne URLen!"}));
            return (
                <Redirect
                    to={{
                        pathname: homePath(currentUser.currentUser.type),
                        state: {
                            requestedPath: path
                        }
                    }}
                />
            );
        }
        dispatch(add({title: "Du har blitt logget ut", message: "Vennligst logg inn for å se denne siden" }));
        return (
            <Redirect
                to={{
                    pathname: NonAuthRoutes.singIn,
                    state: {
                        requestedPath: path
                    }
                }}
            />
        );
    }

    return render();
}

export default PrivateRoute;