import {
     GET_INVOICE_RECEIVERS_FAIL,
    GET_INVOICE_RECEIVERS_REQUEST,
    GET_INVOICE_RECEIVERS_SUCCESS,
    GET_INVOICE_RECEIVER_SEARCH_SUCCESS,
    GET_INVOICE_RECEIVER_SEARCH_REQUEST,
    GET_INVOICE_RECEIVER_SEARCH_FAIL,
    CREATE_INVOICE_RECEIVER_FAIL,
    CREATE_INVOICE_RECEIVER_SUCCESS,
    CREATE_INVOICE_RECEIVER_REQUEST,
} from "../constants.js"
import {act} from "react-dom/test-utils";

const initialState = {
    loading: false,
    allInvoiceReceivers: [],
    invoiceReceiverSearchResult: [],
    error: "",
    emailError: "",
}

export const invoiceReceiverReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_INVOICE_RECEIVERS_REQUEST:
            return { ...state, loading: true};
        case GET_INVOICE_RECEIVERS_SUCCESS:
            return { ...state, loading: false, allInvoiceReceivers: action.payload};
        case GET_INVOICE_RECEIVERS_FAIL:
             return { ...state, loading: false };
        case GET_INVOICE_RECEIVER_SEARCH_REQUEST:
            return { ...state, loading: true};
        case GET_INVOICE_RECEIVER_SEARCH_SUCCESS:
            return {...state, loading: false, invoiceReceiverSearchResult: action.payload};
        case GET_INVOICE_RECEIVER_SEARCH_FAIL:
            return {...state, loading: false};
        case CREATE_INVOICE_RECEIVER_REQUEST:
            return {...state, loading: true}
        case CREATE_INVOICE_RECEIVER_SUCCESS:
            return {...state, loading: false, emailError: ""}
        case CREATE_INVOICE_RECEIVER_FAIL:
            return {...state, loading: false, emailError: action.emailError}
        default:
            return state;
    }
};