import React, {useState, useMemo} from "react";
import LessonRow from "../../../Teacher/components/LessonRow";
import {dateFormatter, timeFormatter} from "../../../../../utils/formattingHelpers";
import {Button} from "reactstrap";
import "./style.css"

import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import "../../../../../stylesheets/components/responsiveTable.css"
import MessageModal from "../../../../../components/MessageModal";
import PaginatedTable from "../../../../../components/PaginatedTable/PaginatedTable";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {GET_USER_ACTIVITIES_PAGE_SUCCESS} from "../../../../../redux/constants";

import { faEdit, faCircle } from '@fortawesome/free-solid-svg-icons';
import {activityPageBaseUrl, fetchActivityPage} from "../../../../../features/activity/activitySlice";
//returns a table displaying the activities of a student from the students perspective
const StudentLessonTable = (userActivities) => {

  const Center = ({children}) => {
    return <div style={{height: "100%"}} className="d-flex justify-content-center align-items-center">
          <div>
             {children}
          </div>
        </div>
  }

    const [messageInfo, setMessageInfo] = useState({});
  const [messageIsOpen, setMessageIsOpen] = useState(false);
  const toggleMessageIsOpen = () => setMessageIsOpen((prevState) => !prevState);

  const updateModal = (header, message) => {
      setMessageInfo({header: header, message: message});
      toggleMessageIsOpen();
  }

    const columns = useMemo(
        () => [
            {
                id: "time",
                Header: "Tidspunkt",
                accessor: d => {
                    const date = new Date(d.start_date_time);
                    return `${dateFormatter(date)}, kl: ${timeFormatter(date)}`
                }
            },
            {
                id: "subjects",
                Header: "Fag",
                accessor: "subject_details"
            },
            {
                id: "duration",
                Header: "Varighet",
                accessor: "duration"
            },
            {
                id: "teacher",
                Header: "Lærer",
                accessor: "teacher_name"
            },
            {
                id: "status",
                Header: "Status",
                accessor: d => d.summary_date ? <>
                    <FontAwesomeIcon icon={faCircle} color={
                        "#2BA501"
                    }/> Fullført
                </> : new Date(d.start_date_time) > new Date() ?
                    <>
                        <FontAwesomeIcon icon={faCircle} color={
                            "#F87F0F"
                        }/> Planlagt
                    </> : <>
                        <FontAwesomeIcon icon={faCircle} color={
                            "#FC4040"
                        }/> Mangler oppsummering
                    </>
            },
            {
                id: "summary",
                Header: "Oppsummering",
                accessor: d => {
                    return (d.summary_student !== "" ? <Center><Button className="button darkButton p-1" style={{width: "100%", fontSize: "var(--text4"}} onClick={e => {
                        updateModal("Oppsummering", d.summary_student)
                    }
                    }>Se oppsummering</Button></Center> : <></>)
                }
            },
            {
                id: "homework",
                Header: "Lekser",
                accessor: d => {
                    return (d.homework !== "" ? <Center><Button className="button darkButton p-1" style={{width: "100%", fontSize: "var(--text4"}} onClick={e => {
                            updateModal("Lekser", d.homework)
                        }
                        }>Se lekser</Button></Center> : <></>)
                }
            }
        ],
        []
    )

    return (

            <div style={{width: "100%"}}>
                {MessageModal({ ...messageInfo, toggle: toggleMessageIsOpen, isOpen: messageIsOpen, link: ""})}
                <PaginatedTable
                    className="tableContainer"
                    columns={columns}
                    reducer='activity'
                    baseEndpoint={activityPageBaseUrl}
                    api={fetchActivityPage}
                />
            </div>
    )

};

export default StudentLessonTable;
