import React from "react";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import {
    faPhone,
    faHome,
    faUser,
    faEnvelope,
} from "@fortawesome/free-solid-svg-icons";

export default ({student, studentDetailUrl}) => {
    return <Col
        xs="12"
        sm="6"
        md="6"
        lg="6"
        xl="6"
        id={student.id}
        style={{ padding: 10 }}
    >
        <Card className="dashboard-card">
            <CardHeader style={{ paddingBottom: 0 }}>
                <Row>
                    <div className="between">
                        <div className="boxTitle">
                            {`${student.first_name} ${student.last_name}`}
                        </div>
                        <div
                            className={`button activityButton ${
                                student.is_active ? "lightStatusLabel" : "redStatusLabel"
                            }`}
                            style={student.is_active ? { color: "black" } : {}}
                        >
                            {student.is_active ? "Aktiv" : "Inaktiv"}
                        </div>
                    </div>
                </Row>
            </CardHeader>
            <CardBody>
                <Row>
                    <Col>
                        <FontAwesomeIcon icon={faPhone} style={{ marginRight: 10 }} />
                        {student.phone_number}
                    </Col>
                </Row>
                <Row style={{ marginBottom: "20px" }}>
                    <Col>
                        <FontAwesomeIcon icon={faHome} style={{ marginRight: 10 }} />
                        {student.address}
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <b>Fakturamottaker</b>
                    </Col>
                </Row>
                {student.invoice_receiver_information.length > 0 ? (
                    <div>
                        <Row>
                            <Col>
                                <FontAwesomeIcon
                                    icon={faUser}
                                    style={{ marginRight: 10 }}
                                />
                                {student.invoice_receiver_information[0].first_name +
                                    " " +
                                    student.invoice_receiver_information[0].last_name}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FontAwesomeIcon
                                    icon={faPhone}
                                    style={{ marginRight: 10 }}
                                />
                                {student.invoice_receiver_information[0].phone_number}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FontAwesomeIcon
                                    icon={faEnvelope}
                                    style={{ marginRight: 10 }}
                                />
                                {student.invoice_receiver_information[0].email}
                            </Col>
                        </Row>
                    </div>
                ) : (
                    <div>
                        <Row>
                            <Col>Eleven har ikke fakturamottaker.</Col>
                        </Row>
                        <Row>
                            <Col>
                                Trykk på se mer for mulighet til å legge til
                                fakturamottaker.
                            </Col>
                        </Row>
                    </div>
                )}
                {studentDetailUrl === "undef" ?
                    null :
                    <Row>
                        <Col>
                            <div className="between">
                                <Link
                                    to={{
                                        pathname: studentDetailUrl,
                                        state: { id: student.id },
                                    }}
                                >
                                    <button
                                        className="button darkButton"
                                        style={{ width: "100px" }}
                                    >
                                        Se mer
                                    </button>
                                </Link>
                            </div>
                        </Col>
                    </Row>
                }
            </CardBody>
        </Card>
    </Col>
}