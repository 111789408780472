import {
  GET_ACTIVITY_FAIL,
  GET_ACTIVITY_SUCCESS,
  GET_ACTIVITY_REQUEST,
  ADD_ACTIVITY_FAIL,
  ADD_ACTIVITY_SUCCESS,
  ADD_ACTIVITY_REQUEST,
  EDIT_ACTIVITY_FAIL,
  EDIT_ACTIVITY_REQUEST,
  EDIT_ACTIVITY_SUCCESS,
  DELETE_ACTIVITY_FAIL,
  DELETE_ACTIVITY_REQUEST,
  DELETE_ACTIVITY_SUCCESS,
  GET_USER_ACTIVITIES_PAGE_SUCCESS,
} from "../constants";
import store from "../store";
import SalesPackageOverview from "../../modules/authenticated/Management/pages/SalesPackageOverview";
import {baseURL} from "../../axios";
import {pageState} from "./PageState";
const initialState = {
  newlyAddedActivity: null,
  userActivities: [
    {
      activity_category: 0,
      activity_status: "",
      activity_status_name: "",
      bonus_pay: 0,
      duration: 0,
      id: 0,
      package: 0,
      revenue: 0,
      start_date_time: "",
      student: [0],
      student_details: [""],
      subject: 0,
      subject_details: "",
      teacher: 0,
      travel_cost: 0,
    },
  ],
  count: null,
  page: [],
  pageIndex: 0,
  nextPage: null,
  previousPage: null,
};

export const activityReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_ACTIVITIES_PAGE_SUCCESS:
      return pageState(state, action);

    case GET_ACTIVITY_REQUEST:
      return { ...state, loading: true, userActivities: [] };

    case GET_ACTIVITY_SUCCESS:
      return { ...state, loading: false, userActivities: action.payload };

    case GET_ACTIVITY_FAIL:
      return { ...state, loading: false };

    case ADD_ACTIVITY_REQUEST:
      return { ...state, loading: true, newlyAddedActivity: null };

    case ADD_ACTIVITY_SUCCESS:
      return { ...state, loading: false, newlyAddedActivity: action.payload };

    case ADD_ACTIVITY_FAIL:
      return { ...state, loading: false };

    case EDIT_ACTIVITY_REQUEST:
      return { ...state, loading: true, newlyAddedActivity: null };

    case EDIT_ACTIVITY_SUCCESS:
      return { ...state, loading: false, newlyAddedActivity: action.payload };

    case EDIT_ACTIVITY_FAIL:
      return { ...state, loading: false };

    case DELETE_ACTIVITY_REQUEST:
      return { ...state, loading: true };

    case DELETE_ACTIVITY_SUCCESS:
      return { ...state, loading: false };

    case DELETE_ACTIVITY_FAIL:
      return { ...state, loading: false };

    default:
      return state;
  };
};
