import React, {useEffect, useMemo, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../../../components/Loader";
import tableContainer from "../../components/TableContainer";

import { } from "reactstrap";
import {
  Card,
  CardBody,
  CardHeader,
  Input,
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  CardTitle,
  FormGroup,
  Label,
  CardText,
  Container
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faHome,
  faUser,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import {
  getSubjects,
  getTeacherMatching,
} from "../../../../../redux/actions/teacherMatchingActions";
import { dateFormatter } from "../../../../../utils/formattingHelpers";
import TeacherMatchingRow from "./TeacherMatchingRow";
import { getCities } from "../../../../../redux/actions/getCities";

import Select from "react-select";
import {teacherSubjectReducer} from "../../../../../redux/reducers/teacherSubjectsReducer";
import TableContainer from "../../components/TableContainer";
import UserScheduleSelector from "../../../../../features/availability/UserScheduleSelector";
import UserSchedule from "../../../../../features/schedule/UserSchedule";

// Page that displays all students of a teacher
const TeacherMatching = () => {
  const dispatch = useDispatch();
  const teachers = useSelector(
    (state) => state.teacherMatching.teacherMatching
  );
  const loading = useSelector(state => state.teacherMatching.loading);

  const cityList = useSelector((state) => state.cities);
  const [cities, cityLoading] = [cityList.cities, cityList.loading];

  //const subjects = useSelector((state) => state.teacherMatching.subjects);

  const options = [
    { value: "matte r1", label: "matte r1" },
    { value: "matte r2", label: "matte r2" },
  ];

  const [subjectOptions, setSubjectOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);

  const [teacherView, setTeacherView] = useState(teachers);

  const [filterOptions, setFilterOptions] = useState({
    filter: false,
    city: [],
    filteredSubjects: [],
    age: null,
    filterOnAge: false,
    upper_age: null,
    lower_age: null,
    wants_students: false,
    tablet: false,
    resetFilter: false,
    no_previous_hours: false
  });

  useEffect(() => {
    dispatch(getTeacherMatching()).then((data) => {
      setTeacherView(data);
    });
    dispatch(getCities()).then((data) => {
      setCityOptions(
        data.map((city) => {
          return { value: city.city_name, label: city.city_name };
        })
      );
    });
    dispatch(getSubjects()).then((data) => {
      setSubjectOptions(
        data.map((subject) => {
          return { value: subject.subject_name, label: subject.subject_name };
        })
      );
    });
  }, [dispatch]);

  useEffect(() => {
    if (filterOptions.resetFilter) {
      filterOptions.resetFilter = false;
      filterTeachers();
    }
  }, [teacherView]);

  useEffect(() => {
    if (filterOptions.resetFilter) {
      filterOptions.resetFilter = false;
      filterTeachers();
    }
  }, [filterOptions.resetFilter]);

  //

  const subjectsToString = (subjects, allsubjects) => {
    subjects = subjects.sort((subject) => {
      if (
        filterOptions.filteredSubjects.findIndex(
          (item) => item.value === subject.subject_name
        ) > -1
      ) {
        return 0;
      }
      return 1;
    });
    let subject_names = subjects.map((subject, key) => {
      if (subject.preferred_subject) {
        return (
          <div
            key={key}
            dangerouslySetInnerHTML={{
              __html: subject.subject_name.fontcolor("green"),
            }}
          />
        );
      }
      return (
        <div
          key={key}
          dangerouslySetInnerHTML={{
            __html: subject.subject_name.fontcolor("black"),
          }}
        />
      );
    });
    if (subject_names.length > 1) {
      let n = 3;
      if (filterOptions.filteredSubjects.length > 0) {
        n = filterOptions.filteredSubjects.length;
      }
      if (subject_names.length > n && !allsubjects) {
        subject_names = subject_names.splice(0, n);
        subject_names.push("...");
      }
    }
    return <div>{subject_names}</div>;
  };

  // Function to filter out elements from the teacher list
  const filterTeachers = (teachers, options) => {
    let newTeacherView = teacherView;

    // filter teachers who do not want more students
    if (filterOptions.wants_students) {
      newTeacherView = newTeacherView.filter((teacher) => {
        return teacher.teacher.wants_student;
      });
    }
    // filter teachers without tablet
    if (filterOptions.tablet) {
      newTeacherView = newTeacherView.filter((teacher) => {
        return teacher.teacher.tablet_status !== "NO_TABLET";
      });
    }
    if (filterOptions.no_previous_hours) {
      newTeacherView = newTeacherView.filter((teacher) => {
        return teacher.teacher.days_since_last_session === null;
      }).sort((a, b) => {
        return a.teacher.created_at > b.teacher.created_at
      });
    }
    // filter on teachers city
    if (filterOptions.city.length > 0) {
      newTeacherView = newTeacherView.filter((teacher) => {
        //return filterOptions.city.indexOf(teacher.teacher.city_name) === -1;
        return (
          filterOptions.city.findIndex(
            (item) => item.value === teacher.teacher.city_name
          ) > -1
        );
      });
    }
    if (filterOptions.age && filterOptions.age >= 0) {
      newTeacherView = [...newTeacherView].sort((a, b) => {
        const diffA = Math.abs(filterOptions.age - a.teacher.age);
        const diffB = Math.abs(filterOptions.age - b.teacher.age);
        return diffA - diffB;
      });
    }
    // filter teachers based on selected subjects
    if (filterOptions.filteredSubjects.length > 0) {
      // filter out teachers who do not have any of selected subjects
      newTeacherView = newTeacherView.filter((teacher) => {
        let keep = false;
        teacher.subjects.forEach((subject) => {
          if (
            filterOptions.filteredSubjects.findIndex(
              (item) => item.value === subject.subject_name
            ) > -1
          ) {
            keep = true;
          }
        });
        return keep;
      });
      // sort the list based on how many of the selected subjects the teacher has

      newTeacherView = newTeacherView.sort((a, b) => {
        let countA = 0;
        let countB = 0;
        filterOptions.filteredSubjects.forEach((subject) => {
          const indexA = a.subjects.findIndex((item) => {
            return item.subject_name === subject.value;
          });
          if (indexA > -1) {
            countA++;
            if (a.subjects[indexA].preferred_subject) {
              countA++;
            }
          }
          const indexB = b.subjects.findIndex((item) => {
            return item.subject_name === subject.value;
          });
          if (indexB > -1) {
            countB++;
            if (b.subjects[indexB].preferred_subject) {
              countB++;
            }
          }
        });
        return countB - countA;
      });
    }

    filterOptions.filter = false;
    setTeacherView(newTeacherView);
  };

  const resetFilter = () => {
    setTeacherView(teachers);
    //filterTeachers();
    if (
      filterOptions.wants_students ||
      filterOptions.lower_age ||
      filterOptions.city.length > 0 ||
      filterOptions.filteredSubjects.length > 0 ||
      filterOptions.age
    ) {
      filterOptions.resetFilter = true;
      //filterTeachers();
    }
  };

  // obsolete
  const getTeacherRows = (teachers) => {
    return teachers.map((teacher, key) => {
      return TeacherMatchingRow(
        {
          teacher: teacher.teacher.first_name + " " + teacher.teacher.last_name,
          field_of_study: teacher.teacher.field_of_study_name,
          town: teacher.teacher.city_name
            ? teacher.teacher.city_name.charAt(0)
            : "",
          status: teacher.teacher.status_name,
          fag: teacher.subjects[0]
            ? subjectsToString(teacher.subjects)
            : "Ingen registrerte fag",
          nettbrett: teacher.teacher.tablet_status_name,
          age: teacher.teacher.age,
          hours_last_30_days: teacher.teacher.hours_last_30_days,
          days_since_last_session:
            teacher.teacher.days_since_last_session === null
              ? "Har ikke hatt timer enda"
              : teacher.teacher.days_since_last_session < 0
              ? "Siste planlagte time om " +
                Math.abs(teacher.teacher.days_since_last_session) +
                " dager"
              : teacher.teacher.days_since_last_session,
          key: teacher.id,
          show_details: false,
        },
        key
      );
    });
  };

  // the dropdown element for the table
  const renderRowSubComponent = (row) => {
    const {
      teacher,
      subjects,
    } = row.original

    return (
        <Card>
          <CardBody>
            <Row style={{marginBottom: "20px"}}>
              Slack: {teacher.slack ? teacher.slack : "Ikke oppgitt"}
            </Row>
            <Row style={{marginBottom: "20px"}}>
              Telefon: {teacher.phone_number ? teacher.phone_number : "Ikke oppgitt"}
            </Row>
            <Row style={{marginBottom: "20px"}}>
              Epost: {teacher.email ? teacher.email : "Ikke oppgitt"}
            </Row>
            <Row style={{marginBottom: "20px"}}>
              Adresse: {teacher.address ? teacher.address : "Ikke oppgitt"}
            </Row>
            <Row style={{marginBottom: "20px"}}>
              Postkode: {teacher.post_code ? teacher.post_code + " " + (teacher.post_code_area ? teacher.post_code_area : "") : "Ikke oppgitt"}
            </Row>
            <Row>
              <Col>
                <Card style={{textAlign: "left"}}>
                  <CardHeader><strong>Fag:</strong></CardHeader>
                  <CardBody>
                    {subjectsToString(subjects, true)}
                  </CardBody>
                </Card>
              </Col>
              <Col>
                <Card style={{textAlign: "left"}}>
                  <CardHeader><strong>Studenter:</strong></CardHeader>
                  <CardBody>
                    {teacher.students.map((student, key) => {
                      return <p key={key}>{student.first_name} {student.last_name}</p>
                    })}
                  </CardBody>
                </Card>
              </Col>
              </Row>
              <Row style={{padding: 15, display: "flex", justifyContent: "center", width: "100%"}}>
                  <UserSchedule userId={teacher.id} canChange={false} />
              </Row>
        </CardBody>

        </Card>
    )
  }

  const columns = useMemo(
      () => [
        {
          Header: () => null,
          id: 'expandedButton', // 'id' is required
          Cell: ({ row }) => (
              <span>
            {row.isExpanded ? '△' : '▽'}
          </span>
          )
        },
        {
          id: "name",
          Header: "Lærer",
          disableFilters: true,
          disableSortBy: true,
          accessor: d => d.teacher.first_name + " " + d.teacher.last_name,
        },
        {
          Header: "Studie",
          accessor: "teacher.field_of_study_name",
        },
        {
          Header: "By",
          accessor: "teacher.city_name"
        },
        {
          Header: "Status",
          accessor: "teacher.status_name"
        },
        {
          id: "subjects",
          Header: "Fag",
          disableFilters: true,
          disableSortBy: true,
          accessor: d => subjectsToString(d.subjects)
        },
        {
          Header: "Nettbrett",
          accessor: "teacher.tablet_status_name"
        },
        {
          Header: "Alder",
          accessor: "teacher.age"
        },
        {
          Header: "Timer siste 30 dager",
          accessor: "teacher.hours_last_30_days",
          disableFilters: true,
        },
        {
          id: "days_since_last_session",
          Header: "Dager siden siste time",
          disableFilters: true,
          accessor: d => (d.teacher.days_since_last_session != null) ? d.teacher.days_since_last_session : "Har ikke hatt timer enda"
        },
        {
          id: "created_at",
          Header: "Ansettelsesdato",
          disableFilters: true,
          accessor: d => new Date(d.teacher.created_at).toLocaleDateString('en-GB')
        }
      ],
      []
  )

  //console.log(teacherView);


  // render component
  return (
    <div>
      <Card>
        <CardHeader style={{ paddingBottom: "0" }}>
          <div className="title">Lærere</div>
        </CardHeader>
        <CardBody>
          {loading || cityLoading ? <Loader/> : (<div>
            <div className="filterContainer">
              <div>
                <p>Filtrer på fag:</p>
                <Select
                    defaultValue={[]}
                    isMulti
                    name="colors"
                    options={subjectOptions}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    style={{ width: "50px", marginBottom: "20px" }}
                    menuPortalTarget={document.body}
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    onChange={(e) => {
                      if (e) {
                        if (filterOptions.filteredSubjects.length === 0) {
                          filterOptions.filteredSubjects = e;
                          filterTeachers();
                        } else {
                          filterOptions.filteredSubjects = e;
                          resetFilter();
                        }
                      } else {
                        filterOptions.filteredSubjects = [];
                        resetFilter();
                      }
                    }}
                />
                <p style={{ marginTop: "20px" }}>Filtrer på by:</p>
                <Select
                    defaultValue={[]}
                    isMulti
                    name="colors"
                    options={cityOptions}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    style={{ width: "50px" }}
                    menuPortalTarget={document.body}
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    onChange={(e) => {
                      if (e) {
                        if (filterOptions.city.length === 0) {
                          filterOptions.city = e;
                          filterTeachers();
                        } else {
                          filterOptions.city = e;
                          resetFilter();
                        }
                      } else {
                        filterOptions.city = [];
                        resetFilter();
                      }
                    }}
                />
              </div>
              <div></div>
              <div>
                <label>
                  <input
                      style={{ marginRight: "10px" }}
                      id="filterOnWantsStudent"
                      type="checkbox"
                      onChange={(e) => {
                        filterOptions.wants_students =
                            !filterOptions.wants_students;
                        if (filterOptions.wants_students) {
                          filterTeachers();
                        } else {
                          resetFilter();
                        }
                      }}
                  />
                  Skjul lærere som ikke ønsker elever.
                </label>
                <br/>
                <br/>
                <label>
                  <input
                      style={{ marginRight: "10px" }}
                      id="filterOnPreviousHours"
                      type="checkbox"
                      onChange={(e) => {
                        filterOptions.no_previous_hours =
                            !filterOptions.no_previous_hours;
                        if (filterOptions.no_previous_hours) {
                          filterTeachers();
                        } else {
                          resetFilter();
                        }
                      }}
                  />
                  Vis kun lærere som ikke har hatt timer enda.
                </label>
                <br />
                <br />
                <label>
                  <input
                      style={{ marginRight: "10px" }}
                      id="filterOnTablet"
                      type="checkbox"
                      onChange={(e) => {
                        filterOptions.tablet = !filterOptions.tablet;
                        if (filterOptions.tablet) {
                          filterTeachers();
                        } else {
                          resetFilter();
                        }
                      }}
                  />
                  Skjul lærere som ikke har nettbrett.
                </label>
                <p style={{ marginTop: "10px" }}>Sorter på alder:</p>
                <input
                    name="age"
                    id="filterOnAge"
                    rows="1"
                    type="number"
                    className="inputField"
                    defaultValue={filterOptions.age}
                    onChange={(e) => {
                      filterOptions.age = e.target.value;
                    }}
                    style={{ width: "80px" }}
                />
                <Button
                    className="goButton"
                    style={{ marginLeft: "15px" }}
                    onClick={() => {
                      if (filterOptions.age) {
                        filterTeachers();
                      } else {
                        resetFilter();
                      }
                    }}
                >
                  Sorter
                </Button>
                <Button
                    className="goButton"
                    style={{ marginLeft: "15px" }}
                    onClick={() => {
                      document.getElementById("filterOnAge").value = null;
                      filterOptions.age = null;
                      resetFilter();
                    }}
                >
                  Clear
                </Button>
              </div>
            </div>
            <Container style={{ marginLeft: 0, marginTop: 50, width: "100%" }}>
              <TableContainer style={{display: "block", maxWidth: "100%"}} columns={columns} data={teacherView} renderRowSubComponent={renderRowSubComponent} />
            </Container>
          </div>)}

        </CardBody>
      </Card>
    </div>
  );
};

export default TeacherMatching;
