import axiosInstance from "../../axios";
import {
  EDIT_TEACHER_PREFERENCE_REQUEST,
  EDIT_TEACHER_PREFERENCE_SUCCESS,
  EDIT_TEACHER_PREFERENCE_FAIL,
} from "../constants";

export const editTeacherPreference = (updatedData) => async (dispatch) => {
  try {
    dispatch({ type: EDIT_TEACHER_PREFERENCE_REQUEST, clear: true });

    const { data } = await axiosInstance.patch(
      "user/teacher/update/profile-side/",
      {
        status: updatedData.status,
      }
    );

    dispatch({
      type: EDIT_TEACHER_PREFERENCE_SUCCESS,
      payload: updatedData,
    });
  } catch (error) {
    dispatch({
      type: EDIT_TEACHER_PREFERENCE_FAIL,
      error:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
