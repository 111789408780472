import Divider from "@material-ui/core/Divider";
import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getContactinfo } from "../../../../../redux/actions/getContactinfo";

const ContactInfo = () => {
  const dispatch = useDispatch();

  // gets the activity info from the store
  const contactEmployees = useSelector((state) => state.employees);
  const { loading, supervisor, allTeachersForStudent } = contactEmployees;

  useEffect(() => {
    dispatch(getContactinfo());
  }, [dispatch]);

  const getInfo = (name, mail, phone, key) => {
    return (
      <Row key={key} style={{ display: "inline" }}>
        <div className="secondTitle"> {name} </div>
        <div style={{ maxWidth: "350px" }}>
          Mail: {mail} <br />
          Tlf: {phone}
          <Divider style={{ marginTop: "30px" }} />
        </div>
      </Row>
    );
  };

  const getTeachers = () => {
    return (
      <Row style={{ display: "inline" }}>
        {allTeachersForStudent.map((teacher, key) =>
          getInfo(teacher.full_name, teacher.email, teacher.phone_number, key)
        )}
      </Row>
    );
  };

  const getSupervisor = () => {
    return (
      <Row style={{ display: "inline" }}>
        {getInfo(
          supervisor.full_name,
          supervisor.email,
          supervisor.phone_number
        )}
      </Row>
    );
  };

  return (
    <div className="page-container">
      <h1 className="title mb-3">Kontaktpersoner</h1>
      <Row>
        <Col>
          <h4>Dine lærere</h4>
          {getTeachers(allTeachersForStudent)}
        </Col>
        <Col>
          <h4>Kontaktperson i Realfagshjelpen</h4>
          {
            !supervisor ?
              <div>
                Du har ingen kontaktperson. Du blir gitt en kontaktperson når du kjøper en pakke.
              </div> : getSupervisor()
          }
        </Col>
      </Row>
    </div>
  );
};

export default ContactInfo;
