import React from "react";
import arrow from "../../../../assets/img/RH/Pil_ned.png";

const RadioWithInnerText = (key, name, text, value, arrowBool, func, disabled, checkedValue, color) => {
  const className = "button " + color + (value === checkedValue ? "Label" : "Button");

  return (
    <div style={{ margin: "0" }}>
      <input key={key} type="radio" name={name} value={value} className={className} onClick={disabled ? null : func} />
      <div className="radioButtonTextContainer">
        {text}
        {arrowBool ? <img src={arrow} style={{ paddingLeft: "5px" }}></img> : ""}
      </div>
    </div>
  );
};

export default RadioWithInnerText;
