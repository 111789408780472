import axiosInstance from "../../axios";
import {
    GET_METABASE_FAIL
} from "../constants";

export const getMetabaseIframeUrl = (dashboardId) => async (dispatch) => {
    try {
        const { data } = await axiosInstance.get("/metabase/?dashboard_id=" + dashboardId);
        return data;
    } catch (error) {
        dispatch({
            type: GET_METABASE_FAIL,
            error:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};