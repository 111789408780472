import React from "react";
import { useSelector } from "react-redux";
import { Col, Row, CardBody, CardHeader } from "reactstrap";
import {dateFormatter, timeFormatter} from "../../utils/formattingHelpers";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCarSide, faCoins, faMapMarkerAlt, faClock, faCalendar
} from '@fortawesome/free-solid-svg-icons';
import Loader from "../../components/Loader"

// Returns the information displayed in the next activity boxes type should either be "teacher" or "student"
const NextPlannedActivityInfo = ({ type }) => {
    const {loading, nextPlannedActivity} = useSelector((state) => state.activity)

    const getDateTime = (activity) => {
        return new Date(activity.start_date_time)
    }

    const getEndTime = (activity) => {
        return new Date(getDateTime(activity).getTime() + Math.floor(activity.duration * 1000*60*60))
    }

    return (
      <>
          {
              nextPlannedActivity === null ?
                      <CardHeader>
                          <Row style={{color: "#10294C"}}>
                              {loading === "idle" ?
                                  "":
                                  "Du har ingen planlagte aktiviteter"}
                          </Row>
                      </CardHeader>
              :
                  <CardBody>
              <Row>
                  <div><b style={{color: "#3B3A3D"}}>Neste aktivitet:</b></div>
              </Row>
              <Row style={{fontWeight: 400, color: "#3B3A3D" }}>
                  {type === "student" ?
                      nextPlannedActivity.subject_details + " - med " + nextPlannedActivity.teacher_name :
                      nextPlannedActivity.subject_details + " - " +
                    nextPlannedActivity.student_details.map(s => s.name).join(", ")
                }
              </Row>
              <div className="m-2"/>
              <Row>
                  <FontAwesomeIcon color="#3B3A3D" icon={faCalendar} style={{marginRight: 10}}/>
                  {dateFormatter(getDateTime(nextPlannedActivity))}
          </Row>
              <Row>
                 <FontAwesomeIcon color="#3B3A3D" icon={faClock} style={{marginRight: 10}}/>
                 {timeFormatter(getDateTime(nextPlannedActivity)) + " - " + timeFormatter(getEndTime(nextPlannedActivity))}
              </Row>
              <Row>
                  <FontAwesomeIcon color="#3B3A3D" icon={faMapMarkerAlt} style={{marginRight: 10}}/>
                  {nextPlannedActivity.activity_location_name}
              </Row>
              {type.toString() === "student" ? (
                  <></>
              ) : (
                  <>
            <Row>
              <Col>
                <FontAwesomeIcon icon={faCoins} style={{marginRight: 10}}/>
                {nextPlannedActivity.base_salary + nextPlannedActivity.travel_salary} kr
              </Col>
            </Row>
            <Row>
              <Col>
                <FontAwesomeIcon icon={faCarSide} style={{marginRight: 10}}/>
                {nextPlannedActivity.travel_time} min
              </Col>
            </Row>
          </>
              )}
              <div className="m-2"/>
              <Row>
                  <Col style={{paddingLeft: 0}}>
                      <div><b style={{color: "#3B3A3D"}}>Plan for aktiviteten:</b></div>
                      <div>{nextPlannedActivity.activity_plan}</div>
                  </Col>
              </Row>
         </CardBody>
          }
      </>
  );
};
export default NextPlannedActivityInfo;
