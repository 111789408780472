import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CustomInput,
  Spinner,
  Input,
  Label,
} from "reactstrap";
import { logIn } from "../../../redux/actions/auth";
import logo from "../../../assets/img/RH/Realfagshjelpen_Logo_PNG_Original.png";
import "../../../stylesheets/loginStyle.css";
import {homePath} from "../../../utils/authHelpers";
import {TEACHER_TYPE} from "../../authenticated/Teacher/teacherConstants";
import teacherMustUpdateProfile from "../teacherMustUpdateProfile";

// ToDo: Make this less complex
const SignIn = () => {
  const dispatch = useDispatch();
  const currentUserState = useSelector((state) => state.currentUser);
  const { error, loading, currentUser, actionSucceeded } = currentUserState;
  const initialFormData = Object.freeze({
    email: "",
    password: "",
  });

  const [submitted, setSubmitted] = useState(false);

  const [formData, updateFormData] = useState(initialFormData);

  const handleChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value.trim(),
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      logIn({
        email: formData.email,
        password: formData.password,
      })
    ).then((d) => {
      setSubmitted(true);
    });
  };

  if (currentUser && !error && submitted && actionSucceeded) {
    if (currentUser.type === TEACHER_TYPE && teacherMustUpdateProfile(currentUser)) {
      return <Redirect to="/teacher/update-profile" />
    }
    return <Redirect to={homePath(currentUser.type)} />
  } else {
    return (
      <React.Fragment>
        <div className="text-center">
          <h2>Velkommen!</h2>
          <p className="lead">Logg inn med brukeren din for å fortsette</p>
        </div>
        <Card className="card-overwrite">
          <CardBody>
            <div>
              <div>
                <img src={logo} style={{ width: "100%", marginBottom: 35 }} />
              </div>
              <div>
                <Label>Email</Label>
                <Input
                  onChange={handleChange}
                  bsSize="lg"
                  type="email"
                  name="email"
                  placeholder="Enter your email"
                  className="input-field-class"
                />
              </div>
              <div>
                <Label>Passord</Label>
                <Input
                  onChange={handleChange}
                  bsSize="lg"
                  type="password"
                  name="password"
                  placeholder="Enter your password"
                  className="input-field-class"
                  onKeyPress={(event) => {
                    if (event.key === "Enter") {
                      handleSubmit(event);
                    }
                  }}
                />
                {submitted && !actionSucceeded ? (
                  <p className="warningText">
                    Brukernavn eller passord er feil
                  </p>
                ) : (
                  ""
                )}
                <div className="forgotten-password-container">
                  <small>
                    <Link
                      className="forgotten-password-text"
                      to="/reset-password"
                    >
                      Glemt passord?
                    </Link>
                  </small>
                </div>
              </div>
              <div className="remember-me-container">
                <CustomInput
                  type="checkbox"
                  id="rememberMe"
                  label="Husk meg til neste gang"
                  defaultChecked
                />
              </div>
              <div className="text-center">
                {loading ? (
                  <Spinner style={{ color: "primary" }} />
                ) : (
                  <button onClick={handleSubmit} className="login-button">
                    Logg inn
                  </button>
                )}
              </div>
            </div>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
};

export default SignIn;
