import React, {useEffect, useState} from "react";
import { useDispatch } from "react-redux";
import {getMetabaseIframeUrl} from "../../../../../redux/actions/managementDashboardActions";
import {Nav, NavItem, NavLink} from "reactstrap";
import {
    MAIN_DASHBOARD,
    MARKETING_DASHBOARD,
    SALES_DASHBOARD
} from "../../managementConstants";

const Dashboard = () => {

    const dispatch = useDispatch();
    const [iframeUrls, setIframeUrls] = useState([]);
    const [metabaseUrl, setMetabaseUrl] = useState("");
    const employeeConstants = [MAIN_DASHBOARD, SALES_DASHBOARD, MARKETING_DASHBOARD];
    const [view, setView] = useState(MAIN_DASHBOARD.metaBaseId);

    useEffect(() => {
        //Main-dashboard
        dispatch(getMetabaseIframeUrl(MAIN_DASHBOARD.metaBaseId)).then(data=>{
            setIframeUrls(iframeUrls => [...iframeUrls, data.iframe_url])
            setMetabaseUrl(data.metabase_url)
            //Sales-dashboard
            dispatch(getMetabaseIframeUrl(SALES_DASHBOARD.metaBaseId)).then(data=>{
                setIframeUrls(iframeUrls => [...iframeUrls, data.iframe_url])
                //Marketing-dashboard
                dispatch(getMetabaseIframeUrl(MARKETING_DASHBOARD.metaBaseId)).then(data=>{
                    setIframeUrls(iframeUrls => [...iframeUrls, data.iframe_url])
                });
            });
        });

    }, [dispatch]);

    return (
        <div>
            <Nav tabs>
                {employeeConstants.map((emp, index) => (
                    <NavItem
                        key={index}
                        onClick={() => setView(emp.metaBaseId)}
                    >
                        <NavLink className={emp.metaBaseId === view ? "active" : ""}>
                            {emp.name}
                        </NavLink>
                    </NavItem>
                ))}
            </Nav>
            {employeeConstants.map((emp, index) => (
                <div key={index} style={emp.metaBaseId === view ? {display: ""} : {display: "none"}}>
                    <iframe
                        src={iframeUrls[index]}
                        frameBorder="0"
                        width="100%"
                        height="1200"
                        style={{borderRadius: "0px"}}
                     />
                    <p>
                        Detaljer rundt dashbordet kan du se og redigere på Realfagshjelpen sin&nbsp;
                        <a href={metabaseUrl}>metabase app</a>
                    </p>
                </div>
            ))}
        </div>
    );
}

export default Dashboard