import React, {useEffect, useMemo, useState} from 'react'
import { Spinner } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { Form, Field, useField} from 'react-final-form'
import {composeValidators, required, mustBeNumber, correctEmail} from "../../utils/formFieldValidators"
import Styles from "./UserScheduleEditorStyle"
import {createUserEvent, getEventTypes, patchUserEvent} from "./scheduleSlice";
import AsyncSelect from 'react-select/async';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {Button} from "@mui/material"
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import de from 'date-fns/locale/de'
import RRuleGenerator from 'react-rrule-generator';
import {
    endOfMonth,
    startOfMonth,
    setHours,
    setMinutes,
    startOfDay,
    endOfDay,
    startOfWeek,
    addDays
} from "date-fns";
import {useStore} from "./lib/store";

const Error = ({ name }) => {
    const {
        meta: { touched, error }
    } = useField(name, { subscription: { touched: true, error: true } });
    return touched && error ? <span>{error}</span> : null;
}

const UserScheduleEditor = ({userId, scheduler}) => {

    const dispatch = useDispatch()
    const {selectedDate, view, day, week} = useStore()

    const getCurrentDateRange = () => {
        switch (view) {
            case "month":
                return {
                    start: startOfMonth(selectedDate),
                    end: endOfMonth(selectedDate),
                }
            case "week":
                const { weekStartOn, weekDays} = week
                const _weekStart = startOfWeek(selectedDate, { weekStartsOn: weekStartOn });
                const daysList = weekDays.map((d) => addDays(_weekStart, d));
                return {
                    start: startOfDay(daysList[0]),
                    end: endOfDay(daysList[daysList.length - 1])
                }
            case "day":
                const { startHour, endHour} = day
                return {
                    start: setMinutes(setHours(selectedDate, startHour), 0),
                    end: setMinutes(setHours(selectedDate, endHour), 0)
                }
            default:
                return Promise.reject("Illegal view state")
        }
    }

    const getInitialValues = () => {
        if (scheduler.edited === undefined) {
            return {
                start: scheduler.state.start?.value,
                end: scheduler.state.end?.value,
                recurrences: null
            }
        }
        return scheduler.edited
    }

    const [showRecurrence, setShowRecurrence] = useState(
        getInitialValues().recurrences !== null && scheduler.edited !== undefined)

    const handleSubmit = async (values, form) => {
        if (scheduler.edited) {
            let dirtyValues = {}
            let empty = true
            for (const key in values) {
                if (Object.hasOwn(form.getState().dirtyFields, key )) {
                    dirtyValues[key] = values[key]
                    empty = false
                }
            }
            if (empty) {
                // Nothing has been edited
                scheduler.close()
                return
            }
            try {
                await dispatch(patchUserEvent({eventId: values.event_id, eventPatch: dirtyValues})).unwrap()
                scheduler.onConfirm(values, "edit")
                scheduler.close()
                return
            } catch (e) {
                // Error handled in redux, but modal is still open
                return
            }
        }
        try {
            const createdEvents = await dispatch(createUserEvent({
                event: {...values, title: values.event_type.label, user: userId, disabled: false},
                range: getCurrentDateRange()
            })).unwrap()
            createdEvents.forEach((event) => scheduler.onConfirm(event, "create"))
            scheduler.close()
        } catch (e) {
            // Error handled in redux, but modal is still open
        }
    }

    const render = () => {
        return (
            <Styles>
                <Form
                    styles={{padding: 10}}
                    onSubmit={handleSubmit}
                    initialValues={getInitialValues()}
                    render={({ handleSubmit, form, submitting, pristine, values }) => (
                        <form onSubmit={handleSubmit}>
                            <Field name="event_type" validate={required}>
                                {props => (
                                    <div>
                                        <label><b>Event Type</b></label>
                                        <AsyncSelect
                                            classNamePrefix="select"
                                            className="basic-multi-select"
                                            styles={{container: base => ({...base, flex: 1})}}
                                            isClearable={false}
                                            loadOptions={(inputValue, callback) => {
                                                dispatch(getEventTypes())
                                                    .unwrap()
                                                    .then(response => {
                                                        callback(response.map(v => {
                                                            return {
                                                                value: v.id, label: v.name
                                                            }
                                                        }))})
                                                    .catch(e => {
                                                        callback([])
                                                    })
                                            }}
                                            name={props.input.name}
                                            value={props.input.value}
                                            onChange={props.input.onChange}
                                            cacheOptions
                                            defaultOptions
                                        />
                                        <Error name="event_type" />
                                    </div>
                                )}
                            </Field>
                            <Field name="start" validate={required}>
                                { props => (
                                    <div>
                                        <label><b>Start</b></label>
                                        <LocalizationProvider
                                            dateAdapter={AdapterDateFns}
                                            adapterLocale={de}
                                        >
                                            <DateTimePicker
                                                renderInput={(props) => <TextField {...props} />}
                                                ampm={false}
                                                label={""}
                                                value={props.input.value}
                                                onChange={props.input.onChange}
                                            />
                                        </LocalizationProvider>
                                        <Error name="start" />
                                    </div>
                                )}
                            </Field>
                            <Field name="end" validate={required}>
                                { props => (
                                    <div>
                                        <label><b>Slutt</b></label>
                                        <LocalizationProvider
                                            dateAdapter={AdapterDateFns}
                                            adapterLocale={de}
                                        >
                                            <DateTimePicker
                                                renderInput={(props) => <TextField {...props} />}
                                                ampm={false}
                                                label={""}
                                                value={props.input.value}
                                                onChange={props.input.onChange}
                                            />
                                        </LocalizationProvider>
                                        <Error name="end" />
                                    </div>
                                )}
                            </Field>
                            {/*<Field name="recurrences">
                                { props => (
                                    <div>
                                        <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                                            <label><b>Gjentagelse</b></label>
                                            <div style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
                                                <Button style={{backgroundImage: "inherit", marginBottom: 5}}
                                                        variant="text" onClick={() => setShowRecurrence(!showRecurrence)}>
                                                    {showRecurrence ? "Skjul" : "Vis"}
                                                </Button>
                                                {
                                                    props.input.value ?
                                                        <Button
                                                            style={{backgroundImage: "inherit", marginBottom: 5}}
                                                            variant="text"
                                                            onClick={() => {
                                                                props.input.onChange(null)
                                                                setShowRecurrence(!showRecurrence)
                                                            }}>
                                                            Slett
                                                        </Button>
                                                        : <></>
                                                }
                                            </div>
                                        </div>
                                        {
                                            showRecurrence ?
                                                <RRuleGenerator
                                                    onChange={props.input.onChange}
                                                    value={props.input.value}
                                                    config={{
                                                        repeat: ["Monthly", "Weekly", "Daily"],
                                                        monthly: "on the",
                                                        end: ["On date", "After"]
                                                    }}
                                                /> : <></>
                                        }
                                        <Error name="recurrences" />
                                    </div>
                                )}
                            </Field>*/}
                            <hr/>
                            <div className="buttons">
                                <button type="submit" disabled={submitting}>
                                    Lagre
                                </button>
                                <button
                                    type="button"
                                    onClick={form.reset}
                                    disabled={submitting || pristine}
                                >
                                    Reset
                                </button>
                                <button
                                    type="button"
                                    onClick={scheduler.close}
                                >
                                    Cancel
                                </button>
                            </div>
                            {/*<pre>{JSON.stringify(values, 0, 2)}</pre>*/}
                        </form>
                    )}
                />
            </Styles>
        )
    }

    return render()
}

export default UserScheduleEditor
