import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import ResetPassword from "./pages/ResetPassword";
import Page404 from "./pages/Page404";
import Page500 from "./pages/Page500";
import ConfirmResetPassword from "./pages/ConfirmResetPassword";

export const NonAuthRoutes = {
  singIn: "/sign-in",
  singUp: "/sign-up",
  resetPassword: "/reset-password",
  confirmResetPassword: "/confirm-reset-password",
  notFound: "/404",
  serverError: "/500"
}

export default [
  {
    path: NonAuthRoutes.singIn,
    name: "Sign In",
    component: SignIn,
  },
  {
    path: NonAuthRoutes.singUp,
    name: "Sign Up",
    component: SignUp,
  },
  {
    path: NonAuthRoutes.resetPassword,
    name: "Reset Password",
    component: ResetPassword,
  },
  {
    path: NonAuthRoutes.confirmResetPassword ,
    name: "Confirm reset password",
    component: ConfirmResetPassword,
  },
  {
    path: NonAuthRoutes.notFound,
    name: "404 Page",
    component: Page404,
  },
  {
    path: NonAuthRoutes.serverError,
    name: "500 Page",
    component: Page500,
  },
];


