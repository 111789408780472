import React from "react"

const Event = ({event}) => {
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "100%",
                background: event.color
            }}
        >
            <div
                style={{ height: 20, fontSize: 10, background: "#ffffffb5", color: event.color}}
            >
                {event.start?.toLocaleTimeString("en-GB", {timeStyle: "short", hour12: false})}
            </div>
            <div style={{fontSize: 12, padding: 2}}>{event.title}</div>
            <div
                style={{ height: 20, fontSize: 10,background: "#ffffffb5", color: event.color}}
            >
                {event.end?.toLocaleTimeString("en-GB", { timeStyle: "short", hour12: false})}
            </div>
        </div>
    );
}

export default Event
