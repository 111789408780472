import axiosInstance from "../../axios";
import {
    GET_ALL_STUDENTS_FAIL,
    GET_ALL_STUDENTS_REQUEST,
    GET_ALL_STUDENTS_SUCCESS,
} from "../constants";
export const getAllStudents = () => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_STUDENTS_REQUEST, clear: true });
    const { data } = await axiosInstance.get("/user/student/list_students/");
    dispatch({ type: GET_ALL_STUDENTS_SUCCESS, payload: data });
    return data;
  } catch (error) {
    dispatch({
      type: GET_ALL_STUDENTS_FAIL,
      error:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};