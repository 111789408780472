import {createSlice, createAsyncThunk} from "@reduxjs/toolkit"
import axiosInstance from "../../axios";
import {returnError} from "../../utils/errorHelper";

const initialState = {
    loading: 'idle',    //  'idle' | 'pending' | 'succeeded' | 'failed'
    schedules: {},      // { userId: [ Date(), Date(), ... ] }
}

export const getUserSchedule = createAsyncThunk(
    "availability/getUserSchedule",
    async (arg, thunkAPI) => {
        try {
            const response = await axiosInstance.get("/availability/user?id=" + arg.id)
            return response.data
        } catch (err) {
            return returnError(err, thunkAPI)
        }
    }
)

// Deletes the users old schedule and create the new one
export const putUserSchedule = createAsyncThunk(
    "availability/putUserSchedule",
    async (arg, thunkAPI) => {
        try {
            await axiosInstance.put("/availability/user/" + arg.id,
                {
                    intervals: arg.intervals
                }
            )
            return { userId: arg.id, schedule: arg.schedule}
        } catch (err) {
            return returnError(err, thunkAPI)
        }
    }
)

export const availabilitySlice = createSlice({
    name: "availability",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getUserSchedule.pending, (state) => {
            state.loading = "pending"
        }).addCase(getUserSchedule.fulfilled, (state, action) => {
            // Creating the date objects from the intervals
            let schedule = []
            for (let i = 0; i < action.payload.available_time_intervals.length; i++) {
                for (
                    let date_time = new Date(action.payload.available_time_intervals[i].start_date_time);
                    date_time <= new Date(action.payload.available_time_intervals[i].end_date_time);
                    date_time.setTime(date_time.getTime() + 30 * 60000)  // Increase with 15 minutes
                ) {
                    schedule.push(new Date(date_time))
                }
            }
            state.schedules[action.payload.user] = schedule
            state.loading = "success"
        }).addCase(getUserSchedule.rejected, (state) => {
            state.loading = "failed"
        }).addCase(putUserSchedule.pending, (state) => {
            state.loading = "pending"
        }).addCase(putUserSchedule.fulfilled, (state, action) => {
            state.schedules[action.payload.userId] = action.payload.schedule
            state.loading = "success"
        }).addCase(putUserSchedule.rejected, (state) => {
            state.loading = "failed"
        })
    }
})

export default availabilitySlice.reducer
