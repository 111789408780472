import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import Doug from "../../../../../components/DoughnutWithInnerText";
import { getStudent } from "../../../../../redux/actions/getStudent";
import TeacherLessonTable from "../../components/TeacherLessonTable.jsx";
import ModalButton from "../../components/RegisterNewCourseModal";
import { getTeacherSubjects } from "../../../../../redux/actions/getTeacherSubjects";
import { getCommonStudentSubject } from "../../../../../redux/actions/getCommonStudentSubject";
import Loader from "../../../../../components/Loader";
import {
  initialInvoiceUserState,
  invoiceHandler,
  validateInvoiceInput,
} from "../../utils/addInvoiceReceiverHelpers";
import { createInvoiceReceiver } from "../../../../../redux/actions/createInvoiceReceiver";
import MessageModal from "../../../../../components/MessageModal";

// Page that displays info about a student
const IndividualStudentOverview = () => {
  const [invoice, setInvoice] = useState(initialInvoiceUserState);
  const dispatch = useDispatch();

  const studentView = "Eleven er også fakturamottaker";
  const different = "Eleven er ikke fakturamottaker";
  const [submitted, setSubmitted] = useState(false);

  // gets the student info from the store
  const studentState = useSelector((state) => state.students);
  const iRState = useSelector((state) => state.invoiceReceivers);
  const { emailError, loading } = iRState;
  const student = studentState.currentStudent;
  const studentLoading = studentState.loading;

  // get teacher subject info from the store
  const teacherSubjectsList = useSelector((state) => state.teacherSubjects);
  const [teacherSubjectsLoading, teacherSubjects] = [
    teacherSubjectsList.loading,
    teacherSubjectsList.subjects,
  ];

  const [view, setView] = useState("");


  useEffect(() => {
    invoice.fields.self_as_student = view === studentView;
  }, [view]);

  // gets the id of the student from the link that directed to this page
  const location = useLocation();
  const { id } = location.state;

  // makes redux fetch the given student and the subjects of the teacher
  useEffect(() => {
    dispatch(getStudent(id));
    dispatch(getTeacherSubjects());
    dispatch(getCommonStudentSubject({ id: id }));
  }, [dispatch]);

  // calculates the amount of lessons left across all packages
  const getPacketLessonsLeft = () =>
    student.packages
      .map((Package) => Package.remaining_hours)
      .reduce((a, b) => a + b, 0);

  // calculates the amount of lessons used in active packages
  const getPacketLessonsUsed = () =>
    student.packages
      .map((Package) => Package.hours_in_package - Package.remaining_hours)
      .reduce((a, b) => a + b, 0);

  // Get text for doug chart
  const getChartText = (Package) => {
    if (Package.remaining_hours > 0) {
      return {
        color: "#1A3457",
        text: ((
            Package.hours_in_package +
            Package.additional_hours_added
            ) - Package.remaining_hours) +
            "/" + (
                Package.hours_in_package +
                Package.additional_hours_added
            ),
      };
    }
    if (Package.remaining_hours === 0) {
      return {
        color: "#6FA65D",
        text: "Fullført",
      };
    }
    return {
      color: "#EBECF0",
      text: "teknisk feil",
    };
  };

  // returns a Row displaying all the packages of a student
  const getPackagesRow = () => (
    <Row>
      {student.packages
        .filter((Package) =>
            (Package.hours_in_package +
            Package.additional_hours_added) > 0
        ).map((Package, key) => (
          <Col xs="12" sm="12" md="4" lg="4" xl="4" key={key}>
            <Row>
              {Doug(
                ["Gjennomført", "Resterende"],
                [
                  {
                    data: [
                        (
                            Package.hours_in_package +
                            Package.additional_hours_added
                        ) - Package.remaining_hours,
                      Package.remaining_hours,
                    ],
                    backgroundColor: [
                      Package.remaining_hours === 0 ? "#6FA65D" : "#1A3457",
                      "#EBECF0",
                    ],
                  },
                ],
                getChartText(Package)
              )}
            </Row>
            <Row className="fullFlexCentre" style={{ flexDirection: "column" }}>
              <div>{Package.name}</div>
              <div className="smallText">Kjøpt {Package.date_sold}</div>
            </Row>
          </Col>
        ))}
    </Row>
  );

  // returns a Row displaying the subjects of a student
  const getSubjectBoxes = () =>
    student.subjects.map((subject, key) => (
      <Row style={{ marginBottom: "10px" }} key={key}>
        <Col className="fullFlexCentre">
          <div className=" button lightStatusLabel subjectButton">
            {subject.subject_name}
          </div>
        </Col>
      </Row>
    ));

  const saveInvoiceReceiver = () => {
    if (
      window.confirm(
        "Hvis du legger til en ny fakturamottaker, vil det være denne fakturamottakeren som " +
          "betaler for eleven fremover. Er du sikker på at du vil det?"
      )
    ) {
      const valid = validateInvoiceInput(invoice, setInvoice);
      if (view === studentView || (view === different && valid)) {
        invoice.fields.pays_for_students.push(student.student.id);
        dispatch(createInvoiceReceiver({ invoice: invoice }));
        setSubmitted(true);
      }
    }
  };
  const [messageInfo, setMessageInfo] = useState({});
  const [messageIsOpen, setMessageIsOpen] = useState(false);

  const displayMarkingSuccessInvoice = () =>
    setMessageInfo({
      header: "Vellykket!",
      message:
        "Fakturamottaker ble opprettet for " +
        student.student.first_name +
        " " +
        student.student.last_name,
      link: {
        pathname: "/teacher/students/:id",
        state: { id: student.student.id },
      },
    });

  const toggleMessageIsOpen = () => setMessageIsOpen((prevState) => !prevState);
  const displayMarkingFailed = (message) =>
    setMessageInfo({ error: true, message: message });

  useEffect(() => {
    if (!loading && submitted && view !== "") {
      if (emailError !== "") {
        displayMarkingFailed(emailError);
      } else displayMarkingSuccessInvoice();
      toggleMessageIsOpen();
      setSubmitted(false);
    }
  }, [loading]);

  return (
    <div>
      {studentLoading || teacherSubjectsLoading || loading ? <Loader /> : ""}
      {MessageModal({
        ...messageInfo,
        toggle: toggleMessageIsOpen,
        isOpen: messageIsOpen,
      })}
      <Card
        className={
          studentLoading || teacherSubjectsLoading || loading ? "invisible" : ""
        }
      >
        <CardHeader style={{ paddingBottom: "0" }}>
          <div
            className="between"
            style={{ marginBottom: "10px", width: "25%" }}
          >
            <div className="title" style={{ marginRight: "10px" }}>
              {student.student.first_name + " " + student.student.last_name}
            </div>
            <div
              className={`button activityButton ${
                student.student.is_active
                  ? "lightStatusLabel"
                  : "redStatusLabel"
              }`}
              style={student.student.is_active ? { color: "black" } : {}}
            >
              {student.student.is_active ? "Aktiv" : "Inaktiv"}
            </div>
          </div>
        </CardHeader>
        <CardBody>
          <Row>
            <Col
              xs="12"
              sm="12"
              md="9"
              lg="9"
              xl="10"
              style={{ marginBottom: "10px" }}
            >
              {getPackagesRow()}
            </Col>
            <Col xs="12" sm="12" md="3" lg="3" xl="2">
              <Row>
                <div className="fullFlexCentre">Fag:</div>
              </Row>
              {getSubjectBoxes()}
              <Row>
                <Col className="fullFlexCentre" id="chooseCourseCol">
                  {ModalButton(
                    {
                      id: student.student.id,
                      name:
                        student.student.first_name +
                        " " +
                        student.student.last_name,
                    },
                    student.subjects_in_common,
                    student.subjects,
                    teacherSubjects,
                    false
                  )}
                </Col>
              </Row>
            </Col>
          </Row>

          <Row style={{ marginTop: 20 }}>
            <Col xs="4" sm="2" md="2" lg="2" xl="2">
              Økter
            </Col>
            <Col xs="6" sm="4" md="2" lg="2" xl="2">
              <Link
                to={{
                  pathname: "/teacher/activity",
                  state: {
                    id: id,
                    name:
                      student.student.first_name +
                      " " +
                      student.student.last_name,
                  },
                }}
              >
                <button
                  className="button darkButton"
                  style={{ width: "100%", maxWidth: "200px" }}
                >
                  Legg til
                </button>
              </Link>
            </Col>
          </Row>
          {TeacherLessonTable(student.activities)}
        </CardBody>
        {!("invoice_receiver_information" in student.student
          ? student.student.invoice_receiver_information.length > 0
          : false) ? (
          <Form>
            <Row>
              <Col xs="12">
                <div
                  className="secondTitle"
                  style={{ marginBottom: "20px", marginTop: "20px" }}
                >
                  Opprett fakturamottaker på elev
                </div>
              </Col>
              <Col xs="6">
                <Input
                  type="button"
                  className="button mediumButton"
                  value={studentView}
                  onClick={() => setView(studentView)}
                  style={
                    view === studentView
                      ? { width: "fit-content", backgroundColor: "#2a9d8f85" }
                      : { width: "fit-content" }
                  }
                />
              </Col>
              <Col xs="6">
                <Input
                  type="button"
                  className="button mediumButton"
                  value={different}
                  onClick={() => setView(different)}
                  style={
                    view === different
                      ? { width: "fit-content", backgroundColor: "#2a9d8f85" }
                      : { width: "fit-content" }
                  }
                />
              </Col>
            </Row>
            {view === different ? (
              <Col style={{ marginTop: "20px" }}>
                <Row>
                  <Col xs="12" sm="5">
                    <FormGroup>
                      <Label>Fornavn</Label>
                      <Input
                        name="fName"
                        placeholder="Ola"
                        rows="1"
                        className="inputField"
                        onChange={(e) =>
                          invoiceHandler(
                            "billing_first_name",
                            e.target.value,
                            setInvoice
                          )
                        }
                        invalid={invoice.errors.billing_first_name !== ""}
                        required
                      />
                      {invoice.errors.billing_first_name === "" ? (
                        ""
                      ) : (
                        <p style={{ color: "red" }}>
                          {invoice.errors.billing_first_name}
                        </p>
                      )}
                    </FormGroup>
                  </Col>
                  <Col xs="12" sm="7">
                    <FormGroup>
                      <Label>Etternavn</Label>
                      <Input
                        name="lName"
                        placeholder="Nordmann"
                        rows="1"
                        className="inputField"
                        onChange={(e) =>
                          invoiceHandler(
                            "billing_last_name",
                            e.target.value,
                            setInvoice
                          )
                        }
                        invalid={invoice.errors.billing_last_name !== ""}
                        required
                      />
                      {invoice.errors.billing_last_name === "" ? (
                        ""
                      ) : (
                        <p style={{ color: "red" }}>
                          {invoice.errors.billing_last_name}
                        </p>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" md="5">
                    <FormGroup>
                      <Label>Telefonnummer</Label>
                      <Input
                        name="phoneFac"
                        rows="1"
                        className="inputField"
                        onChange={(e) =>
                          invoiceHandler(
                            "billing_phone_number",
                            e.target.value,
                            setInvoice
                          )
                        }
                        invalid={invoice.errors.billing_phone_number !== ""}
                        required
                      />
                      {invoice.errors.billing_phone_number === "" ? (
                        ""
                      ) : (
                        <p style={{ color: "red" }}>
                          {invoice.errors.billing_phone_number}
                        </p>
                      )}
                    </FormGroup>
                  </Col>
                  <Col xs="12" md="7">
                    <FormGroup>
                      <Label>E-post</Label>
                      <Input
                        name="emailFac"
                        rows="1"
                        className="inputField"
                        onChange={(e) =>
                          invoiceHandler(
                            "billing_email",
                            e.target.value,
                            setInvoice
                          )
                        }
                        invalid={invoice.errors.billing_email !== ""}
                        required
                      />
                      {invoice.errors.billing_email === "" ? (
                        ""
                      ) : (
                        <p style={{ color: "red" }}>
                          {invoice.errors.billing_email}
                        </p>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" sm="12" xl="7">
                    <FormGroup>
                      <Label>Adresse</Label>
                      <Input
                        name="billing_address"
                        rows="1"
                        className="inputField"
                        onChange={(e) =>
                          invoiceHandler(
                            "billing_address",
                            e.target.value,
                            setInvoice
                          )
                        }
                        invalid={invoice.errors.billing_address !== ""}
                        required
                      />
                      {invoice.errors.billing_address === "" ? (
                        ""
                      ) : (
                        <p style={{ color: "red" }}>
                          {invoice.errors.billing_address}
                        </p>
                      )}
                    </FormGroup>
                  </Col>
                  <Col xs="12" sm="5" xl="2">
                    <FormGroup>
                      <Label>Postnummer</Label>
                      <Input
                        name="billing_post_code"
                        rows="1"
                        className="inputField"
                        onChange={(e) =>
                          invoiceHandler(
                            "billing_post_code",
                            e.target.value,
                            setInvoice
                          )
                        }
                        invalid={invoice.errors.billing_post_code !== ""}
                        required
                      />
                      {invoice.errors.billing_post_code === "" ? (
                        ""
                      ) : (
                        <p style={{ color: "red" }}>
                          {invoice.errors.billing_post_code}
                        </p>
                      )}
                    </FormGroup>
                  </Col>
                  <Col xs="12" sm="7" xl="3">
                    <FormGroup>
                      <Label>Poststed</Label>
                      <Input
                        name="billing_post_code_area"
                        rows="1"
                        className="inputField"
                        onChange={(e) =>
                          invoiceHandler(
                            "billing_post_code_area",
                            e.target.value,
                            setInvoice
                          )
                        }
                        invalid={invoice.errors.billing_post_code_area !== ""}
                        required
                      />
                      {invoice.errors.billing_post_code_area === "" ? (
                        ""
                      ) : (
                        <p style={{ color: "red" }}>
                          {invoice.errors.billing_post_code_area}
                        </p>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            ) : (
              <div />
            )}
            {view !== "" ? (
              <Row>
                <Col>
                  <Button
                    className="button darkButton saveButton"
                    onClick={() => saveInvoiceReceiver()}
                  >
                    Lagre fakturamottaker
                  </Button>
                </Col>
              </Row>
            ) : (
              <div />
            )}
          </Form>
        ) : (
          ""
        )}
      </Card>
    </div>
  );
};

export default IndividualStudentOverview;
