import {
  GET_FIELDSOFSTUDY_FAIL,
  GET_FIELDSOFSTUDY_REQUEST,
  GET_FIELDSOFSTUDY_SUCCESS,
} from "../constants";
const initialState = {
  loading: true,
  fields: [],
};

export const fieldsOfStudyReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_FIELDSOFSTUDY_REQUEST:
      return { ...state, loading: true, fields: initialState.fields };

    case GET_FIELDSOFSTUDY_SUCCESS:
      return { ...state, loading: false, fields: action.payload };

    case GET_FIELDSOFSTUDY_FAIL:
      return { ...state, loading: false };

    default:
      return state;
  }
};