import validator from "validator";

export const mustBeNumber = value => (isNaN(value) ? 'Must be a number' : undefined)

export const required = value => (value ? undefined : 'Required')

export const correctEmail = value => ( validator.isEmail(value) ? undefined : "Ugyldig epostadresse")

export const composeValidators = (...validators) => value =>
    validators.reduce((error, validator) => error || validator(value), undefined)
