import {useState} from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { useDialog } from 'react-st-modal'

export type DeleteDecision = "THIS_EVENT" | "ALL_FOLLOWING_EVENTS" | "ALL_EVENTS"

const DeleteModal = ({resolve}) => {
  const [value, setValue] = useState("THIS_EVENT");  // THIS_EVENT | ALL_FOLLOWING_EVENTS | ALL_EVENTS
  const {isOpen, close } = useDialog()

  return (
      <div id={"delete-modal-container"} style={{zIndex: 1050}} >
        <Modal
            open={isOpen}
            onClose={() => {close(null)}}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{
                position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400,
                bgcolor: 'background.paper', boxShadow: 24, p: 4,
            }}>
            <Typography id="modal-modal-title" variant="h6" component="h4">
              Slett denne gjentagende hendelsen
            </Typography>
            <FormControl style={{flexDirection: "column", display: "flex"}}>
              <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={value}
                  onChange={(event) => setValue(event.target.value)}
              >
                  <FormControlLabel value="THIS_EVENT" control={<Radio />} label="Denne hendelsen" />
                  <FormControlLabel value="ALL_FOLLOWING_EVENTS" control={<Radio />} label="Denne og påfølgende hendelser" />
                  <FormControlLabel value="ALL_EVENTS" control={<Radio />} label="Alle hendelser" />
              </RadioGroup>
              <div style={{display: "flex", justifyContent: "right"}}>
                <Button sx={{ mt: 1, mr: 1 }} variant="text" onClick={() => close(null)}>Avbryt</Button>
                <Button sx={{ mt: 1, mr: 1 }} variant="outlined" onClick={() => close(value)}>Ok</Button>
              </div>
            </FormControl>
          </Box>
        </Modal>
      </div>
  );
}

export default DeleteModal
