import React, {useEffect, useRef, useState} from "react"
import { useSelector, useDispatch } from "react-redux";
import ScheduleSelector from 'react-schedule-selector'
import {getUserSchedule, putUserSchedule} from "./availabilitySlice";


const UserScheduleSelector = ({userId, canChange}) => {

    const dispatch = useDispatch()
    const {schedules, loading} = useSelector((state) => state.availability)

    const [schedule, setSchedule] = useState([])
    const [didChange, setDidChange]= useState(false)


    const handleChange = (newSchedule) => {
        if (!canChange) return

        if (!didChange) {
            setDidChange(true)
        }

        setSchedule(newSchedule)
    }

    const sendSchedulePut = () => {

        // Sending only the intervals to the backend
        let sortedSchedule = [...schedule].sort()
        let intervals = []
        let i = 0;
        while (i < sortedSchedule.length) {
            let interval = {start_date_time: sortedSchedule[i], end_date_time: null}
            let intervalLength = 0
            for (let j = i; j < sortedSchedule.length; j++) {
                // Difference in minutes
                let difference = (
                    Math.abs(sortedSchedule[j].getTime() - sortedSchedule[i].getTime())/1000)/60
                if (difference > 30 * intervalLength) {
                    break
                }
                interval.end_date_time = sortedSchedule[j]
                intervalLength++
            }
            i += intervalLength
            intervals.push(interval)
        }
        dispatch(putUserSchedule({id: userId, schedule: schedule, intervals: intervals}))
    }

    useEffect(() => {
        dispatch(getUserSchedule({id: userId}))
    }, [])

    useEffect(() => {
        if (schedules.hasOwnProperty(userId)) {
            setSchedule(schedules[userId])
        }
    }, [schedules])


    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            // Put is only sent if things have changed
            if(didChange){
                sendSchedulePut()
            }
        }, 1000)

        return () => clearTimeout(delayDebounceFn)
    }, [schedule])

    const startDate = new Date(2023, 1, 13)

    return (
        <>
            <ScheduleSelector
                selection={schedule}
                selectionScheme={"linear"}
                dateFormat={"ddd"}
                startDate={startDate}
                timeFormat={"hh:mm a"}
                numDays={7}
                minTime={7}
                maxTime={21}
                hourlyChunks={2}
                onChange={handleChange}
            />
        </>
    )
}

export default UserScheduleSelector
