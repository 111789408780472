import {
    GET_SCHOOLS_FAIL,
    GET_SCHOOLS_REQUEST,
    GET_SCHOOLS_SUCCESS,
  } from "../constants";
  const initialState = {
    loading: true,
    schools: [],
  };
  
  export const schoolReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_SCHOOLS_REQUEST:
        return { ...state, loading: true, };
  
      case GET_SCHOOLS_SUCCESS:
        return { ...state, loading: false, schools: action.payload };
  
      case GET_SCHOOLS_FAIL:
        return { ...state, loading: false };
  
      default:
        return state;
    }
  };