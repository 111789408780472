import React, {useMemo, useState, useEffect} from "react";

import StudentDropdown from "./StudentDropdown";
import PaginatedTable from "../../../../../components/PaginatedTable/PaginatedTable";
import {fetchStudentPage, studentPageBaseUrl} from "../../../../../features/student/studentSlice";

// Page that displays all students for sales
const AllStudentsOverview = () => {
    // the dropdown element for the table
    const renderRowSubComponent = (row) => {
        const {
            id,
        } = row.original
        return (
                    <StudentDropdown id={id}/>
        )
    }

    const [showAll, setShowAll] = useState(false)
    const [filter, setFilter] = useState("is_active=1")

    useEffect(() => {
        if(showAll){
            setFilter("")
        }else{
            setFilter("is_active=1")
        }
    }, [showAll]);

    const columns = useMemo(
        () => [
            {
                Header: () => null,
                id: 'expandedButton', // 'id' is required
                Cell: ({ row }) => (
                    <span>
            {row.isExpanded ? '△' : '▽'}
          </span>
                )
            },
            {
                id: "name",
                Header: "Student",
                accessor: d => d.first_name + " " + d.last_name,
            },
            {
                id: "email",
                Header: "E-post",
                accessor: "email"
            },
            {
                id: "city",
                Header: "By",
                accessor: d => d.city
            },
            {
                id: "hours_left",
                Header: "Timer igjen av alle pakker",
                accessor: d => d.number_of_hours_remaining_in_packages + " timer"
            },
            {
                id: "days_since_last_activity",
                Header: "Dager siden siste time",
                accessor: d => {return (isNaN(d.days_since_last_activity) ? d.days_since_last_activity : d.days_since_last_activity + " dager")}
            },
            {
                id: "number_of_planned_hours",
                Header: "Antall planlagte timer",
                accessor: d => d.number_of_planned_hours + " timer"
            }
        ],
        []
    )

    // render component  reducer : students
    return (
        <>
          <div className="title mb-1">Elevoversikt</div>
            <div>
                <label>
                    <input
                        style={{ marginRight: "10px" }}
                        id="filterOnIsActive"
                        type="checkbox"
                        onChange={(e) => {
                            setShowAll(!showAll)
                        }}
                    />
                    Vis inaktive elever
                </label>
            </div>
            <PaginatedTable
                className="tableContainer"
                columns={columns}
                reducer='student'
                baseEndpoint={studentPageBaseUrl}
                api={fetchStudentPage}
                renderRowSubComponent={renderRowSubComponent}
                filter={filter}
            />
        </>
    );
};

export default AllStudentsOverview;
