import { combineReducers } from "redux";
import { listTabletsReducer } from "./reducers/tabletsReducer";
import layout from "./reducers/layoutReducer";
import sidebar from "./reducers/sidebarReducers";
import { reducer as toastr } from "react-redux-toastr";
import { listApplicantsReducer } from "./reducers/applicantsReducers";
import { applicationCreateReducer } from "./reducers/applicationReducers";
import { sessionCreateReducer } from "./reducers/sessionReducers";
import { authReducer } from "./reducers/userReducers";
import { subjectTreeReducer } from "./reducers/subjectReducers";
import { teacherSubjectReducer } from "./reducers/teacherSubjectsReducer";
import { studentReducer } from "./reducers/studentReducers";
import { activityReducer } from "./reducers/activityReducers";
import { packageReducer } from "./reducers/packageReducers";
import { teacherReducer } from "./reducers/teacherReducers";
import { fieldsOfStudyReducer } from "./reducers/fieldsOfStudyReducer";
import { cityReducer } from "./reducers/cityReducers";
import { employeeReducer } from "./reducers/employeeReducers";
import { salaryReducer } from "./reducers/salaryReducers";
import { schoolReducer } from "./reducers/schoolReducers"
import {teacherMatchingReducer} from "./reducers/TeacherMatchingReducer";
import {teacherOverviewReducer} from "./reducers/teacherOverviewReducer";
import { invoiceReceiverReducer } from "./reducers/invoiceReceiverReducers"
import { invoiceReducer} from "./reducers/invoiceReducer";
import studentSlice from "../features/student/studentSlice";
import errorSlice from "../features/error/errorSlice";
import invoiceReceiverSlice from "../features/invoiceReceiver/invoiceReceiverSlice";
import teacherSlice from "../features/teacher/teacherSlice";
import packageSlice from "../features/package/packageSlice";
import activitySlice from "../features/activity/activitySlice";
import availabilitySlice from "../features/availability/availabilitySlice";
import scheduleSlice from "../features/schedule/scheduleSlice";

export default combineReducers({
    sidebar,
    layout,
    toastr,
    tabletsList: listTabletsReducer,
    applicantsList: listApplicantsReducer,
    applicationCreate: applicationCreateReducer,
    availability: availabilitySlice,
    sessionCreate: sessionCreateReducer,
    currentUser: authReducer,
    students: studentReducer,
    student: studentSlice,
    invoiceReceivers: invoiceReceiverReducer,
    invoiceReceiver: invoiceReceiverSlice,
    teachers: teacherSlice, // ToDo: once the reducer below is removed, rename to 'teacher'
    subjects: subjectTreeReducer,
    teacherSubjects: teacherSubjectReducer,
    userActivities: activityReducer,
    activity: activitySlice,
    userPackages: packageReducer,
    package: packageSlice,
    teacher: teacherReducer, // ToDO: get rid of this
    teacherMatching: teacherMatchingReducer,
    teacherOverview: teacherOverviewReducer,
    fieldsOfStudy: fieldsOfStudyReducer,
    cities: cityReducer,
    schools: schoolReducer,
    employees: employeeReducer,
    salary: salaryReducer,
    errors: errorSlice,
    invoices: invoiceReducer,
    schedule: scheduleSlice
});
