import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {fetchStudentsWithSearch} from "./studentSlice";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import Loader from "../../components/Loader";
import StudentBox from "./StudentBox"

const StudentOverview = ({studentDetailUrl}) => {
    const dispatch = useDispatch();

    // gets the student info from the store
    const {entities, loading} = useSelector((state) => state.student);
    const {user} = useSelector((state) => state.currentUser.currentUser)

    // Makes redux fetch data from the backend
    useEffect(() => {
        dispatch(fetchStudentsWithSearch({search: ""}))
    }, [dispatch]);

    const getStudentBoxes = () =>
        entities.length > 0 ? (
            entities.map((student, key) => (
                <StudentBox
                    key={key}
                    student={student}
                    studentDetailUrl={studentDetailUrl}
                />
            ))
        ) : (
            <Col>Ingen elever å vise</Col>
        );

    // ToDo: this component should include some form of pagination
    //  maybe create a new styling version of the paginated table
    return (
        <div>
            {loading === 'idle' || loading === 'pending' ? (
                <Loader />
            ) : (
                <Card>
                    <CardHeader style={{ paddingBottom: "0" }}>
                        <div className="title">Mine elever</div>
                    </CardHeader>
                    <CardBody>
                        <Row>{getStudentBoxes()}</Row>
                    </CardBody>
                </Card>
            )}
        </div>
    );
};

export default StudentOverview;
