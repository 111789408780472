import React, {useEffect, useRef, useMemo, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Card,
    CardBody,
    CardHeader,
    Input,
    Col,
    Row,
    Table,
    Button,
    Label,
} from "reactstrap";
import Loader from "../../../../../components/Loader";
import {
    initialInvoiceUserState,
    invoiceHandler,
    validateInvoiceInput
} from "../../../Teacher/utils/addInvoiceReceiverHelpers";
import {getStudentDropdown} from "../../../../../redux/actions/getStudents";
import {dateFormatter, timeFormatter} from "../../../../../utils/formattingHelpers";
import {
    addStudentToInvoiceReceiver,
    getInvoiceReceiversSearch
} from "../../../../../redux/actions/studentOverviewActions";
import {createInvoiceReceiver} from "../../../../../redux/actions/createInvoiceReceiver";
import "./style.css";


// Page that displays all students
const StudentDropdown = ( {id} ) => {
    const dispatch = useDispatch();

    const didMount = useRef(false);

    const studentView = "Eleven er også fakturamottaker";
    const differentView = "Opprett ny fakturamottaker";
    const existingView = "Velg eksisterende fakturamottaker";
    const laterView = "Opprett fakturamottaker senere";

    const [edit, setEdit] = useState(false);

    const [invoice, setInvoice] = useState(initialInvoiceUserState);

    const [view, setView] = useState("");

    const [loading, setLoading] = useState(true);

    const [choiceSelected, setChoiceSelected] = useState(false);

    const [searchString, setSearchString] = useState("");

    const [student, setStudent] = useState(null);

    const [searched, setSearched] = useState(false);

    const invoiceReceiverState = useSelector((state) => state.invoiceReceivers);

    const { loadingInvoiceReceivers, allInvoiceReceivers, invoiceReceiverSearchResult } = invoiceReceiverState;

    const [chosenIR, setChosenIR] = useState(null);
    const [invoiceReceiverPatch, setInvoiceReceiverPatch] = useState({});


    const invoiceError = invoiceReceiverState.emailError;

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if(didMount.current){
                searchForInvoiceReceiver();
            }else{
                didMount.current = true;
            }
        }, 500)

        return () => clearTimeout(delayDebounceFn)
    }, [searchString, searched])


    const searchForInvoiceReceiver = () => {
        dispatch(getInvoiceReceiversSearch(searchString));
    }

    useEffect(() => {
        invoice.fields.self_as_student = view === studentView;
    }, [view])

    const saveInvoiceReceiver = () => {
        if(view === existingView){
            setInvoiceReceiverPatch({
                add_new_student: id
            })
        }else if(view === differentView){
            invoice.fields.pays_for_students.push(id);
            let validInvoice = validateInvoiceInput(invoice, setInvoice);

            if(validInvoice){
                dispatch(createInvoiceReceiver({invoice: invoice}));
                setLoading(true);
            }

            if(invoiceError === ""){
                console.log("nothing");
            }

        }
        else if(view === studentView){
            invoice.self_as_student = true;
            invoice.fields.pays_for_students = [id];
            dispatch(createInvoiceReceiver({invoice: invoice}));
            setLoading(true);
        }

    }

    useEffect(() => {
        if(didMount.current){
            dispatch(addStudentToInvoiceReceiver(chosenIR.id, invoiceReceiverPatch));
            student.invoice_receivers.push(chosenIR);
            setEdit(false);
        }else{
            didMount.current = true;
        }
    }, [invoiceReceiverPatch])

    useEffect(() => {
        setEdit(false);
        dispatch(getStudentDropdown(id)).then(data => {
            setStudent(data);
        })
        setLoading(false);
    }, [dispatch, loading]);

    // render component
    return (
        <div>
            <Card style={{boxShadow: "0 0 0 0", background: "#f4f4f4"}}>
                <CardHeader style={{ paddingBottom: "0", textAlign: "left", background: "#f4f4f4" }}>
                    <Row>
                        <Col>
                            <div className="title">Elevdetaljer</div>
                        </Col>
                        <Col>
                            <Button className="button darkButton btn btn-secondary" style={{float: "right"}} onClick={() => {
                                setEdit(!edit);
                            }}>{edit ? "Avbryt" : "Rediger"}</Button>
                        </Col>
                    </Row>
                </CardHeader>
                {
                    edit ?
                        <CardBody style={{textAlign: "left"}}>

                            <Row style={{display:"flex"}}>
                                <Col xs="12" >
                                    <div className="secondTitle" style={{marginBottom: "20px", marginTop: "20px"}}>
                                        Velg alternativ for fakturamottaker
                                    </div>
                                </Col>
                                <Col className="fullFlexCentre.col-md-4" style={{marginBottom: "10px"}}>
                                    <Input type="button" className="button mediumButton" value={studentView} onClick={() => {
                                        setView(studentView);
                                        setChoiceSelected(true);
                                    }}
                                           style={view === studentView ? {whiteSpace: "normal",width: "250px", backgroundColor: "#2a9d8f85"} : {whiteSpace: "normal",width: "250px"}}/>
                                </Col>
                                <Col className="fullFlexCentre.col-md-4" style={{marginBottom: "10px"}}>
                                    <Input type="button" className="button mediumButton" value={differentView} onClick={() => {
                                        setView(differentView);
                                        setChoiceSelected(true);
                                    }}
                                           style={view === differentView ? {whiteSpace: "normal",width: "250px", backgroundColor: "#2a9d8f85"} : {whiteSpace: "normal",width: "250px"}}/>
                                </Col>
                                <Col className="fullFlexCentre.col-md-4" style={{marginBottom: "10px"}}>
                                    <Input type="button" className="button mediumButton" value={existingView} onClick={() => {
                                        setView(existingView);
                                        setChoiceSelected(false);
                                        setChosenIR(null);
                                    }}
                                           style={view === existingView ? {whiteSpace: "normal",width: "250px", backgroundColor: "#2a9d8f85"} : {whiteSpace: "normal",width: "250px"}}/>
                                </Col>
                            </Row>
                            {view === differentView ?
                                <Col style={{marginTop: "20px"}}>
                                    <Row>
                                        <Col xs="12" sm="5">

                                            <Label>Fornavn</Label>
                                            <Input
                                                name="fName"
                                                placeholder="Ola"
                                                rows="1"
                                                className="inputField"
                                                onChange={(e) => invoiceHandler("billing_first_name", e.target.value, setInvoice)}
                                                invalid={invoice.errors.billing_first_name !== ""}
                                                required
                                            />
                                            {invoice.errors.billing_first_name === "" ? "" : <p style={{ color: "red" }}>{invoice.errors.billing_first_name}</p>}

                                        </Col>
                                        <Col xs="12" sm="7">

                                            <Label>Etternavn</Label>
                                            <Input
                                                name="lName"
                                                placeholder="Nordmann"
                                                rows="1"
                                                className="inputField"
                                                onChange={(e) => invoiceHandler("billing_last_name", e.target.value, setInvoice)}
                                                invalid={invoice.errors.billing_last_name !== ""}
                                                required
                                            />
                                            {invoice.errors.billing_last_name === "" ? "" : <p style={{ color: "red" }}>{invoice.errors.billing_last_name}</p>}

                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="12" md="5">

                                            <Label>Telefonnummer</Label>
                                            <Input
                                                name="phoneFac"
                                                rows="1"
                                                className="inputField"
                                                onChange={(e) => invoiceHandler("billing_phone_number", e.target.value, setInvoice)}
                                                invalid={invoice.errors.billing_phone_number !== ""}
                                                required
                                            />
                                            {invoice.errors.billing_phone_number === "" ? "" : <p style={{ color: "red" }}>{invoice.errors.billing_phone_number}</p>}

                                        </Col>
                                        <Col xs="12" md="7">

                                            <Label>E-post</Label>
                                            <Input
                                                name="emailFac"
                                                rows="1"
                                                className="inputField"
                                                onChange={(e) => invoiceHandler("billing_email", e.target.value, setInvoice)}
                                                invalid={invoice.errors.billing_email !== ""}
                                                required
                                            />
                                            {invoice.errors.billing_email === "" ? "" : <p style={{ color: "red" }}>{invoice.errors.billing_email}</p>}

                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="12" sm="12" xl="7">

                                            <Label>Adresse</Label>
                                            <Input
                                                name="billing_address"
                                                rows="1"
                                                className="inputField"
                                                onChange={(e) => invoiceHandler("billing_address", e.target.value, setInvoice)}
                                                invalid={invoice.errors.billing_address !== ""}
                                                required
                                            />
                                            {invoice.errors.billing_address === "" ? "" : <p style={{ color: "red" }}>{invoice.errors.billing_address}</p>}

                                        </Col>
                                        <Col xs="12" sm="5" xl="2">

                                            <Label>Postnummer</Label>
                                            <Input
                                                name="billing_post_code"
                                                rows="1"
                                                className="inputField"
                                                onChange={(e) => invoiceHandler("billing_post_code", e.target.value, setInvoice)}
                                                invalid={invoice.errors.billing_post_code !== ""}
                                                required
                                            />
                                            {invoice.errors.billing_post_code === "" ? "" : <p style={{ color: "red" }}>{invoice.errors.billing_post_code}</p>}

                                        </Col>
                                        <Col xs="12" sm="7" xl="3">

                                            <Label>Poststed</Label>
                                            <Input
                                                name="billing_post_code_area"
                                                rows="1"
                                                className="inputField"
                                                onChange={(e) => invoiceHandler("billing_post_code_area", e.target.value, setInvoice)}
                                                invalid={invoice.errors.billing_post_code_area !== ""}
                                                required
                                            />
                                            {invoice.errors.billing_post_code_area === "" ? "" : <p style={{ color: "red" }}>{invoice.errors.billing_post_code_area}</p>}

                                        </Col>
                                    </Row>
                                </Col>
                                : <div/>
                            }
                            {view === existingView ?
                                <Col style={{marginTop: "20px"}} >
                                    Søk etter fakturamottaker:
                                    <Row>
                                        <Col >
                                            <Input
                                                name="fName"
                                                placeholder="Navn/Epost/Tlf"
                                                rows="1"
                                                className="inputField"
                                                onChange={(e) => {
                                                    setSearched(true);
                                                    setSearchString(e.target.value);
                                                }}
                                                style={{width: "100%"}}
                                            />
                                            {
                                                searched ? <div className="ir-list" style={{marginTop: "15px"}}>
                                                    {invoiceReceiverSearchResult.map((res) => (
                                                        <div className="ir-list-item" onClick={() => {
                                                            setChosenIR(res);
                                                            setChoiceSelected(true);
                                                        }}>
                                                            {res.first_name} {res.last_name} - {res.email} - tlf: {res.phone_number}
                                                        </div>
                                                    ))}
                                                </div> : <div/>
                                            }
                                        </Col>
                                        <Col>
                                            {chosenIR ?
                                                <div>
                                                    <p>{chosenIR.first_name} {chosenIR.last_name}</p>
                                                    <p>Tlf: {chosenIR.phone_number}</p>
                                                    <p>Epost: {chosenIR.email}</p>
                                                </div>
                                                :
                                                <p>Ingen fakturamottaker valgt</p>
                                            }
                                        </Col>
                                    </Row>

                                </Col>
                                :
                                <div/>
                            }
                            {
                                view !== "" && choiceSelected?
                                    <Button className="button darkButton btn btn-secondary" style={{float: "right", marginTop: "15px"}} onClick={() => {
                                        saveInvoiceReceiver();
                                    }}>Lagre</Button>
                                    :
                                    <div/>
                            }

                        </CardBody>
                        :
                        <CardBody style={{textAlign: "left"}}>
                            {student ? <div>
                                    <Row>
                                        <Col>
                                            <h3>Om eleven</h3>
                                            <div>
                                                Alder: {student.student_info.age}
                                            </div>
                                            <div>
                                                Kontaktperson: {
                                                student.student_info.contact_person ?
                                                    student.student_info.contact_person.first_name + " " +
                                                    student.student_info.contact_person.last_name : ""
                                            }
                                            </div>
                                            <div style={{marginBottom: "15px"}}>
                                                Fag: {student.student_info.subjects.map(
                                                (subject) => student.student_info.subjects.indexOf(subject) + 1
                                                === student.student_info.subjects.length ? subject.subject_name
                                                    : subject.subject_name + ", "
                                            )}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="ir-list">
                                            <h3>Lærer(e)</h3>
                                            {student.teachers.map((teacher, key) => {
                                                Object.keys(teacher).forEach(key => {if (!teacher[key])  teacher[key] = ""})
                                                const info =
                                                    "Navn: "+ teacher.first_name + " " + teacher.last_name +
                                                    "\nE-post: " + teacher.email +
                                                    "\nTlf: " + teacher.phone_number +
                                                    "\nBy: " + teacher.city_name + "\n\n";
                                                return (
                                                    <div key={key} style={{whiteSpace: "pre-wrap"}}>
                                                        {info}
                                                    </div>
                                                )
                                            })}
                                        </Col>
                                        <Col className="ir-list">
                                            <h3>Fakturamottaker(e)</h3>
                                            {student.invoice_receivers.map((ir, key) => {
                                                Object.keys(ir).forEach(key => {if (!ir[key])  ir[key] = ""})
                                                const info =
                                                    "Navn: "+ ir.first_name + " " + ir.last_name +
                                                    "\nE-post: " + ir.email +
                                                    "\nTlf: " + ir.phone_number +
                                                    "\nAdresse: " + ir.address + " " + ir.post_code + " " + ir.post_code_area + "\n\n";
                                                return (
                                                    <div key={key} style={{whiteSpace: "pre-wrap"}}>
                                                        {info}
                                                    </div>
                                                )
                                            })}
                                        </Col>
                                    </Row>
                                    <Row style={{marginTop: "35px", marginLeft: "5px"}}>
                                        <h3>Pakker:</h3>
                                    </Row>
                                    <Row className="ir-list" style={{marginTop: "15px", marginLeft: "5px"}}>
                                        {student.packages.map((pack, key) => {return (<div key={key}>
                                            <Card style={{marginRight: "10px"}}>
                                                <CardHeader>{pack.name} - {pack.hours_in_package} timer</CardHeader>
                                                <CardBody style={{textAlign: "left"}}>
                                                    <p>Dato solgt: {`${dateFormatter(new Date(pack.date_sold))}`}</p>
                                                    <p>Timer igjen: {pack.remaining_hours}</p>
                                                    <p>Fakturamottaker: {pack.invoice_receiver_name}</p>
                                                    <p>Total pris: {pack.sold_for_price} kr</p>
                                                </CardBody>
                                            </Card>
                                        </div>)})}
                                    </Row>
                                    <Row style={{marginLeft: "5px"}}>
                                        <h3>Aktiviteter:</h3>
                                    </Row>
                                    <Row  style={{marginTop: "15px", marginLeft: "5px"}}>
                                        <div className="tableContainer under-top-layer">
                                            <Table>
                                                <thead>
                                                <tr>
                                                    <th> Aktivitetskategori </th>
                                                    <th> Lærer </th>
                                                    <th> Starttidspunkt </th>
                                                    <th> Varighet </th>
                                                </tr>
                                                </thead>
                                                {student.activities.length > 1 ? student.activities.map((activity, key) => {
                                                    return (
                                                        <tbody className="ir-list" key={key}>
                                                        <tr>
                                                            <td>{activity.activity_category_name}</td>
                                                            <td>{activity.teacher_name}</td>
                                                            <td>{`${dateFormatter(new Date(activity.start_date_time))}`} {`${timeFormatter(new Date(activity.start_date_time))}`}</td>
                                                            <td>{activity.duration} time(r)</td>
                                                        </tr>
                                                        </tbody>
                                                    )
                                                }) : <tbody>
                                                <tr>
                                                    <td>Eleven har ingen aktiviteter</td>
                                                    <td/>
                                                    <td/>
                                                    <td/>
                                                </tr>
                                                </tbody>
                                                }
                                            </Table>
                                        </div>

                                    </Row>
                                </div>
                                :
                                <Loader/>}
                        </CardBody>
                }
            </Card>
        </div>
    );
};

export default StudentDropdown;
