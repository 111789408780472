import React, { useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { connect } from "react-redux";
import {NavLink, withRouter} from "react-router-dom";
import { Badge, Collapse } from "reactstrap";
import rhLogo from "../assets/img/RH/Realfagshjelpen_Logo_PNG_Original.png";
import {managementRoutes} from "../modules/authenticated/Management/routesConfig";
import {customerRoutes} from "../modules/authenticated/Customer/routesConfig";
import {teacherRoutes} from "../modules/authenticated/Teacher/routesConfig";
import store from "../redux/store";
import {filterIllegalRoutes, getAuthGroups, homePath} from "../utils/authHelpers";
import {CUSTOMER_TYPE} from "../modules/authenticated/Customer/customerConstants";
import {TEACHER_TYPE} from "../modules/authenticated/Teacher/teacherConstants";
import {MANAGEMENT_TYPE} from "../modules/authenticated/Management/managementConstants";

let routes = []
let homepage = ""

const InitOpenRoutes = (location) => {
  /* Open collapse element that matches current url */
  const pathName = location.pathname;

  let _routes = {};
  const userState = store.getState().currentUser.currentUser;
  const userType = userState.type;

  const getSideBarRoutes = (userType) => {
      switch (userType) {
          case CUSTOMER_TYPE:
              routes = filterIllegalRoutes(userState, customerRoutes, true);
              break;
          case TEACHER_TYPE:
              routes = filterIllegalRoutes(userState, teacherRoutes, true);
              break;
          case MANAGEMENT_TYPE:
              routes = filterIllegalRoutes(userState, managementRoutes, true);
              break;
          default:
              console.error("Incorrect user type");
              break;
      }
      homepage = homePath(userType);
      return routes;
  }

  getSideBarRoutes(userType).forEach((route, index) => {
    const isActive = pathName.indexOf(route.path) === 0;
    const isOpen = route.open;
    const isHome = !!(route.containsHome && pathName === "/");
    _routes = Object.assign({}, _routes, {[index]: isActive || isOpen || isHome})
  });
  return _routes;
};

const SidebarCategory = withRouter(
  ({
    name,
    badgeColor,
    badgeText,
    icon: Icon,
    isOpen,
    children,
    onClick,
    location,
    to
  }) => {
   
    const getSidebarItemClass = path => {
      return location.pathname.indexOf(path) !== -1 ||
        (location.pathname === "/" && path === "/student")
        ? "active"
        : "";
    };

    return (
      <li>
        <span
          data-toggle="collapse"
          onClick={onClick}
          aria-expanded={isOpen ? "true" : "false"}
        >
          <Icon color={"#000000"} size={18} />
          <span className="align-middle">{name}</span>
          {badgeColor && badgeText ? (
            <Badge color={"#000000"} size={18}>
              {badgeText}
            </Badge>
          ) : null}
        </span>
        <Collapse isOpen={isOpen}>
          <ul id="item" >
            {children}
          </ul>
        </Collapse>
      </li>
    );
  }
);

const SidebarItem = withRouter(
  ({ name, badgeColor, badgeText, icon: Icon, location, to }) => {
    const getSidebarItemClass = path => {
      return location.pathname === path ? "active" : "";
    };
    return (
      <li className={"sidebar-item " + getSidebarItemClass(to)}>
        <NavLink to={to} className="sidebar-link" activeClassName="active">
          {Icon ? <Icon color={"#000000"} size={18} className="align-middle mr-3" /> : null}
          {name}
          {badgeColor && badgeText ? (
            <Badge color={"#000000"} size={18} className="sidebar-badge">
              {badgeText}
            </Badge>
          ) : null}
        </NavLink>
      </li>
    );
  }
);

const Sidebar = ({ location, sidebar, layout }) => {
  const [openRoutes, setOpenRoutes] = useState(() => InitOpenRoutes(location));

  const toggle = index => {
    // Collapse all elements
    Object.keys(openRoutes).forEach(
      item => openRoutes[index] || setOpenRoutes(openRoutes => Object.assign({}, openRoutes, {[item]: false}))
    )
    
    // Toggle selected element
    setOpenRoutes(openRoutes => Object.assign({}, openRoutes, {[index]: !openRoutes[index]}));
  }
  
  return (
    <nav
      className={
        "sidebar" +
        (!sidebar.isOpen ? " toggled" : "") +
        (sidebar.isSticky ? " sidebar-sticky" : "")
      }
      style={{backgroundColor: "#F2F2F2", color: "#000000"}}
    >
      <div className="sidebar-content"  style={{backgroundColor: "#F2F2F2", color: "#000000"}}>
        <PerfectScrollbar>
          <a className="sidebar-brand" href={homepage}>
          <img
              className="img-fluid"
              src={rhLogo}
              alt="rhLogo"
            />
          </a>
          <ul className="sidebar-nav">
            {routes.map((route, index) =>
                route.children ?
                  <React.Fragment key={index}>
                    <SidebarCategory
                      name={route.name}
                      badgeColor={route.badgeColor}
                      badgeText={route.badgeText}
                      icon={route.icon}
                      to={route.path}
                      isOpen={openRoutes[index]}
                      onClick={() => toggle(index)}
                    >
                      {route.children.map((child, i) => (
                        <SidebarItem
                          key={i}
                          name={child.name}
                          icon={child.icon}
                          to={child.path}
                          badgeColor={child.badgeColor}
                          badgeText={child.badgeText}
                        />
                      ))}
                    </SidebarCategory>
                  </React.Fragment>
                   :
                <React.Fragment key={index}>
                    <SidebarItem
                      key={index}
                      name={route.name}
                      to={route.path}
                      icon={route.icon}
                      badgeColor={route.badgeColor}
                      badgeText={route.badgeText}
                    />
                </React.Fragment>

            )}
          </ul>
        </PerfectScrollbar>
      </div>
    </nav>
  )
}

export default withRouter(
  connect(store => ({
    sidebar: store.sidebar,
    layout: store.layout
  }))(Sidebar)
);
