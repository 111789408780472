import {
  GET_CITIES_FAIL,
  GET_CITIES_REQUEST,
  GET_CITIES_SUCCESS,
} from "../constants";
const initialState = {
  loading: true,
  cities: [],
};

export const cityReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CITIES_REQUEST:
      return { ...state, loading: true, cities: initialState.cities };

    case GET_CITIES_SUCCESS:
      return { ...state, loading: false, cities: action.payload };

    case GET_CITIES_FAIL:
      return { ...state, loading: false };

    default:
      return state;
  }
};