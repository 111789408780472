import React from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import {isInvoiceReceiver, isStudent} from "../../../../../utils/authHelpers";
import {NonAuthRoutes} from "../../../../unauthenticated/routesConfig";
import StudentDashboard from "./StudentDashboard";
import InvoiceReceiverDashboard from "./InvoiceReceiverDashboard";
import {INVOICE_RECEIVER, STUDENT} from "../../customerConstants";

const CustomerDashboard = () => {

    const user = useSelector(
        (state) => state.currentUser.currentUser.user
    );

    const render = (user) => {
        if (isStudent(user) && isInvoiceReceiver(user)) {
            // ToDo: Add specific dashboard for customers that have this characteristic
            return <StudentDashboard />
        }

        if (isStudent(user)) {
            return <StudentDashboard />
        }

        if (isInvoiceReceiver(user)) {
            return <InvoiceReceiverDashboard />
        }

        return <Redirect to={NonAuthRoutes.serverError} />
    }

    return render(user)
}

export default CustomerDashboard;