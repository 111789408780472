import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, CardHeader, CardTitle, Container, FormGroup, Label, CardImg, CardText, Input, CustomInput, Col, Row, Modal, ModalHeader, ModalBody, Button } from "reactstrap";
import Select from 'react-select';
import {getTeacherOverview, patchTeacher} from "../../../../../redux/actions/teacherOverviewActions";
import {getTeacher} from "../../../../../redux/actions/getTeacher";
import {getCities} from "../../../../../redux/actions/getCities";
import {getFieldsOfStudy} from "../../../../../redux/actions/getFieldsOfStudy";
import Loader from "../../../../../components/Loader";
import {editTeacherDetails} from "../../../../../redux/actions/editTeacherDetails";

import { useHistory } from "react-router-dom";

const InitialUpdateTeacher = () => {
    const dispatch = useDispatch();

    const history = useHistory()

    const [teacher, setTeacher] = useState(undefined);

    const [error, setError] = useState([]);

    const [patch, setPatch] = useState({})

    useEffect(() => {
        dispatch(getTeacher()).then(
            data => setTeacher(data)
        );
    }, [dispatch]);

    const initialTeacherPatch = {
        id: null,
        patch: {
        },
        subjects: []
    }

    const updateTeacher = () => {
        //console.log("updating teacher:", teacherPatch);

        dispatch(editTeacherDetails(teacherPatch.patch)).then(response => {
            if (response){
                for (var key in response.data) {
                    if (response.data.hasOwnProperty(key)) {
                        error.push(key + ": " + response.data[key])
                    }
                }
                setMessageIsOpen(true);
            } else {
                history.push("/teacher/profile");
            }
        });

    }

    const [teacherPatch, setTeacherPatch] = useState(initialTeacherPatch);

    // modal functionality
    const [messageIsOpen, setMessageIsOpen] = useState(false);

    const form = teacher => {
        const {
            id,
            email,
            first_name,
            last_name,
            city,
            city_name,
            age,
            gender,
            slack,
            our_comments,
            other_comments,
            activity_categories,
            students,
            subjects,
            field_of_study_name,
            police_report_file,
            diploma_file,
            verified_police_attestation,
            is_active,
            birth_date,
            phone_number,
            address,
            post_code,
            post_code_area,
            bank_account_number,
            social_security,
            heard_about_rh,
        } = teacher

        // current data and variables for submitting new data

        let birth_date_array = [];
        if (birth_date) {
            birth_date_array = birth_date.split("-");
        }


        return (
            <Card>
                <CardBody>
                    <h1>Rediger Lærer - Du ser denne siden fordi noen av feltene under ikke er fylt inn.</h1>
                    <Row style={{marginBottom: "20px", marginTop: "40px"}}>
                        <Col xs="12" sm="5">
                            <FormGroup>
                                <Label>Fornavn</Label>
                                <Input
                                    name="fName"
                                    placeholder="Ola"
                                    rows="1"
                                    defaultValue={first_name}
                                    className="inputField"
                                    onChange={(e) => patch.first_name = e.target.value}
                                    invalid={false}
                                    required
                                />
                            </FormGroup>
                        </Col>
                        <Col xs="12" sm="7">
                            <FormGroup>
                                <Label>Etternavn</Label>
                                <Input
                                    name="lName"
                                    placeholder="Nordmann"
                                    defaultValue={last_name}
                                    rows="1"
                                    className="inputField"
                                    onChange={(e) => patch.last_name = e.target.value}
                                    invalid={false}
                                    required
                                />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row style={{marginBottom: "20px"}}>
                        <Col>
                            <Label>Fødselsdato</Label>
                            <Row>
                                <Col>
                                    <Input
                                        name="year"
                                        placeholder="YYYY"
                                        defaultValue={birth_date_array[0]}
                                        rows="1"
                                        className="inputField"
                                        onChange={(e) => birth_date_array[0] = e.target.value}
                                        invalid={false}
                                        required
                                    />
                                </Col>
                                <Col>
                                    <Input
                                        name="month"
                                        placeholder="MM"
                                        defaultValue={birth_date_array[1]}
                                        rows="1"
                                        className="inputField"
                                        onChange={(e) => birth_date_array[1] = e.target.value}
                                        invalid={false}
                                        required
                                    />
                                </Col>
                                <Col>
                                    <Input
                                        name="day"
                                        placeholder="DD"
                                        type="integer"
                                        defaultValue={birth_date_array[2]}
                                        rows="1"
                                        className="inputField"
                                        onChange={(e) => birth_date_array[2] = e.target.value}
                                        invalid={false}
                                        required
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label>Telefonnummer</Label>
                                <Input
                                    name="phone"
                                    placeholder="tlf"
                                    rows="1"
                                    defaultValue={phone_number}
                                    className="inputField"
                                    onChange={(e) => patch.phone_number = e.target.value}
                                    invalid={false}
                                    required
                                />
                            </FormGroup>

                        </Col>
                    </Row>
                    <Row style={{marginBottom: "20px"}}>
                        <Col>
                            <FormGroup>
                                <Label>Adresse</Label>
                                <Input
                                    name="address"
                                    placeholder="Adresse"
                                    rows="1"
                                    defaultValue={address}
                                    className="inputField"
                                    onChange={(e) => patch.address = e.target.value}
                                    invalid={false}
                                    required
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <Row>
                                <Col xs={4}>
                                    <FormGroup>
                                        <Label>Postnummer</Label>
                                        <Input
                                            name="postcode"
                                            placeholder="0000"
                                            rows="1"
                                            defaultValue={post_code}
                                            className="inputField"
                                            onChange={(e) => patch.post_code = e.target.value}
                                            invalid={false}
                                            required
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs={8}>
                                    <FormGroup>
                                        <Label>Poststed</Label>
                                        <Input
                                            name="postcodearea"
                                            placeholder="Poststed"
                                            rows="1"
                                            defaultValue={post_code_area}
                                            className="inputField"
                                            onChange={(e) => patch.post_code_area = e.target.value}
                                            invalid={false}
                                            required
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row style={{marginBottom: "20px"}}>
                        <Col>
                            <FormGroup>
                                <Label>Kontonummer</Label>
                                <Input
                                    name="bankaccountnumber"
                                    placeholder=""
                                    rows="1"
                                    defaultValue={bank_account_number}
                                    className="inputField"
                                    onChange={(e) => patch.bank_account_number = e.target.value}
                                    invalid={false}
                                    required
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label>Fødselsnummer (11 siffer)</Label>
                                <Input
                                    name="socialsecurity"
                                    placeholder=""
                                    rows="1"
                                    defaultValue={social_security}
                                    className="inputField"
                                    onChange={(e) => patch.social_security = e.target.value}
                                    invalid={false}
                                    required
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row style={{marginBottom: "20px"}}>
                        <Col>
                            <FormGroup>
                                <Label>Hvor hørte du om Realfagshjelpen?</Label>
                                <Input
                                    name="heardaboutrh"
                                    placeholder=""
                                    rows="1"
                                    defaultValue={heard_about_rh}
                                    className="inputField"
                                    onChange={(e) => patch.heard_about_rh = e.target.value}
                                    invalid={false}
                                    required
                                />
                            </FormGroup>
                        </Col>
                        <Col></Col>
                    </Row>
                    <Row style={{float: "right", marginTop: "10px"}}>
                        <Col style={{textAlign: "right", marginTop: "10px"}}>
                            <Button className="button darkButton saveButton" onClick={() => {
                                //console.log(teacherPatch);
                                patch.birth_date = birth_date_array[0] + "-" + birth_date_array[1] + "-" + birth_date_array[2];
                                teacherPatch.id = id;
                                teacherPatch.patch = patch;
                                console.log(teacherPatch);
                                updateTeacher();
                            }}>Lagre</Button>
                        </Col>
                    </Row>
                </CardBody>

                <Modal isOpen={messageIsOpen} onClick={() => setMessageIsOpen(false)}>
                    <div
                        id="modalHeader"
                        className="fullFlexCentre"
                    >
                        <div className="title whiteText">Feil</div>
                    </div>
                    <ModalBody>
                        <div>Det skjedde en feil:</div>
                        {error.map((e, key) => {
                            return <div key={key}>{e}</div>
                        })}
                        <Button className="button darkButton" style={{float: "center", margin: "auto", marginTop: "20px"}} onClick={() => {
                            setError([]);
                            setMessageIsOpen(false);
                        }}>Ok</Button>
                    </ModalBody>
                </Modal>
            </Card>
        )
    }



    return (
        teacher ?
            <div>
                {form(teacher.teacher)}
            </div>
            :
            <Loader/>
    )
}

export default InitialUpdateTeacher;