import axiosInstance from "../../axios";
import {
  GET_TEACHER_SALARIES_FAIL,
  GET_TEACHER_SALARIES_REQUEST,
  GET_TEACHER_SALARIES_SUCCESS,
} from "../constants";
export const getTeacherSalaries = (payload) => async (dispatch) => {
  try {
    dispatch({ type: GET_TEACHER_SALARIES_REQUEST });

    const { data } = await axiosInstance.post(
      "/salary/next-payment-overview/",
      payload
    );

    dispatch({ type: GET_TEACHER_SALARIES_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_TEACHER_SALARIES_FAIL,
      error:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
