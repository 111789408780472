import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Row,
  Col,
  FormGroup,
  Input,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";

import validator from "validator";
import { addPackageStudent } from "../../../../../redux/actions/addPackageStudent";
import { getAllStudents } from "../../../../../redux/actions/getAllStudents";
import { getAllInvoiceReceivers } from "../../../../../redux/actions/invoiceReceiverActions";
import { getPackageTypes } from "../../../../../redux/actions/getPackageTypes";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../../../components/Loader";
import { getDbDate } from "../../../../../utils/timeUtils";
import { Multiselect } from "multiselect-react-dropdown";
import MessageModal from "../../../../../components/MessageModal";

const NewPackage = () => {

  /* GLOBAL STATE - REDUX */
  const packages = useSelector((state) => state.userPackages);
  const { packageTypes, actionSucceeded } = packages;
  const submitLoading = packages.loading;

  const students = useSelector((state) => state.students);
  const { loadingStudents, allStudents } = students;

  const invoiceReceiverState = useSelector((state) => state.invoiceReceivers);
  const { loadingInvoiceReceivers, allInvoiceReceivers } = invoiceReceiverState;

  /* HANDLE LOCAL STATE */
  // Package state
  const initialPackageState = {
    fields: {
      dateSold: "",
      description: "",
      packageID: "",
      discount: 0,
      extraHours: 0,
      extraPrice: 0,
      numberOfPayments: 1,
      invoiceReceiverID: -1,
      comment: "",
    },

    errors: {
      dateSold: "",
      description: "",
      packageID: "",
      students: "",
      discount: "",
      extraHours: "",
      extraPrice: "",
      numberOfPayments: "",
      invoiceReceiverID: "",
      comment: "",
    },
  };
  const [packageInfo, setPackage] = useState(initialPackageState);

  /*Message to user*/
  const [infoOpen, setOpen] = useState(false);
  const [submitted, setSubmitted] = useState(false);

    /* Handle multi select of students */
  const [selectedStudents, setSelectedStudents] = useState(new Set());
  const [selectedStudentsIDs, setSelectedStudentsIDs] = useState([]);
  const [sendID, setSendID] = useState(selectedStudentsIDs);

  // Handle render of invoice receivers based on selected students
  const [invoiceReceivers, setInvoiceReceivers] = useState([]);

  const [messageInfo, setMessageInfo] = useState({});
  const [messageIsOpen, setMessageIsOpen] = useState(false);

  /* EFFECT HOOKS */


  useEffect(() => {
    if (!submitLoading && submitted) {
      if (actionSucceeded) displayMarkingSuccess();
      else displayMarkingFailed();
      toggleMessageIsOpen();
      setSubmitted(false);
    }
  }, [submitLoading]);


  /* METHODS */
  const packageHandler = (field, value) => {
    setPackage((prevState) => ({
      ...prevState,
      fields: {
        ...prevState.fields,
        [field]: value,
      },
    }));
  };

  /* Print error message */
  const printErrormsg = (field) => {
    return packageInfo.errors[field] === "" ? (
      ""
    ) : (
      <p style={{ color: "red" }}>{packageInfo.errors[field]}</p>
    );
  };

  /* Set state to the same, except for error.
  There you set the field to the error message. */
  const errorHandler = (field, errormsg) => {
    setPackage((prevState) => ({
      ...prevState,
      errors: {
        ...prevState.errors,
        [field]: errormsg,
      },
    }));
  };

  /* Validates input */
  const validateInput = (packageInfo) => {
    let valid = true;

    /* Date sold*/
    errorHandler("dateSold", "");
    if (packageInfo.fields.dateSold === "") {
      errorHandler("dateSold", "Dato ikke valgt");
      valid = false;
    }

    if (!validator.isDate(packageInfo.fields.dateSold)) {
      errorHandler("dateSold", "Valgt dato er ikke gyldig");
      valid = false;
    }

    if (new Date(packageInfo.fields.dateSold) > new Date()) {
      errorHandler("dateSold", "Valgt dato kan ikke være frem i tid");
      valid = false;
    }

    /* Description*/
    errorHandler("description", "");
    if (packageInfo.fields.description === "") {
      errorHandler("description", "Beskrivelse mangler");
      valid = false;
    }

    /* PackageID */
    errorHandler("packageID", "");
    if (packageInfo.fields.packageID === "") {
      errorHandler("packageID", "Pakke ikke valgt");
      valid = false;
    }

    /* Students*/
    errorHandler("students", "");
    if (selectedStudents.length === 0) {
      errorHandler("students", "Ingen elev valgt");
      valid = false;
    }

     /* InvoiceReceiverID*/
    errorHandler("invoiceReceiverID", "");
    if (packageInfo.fields.invoiceReceiverID <= 0) {
      errorHandler("invoiceReceiverID",
          "Fakturamottaker ikke valgt");
      valid = false;
    }

    /* Discount*/
    errorHandler("discount", "");
    if (isNaN(packageInfo.fields.discount)) {
      errorHandler("discount", "Avslag må være et tall.");
      valid = false;
    }

    if (packageInfo.fields.discount < 0 || packageInfo.fields.discount > 100) {
      errorHandler("discount", "Avslag må være mellom 0 og 100");
      valid = false;
    }

    /* Extra hours*/
    errorHandler("extraHours", "");
    if (isNaN(packageInfo.fields.extraHours)) {
      errorHandler("extraHours", "Ekstra timer må være et positivt flyttall eller null.");
      valid = false;
    }

    if (packageInfo.fields.extraHours < 0) {
      errorHandler("extraHours", "Ekstra timer må være positive");
      valid = false;
    }

    /* Number of payments*/
    errorHandler("numberOfPayments", "");
    if (
      packageInfo.fields.numberOfPayments < 1 ||
      !Number.isInteger(packageInfo.fields.numberOfPayments)
    ) {
      errorHandler(
        "numberOfPayments",
        "Antall betalinger må være et positivt heltall"
      );
      valid = false;
    }

    /* Extra price */
    errorHandler("extraPrice", "");
    if (isNaN(packageInfo.fields.extraPrice)) {
      errorHandler("extraPrice", "Ekstra pris må være et tall.")  // Int or float
      valid = false;
    }

    if (valid === true) {
      toggle();
    } else {
      window.scroll(0, 400);
    }

    return valid;
  };

  //Values to choose from in numberOfPayments-input-field
  const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];


  /* Send package to backend */
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllStudents());
    dispatch(getPackageTypes(getDbDate()));
    dispatch(getAllInvoiceReceivers());
  }, [dispatch]);

  const registerNewPackage = () => {
    let valid = validateInput(packageInfo);

    if (!valid) window.scroll(0, 0);
    else {
      const NewPackage = {
        students: sendID,
        package_type: packageInfo.fields.packageID,
        date_sold: packageInfo.fields.dateSold,
        discount_percentage: parseFloat(packageInfo.fields.discount),
        extra_price: parseFloat(packageInfo.fields.extraPrice),
        additional_hours_added: parseFloat(packageInfo.fields.extraHours),
        description: packageInfo.fields.description,
        number_of_payments: packageInfo.fields.numberOfPayments,
        comment_for_invoice: packageInfo.fields.comment,
        invoice_receiver: packageInfo.fields.invoiceReceiverID

      };
      dispatch(addPackageStudent(NewPackage));
      clearPage();
      setSubmitted(true);
    }
  };

  /* Clears everything from form and renders new form */
  const clearPage = () => {
    setPackage(initialPackageState);
    setSelectedStudents(new Set());
    setSelectedStudentsIDs([]);
    setInvoiceReceivers([]);
  };

  const toggle = () => {
    setOpen(!infoOpen);
    clearPage();
  };

  const getPackages = () =>
    packageTypes.filter(p => p.package_type_is_active).map((pck, key) => (
      <option key={key} value={pck.id}>
        {pck.name} ({pck.hours_in_package}t): {pck.price} kr
      </option>
    ));

  const getSelectedPackage = () => packageTypes.find(
      p => p.id.toString() === packageInfo.fields.packageID
  );

  const getStudents = () => {
    const listOfStudents = [];
    allStudents.map((student) => {
      const stud = {
        id: student.id,
        name: student.first_name + " " + student.last_name,
      };
      listOfStudents.push(stud);
    });
    return listOfStudents;
  };


  const filterInvoiceReceivers = (student_ids) => {
    if (student_ids.length <= 0) {
      return null
    }
    return allInvoiceReceivers.filter(
        // Invoice receiver must pay for at least one of the selected students
        invoice_receiver => invoice_receiver.students_to_pay_for.some(
            v => student_ids.includes(v)
        ) && invoice_receiver.students_to_pay_for.length > 0
    ).map((invoiceReceiver, key) => (
        <option key={key} value={invoiceReceiver.id}>
          {invoiceReceiver.first_name + " " + invoiceReceiver.last_name}
        </option>
    ))
  };

  const addSelectedStudent = (value, item) => {
    let old = selectedStudentsIDs;
    old.push(item.id);
    setSelectedStudentsIDs(old);
    setSendID(selectedStudentsIDs);
    setSelectedStudents(value);

    // Update invoice receiver list
    setInvoiceReceivers(filterInvoiceReceivers(old));
  };

  const removeSelectedStudent = (value, item) => {
    let old = selectedStudentsIDs;
    old.splice(old.indexOf(item.id), 1);
    setSelectedStudentsIDs(old);
    setSelectedStudents(value);

    // Update invoice receiver list
    setInvoiceReceivers(filterInvoiceReceivers(old));
  };

  const toggleMessageIsOpen = () => setMessageIsOpen((prevState) => !prevState);

  const displayMarkingSuccess = () =>
    setMessageInfo({
      header: "Vellykket!",
      message: "Pakken ble opprettet!",
      link: "",
    });
  const displayMarkingFailed = () => setMessageInfo({ error: true });

  const undefGivesZero = (v) => {
    if ("" === v || v === null) {
      return 0
    }
    return v
  }

  if (loadingStudents || loadingInvoiceReceivers || submitLoading)
    return <Loader/>;

  return (
    <Card>
      {MessageModal({
        ...messageInfo,
        toggle: toggleMessageIsOpen,
        isOpen: messageIsOpen,
      })}
      <CardHeader className="title">Ny pakke på elev</CardHeader>
      <CardBody>
        <Row>
          <Col
            className="infobox redInfobox"
            xs="12"
            sm="6"
            md="7"
            lg="7"
            xl="7"
          >
            <p>
              {" "}
              Ekstra pris og timer legges bare til dersom du skal endre på
              standard pakke{" "}
            </p>
            <p>
              {" "}
              <b>NB:</b> Du kan registrere en pakke med både ekstra pris og
              prosentavslag, men prosentavsalg regnes kun ut ifra den
              opprinnelige prisen
            </p>
          </Col>
          <Col xs="12" sm="6" md="5" lg="5" xl="5">
            <p className="infobox lightInfobox">
              {" "}
              <b>Eksempel 1</b> 21 timer selges til pris for 20. <br />
              Du velger grunnpakken og setter 1 som ekstra time.
            </p>
            <p className="infobox lightInfobox">
              {" "}
              <b>Eksempel 2</b> 40 timer selges til 10% avslag (dvs. avslag på
              1880kr). <br />
              Du skriver 10 i prosentavslag.
            </p>
          </Col>
        </Row>
        <Row style={{ marginTop: "-20px" }}>
          <Col xs="12" sm="6" md="7" lg="7" xl="7">
            <FormGroup>
              <Label>Dato solgt</Label>
              <Input
                name="dateSold"
                type="date"
                className="inputField"
                value={packageInfo.fields.dateSold}
                required
                onChange={(e) => packageHandler("dateSold", e.target.value)}
              />
              {printErrormsg("dateSold")}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label>Beskrivelse/Kommentar</Label>
              <Input
                name="description"
                placeholder="Vises ikke for kunde"
                rows="1"
                className="inputField"
                value={packageInfo.fields.description}
                onChange={(e) => packageHandler("description", e.target.value)}
              />
              {printErrormsg("description")}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label>Velg pakke</Label>
              <Input
                value={packageInfo.fields.packageID}
                type="select"
                rows="1"
                className="inputField"
                onChange={(e) => packageHandler("packageID", e.target.value)}
              >
                <option value="">Velg</option>
                {getPackages()}
              </Input>
              {printErrormsg("packageID")}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label>Velg elev(er)</Label>
              <Multiselect
                onSelect={addSelectedStudent}
                onRemove={removeSelectedStudent}
                className="inputField"
                options={getStudents()}
                displayValue="name"
                placeholder="Søk.."
                closeIcon="cancel"
                selectedValues={selectedStudentsIDs}
                emptyRecordMsg="Ingen flere elever"
                hidePlaceholder="true"
                style={{
                  multiselectContainer: {
                    // To change input field position or margin
                    minHeight: "38px",
                    background: "rgba(196, 196, 196, 0.24)",
                    border: "1px solid rgba(196, 196, 196, 0.8)",
                    borderRadius: "10px",
                    lineHeight: "22px",
                  },
                  searchBox: {
                    // To change search box element look
                    border: "none",
                  },
                  inputField: {
                    // To change input field position or margin
                    fontSize: "15px",
                    fontFamily: "Montserrat",
                    fontStyle: "normal",
                    fontWeight: "300",
                    marginLeft: "5px",
                  },
                  chips: {
                    // To change css chips(Selected options)
                    background: "rgba(38, 70, 83, 1)",
                    marginTop: "-5px",
                  },
                  option: {
                    // To change css for dropdown options
                    color: "rgba(38, 70, 83, 1)",
                    fontSize: "15px",
                    fontFamily: "Montserrat",
                    fontStyle: "normal",
                    fontWeight: "300",
                  },
                }}
              />
              {printErrormsg("students")}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label>Velg Fakturamottaker</Label>
              <Input
                value={packageInfo.fields.invoiceReceiverID}
                type="select"
                rows="1"
                className="inputField"
                onChange={(e) => packageHandler("invoiceReceiverID", e.target.value)}
              >
                {selectedStudentsIDs.length <= 0 ?
                    <option value="">Du må velge elev først</option> :
                    <option value="">Velg</option>}
                {invoiceReceivers}
              </Input>
              {printErrormsg("invoiceReceiverID")}
            </FormGroup>
          </Col>
        </Row>
        {packageInfo.fields.packageID !== "" ?
        <Row style={{ marginTop: "50px" }}>
          <Col>
            <p style={{borderWidth: 0}} className="infobox lightInfobox">
              {" "}
              <b>Pris kunden betaler:</b>
              Pakkepris * (1 - (prosent avslag / 100) ) + ekstra pris = Pris på faktura <br />
              {getSelectedPackage().price}  *
              (1 - ( {undefGivesZero(packageInfo.fields.discount)} / 100 )
              ) + {undefGivesZero(packageInfo.fields.extraPrice)} = {
              // Total price
              (getSelectedPackage().price * (
                    1 - (packageInfo.fields.discount / 100)
                ) + parseInt(
                    undefGivesZero(packageInfo.fields.extraPrice).toString()
                )).toFixed(2)
              }
            </p>
          </Col>
        </Row> : null }
        <Row style={{ marginTop: "25px" }} >
          <Col xs="12" sm="6" md="4" lg="4" xl="4">
            <FormGroup>
              <Label>Avslag</Label>
              <br />
              <div style={{ display: "inline-flex" }}>
                <Input
                  name="discount"
                  type="number"
                  placeholder="Prosent"
                  rows="1"
                  className="inputField"
                  value={packageInfo.fields.discount}
                  onChange={(e) => packageHandler("discount",e.target.value === "" ? "NotANumber" : parseFloat(e.target.value))}
                />
                <p style={{ marginTop: "6px", marginLeft: "6px" }}>%</p>
              </div>
              {printErrormsg("discount")}
            </FormGroup>
          </Col>
          <Col xs="12" sm="6" md="4" lg="4" xl="4">
            <FormGroup>
              <Label>Ekstra pris</Label>
              <Input
                name="extraPrice"
                type="number"
                rows="1"
                value={packageInfo.fields.extraPrice}
                className="inputField"
                onChange={(e) => packageHandler("extraPrice", e.target.value === "" ? "notANumber" : parseFloat(e.target.value))}
              />
              {printErrormsg("extraPrice")}
            </FormGroup>
          </Col>
            <Col xs="12" sm="6" md="4" lg="4" xl="4">
            <FormGroup>
              <Label>Ekstra timer</Label>
              <Input
                name="extraHours"
                rows="1"
                type="number"
                className="inputField"
                value={packageInfo.fields.extraHours}
                onChange={(e) => packageHandler("extraHours", e.target.value === "" ? "NotANumber" : parseFloat(e.target.value))}
              />
              {printErrormsg("extraHours")}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs="12" sm="6" md="4" lg="4" xl="4">
            <FormGroup>
              <Label> Antall betalinger </Label>
              <Input
                name="numberOfPayments"
                type="select"
                rows="1"
                className="inputField"
                value={packageInfo.fields.numberOfPayments}
                onChange={(e) =>
                  packageHandler("numberOfPayments", parseInt(e.target.value))
                }
              >
                {numbers.map((number, id) => (
                  <option key={id}> {number} </option>
                ))}

              </Input>
              {printErrormsg("numberOfPayments")}
            </FormGroup>
          </Col>
        </Row>
        <Row style={{ marginTop: "50px" }}>
          <Col>
            <FormGroup>
              <Label>Kommentar</Label>
              <Input
                name="comment"
                placeholder="Sendes med ordrebekreftelse"
                className="inputField"
                type="textarea"
                style={{ height: "100px" }}
                value={packageInfo.fields.comment}
                onChange={(e) => packageHandler("comment", e.target.value)}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row style={{ marginTop: "-40px" }}>
          <Col>
            <Button
              type="submit"
              className="button darkButton saveButton"
              onClick={() => registerNewPackage()}
            >
              Lagre pakke
            </Button>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default NewPackage;
