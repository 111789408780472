// Teacher type
export const TEACHER_TYPE = "teacher";

// Teacher Auth Groups

// Teacher Routes
export const TeacherPaths = {
    home: "/teacher/dashboard",
    updateProfile: "/teacher/update-profile",
    newActivity: "/teacher/activity",
    editActivity: "/teacher/activity/:id?",
    studentOverview: "/teacher/students",
    newStudent: "/teacher/students/new",
    individualStudentOverview: "/teacher/students/:id",
    resources: "/teacher/resources",
    profile: "/teacher/profile",
    schedule: "/teacher/schedule"
}
