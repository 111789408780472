import store from "../redux/store";
import {CUSTOMER_TYPE, STUDENT, INVOICE_RECEIVER, CustomerPaths} from "../modules/authenticated/Customer/customerConstants";
import {MANAGEMENT_TYPE, ManagementPaths} from "../modules/authenticated/Management/managementConstants";
import {TEACHER_TYPE, TeacherPaths} from "../modules/authenticated/Teacher/teacherConstants";

export const isAuthenticated = () => {
  // TODO: check currentUser in redux store.
  //  Plan: check for error message in redux store

  return (
    store.getState().currentUser.currentUser &&
    localStorage.getItem("access_token") !== "" &&
    localStorage.getItem("refresh_token") !== ""
  );
};

export const getAuthGroups = (currentUser) => {
  return [...currentUser.user.groups, currentUser.type]
}

export const isTeacher = () => {
  return store.getState().currentUser.currentUser.type === TEACHER_TYPE;
};

export const isStudent = (user) => {
  return user.groups.includes(STUDENT);
};

export const isInvoiceReceiver = (user) => {
  return user.groups.includes(INVOICE_RECEIVER);
};

/**
 * Takes a list of routes and returns a subset where the user passers the auth check
 * @param user The the user we will filter routes for
 * @param listOfRoutes The list of routes to filter
 * @param sidebar whether we should check for sidebar specific filtering
 * @returns List of the routes the user have access to
 */
export const filterIllegalRoutes = (user, listOfRoutes, sidebar= true) => {
  if (sidebar) {
    return listOfRoutes.filter(r => r.authCheck(user) && r.showOnSidebar);
  }
  return listOfRoutes.filter(r => r.authCheck(user));
}



export const homePath = (type) => {
    switch (type) {
      case CUSTOMER_TYPE:
        return CustomerPaths.home;
      case MANAGEMENT_TYPE:
        return ManagementPaths.home;
      case TEACHER_TYPE:
        return TeacherPaths.home;
      default:
        console.error("Illegal user type!");
    }
}
