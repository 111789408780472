import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import editPng from "../../../../../assets/img/RH/Rediger.png";
import Pil_ned from "../../../../../assets/img/RH/Pil_ned.png";
import {
  Modal, ModalBody, ModalHeader, Label, Card, CardBody, CardHeader, Col, Input,
  Row,
} from "reactstrap";
import Loader from "../../../../../components/Loader";
import { editTeacherDetails } from "../../../../../redux/actions/editTeacherDetails";
import { editTeacherPreference } from "../../../../../redux/actions/editTeacherPreference";
import { getCities } from "../../../../../redux/actions/getCities";
import { getFieldsOfStudy } from "../../../../../redux/actions/getFieldsOfStudy";
import { getTeacher } from "../../../../../redux/actions/getTeacher";
import CheckButton from "../../components/CheckButton";
import {editTeacherPreferredSubjects} from "../../../../../redux/actions/editTeacherPreferredSubjects";
import TeacherStudentTable from "../../components/TeacherStudentTable";
import {changePassword} from "../../../../../redux/actions/changePassword";
import viewIcon from "../../../../../assets/img/RH/view_icon.png";
import {getStudents} from "../../../../../redux/actions/getStudents";

const TeacherProfile = () => {
  // maps that convert to and from database/display names
  const statusToId = {
    "Ønsker tilbud om flere elever": "WANT_TO_BE_OFFERED_MORE_STUDENTS",
    "Ønsker ikke tilbud om flere elever": "DO_NOT_WANT_MORE_STUDENTS",
    "Ønsker flere elever": "WANT_MORE_STUDENTS",
  };
  const idToStatus = {
    WANT_TO_BE_OFFERED_MORE_STUDENTS: "Ønsker tilbud om flere elever",
    DO_NOT_WANT_MORE_STUDENTS: "Ønsker ikke tilbud om flere elever",
    WANT_MORE_STUDENTS: "Ønsker flere elever",
  };
  const dispatch = useDispatch();

  // gets a list a cities, fields of study and the current teacher
  const currentTeacherObject = useSelector((state) => state.teacher);
  const cityList = useSelector((state) => state.cities);
  const fieldList = useSelector((state) => state.fieldsOfStudy);
  const { currentTeacher, loading } = currentTeacherObject;
  const [cities, cityLoading] = [cityList.cities, cityList.loading];
  const [studyFields, fieldLoading] = [fieldList.fields, fieldList.loading];

  useEffect(() => {
    dispatch(getTeacher());
    dispatch(getCities());
    dispatch(getFieldsOfStudy());
  }, [dispatch]);

  // takes the values from redux and puts them into the local state of
  // the component when redux is no longer loading
  useEffect(() => {
    if (!loading) {
      stateHandler("diploma", currentTeacher.teacher.diploma_file);
      stateHandler("police", currentTeacher.teacher.police_report_file);
      stateHandler("status", idToStatus[currentTeacher.teacher.status]);
      stateHandler(
        "allSubjects",
        currentTeacher.subjects.map((subject) => ({
          id: subject.subject,
          name: subject.subject_name,
          preferred: subject.preferred_subject
        }))
      );
      stateHandler(
        "favSubjects",
        currentTeacher.subjects
          .filter((subject) => subject.preferred_subject)
          .map((subject) => ({
            id: subject.subject,
            name: subject.subject_name,
            preferred: true
          }))
      );
      stateHandler(
        "otherComments",
        currentTeacher.teacher.other_comments
          ? currentTeacher.teacher.other_comments
          : ""
      );
      newDetailsHandler("fieldOfStudy", {
        id: currentTeacher.teacher.field_of_study,
        name: currentTeacher.teacher.field_of_study_name,
      });
      newDetailsHandler(
        "studyYear",
        Number(currentTeacher.teacher.year_of_study)
      );
      newDetailsHandler("city", {
        id: currentTeacher.teacher.city,
        name: currentTeacher.teacher.city_name,
      });
      newDetailsHandler("hasTablet", currentTeacher.teacher.owns_tablet);
    }
  }, [loading]);

  // the initial state of the leftmost form, there is some redundant
  // information here
  const initialState = {
    police: null,
    diploma: null,
    fieldOfStudy: { id: 0, name: "" },
    studyYear: "",
    city: { id: 0, name: "" },
    hasTablet: "",
    status: "",
    favSubjects: [],
    allSubjects: [],
    otherComments: "",
    showDetailEdit: false,
    showChangePassword: false,
    phone_number: "",
    birth_date_array: [],
  };
  const [state, setState] = useState(initialState);

  const [viewPass, setViewPass] = useState(false);

  const stateHandler = (field, value) => {
    setState((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  // gets the activity info from the store
  const userActivities = useSelector(
      (state) => state.userActivities.userActivities
  );

  const studentsList = useSelector((state) => state.students.allStudentsForUser);

  useEffect(() => {
    dispatch(getStudents());
  }, [dispatch]);

  //the state of the rightmost form
  const newDetailsState = {
    fields: {
      police: state.police,
      diploma: state.diploma,
      fieldOfStudy: state.fieldOfStudy,
      studyYear: state.studyYear,
      city: state.city,
      hasTablet: state.hasTablet,
      phone_number: currentTeacher.teacher.phone_number,
      birth_date_array: (currentTeacher.teacher.birth_date ? currentTeacher.teacher.birth_date.split("-") : []),
    },
    errors: {
      police: "",
      diploma: "",
      fieldOfStudy: "",
      studyYear: "",
      city: "",
      hasTablet: "",
    },
  };
  const [newDetails, setNewDetails] = useState(newDetailsState);

  const newPasswordState = {
    oldPassword: "",
    newPassword: "",
    newPasswordRepeat: "",
    passwordMatch: true,
    passwordLengthOk: true,
    wrongOldPassword: false,
    savePasswordSuccess: false
  }

  const [newPassword, setNewPassword] = useState(newPasswordState);

  const newPasswordHandler = (field, value) => {

    setNewPassword((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const validateNewPassword = () => {
    if(!(newPassword.newPassword === newPassword.newPasswordRepeat)){
      newPasswordHandler("passwordMatch", false);
      newPasswordHandler("wrongOldPassword", false);
      return false;
    } else if(!(newPassword.newPassword.length >= 8)){
      newPasswordHandler("passwordMatch", true);
      newPasswordHandler("passwordLengthOk", false);
      newPasswordHandler("wrongOldPassword", false);
      return false;
    }
    else{
      newPasswordHandler("passwordMatch", true);
      newPasswordHandler("passwordLengthOk", true);
      return true;
    }
  };


  const newDetailsHandler = (field, value) => {
    setNewDetails((prevState) => ({
      ...prevState,
      fields: {
        ...prevState.fields,
        [field]: value,
      },
      errors: {
        ...prevState.errors,
        [field]: "",
      },
    }));
  };

  const detailErrorHandler = (field, message) => {
    setNewDetails((prevState) => ({
      ...prevState,
      errors: {
        ...prevState.errors,
        [field]: message,
      },
    }));
  };

  // returns a grid displaying the prefered subjects
  const getFavSubjectsGrid = () => {
    return (
      <Row>
        {state.favSubjects.map((subject, key) => (
          <Col
            key={key}
            xl="4"
            lg="5"
            md="5"
            sm="12"
            xs="12"
            style={{ marginBottom: "10px" }}
          >
            {CheckButton(
              key,
              subject.name,
              subject.id,
              true,
              true,
              () => removeFromFav(subject),
              subject.preferred
            )}
          </Col>
        ))}
      </Row>
    );
  };
  // removes a given subject from preferred subjects,
  // changes the preferred status on that given subject in the allSubject state
  // and updates the backend
  const removeFromFav = (removedSubject) => {
    stateHandler(
      "favSubjects",
      state.favSubjects.filter((subject) => subject.id !== removedSubject.id)
    )

    let newAllSubjects = state.allSubjects.filter(
        (subject) => subject.id !== removedSubject.id
    )
    removedSubject["preferred"] = false
    newAllSubjects = [...newAllSubjects, removedSubject]
    stateHandler(
        "allSubjects",
        newAllSubjects
    )

    dispatch(
        editTeacherPreferredSubjects({
          subject: removedSubject.name,
          preferred_subject: false
        }
    ));
  };

  //adds a given subject to preferred subjects and changes the preferred status
  // and updates the backend
  const addToFav = (addedSubject) => {
    addedSubject["preferred"] = true
    stateHandler(
      "favSubjects",
      state.favSubjects.filter(
          (subject) => subject.id === addedSubject.id
      ).length === 1
        ? state.favSubjects
        : [...state.favSubjects, addedSubject]
    )
    dispatch(
        editTeacherPreferredSubjects({
              subject: addedSubject.name,
              preferred_subject: true
            }
        ));
  };

  const setStatus = (e) => {
    stateHandler(
        "status",
        e.target.children[e.target.selectedIndex].value
    );
  };
  // returns a Row displaying all the subjects of a teacher
  const getAllSubjectsGrid = () => {
    return (
      <Row>
        {state.allSubjects.map((subject, key) => (
          <Col
            key={key}
            xl="4"
            lg="5"
            md="5"
            sm="12"
            xs="12"
            style={{ marginBottom: "10px" }}
          >
            {CheckButton(
              key,
              subject.name,
              subject.id,
              false,
              false,
              () => addToFav(subject),
               subject.preferred
            )}
          </Col>
        ))}
      </Row>
    );
  };
  // returns the input for year of study
  const getYearInput = () => {
    return (
      <input
          required
          type="text"
          pattern="/d?"
          maxLength="1"
          style={{
            width: "35px",
            height: "22px",
            paddingLeft: "10px",
            paddingRight: "8px",
            marginTop: "3px",
            marginRight: "8px",
          }}
          className="inputField"
          value={Number(newDetails.fields.studyYear)}
          onChange={(e) => {
              newDetailsHandler("studyYear", Number(e.target.value));
    }}
    />
    );
  };
  // returns a dropdown of fields of study
  const getStudyDrop = () => {
    return (
      <Input
        type="select"
        value={newDetails.fields.fieldOfStudy.id}
        className="inputField"
        onChange={(e) => {
          let opt = e.target.children[e.target.selectedIndex];
          newDetailsHandler("fieldOfStudy", {
            id: opt.value,
            name: opt.innerHTML,
          });
        }}
      >
        {studyFields.map((field, key) => (
          <option value={field.id} key={key}>
            {field.study_name + " " + field.school_name}
          </option>
        ))}
      </Input>
    );
  };
  
  // returns a dropdown of cities
  const getCityDrop = () => {
    return (
      <Input
        type="select"
        value={newDetails.fields.city.id}
        className="inputField"
        onChange={(e) => {
          let opt = e.target.children[e.target.selectedIndex];
          newDetailsHandler("city", { id: opt.value, name: opt.innerHTML });
        }}
      >
        {cities.map((city, key) => (
          <option value={city.id} key={key}>
            {city.city_name}
          </option>
        ))}
      </Input>
    );
  };

  //returns a dropdown of statuses 
  const getStatusDrop = () => {
    return (
      <Input
        type="select"
        className="inputField"
        value={state.status}
        style={{ maxWidth: "100%" }}
        onChange={(e) => {
          // Stores the change of status in backend
          dispatch(
              editTeacherPreference({
                status: statusToId[e.target.children[e.target.selectedIndex].value]
              })
          );
          setStatus(e);
        }}
      >
        {Object.keys(statusToId).map((status, key) => (
          <option key={key} value={status}>
            {status}
          </option>
        ))}
      </Input>
    );
  };

  // returns a Row with a label and a value
  const getInfoRow = (label, value, margin = 20) => (
    <Row style={{ marginTop: margin + "px", maxWidth:"100%"}}>
      <Col xs="6" sm="12" md="12" lg="12" xl="12">
        {label}
      </Col>
      <Col xs="6" sm="12" md="12" lg="12" xl="12">
        {value}
      </Col>
    </Row>
  );

  // validates the study year input
  const validateDetails = () => {
    let valid = false;
    let year = newDetails.fields.studyYear;
    if (year < 1 || year > 5) {
      detailErrorHandler("studyYear", "Ugyldig årstrinn");
    } else valid = true;
    return valid;
  };


  // handles saving of the new password
  const handleNewPasswordSave = () => {
    if(validateNewPassword()){
      dispatch(
          changePassword(newPassword.oldPassword, newPassword.newPassword)
      ).then(
          (response) => {

            stateHandler("showChangePassword", false);
            newPasswordHandler("passwordLengthOk", true);
            newPasswordHandler("passwordMatch", true);
            newPasswordHandler("wrongOldPassword", false);
            newPasswordHandler("savePasswordSuccess", true)
            newPasswordHandler("oldPassword", "")
            newPasswordHandler("newPassword", "")
          }
      ).catch(function (error) {
        // error.response.data.old_password[0] = undefined;
        try{
          if( error.response.data.old_password[0] === "Wrong password.") {
            newPasswordHandler("wrongOldPassword", true);
          } else if(
              error.response.data.old_password[0] ===
              "This field may not be blank."
          ){
            newPasswordHandler("wrongOldPassword", true);
          }
        }catch (e) {

        }
      });
    }
    else {

    }
  };

  // handles saving of the leftmost form
  const handleDetailsSave = (e) => {
    let valid = validateDetails();
    if (!valid) window.scroll(0, 0);
    else {
      // e.preventDefault();
      let data = {
        type: "details",
        owns_tablet: String(newDetails.fields.hasTablet),
        field_of_study: newDetails.fields.fieldOfStudy.id,
        year_of_study: String(newDetails.fields.studyYear),
        city: newDetails.fields.city.id,
        phone_number: newDetails.fields.phone_number,
        birth_date: (newDetailsState.fields.birth_date_array[0] + "-" + newDetailsState.fields.birth_date_array[1] + "-" + newDetailsState.fields.birth_date_array[2]),
        address: newDetails.fields.address
      };


      console.log(data);

      // saving details apart from files:
      dispatch(editTeacherDetails(data));

      // handle the file uploads if there are new files:

      let diplomaFile = document.getElementById("diploma").files[0];
      let policeFile = document.getElementById("police").files[0];

      let formData = new FormData();
      if(diplomaFile){
        formData.append('diploma_file', diplomaFile);
      }
      if(policeFile){
        formData.append('police_report_file', policeFile);
      }
      if(diplomaFile || policeFile){
        dispatch(editTeacherDetails(formData));
      }
      stateHandler("showDetailEdit", false);
    }
  };

  return (
    <div>
      <Card>
        {loading ? (
          <Loader />
        ) : (
          <>
            <CardHeader
              style={{
                display: "inline-flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <div style={{ width: "auto" }} className="title">
                {currentTeacher.teacher.first_name +
                  " " +
                  currentTeacher.teacher.last_name}{" "}
              </div>
            </CardHeader>
            <CardBody>
              <Row>
                <Col
                  xs="12"
                  sm="6"
                  md="7"
                  lg="7"
                  xl="8"
                  style={{ marginRight: "0px" }}
                >
                  <Row style={{ marginBottom: "80px" }}>
                    <Col xs="" sm="" md="" lg="" xl="">
                      <div className="start">
                        <div style={{ marginRight: "10px" }}>Status:</div>{" "}
                        {getStatusDrop()}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="" sm="" md="" lg="" xl="">
                      <div style={{marginBottom: "10px"}}>
                        Foretrukne fag (Trykk på et fag for å fjerne det
                        fra "Foretrukne fag"):
                      </div>
                    </Col>
                  </Row>
                  {getFavSubjectsGrid()}
                  <Row style={{ marginTop: "40px" }}>
                    <Col xs="" sm="" md="" lg="" xl="">
                      <div style={{marginBottom: "10px"}}>
                        Alle fag (Trykk på et fag for å legge til "Foretrukne
                        fag"):
                      </div>
                    </Col>
                  </Row>
                  {getAllSubjectsGrid()}
                  <Row style={{marginBottom: "60px"}}>
                    <Col xs="" sm="" md="" lg="" xl="">
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      { TeacherStudentTable(userActivities, studentsList) }
                    </Col>
                  </Row>
                  <Row/>
                  <Row/>
                </Col>
                <Col xs="12" sm="5" md="4" lg="4" xl="4">
                  <Row>
                    <Card
                        className={"dashboard-card"}
                        style={{
                          width: "auto",
                          minWidth: "500px",
                          maxWidth: "100%",
                          marginRight: "15px"
                        }}>
                        <CardHeader
                            style={{ paddingBottom: "0" , fontSize: "30px"}}
                        >
                          <Row>
                            <Col style={{fontSize: "30px"}}>
                              Personopplysninger
                            </Col>
                            <Col>
                              { !state.showDetailEdit || cityLoading || fieldLoading ? (
                                  <img src={editPng} alt="editPng"
                                       style={{ width: 30, cursor: "pointer" }}
                                       onClick={() => stateHandler(
                                           "showDetailEdit", true
                                       )}
                                  />
                              ) : (
                                  <img src={editPng} alt="editPng"
                                       style={{ width: 30, cursor: "pointer" }}
                                       onClick={() => stateHandler(
                                           "showDetailEdit", false
                                       )}
                                  />
                              )}

                            </Col>

                          </Row>

                        </CardHeader>

                      <CardBody>

                        {!state.showDetailEdit || cityLoading || fieldLoading ?
                            <Row>
                              <div
                                  style={{ marginBottom: "10px", marginTop: "0px" }}
                              >
                                {getInfoRow(
                                    "Epost:",
                                    currentTeacher.teacher.email,
                                    0
                                )}
                                {getInfoRow(
                                    "Telefonnummer:",
                                    currentTeacher.teacher.phone_number
                                )}
                                {getInfoRow(
                                    "Fødselsdato:",
                                    currentTeacher.teacher.birth_date
                                )}
                                {getInfoRow("Adresse:", currentTeacher.teacher.address)}
                              </div>
                            </Row>
                            :
                            <Row>
                              <div
                                  style={{ marginBottom: "10px", marginTop: "0px" }}
                              >
                                {getInfoRow(
                                    "Epost:",
                                    currentTeacher.teacher.email,
                                    0
                                )}
                                {getInfoRow(
                                    "Telefonnummer:",
                                    (<Input
                                        name="pNum"
                                        placeholder="12345678"
                                        defaultValue={currentTeacher.teacher.phone_number}
                                        rows="1"
                                        className="inputField"
                                        onChange={(e) => newDetails.fields.phone_number = e.target.value}
                                        invalid={false}
                                        required
                                    />)
                                )}
                                {getInfoRow(
                                    "Fødselsdato:",
                                    //currentTeacher.teacher.birth_date
                                    (
                                        <Row>
                                          <Col>
                                            <Input
                                                name="year"
                                                placeholder="YYYY"
                                                defaultValue={newDetailsState.fields.birth_date_array[0]}
                                                rows="1"
                                                className="inputField"
                                                onChange={(e) => newDetailsState.fields.birth_date_array[0] = e.target.value}
                                                invalid={false}
                                                required
                                            />
                                          </Col>
                                          <Col>
                                            <Input
                                                name="month"
                                                placeholder="MM"
                                                defaultValue={newDetailsState.fields.birth_date_array[1]}
                                                rows="1"
                                                className="inputField"
                                                onChange={(e) => newDetailsState.fields.birth_date_array[1] = e.target.value}
                                                invalid={false}
                                                required
                                            />
                                          </Col>
                                          <Col>
                                            <Input
                                                name="day"
                                                placeholder="DD"
                                                defaultValue={newDetailsState.fields.birth_date_array[2]}
                                                rows="1"
                                                className="inputField"
                                                onChange={(e) => newDetailsState.fields.birth_date_array[2] = e.target.value}
                                                invalid={false}
                                                required
                                            />
                                          </Col>
                                        </Row>
                                  )
                                )}
                                {getInfoRow("Adresse:", (
                                    <Input
                                    name="adress"
                                    placeholder="Adresseveien 1"
                                    defaultValue={currentTeacher.teacher.address}
                                    rows="1"
                                    className="inputField"
                                    onChange={(e) => newDetails.fields.address = e.target.value}
                                    invalid={false}
                                    required
                                />))}
                              </div>
                            </Row>
                        }

                        <Row>
                          <div
                              style={{ marginBottom: "10px", marginTop: "10px" }}
                          >
                            {!state.showDetailEdit || cityLoading || fieldLoading ? (
                                <>
                                  {getInfoRow(
                                      "Studieretning:",
                                      currentTeacher.teacher.field_of_study_name,
                                      0
                                  )}
                                  {getInfoRow(
                                      "Årstrinn:",
                                      currentTeacher.teacher.year_of_study + ". Trinn"
                                  )}
                                  {getInfoRow(
                                      "Undervisningsby:",
                                      currentTeacher.teacher.city_name
                                  )}
                                  {getInfoRow(
                                      "Nettbrett?",
                                      currentTeacher.teacher.owns_tablet ? "Ja" : "Nei"
                                  )}
                                  {getInfoRow(
                                      "Politiattest:",
                                      state.police == null ? (
                                          "Ingen fil"
                                      ) : (
                                          <a target="blank" href={state.police}>
                                            Åpne fil
                                          </a>
                                      )
                                  )}
                                  {getInfoRow(
                                      "Vitnemål:",
                                      state.diploma == null ? (
                                          "Ingen fil"
                                      ) : (
                                          <a target="blank" href={state.diploma}>
                                            Åpne fil
                                          </a>
                                      )
                                  )}
                                </>
                            ) : (
                                <form style={{maxWidth:"100%"}}>
                                  {getInfoRow("Studieretning:", getStudyDrop(), 0)}
                                  {getInfoRow(
                                      "Årstrinn:",
                                      <>{getYearInput()}. Trinn</>
                                  )}
                                  { /* getInfoRow("Undervisningsby:", getCityDrop()) */}
                                  {getInfoRow(
                                      "Nettbrett?",
                                      <>
                                        <input
                                            style={{
                                              marginTop: "14px",
                                              marginRight: "14px",
                                            }}
                                            type="checkbox"
                                            checked={newDetails.fields.hasTablet}
                                            onChange={() =>
                                                newDetailsHandler(
                                                    "hasTablet",
                                                    !newDetails.fields.hasTablet
                                                )
                                            }
                                        />
                                        <label>Har nettbrett</label>
                                      </>
                                  )}
                                  {getInfoRow(
                                      "Politiattest:",
                                      state.police == null ? (
                                          <input type="file" id={"police"} onChange={(e)=>{
                                            newDetailsHandler("police", e.target.value);
                                          }}/>
                                      ) : (
                                          <>
                                            <div>
                                              <a target="blank" href={state.police}>
                                                Åpne fil
                                              </a>
                                            </div>
                                            <div>
                                              <input
                                                  type="file"
                                                  placeholder="Endre fil"
                                                  id={"police"}
                                              />
                                            </div>
                                          </>
                                      )
                                  )}
                                  {getInfoRow(
                                      "Vitnemål:",
                                      state.diploma == null ? (
                                          <input type="file" id={"diploma"} onChange={(e)=>{
                                            newDetailsHandler("diploma", e.target.value);
                                          }}/>
                                      ) : (
                                          <>
                                            <div>
                                              <a target="blank" href={state.diploma}>
                                                Åpne fil
                                              </a>
                                            </div>
                                            <div>
                                              <input
                                                  type="file"
                                                  id={"diploma"}
                                                  placeholder="Endre fil"
                                                  onChange={(e)=>{
                                                    newDetailsHandler("diploma", e.target.value);
                                                  }}
                                              />
                                            </div>
                                          </>
                                      )
                                  )}
                                  <Row>
                                    <Col></Col>
                                    <Col></Col>
                                    <Col>
                                      <button className="button darkButton hovPoint" style={{
                                        fontSize: "15px",
                                        width: "80px",
                                        align: "Right",
                                        //display: "inline-flex",
                                        //width: "100%",
                                        //justifyContent: "flex-end",
                                        //paddingRight: "10px",
                                        //textDecoration: "underline",
                                      }} onClick={() => {
                                        handleDetailsSave();
                                      }}>
                                        Lagre
                                      </button>
                                    </Col>
                                  </Row>
                                </form>
                            )}
                          </div>

                        </Row>

                        <Card className={"dashboard-card"} style={{width: "auto", minWidth: "400px"}}>

                        </Card>
                      </CardBody>
                    </Card>
                  </Row>
                    <Row>
                      <Card className={"dashboard-card"}
                            style={{width: "auto", minWidth: "500px", align: "center", marginRight: "15px"}}>
                        {
                          !state.showChangePassword || cityLoading || fieldLoading ? (
                            <CardBody style={{cursor: "pointer"}} onClick={()=>{
                              stateHandler("showChangePassword", true);
                            }}>
                              <div
                                  style={{ marginBottom: "15px", marginTop: "0px" }}
                              >
                                <Row>
                                  <Col>Endre passord</Col>
                                  <Col style={{textAlign: "right"}}>
                                    <img src={Pil_ned} alt="Pil_ned" style={{ width: 20, cursor: "pointer" }}/>
                                  </Col>
                                </Row>

                              </div>
                        </CardBody>) : (<CardBody>
                          <div>
                            <div style={{
                              fontSize: "30px",
                              marginBottom: "13px",
                              marginLeft: "5px"
                            }}>Endre Passord</div>
                            <Row>
                              <Col xs="12" sm="7">
                                <div>
                                  <Label>Gammelt Passord</Label>
                                  {newPassword.wrongOldPassword ? (
                                      <div style={{color: "red"}}>Feil Passord.</div>
                                  ) : (
                                      <div style={{color: "red"}}/>
                                  )}
                                  <Input
                                      type="password"
                                      placeholder="Gammelt Passord"
                                      onChange={(e) => newPasswordHandler("oldPassword", e.target.value)}
                                      invalid={false}
                                      required
                                  />
                                </div>
                                <div>
                                  <Label style={{marginTop: "30px"}}>Nytt Passord</Label>
                                  {!newPassword.passwordMatch ? (
                                      <div style={{color: "red"}}>Passord må være like.</div>
                                  ) : (
                                      <div>

                                      </div>
                                  )}
                                  {!newPassword.passwordLengthOk ? (
                                      <div style={{color: "red"}}>
                                          Passord må være minst 8 tegn.
                                      </div>
                                  ) : (
                                      <div>

                                      </div>
                                  )}

                                  {viewPass ? (<div className={"passInputContainer"}>
                                    <Input
                                        placeholder="Nytt Passord"
                                        onChange={(e) => {
                                          newPasswordHandler("newPassword", e.target.value);
                                          newPasswordHandler("newPasswordRepeat", e.target.value)
                                        }}
                                        invalid={!(newPassword.passwordMatch && newPassword.passwordLengthOk)}
                                        required
                                    />
                                    <button className={"showOnlyButtonContent"} style={{ marginLeft: "5px" }} onClick={() => {
                                      setViewPass(!viewPass);
                                    }}><img src={viewIcon} alt="editPng" style={{ width: 30, cursor: "pointer" }}/></button>
                                  </div>) : (<div className={"passInputContainer"}>
                                    <Input
                                        type="password"
                                        placeholder="Nytt Passord"
                                        onChange={(e) => {
                                          newPasswordHandler("newPassword", e.target.value);
                                          newPasswordHandler("newPasswordRepeat", e.target.value)
                                        }}
                                        invalid={!(newPassword.passwordMatch && newPassword.passwordLengthOk)}
                                        required
                                    />
                                    <button className={"showOnlyButtonContent"} style={{ marginLeft: "5px"}} onClick={() => {
                                      setViewPass(!viewPass);
                                    }}><img src={viewIcon} alt="editPng" style={{ width: 30, cursor: "pointer" }}/></button>
                                  </div>)}

                                </div>
                                <Col>

                                </Col>
                                <button className="button darkButton" style={{marginTop: "20px", fontSize: "15px", width: "100%"}}
                                        onClick={handleNewPasswordSave}>

                                  Lagre Nytt Passord
                                </button>
                              </Col>
                            </Row>
                            <Row style={{textAlign: "right"}}>
                              <Col></Col>
                              <Col style={{textAlign: "right"}}><img className={"rotate180"} src={Pil_ned} alt="Pil_ned" style={{ width: 20, cursor: "pointer" }}
                              onClick={
                                () => {

                                  stateHandler("showChangePassword", false);
                                  newPasswordHandler("passwordLengthOk", true);
                                  newPasswordHandler("passwordMatch", true);
                                  newPasswordHandler("wrongOldPassword", false);
                                }
                              }/></Col>
                            </Row>

                          </div>

                        </CardBody>)}

                      </Card>

                  </Row>
                  <Modal isOpen={newPassword.savePasswordSuccess}
                         toggle={() => newPasswordHandler("savePasswordSuccess", false)}>
                    <ModalHeader>
                      Passordet har blitt endret!
                    </ModalHeader>
                    <ModalBody style={{align: "center", margin: "auto"}}>
                      <button className="button darkButton"
                              onClick={()=>{
                                newPasswordHandler("savePasswordSuccess", false)
                              }}>
                        Ok
                      </button>
                    </ModalBody>
                  </Modal>
                </Col>
              </Row>
            </CardBody>
          </>
        )}
      </Card>
    </div>
  );
};


export default TeacherProfile;
