import React, {useState} from "react";
import {
  Card,
  CardBody,
  Col,
  Input,
  Tooltip,
  Spinner
} from "reactstrap";

import missingSummaryImg from "../../../../../assets/img/RH/missingSummary.png";
import axiosInstance from "../../../../../axios";

const TableRow = (props) => {

    const [vismaID, setVismaID] = useState(props.teacher.visma_employment_id);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const addVismaID = async (teacherID, givenVismaID) => {
        try {
          setLoading(true)
          await axiosInstance.patch("user/teacher/" + teacherID + "/",
              {
                "visma_employment_id": givenVismaID
              }
          )
          setVismaID(givenVismaID);
          setLoading(false);
        } catch (error) {
          setError(JSON.stringify(error.response.data));
          setLoading(false);
        }
    }

     // returns the total unpaid salary of a teacher

    return (
      <tr style={props.onlyShowPaid ? {background: "#daf0de"} : {background: "#f0f0f0"}}>
        <td>{props.teacher.name}</td>
        <td>{props.teacher.total_activity_duration_for_payment}</td>
        <td>
          {props.unpaid(props.teacher)}
          {props.teacher.number_of_activities_without_summary ? (
            <>
              <img
                src={missingSummaryImg}
                style={{ width: "40px", height: "40px" }}
                href="#"
                id={"toolTip" + props.keyProp}
              />
              <Tooltip
                placement="right"
                target={"toolTip" + props.keyProp}
                toggle={() => props.toggle(props.teacher.id)}
                isOpen={props.tooltipOpen[props.teacher.id]}
              >
                <div>
                  Har økt(er) som <br />
                  mangler oppsummering <br />
                  <br />
                  Se "Se mer" from mer info
                </div>
              </Tooltip>
            </>
          ) : (
            ""
          )}
        </td>
        <td>
          <Col style={{ paddingLeft: "0" }}>
            <div className="bold">Kontonummer:</div>
            <div>{props.teacher.bank_account_number}</div>
            <div className="bold">Adresse:</div>
            <div>{props.teacher.address}</div>
            <div className="bold">Epost:</div>
            <div>{props.teacher.email}</div>
            <div className="bold">Personnummer:</div>
            <div>{props.teacher.social_security}</div>
          </Col>
        </td>
        <td>
          <Col className="fullFlexCentre flex-lg-column pt-2 pb-2">
            { vismaID !== null ?
                (
                    !props.onlyShowPaid ?
                <button
                    className="button lightButton"
                    onClick={() => {
                        props.setIsMarkingAll(false);
                        props.setCurrentTeacher(props.teacher);
                        props.toggleAreYouSure();
                    }}
                    style={{ marginBottom: "25px" }}
                >
                    Marker som betalt
                </button> :
                        <div/>
                )
              :
              // User must specify new Visma id if none is existent
              <Card className="mb-0" style={{borderColor: 'red', borderWidth: "1px"}}>
                <CardBody>
                  {error != null ?
                    // Display error
                    <div className="justify-content-center align-items-center d-flex flex-lg-column pb-2">
                      <div style={{width: "90%"}} className="bold">Det skjedde en feil:</div>
                      <div style={{width: "90%"}} >{error}</div>
                      <button className="button mediumButton mt-2"
                            style={{borderColor: "#264653"}}
                            disabled={loading}
                            onClick={
                                () => setError(null)
                            }
                    >
                        Prøv igjen
                      </button>
                    </div>
                    :
                    // Display edit form
                    <div className="justify-content-center align-items-center d-flex flex-lg-column pb-2">
                      <div style={{width: "90%"}} className="bold">Visma employment id:</div>
                      <Input style={{width: "90%"}} id="vismaInput" placeholder="Skriv inn ID"/>
                      <button className="button mediumButton mt-2"
                              style={{borderColor: "#264653"}}
                              disabled={loading}
                              onClick={
                                () => addVismaID(
                                  props.teacher.id,
                                  document.getElementById("vismaInput").value
                                )
                              }
                      >
                        {loading ? <Spinner color="#264653"  size="sm" style={{margin: '6px'}}/> : 'Lagre'}
                      </button>
                    </div>
                  }
                </CardBody>
              </Card>
            }
            <button
              className="button m-2 mt-3"
              onClick={() => props.showTeacher(props.teacher)}
            >
              Se mer
            </button>
          </Col>
        </td>
      </tr>
    );
  };

export default TableRow;