import { createSlice, createAsyncThunk} from "@reduxjs/toolkit"
import PaginatedService from "../../services/PaginatedService";
import axiosInstance from "../../axios";
import {returnError} from "../../utils/errorHelper";

const initialState = {
    loading: 'idle',    //  'idle' | 'pending' | 'succeeded' | 'failed'
    nextPlannedActivity: null,
    count: null,
    page: [],
    pageIndex: 0,
    nextPage: null,
    previousPage: null,
}

export const activityPageBaseUrl = "activity/activities/"
export const fetchActivityPage = createAsyncThunk(
    "activity/fetchActivityPage", PaginatedService.getPage
)

export const destroy = createAsyncThunk(
    "activity/destroy",
    async (arg, thunkAPI) => {
        try {
            const response = await axiosInstance.delete("/activity/activity/" + arg.id)
            return {deletedId: arg.id}
        } catch (err) {
            return returnError(err, thunkAPI)
        }
    }
)

const activitySlice = createSlice({
    name: "activity",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchActivityPage.pending, (state, action) => {
            state.loading = "pending"
        }).addCase(fetchActivityPage.fulfilled, (state, action) => {
            PaginatedService.renderFulfilled(state, action)

            // Setting nextPlannedActivity
            let plannedActivities = action.payload.results.filter(
                (activity) => new Date(activity.start_date_time) > new Date()
            ).sort(
                (a, b) => new Date(b.start_date_time) < new Date(a.start_date_time) ? 1 : -1
            )
            if (plannedActivities.length > 0) {
                if (state.nextPlannedActivity === null) {
                    state.nextPlannedActivity = plannedActivities[0]
                } else if (
                    new Date(state.nextPlannedActivity.start_date_time) >
                    new Date(plannedActivities[0].start_date_time)
                ) {
                    // The new activity is earlier than the currently next planned
                    state.nextPlannedActivity = plannedActivities[0]
                }
            }
            state.loading = "success"
        }).addCase(fetchActivityPage.rejected, (state, action) => {
            state.loading = "failed"
        }).addCase(destroy.pending, (state, action) => {
            state.loading = "pending"
        }).addCase(destroy.fulfilled, (state, action) => {
            // Updating nextPlannedActivity
            if (state.nextPlannedActivity !== null) {
                if (action.payload.deletedId.toString() === state.nextPlannedActivity.id.toString()) {
                    state.nextPlannedActivity = null
                }
            }
            state.loading = "success"
        }).addCase(destroy.rejected, (state, action) => {
            state.loading = "failed"
        })
    }
})

export default activitySlice.reducer
