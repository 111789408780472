import {
  ADD_STUDENT_FAIL,
  ADD_STUDENT_REQUEST,
  ADD_STUDENT_SUCCESS,
  GET_COMMON_STUDENT_SUBJECT_REQUEST,
  GET_COMMON_STUDENT_SUBJECT_SUCCESS,
  GET_COMMON_STUDENT_SUBJECT_FAIL,
  GET_STUDENT_SUBJECT_REQUEST,
  GET_STUDENT_SUBJECT_SUCCESS,
  GET_STUDENT_SUBJECT_FAIL,
  GET_STUDENTS_FAIL,
  GET_STUDENTS_REQUEST,
  GET_STUDENTS_SUCCESS,
  GET_STUDENT_FAIL,
  GET_STUDENT_REQUEST,
  GET_STUDENT_SUCCESS,
  GET_ALL_STUDENTS_REQUEST,
  GET_ALL_STUDENTS_SUCCESS,
  GET_ALL_STUDENTS_FAIL,
  GET_ALL_STUDENTS_PAGE_SUCCESS
} from "../constants";
import {baseURL} from "../../axios";
import {pageState} from "./PageState";

const initialState = {
  loading: false,
  newlyAddedStudent: null,
  allStudentsForUser: [],
  currentStudent: { student: {}, subjects: [], packages: [], activities: [], subjects_in_common: [] },
  allStudents: [],
  error: "",
  emailError: "",
  count: null,
  page: [],
  pageIndex: 0,
  nextPage: null,
  previousPage: null,
};




export const studentReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_STUDENTS_PAGE_SUCCESS:
      return pageState(state, action);

    case ADD_STUDENT_REQUEST:
      return { ...state, loading: true, newlyAddedStudent: null };

    case ADD_STUDENT_SUCCESS:
      return { ...state, loading: false, newlyAddedStudent: action.payload, actionSucceeded: true, error:"", emailError: ""};

    case ADD_STUDENT_FAIL:
      return { ...state, loading: false, actionSucceeded: false, emailError: action.emailError };

    case GET_STUDENTS_REQUEST:
      return { ...state, loading: true, allStudentsForUser: [] };

    case GET_STUDENTS_SUCCESS:
      return { ...state, loading: false, allStudentsForUser: action.payload };

    case GET_STUDENTS_FAIL:
      return { ...state, loading: false };

    case GET_COMMON_STUDENT_SUBJECT_REQUEST:
      return { ...state, loading: true };

    case GET_COMMON_STUDENT_SUBJECT_FAIL:
      return { ...state, loading: false };

    case GET_COMMON_STUDENT_SUBJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        currentStudent: {
          ...state.currentStudent,
          subjects_in_common: action.payload,
        },
      };

    case GET_STUDENT_SUBJECT_REQUEST:
      return { ...state, loading: true };

    case GET_STUDENT_SUBJECT_FAIL:
      return { ...state, loading: false };

    case GET_STUDENT_SUBJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        currentStudent: { ...state.currentStudent, subjects: action.payload },
      };

    case GET_STUDENT_REQUEST:
      return { ...state, loading: true };

    case GET_STUDENT_SUCCESS:
      return { ...state, loading: false, currentStudent: { ...state.currentStudent, ...action.payload } };

    case GET_STUDENT_FAIL:
      return { ...state, loading: false };

    case GET_ALL_STUDENTS_REQUEST:
      return { ...state, loading: true, allStudents: [] };

    case GET_ALL_STUDENTS_SUCCESS:
      return { ...state, loading: false, allStudents: action.payload };

    case GET_ALL_STUDENTS_FAIL:
      return { ...state, loading: false };

    default:
      return state;
  }
};
