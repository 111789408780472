import { createSlice, createAsyncThunk} from "@reduxjs/toolkit"
import PaginatedService from "../../services/PaginatedService";

const initialState = {
    loading: 'idle',    //  'idle' | 'pending' | 'succeeded' | 'failed'
    count: null,
    page: [],
    pageIndex: 0,
    nextPage: null,
    previousPage: null,
}

export const packagePageBaseUrl = "package/packages/"
export const fetchPackagePage = createAsyncThunk(
    "package/fetchPackagePage", PaginatedService.getPage
)

const packageSlice = createSlice({
    name: "package",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchPackagePage.pending, (state, action) => {
            state.loading = "pending"
        }).addCase(fetchPackagePage.fulfilled, (state, action) => {
            state.loading = "success"
            PaginatedService.renderFulfilled(state, action)
        }).addCase(fetchPackagePage.rejected, (state, action) => {
            state.loading = "failed"
        })
    }
})

export default packageSlice.reducer
