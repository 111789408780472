import React from "react"
import { useSelector } from "react-redux";

import {IoMail} from "react-icons/io5"
import {GiPhone} from "react-icons/gi"
import {isInvoiceReceiver} from "../../../../../utils/authHelpers";

const ContactPersonCard = (props) => {
    if (props.contact) {
        return (
            <div className="contact-person-card">
                <div className="contact-person-card-header">Din kontaktperson i Realfagshjelpen:</div>
                <div><b>{props.contact.full_name}</b></div>
                <div><span><IoMail /> {props.contact.email}</span></div>
                <div><span><GiPhone style={{transform: "rotate(270deg)"}} /> {props.contact.phone_number}</span></div>
            </div>);
    }else {
        return (
            <div className="contact-person-card">
                <div className="contact-person-card-header">Din kontaktperson i Realfagshjelpen:</div>
                <div><b>Du har ingen kontaktperson. Du blir gitt en kontaktperson når du kjøper en pakke.</b></div>
            </div>);
    }
}

export default ContactPersonCard;