import React from "react";
import InvoiceReceiverPaginatedOverview from "../../../../../features/invoiceReceiver/InvoiceReceiverPaginatedOverview";

const InvoiceReceiversOverviewPage = () => {

    return (
        <>
            <div className="title mb-1">Fakturamottakeroversikt</div>
            <InvoiceReceiverPaginatedOverview />
        </>
    );
};

export default InvoiceReceiversOverviewPage