import axiosInstance from "../../axios";
import {
  EDIT_ACTIVITY_FAIL,
  EDIT_ACTIVITY_REQUEST,
  EDIT_ACTIVITY_SUCCESS,
} from "../constants";
export const patchActivity = (props, id) => async (dispatch) => {
  try {
    dispatch({ type: EDIT_ACTIVITY_REQUEST });

    const { data } = await axiosInstance.patch(
      "/activity/activity/" + id,
      props
    );

    dispatch({ type: EDIT_ACTIVITY_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: EDIT_ACTIVITY_FAIL,
      error:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
