import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, Col, Row} from "reactstrap";
import { getActivities } from "../../../../../redux/actions/getActivities";
import { getMyPackages } from "../../../../../redux/actions/getMyPackages";
import StudentLessonTable from "./StudentLessonTable";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBox, faPen } from '@fortawesome/free-solid-svg-icons';
import "../../../../../stylesheets/dashboard.css";
import Loader from "../../../../../components/Loader";
import NextActivityInfo from "../../../../../features/activity/NextActivityInfo";

// page that displays the lessons of a student
const StudentDashboard = () => {
  const dispatch = useDispatch();

  //gets the activity info from the store
  const allUserActivities = useSelector(
    (state) => state.userActivities
  );

  const userActivities = allUserActivities.userActivities;
  const activityLoading = allUserActivities.loading;

  const userPackages = useSelector(
      (state) => state.userPackages
  );
  const allPackages = userPackages.allPackages;
  const packageLoading = userPackages.loading;

  // Makes redux fetch from the backend
  useEffect(() => {
    dispatch(getMyPackages());
  }, [dispatch]);

  // Returns the number of planned lessons
  const getHoursCompleted = () => {
    if (Array.isArray(userActivities)) {
      return userActivities.filter(
          (activity) => new Date(activity.start_date_time) < new Date()
      ).map(act => act.duration).reduce((sum, duration) => sum + duration, 0);
    }
    return 0;
  };

  
  //returns the number of package hours left
  //TODO fix this API call
  const getRemainingPackageHours = () => {
      if (Array.isArray(allPackages)) {
        return allPackages.length === 0 ?
          0 :
          allPackages.filter(
            p => !p.is_expired
          ).map(
            pack => pack.remaining_hours
          ).reduce((sum, hours) => sum + hours,0);
      }
      return 0;
  }


  return (
    <div className="page-container">
    {activityLoading || packageLoading ? <Loader/> : ""}
    <div style={activityLoading || packageLoading ? {display: "none"} : {}}>
      <h1 className="title mb-3">Mine aktiviteter</h1>
      <Row >
        <Col style={{flex: 2, marginBottom: "1em"}}>
          <Card className="info-card" style={{height: "100%"}}>
              <NextActivityInfo type="student"/>
          </Card>
        </Col>
        <Col style={{flex: 2, flexDirection: "row"}}>
            <Card className="info-card">
              <CardBody className="d-flex" style={{flexDirection: "row", flex: 2}}>
                 <div style={{flex: 1}}>
                    <p className="header text-sm mb-0 text-capitalize font-weight-bold">
                      Dine aktive pakker har
                    </p>
                    <h5 className="details font-weight-bolder mb-0">
                            {getRemainingPackageHours() + " timer igjen"}
                    </h5>
                  </div>
                 <div className="d-flex" style={{justifyContent: "end", flex: 1}}>
                  <div className="icon icon-shape shadow border-radius-md">
                    <FontAwesomeIcon
                        icon={faBox}
                    />
                  </div>
                </div>
              </CardBody>
            </Card>
            <Card className="info-card">
              <CardBody className="d-flex" style={{flexDirection: "row", flex: 2}}>
                 <div style={{flex: 1}}>
                    <p className="header text-sm mb-0 text-capitalize font-weight-bold">
                      Totalt antall timer gjennomført
                    </p>
                    <h5 className="details font-weight-bolder mb-0">
                        {getHoursCompleted()}
                    </h5>
                  </div>
                 <div className="d-flex" style={{justifyContent: "end", flex: 1}}>
                  <div className="icon icon-shape shadow border-radius-md">
                    <FontAwesomeIcon
                        icon={faPen}
                    />
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
      </Row>
      <Row className="d-flex" style={{}}>
         {StudentLessonTable(userActivities)}
      </Row>
    </div>
    </div>
  );
};
/*
<Row>
        <Col className="col-xl-6 col-xxl-5 d-flex">
          <div className="w-100">
            <Card className="info-card" style={{height: "100%"}} >
            <CardBody>
              {NextLessonInfo(userActivities, "student")}
            </CardBody>
          </Card>
          </div>
        </Col>
        <Col className="col-xl-6 col-xxl-5 d-flex">
          <div className="w-100">
            <Row>
              <Col className="col-sm-6">
                <Card className="info-card">
                  <CardBody>
              <Row >
                <Col className="col-8">
                  <div>
                    <p className="header text-sm mb-0 text-capitalize font-weight-bold">
                      Aktive pakker har
                    </p>
                    <h5 className="details font-weight-bolder mb-0">
                      23 timer igjen
                    </h5>
                  </div>
                </Col>
                <div className="col-4 end">
                  <div className="icon icon-shape shadow border-radius-md">
                    <FontAwesomeIcon
                        icon={faBox}
                    />
                  </div>
                </div>
              </Row>
            </CardBody>
                </Card>
              </Col>
              <Col className="col-sm-6">
                <Card className="info-card">
                  <CardBody>
                    <Row >
                      <Col className="col-8">
                    <div>
                      <p className="header text-sm mb-0 font-weight-bold">
                        PLANLAGTE TIMER
                      </p>
                      <h5 className="details font-weight-bolder mb-0">
                        4
                      </h5>
                    </div>
                  </Col>
                  <div className="col-4 end">
                    <div className="icon icon-shape shadow border-radius-md">
                      <FontAwesomeIcon
                          icon={faBox}
                      />
                    </div>
                  </div>
                </Row>
              </CardBody>
          </Card>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <div style={{marginBottom: "3em"}}/>
      {StudentLessonTable(userActivities)}
* */


export default StudentDashboard;
