import axiosInstance from "../../axios";
import {
    GET_ACTIVITY_CATEGORIES_SUCCESS,
    GET_SUBJECTS_FAIL, GET_SUBJECTS_REQUEST, GET_SUBJECTS_SUCCESS,
    GET_TEACHER_OVERVIEW_FAIL,
    GET_TEACHER_OVERVIEW_REQUEST,
    GET_TEACHER_OVERVIEW_SUCCESS,
} from "../constants";
import {resetPassword} from "./resetPassword";
export const getTeacherOverview = () => async (dispatch) => {
    try {
        dispatch({ type: GET_TEACHER_OVERVIEW_REQUEST, clear: true });
        const { data } = await axiosInstance.get("/user/teacher/");
        dispatch({ type: GET_TEACHER_OVERVIEW_SUCCESS, payload: data });
        return data;
    } catch (error) {
        dispatch({
            type: GET_TEACHER_OVERVIEW_FAIL,
            error:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const submitNewTeacher = (newTeacher) => async (dispatch) => {
    try {
        // this will not be the actual password for the new teacher but the api call still requires a password.
        newTeacher.password = "12023saaq_UNDEFINED";
        newTeacher.activity_categories = [1];
        newTeacher.is_active = true;
        const { data } = await axiosInstance.post("/user/teacher/", newTeacher);

        //console.log("data: ", data);
        //console.log("data reset pass: ", resetPassData);
    } catch (error) {
        if(error.response.status === 400) {
            return error.response;
        }else {
            /*dispatch({
                type: GET_TEACHER_OVERVIEW_FAIL,
                error:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,
            });*/
        }
    }
}

export const patchTeacher = (teacher) => async (dispatch) => {
    try {


        const { data } = await axiosInstance.patch("/user/teacher/" + teacher.id + "/", teacher);
        //console.log("data: ", data);
    } catch (error) {
        if(error.response.status === 400) {
            return error.response;
        }else {
            /*dispatch({
                type: GET_TEACHER_OVERVIEW_FAIL,
                error:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,
            });*/
        }
    }
}

export const getActivityCategories = () => async (dispatch) => {
    try {
        dispatch({ type: GET_ACTIVITY_CATEGORIES_SUCCESS, clear: true });
        const { data } = await axiosInstance.get("/activity/activity-category/");
        return data;
    } catch (error) {
        dispatch({
            type: GET_TEACHER_OVERVIEW_FAIL,
            error:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const getSubjects = () => async (dispatch) => {
    try {
        dispatch({type: GET_SUBJECTS_REQUEST, clear: true});
        const { data } = await axiosInstance.get("/subject/all-subjects/");
        dispatch({type: GET_SUBJECTS_SUCCESS, payload: data});
        return data;
    } catch (error) {
        dispatch({
            type: GET_SUBJECTS_FAIL,
            error:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

