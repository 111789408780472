import jwt from 'jsonwebtoken';

const TokenService = (function tokenService() {
  let service;
  function getServiceFunc() {
    if (!service) {
      service = this;
      return service;
    }
    return service;
  }

  const setToken = (tokenObj) => {
    if (tokenObj.access) {
      localStorage.setItem('access_token', tokenObj.access);
    }
    if (tokenObj.refresh) {
      localStorage.setItem('refresh_token', tokenObj.refresh);
    }
  };

  const getAccessToken = () => localStorage.getItem('access_token');

  const getRefreshToken = () => localStorage.getItem('refresh_token');

  const getTokenValidity = (tokenObj) => {
    const decodedToken = jwt.decode(tokenObj, { complete: true });
    const dateNow = new Date();
    const timeStamp = dateNow.getTime() / 1000;

    return decodedToken.payload.exp >= timeStamp;
  };

  const getAccessTokenValidity = () => {
    const access_token = getAccessToken();
    if (access_token) {
      return getTokenValidity(access_token);
    }
    return null;
  };

  const getRefreshTokenValidity = () => {
    const refresh_token = getRefreshToken();
    if (refresh_token) {
      return getTokenValidity(refresh_token);
    }
    return null;
  };

  const clearToken = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
  };

  return {
    getService: getServiceFunc,
    setToken,
    getAccessToken,
    getRefreshToken,
    getAccessTokenValidity,
    getRefreshTokenValidity,
    clearToken,
  };
}());

export default TokenService;
