import React from "react";
import PrivateRoute from "./PrivateRoute";
import {managementRoutes} from "./Management/routesConfig";
import {customerRoutes} from "./Customer/routesConfig";
import {teacherRoutes} from "./Teacher/routesConfig";
import Layout from "./Layout";

const AuthenticatedRoutes = () => {
    const allRoutes = managementRoutes.concat(teacherRoutes).concat(customerRoutes);
    return allRoutes.map((route, index) =>
      <PrivateRoute
          key={index}
          path={route.path}
          icon={route.icon}
          component={route.component}
          layout={Layout}
          authCheck={route.authCheck}
          exact
      />
    )
}

export default AuthenticatedRoutes;