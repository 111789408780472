import axiosInstance from "../../axios";
import {
    GET_SUBJECTS_FAIL, GET_SUBJECTS_REQUEST, GET_SUBJECTS_SUCCESS,
    GET_TEACHER_MATCHING_FAIL,
    GET_TEACHER_MATCHING_REQUEST,
    GET_TEACHER_MATCHING_SUCCESS,
} from "../constants";
export const getTeacherMatching = () => async (dispatch) => {
    try {
        dispatch({ type: GET_TEACHER_MATCHING_REQUEST, clear: true });
        const { data } = await axiosInstance.get("/user/get-teachers/");
        dispatch({ type: GET_TEACHER_MATCHING_SUCCESS, payload: data });
        return data;
    } catch (error) {
        dispatch({
            type: GET_TEACHER_MATCHING_FAIL,
            error:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const getSubjects = () => async (dispatch) => {
    try {
        dispatch({type: GET_SUBJECTS_REQUEST, clear: true});
        const { data } = await axiosInstance.get("/subject/all-subjects/");
        dispatch({type: GET_SUBJECTS_SUCCESS, payload: data});
        return data;
    } catch (error) {
        dispatch({
            type: GET_SUBJECTS_FAIL,
            error:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

