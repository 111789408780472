import React from "react"
import { useSelector } from "react-redux";
import UserSchedule from "../../../../../features/schedule/UserSchedule";

const TeacherSchedulePage = () => {

    const {user} = useSelector((state) => state.currentUser.currentUser)

    return (
        <>
            <h3>{user.first_name + " " + user.last_name}s kalender</h3>
            <ul>
                <li>Legg inn tidspunktene du er tilgjengelig hver uke. </li>
                <li>
                    Per nå kan du ikke legge inn repeterende hendelser<br/>
                    Du må derfor oppdattere kalenderen hver uke.
                </li>
                <li>
                    Driftsteamet bruker disse tidspunktene for å se når du har mulighet til å ta oppdrag med elever.
                </li>
                <li>Husk at du minimum må sette opp 4 timer der du er tilgjengelig.</li>
            </ul>
            <hr/>
            <div style={{padding: 5}}>
                <UserSchedule userId={user.id} />
            </div>
        </>
    )
}

export default TeacherSchedulePage
