import {
  ADD_SUBJECT_TO_STUDENT_FAIL,
  ADD_SUBJECT_TO_STUDENT_REQUEST, ADD_SUBJECT_TO_STUDENT_SUCCESS,
  GET_SUBJECT_TREE_FAIL,
  GET_SUBJECT_TREE_REQUEST,
  GET_SUBJECT_TREE_SUCCESS
} from "../constants";

const initialState = { loading: false, tree: [] , newlyAddedSubject: null};

export const subjectTreeReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_SUBJECT_TO_STUDENT_REQUEST:
      return {...state, loading: true};

    case ADD_SUBJECT_TO_STUDENT_SUCCESS:
      return {
        ...state,
        loading: false
      };

    case ADD_SUBJECT_TO_STUDENT_FAIL:
      return {...state, loading: false};

    case GET_SUBJECT_TREE_REQUEST:
      return { ...state, loading: true };

    case GET_SUBJECT_TREE_SUCCESS:
      return { ...state, loading: false, tree: action.payload };

    case GET_SUBJECT_TREE_FAIL:
      return { ...state, loading: false };

    default:
      return state;
  }
};
