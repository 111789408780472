import {
  GET_SUBJECTS_FAIL,
  GET_SUBJECTS_REQUEST,
  GET_SUBJECTS_SUCCESS,
} from "../constants";
const initialState = {
  subjects: [],
};

export const teacherSubjectReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SUBJECTS_REQUEST:
      return { ...state, loading: true, subjects: [] };

    case GET_SUBJECTS_SUCCESS:
      return {
        ...state,
        loading: false,
        subjects: action.payload,
      };

    case GET_SUBJECTS_FAIL:
      return { ...state, loading: false };

    default:
      return state;
  }
};
