import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Tooltip,
  FormGroup,
  Label
} from "reactstrap";
import Loader from "../../../../../components/Loader";
import { CSVLink } from "react-csv";
import { getTeacherSalaries } from "../../../../../redux/actions/getTeacherSalaries";
import { markTeachersAsPaid } from "../../../../../redux/actions/markTeachersAsPaid";
import MessageModal from "../../../../../components/MessageModal";
import TableRow from "./TableRow";

const SalaryOverview = () => {
  const dispatch = useDispatch();

  //getting the list of teacher salaries
  const salaryObjects = useSelector((state) => state.salary);
  const { teachers, loading, markAsPaidLoading, actionSucceeded } =
    salaryObjects;

  const csvLink = useRef();


  const [onlyShowPaid, setOnlyShowPaid] = useState(false);
  const toggleFilterPaid = () => setOnlyShowPaid((prevState) => !prevState);

  //making redux update the list of teacher salaries when a teacher is marked as paid
  useEffect(() => {
    if (!markAsPaidLoading && salaryHasBeenMarked) {
      dispatch(
        getTeacherSalaries({
          year: currentMonth.year,
          month: currentMonth.month_num,
          month_is_unpaid: true,
        })
      );
      if (actionSucceeded) displayMarkingSuccess();
      else displayMarkingFailed();
      setSalaryHasBeenMarked(false);
    }
  }, [markAsPaidLoading]);

  //initializing all tooltips as disabled
  const tooltipBools = {};
  teachers.forEach((teacher) =>
    teacher.number_of_activities_without_summary
      ? (tooltipBools[teacher.id] = false)
      : ""
  );

  const [tooltipOpen, setTooltipOpen] = useState(tooltipBools);
  const toggle = (id) =>
    setTooltipOpen((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));


  const unpaid = (teacher) => {return (
    teacher.next_base_payment +
    teacher.incentive_bonus_next_payment +
    teacher.travel_salary +
    teacher.special_bonus_salary
)}


  //toggle only show alerts
  const [onlyShowAlerts, setOnlyShowAlerts] = useState(false);
  const toggleFilter = () => setOnlyShowAlerts((prevState) => !prevState);

  //toggle modal window
  const [showModal, setShowModal] = useState(false);
  const toggleModal = () => setShowModal((prevState) => !prevState);

  //toggle are you sure window
  const [showAreYouSure, setAreYouSure] = useState(false);
  const [isMarkingAll, setIsMarkingAll] = useState(false);
  const toggleAreYouSure = () => setAreYouSure((prevState) => !prevState);

  const [currentTeacher, setCurrentTeacher] = useState({});

  //filters the table according to the search text
  const [searchText, setSearchText] = useState("");
  const searchFilter = (name) => {
    return name.toLowerCase().includes(searchText.toLowerCase());
  };

  const [messageInfo, setMessageInfo] = useState({ header: "", message: "" });
  const [messageIsOpen, setMessageIsOpen] = useState(false);
  const toggleMessageIsOpen = () => setMessageIsOpen((prevState) => !prevState);

  const [salaryHasBeenMarked, setSalaryHasBeenMarked] = useState(false);

  const monthNames = [
    "Januar",
    "Februar",
    "Mars",
    "April",
    "Mai",
    "Juni",
    "Juli",
    "August",
    "September",
    "Oktober",
    "November",
    "Desember",
  ];

  const calculateMonthOptions = () => {
    let months = [];
    let d = new Date(getYear(), getMonthNumber() -1);
    for (let i=0; i<=12; i++) {
        months.push(
          {
            id: i,
            month_num: d.getMonth()+1,
            year: d.getFullYear(),
            month_name: monthNames[d.getMonth()]
          }
        );
        d.setMonth(d.getMonth() - 1);
    }
    return months
  }
  const getMonthNumber = (date = new Date()) =>{
    var month_number = (date.getMonth() + (date.getDate() > 15 ? 2 : 1)) % 12;
    if (month_number === 0) {
      month_number = 12
    }
    return month_number
  }
    

  const getYear = (date = new Date()) => 
    date.getFullYear() + (date.getMonth() === 11 && date.getDate() > 15 ? 1 : 0);
  

  const monthOptions = calculateMonthOptions();
  const [currentMonth, setCurrentMonth] = useState(monthOptions[0]);
  const [currentMonthId, setCurrentMonthId] = useState(monthOptions[0].id)

  const updateCurrentMonth = (id) => {

    let m = monthOptions.find((e) => e.id.toString() === id.toString())
    setCurrentMonthId(id);
    setCurrentMonth(m);
  }

  const listMonthOptionsCategories = () => {
    return monthOptions.map((ac, key) => (
      <option value={ac.id} key={key}>
        {ac.month_name + ' ' + ac.year.toString()}
      </option>
    ));
  };

  // making redux fetch teacher salaries from the backend
  useEffect(() => {
    dispatch(
      getTeacherSalaries({
        year: currentMonth.year,
        month: currentMonth.month_num,
        month_is_unpaid: !onlyShowPaid,
      })
    );
  }, [dispatch, currentMonth, onlyShowPaid]);


  const displayMarkingSuccess = () => {
    setMessageInfo({
      header: "Vellykket!",
      message: "Lærerene du valgte ble markert som betalt",
      link: "",
    });
    toggleMessageIsOpen();
  };

  const displayMarkingFailed = () => {
    setMessageInfo({ error: true });
    toggleMessageIsOpen();
  };


  //returns the rows of the table
  const shadowBox = (text) => (
    <Row className="shadowBox">
      <Col xs="12" sm="" md="" lg="" xl="">
        <div className="lightCircle"/>
      </Col>
      {text}
      <Col xs="12" sm="" md="" lg="" xl=""/>
    </Row>
  );

  const markAsPaid = (ids, date = new Date()) => {
    dispatch(
      markTeachersAsPaid({
        activity_ids: ids,
        payment_date: date.toJSON().slice(0, 10),
      })
    );
    setSalaryHasBeenMarked(true);
  };

  const showTeacher = (teacher) => {
    setCurrentTeacher(teacher);
    toggleModal();
  };

  const getTotalHours = () =>
    teachers
      .map((teacher) => teacher.total_activity_duration_for_payment)
      .reduce((a, b) => a + b, 0);

  const getTotalUnpaid = () =>
    teachers.map((teacher) => unpaid(teacher)).reduce((a, b) => a + b, 0);

  const getTotalCompanyHours = () =>
    teachers
      .map(
        (teacher) =>
          teacher.total_custom_price_customer_activity_duration_for_payment
      )
      .reduce((a, b) => a + b, 0);


  const downloadCSV = async () => {

    await dispatch(
        getTeacherSalaries({
          year: currentMonth.year,
          month: currentMonth.month_num,
          month_is_unpaid: true,
        })
      );

    csvLink.current.link.click()

  };

  if (loading) return <Loader/>;

  return (
    <div>
      {MessageModal({
        ...messageInfo,
        toggle: toggleMessageIsOpen,
        isOpen: messageIsOpen,
      })}
      <Modal isOpen={showAreYouSure} toggle={toggleAreYouSure} size="sm">
        <ModalHeader toggle={toggleAreYouSure}>
          Marker {isMarkingAll ? "all" : currentTeacher.name + " sin"} lønn som
          betalt
        </ModalHeader>
        <ModalBody>
          Er du sikker på at du vil markere{" "}
          {isMarkingAll ? "all" : currentTeacher.name + " sin"} lønn som betalt?
        </ModalBody>
        <ModalFooter>
          <div className="between" style={{ width: "100%" }}>
            <Button color="secondary" onClick={toggleAreYouSure}>
              Avbryt
            </Button>
            <Button
              color="secondary"
              onClick={() => {
                if (isMarkingAll)
                  markAsPaid(
                    teachers
                      .map((teacher) => teacher.activity_ids)
                      .reduce((a, b) => [...a, ...b], [])
                  );
                else markAsPaid(currentTeacher.activity_ids);
                setAreYouSure(false);
              }}
            >
              Ja
            </Button>
          </div>
        </ModalFooter>
      </Modal>
      <Modal isOpen={showModal} toggle={toggleModal} size="lg">
        <ModalHeader toggle={toggleModal}>{currentTeacher.name}</ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <Row>
                <Col>Ubetalt lønn</Col>
              </Row>
              <Row>
                <Col>Basislønn:</Col>
                <Col>{currentTeacher.next_base_payment}</Col>
              </Row>
              <Row>
                <Col>+ Bonuslønn:</Col>
                <Col>{currentTeacher.incentive_bonus_next_payment}</Col>
              </Row>
              <Row>
                <Col>+ Reiselønn</Col>
                <Col>{currentTeacher.travel_salary}</Col>
              </Row>
              <Row>
                <Col>+ Ekstralønn</Col>
                <Col>{currentTeacher.special_bonus_salary}</Col>
              </Row>
              <Row>
                <Col>= Total lønn:</Col>
                <Col>{unpaid(currentTeacher)}</Col>
              </Row>
            </Col>
            <Col>
              <Row>
                <Col className="fullFlexCentre">Totalt antall timer</Col>
              </Row>
              <Row>
                <Col className="fullFlexCentre">
                  {currentTeacher.total_activity_duration_for_payment}
                </Col>
              </Row>
            </Col>
            <Col>
              <Row>
                <Col
                  className={
                    "fullFlexCentre" +
                    (currentTeacher.number_of_activities_without_summary > 0
                      ? " warningText"
                      : "")
                  }
                >
                  Økter som mangler oppsummering
                </Col>
              </Row>
              <Row>
                <Col
                  className={
                    "fullFlexCentre" +
                    (currentTeacher.number_of_activities_without_summary > 0
                      ? " warningText"
                      : "")
                  }
                >
                  {currentTeacher.number_of_activities_without_summary}
                </Col>
              </Row>
              {currentTeacher.number_of_activities_without_summary ? (
                <Row>
                  <Col className="fullFlexCentre">
                    <a>Send påminnelse til lærer</a>
                  </Col>
                </Row>
              ) : (
                ""
              )}
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleModal}>
            Lukk vindu
          </Button>
        </ModalFooter>
      </Modal>
          <div className="title mb-2">Betal lønn</div>
          <Row>
            <Col  xs="12" sm="12" md="4" lg="5" xl="4">
              <Row>
                <Col style={{paddingLeft: 20, paddingRight: 20}}>
                  <FormGroup>
                    <Label>Lønn til og med:</Label>
                    <Input
                      type="select"
                      value={currentMonthId}
                      onChange={(e) => updateCurrentMonth(e.target.value)}
                      invalid={false}
                      className="inputField"
                      required
                      id="month"
                    >
                      {listMonthOptionsCategories()}
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
              <Row className="mt-1 mb-3">
                <Col style={{paddingLeft: 20, paddingRight: 20}}>
                  {!onlyShowPaid ? (
                      <div>
                        <button
                            style={{width: "100%"}}
                            className="growButton darkButton"
                            onClick={downloadCSV}
                        >
                          Last ned lønn - CSV
                        </button>
                        <CSVLink
                            data={salaryObjects.teachers.map((teacher) =>
                                [
                                  0,
                                  0,
                                  teacher.visma_employment_id,
                                  13050,
                                  0,
                                  0,
                                  0,
                                  unpaid(teacher),
                                  "",
                                  "",
                                  "",
                                  "",
                                  "",
                                  "",
                                  "",
                                  "",
                                  ""
                                ].join(";")
                            )
                                .join("\n")}
                            ref={csvLink}
                            filename={"Lønn-" + currentMonth.month_name + "-" + getYear() + ".csv"}
                        />
                      </div>
                    ): <div/>}

                </Col>
              </Row>
            </Col>
            <Col  xs="12" sm="12" md="8" lg="7" xl="8">
              {shadowBox("Totalt antall timer: " + getTotalHours())}
              {
                !onlyShowPaid ?
                    shadowBox("Total ubetalt lønn: " + getTotalUnpaid())
                    :
                    shadowBox("Total utbetalt lønn: " + getTotalUnpaid())
              }
              {shadowBox("Antall timer bedrift: " + getTotalCompanyHours())}
            </Col>
          </Row>
          <Row>
            <Col
              className="bottom"
              xs="12"
              sm="6"
              md="4"
              lg="6"
              xl="4"
              style={{ marginBottom: "10px", display: "flex", alignContent: "center", justifyContent: "center", flexDirection: "column"}}
            >
              <div className="start" style={{ width: "100%"}}>
                Søk:{" "}
                <Input
                  className="inputField"
                  onChange={(e) => setSearchText(e.target.value)}
                  style={{ marginLeft: "20px" }}
                />
              </div>
            </Col>
            <Col
              className="bottom"
              xs="16"
              sm="6"
              md="8"
              lg="12"
              xl="6"
              style={{ marginBottom: "10px", alignItems: "center" }}
            >
                {
                  !onlyShowPaid ?
                  <button
                      className="growButton darkButton"
                      style={{ width: "50%", marginRight: "5px" }}
                      onClick={toggleFilter}
                  >
                    {onlyShowAlerts
                        ? "Vis alle lærere"
                        : "Bare vis lærere med varsler"}
                  </button>:<div/>
                }
                {
                  <button
                      className="growButton darkButton"
                      style={{ width: "50%"}}
                      onClick={toggleFilterPaid}
                  >
                    {!onlyShowPaid
                        ? "Bare vis betalt lønn"
                        : "Bare vis ubetalt lønn"}
                  </button>
                }

            </Col>
            <Col
              className="bottom end"
              xs="6"
              md="2"
              lg="6"
              xl="2"
              style={{ marginBottom: "10px" }}
            >
              {
                !onlyShowPaid ? <button
                    className="button lightButton"
                    // onClick={() => markAsPaid(teachers.map((teacher) => teacher.activity_ids).reduce((a, b) => [...a, ...b], []))}

                    onClick={() => {
                      setIsMarkingAll(true);
                      toggleAreYouSure();
                    }}

                    style={{width: "100%"}}
                >
                  Marker alt som betalt
                </button> : <div/>
              }
            </Col>
          </Row>
          <Row style={{ marginTop: "10px", overflowX: "auto" }}>
            <table className="economyTable">
              <thead style={{position: "inherit"}}>
                <tr style={{ borderTop: "0px solid #000000" }}>
                  <th>Navn</th>
                  <th>Timer</th>
                  <th>{!onlyShowPaid ? "Ikke-betalt lønn" : "Betalt lønn"}</th>
                  <th>Informasjon</th>
                  <th/>
                </tr>
              </thead>
              <tbody>
                {teachers.sort((a,b) => a.visma_employment_id - b.visma_employment_id)
                  .filter((teacher) => searchFilter(teacher.name))
                  .filter((teacher) =>
                    onlyShowAlerts ? teacher.number_of_activities_without_summary : true
                  )
                  .map((teacher, key) =>
                      <TableRow key={key} teacher={teacher} keyProp={key}
                                unpaid={unpaid}
                                setIsMarkingAll={setIsMarkingAll}
                                setCurrentTeacher={setCurrentTeacher}
                                toggleAreYouSure={toggleAreYouSure}
                                showTeacher={showTeacher}
                                tooltipOpen={tooltipOpen}
                                toggle={toggle}
                                onlyShowPaid={onlyShowPaid}
                      />
                  )}
              </tbody>

            </table>
          </Row>
    </div>
  );
};

export default SalaryOverview;
