import axiosInstance from "../../axios";
import {
  MARK_TEACHERS_AS_PAID_FAIL,
  MARK_TEACHERS_AS_PAID_REQUEST,
  MARK_TEACHERS_AS_PAID_SUCCESS,
} from "../constants";
export const markTeachersAsPaid = (payload) => async (dispatch) => {
  try {
    dispatch({ type: MARK_TEACHERS_AS_PAID_REQUEST, clear: true });

    const { data } = await axiosInstance.post(
      "/salary/mark-activities-as-paid/",
      payload
    );

    dispatch({ type: MARK_TEACHERS_AS_PAID_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: MARK_TEACHERS_AS_PAID_FAIL,
      error:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
