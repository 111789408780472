import axiosInstance from "../../axios";
import {
    GET_TEACHER_RESOURCES_FAIL,
    GET_TEACHER_RESOURCES_REQUEST,
    GET_TEACHER_RESOURCES_SUCCESS,
    CREATE_TEACHER_RESOURCES_FAIL,
    CREATE_TEACHER_RESOURCES_REQUEST,
    CREATE_TEACHER_RESOURCES_SUCCESS,
} from "../constants";
export const getTeacherResources = () => async (dispatch) => {
    try {
        dispatch({ type: GET_TEACHER_RESOURCES_REQUEST, clear: true });
        const { data } = await axiosInstance.get("/document/file-tree/");
        dispatch({ type: GET_TEACHER_RESOURCES_SUCCESS, payload: data });
        return data;
    } catch (error) {
        dispatch({
            type: GET_TEACHER_RESOURCES_FAIL,
            error:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const createNode = (data) => async (dispatch) => {
    try {
        dispatch({ type: CREATE_TEACHER_RESOURCES_REQUEST, clear: true });
        const { data } = await axiosInstance.post("/document/create-node/", data);
        dispatch({ type: CREATE_TEACHER_RESOURCES_SUCCESS, payload: data });
        return data;
    } catch (error) {
        dispatch({
            type: CREATE_TEACHER_RESOURCES_FAIL,
            error:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
}