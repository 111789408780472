import {
  APPLICATION_CREATE_FAIL,
  APPLICATION_CREATE_REQUEST,
  APPLICATION_CREATE_SUCCESS,
} from "../constants";

export const applicationCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case APPLICATION_CREATE_REQUEST:
      return {
        loading: true,
      };

    case APPLICATION_CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
        order: action.payload,
      };

    case APPLICATION_CREATE_FAIL:
      return {
        loading: false,
      };
    default:
      return state;
  }
};