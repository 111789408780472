import React, {useEffect, useMemo, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import {editPackage, deletePackage, getFirstPackagePage, getPackagePage} from "../../../../../redux/actions/packageOverviewActions";
import PaginatedTable from "../../../../../components/PaginatedTable/PaginatedTable";
import {dateFormatter} from "../../../../../utils/formattingHelpers";
import { Row, Col, Button, Label, Input, FormGroup, Modal, ModalBody } from "reactstrap";
import MessageModal from "../../../../../components/MessageModal";


const PackageDropdown = ({pack, currentPage}) => {

    const dispatch = useDispatch()

    const {
        id,
        sold_for_price,
        package_type_price,
        additional_hours_added,
        discount_percentage,
        extra_price,
        description,
        comment_for_invoice
    } = pack

    const [edit, setEdit] = useState(false);

    const [patch, setPatch] = useState({
        discount_percentage: discount_percentage,
        additional_hours_added: additional_hours_added,
        extra_price: extra_price
    });

    const patchHandler = (field, value) => {
        setPatch((prevState) => ({
            ...prevState,
            [field]: value,
        }));
    };

    const newOrderconfirmation = () => {
        if (patch.discount_percentage !== discount_percentage || patch.extra_price !== extra_price || patch.additional_hours_added !== additional_hours_added) {
            return true;
        }else {
            return false;
        }
    }
    const savePackage = () => {
        dispatch(editPackage(id, patch, newOrderconfirmation()))
    }

    const [showAreYouSure, setShowAreYouSure] = useState(false);

    const deleteThisPackage = () => {
        dispatch(deletePackage(id));
    }

    return (
        <div style={{textAlign: "left", background: "white", padding: "20px", borderRadius: "15px"}}>
            <Modal isOpen={showAreYouSure} onClick={() => setShowAreYouSure(false)}>
                <div
                    id="failModalHeader"
                    className="fullFlexCentre"
                >
                    <div className="title whiteText">Advarsel</div>
                </div>
                <ModalBody>
                    Er du sikker på at du vil slette denne pakken? Den kan ikke gjenopprettes!
                    <Row>
                        <Col>
                            <Button className="button redButton" style={{ float: "right", marginTop: "20px"}} onClick={() => {
                                setShowAreYouSure(false);
                                deleteThisPackage();
                            }}>Ja slett pakken</Button>
                        </Col>
                        <Col>
                            <Button className="button darkButton" style={{ float: "left", marginTop: "20px"}} onClick={() => {
                                setShowAreYouSure(false);
                            }}>Nei avbryt</Button>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
            <Row style={{marginBottom: "25px"}}>
                <Col>
                    {
                        edit ?
                            <div className="title">Rediger pakkedetaljer</div>
                            :
                            <div className="title">Pakkedetaljer</div>
                    }
                </Col>
                <Col>
                    <Button className="button darkButton btn btn-secondary" style={{float: "right"}} onClick={() => {
                        setEdit(!edit);
                    }}>{edit ? "Avbryt" : "Rediger"}</Button>
                    <Button className="button redButton btn btn-secondary" style={{float: "right", marginRight: "15px"}} onClick={() => {
                        setShowAreYouSure(true);
                    }}>Slett pakke</Button>
                </Col>
            </Row>
            {
                edit ?
                    <div>
                        <Row style={{marginBottom: "20px"}}>
                            <Col>
                                Hvis du redigerer ekstra timer, avslag og ekstra pris, vil du kunne sende en ny ordrebekreftelse til kunden med de oppdatterte vilkårende.
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                            <Label>Beskrivelse:</Label>
                            <Input
                                name="our_comment"
                                type="textarea"
                                placeholder="Beskrivelse..."
                                rows="4"
                                defaultValue={description}
                                className="inputField"
                                onChange={(e) => patch.description = e.target.value}
                                invalid={false}
                                required
                            />
                            <Row style={{marginTop: "25px"}}>
                                <Col>
                                    <FormGroup>
                                        <Label>Ekstra timer</Label>
                                        <Input
                                            name="extraHours"
                                            rows="1"
                                            type="number"
                                            className="inputField"
                                            style={{maxWidth: "200px"}}
                                            value={patch.additional_hours_added}
                                            onChange={(e) => patchHandler("additional_hours_added", e.target.value === "" ? 0 : parseFloat(e.target.value))}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <Label>Ekstra pris</Label>
                                    <Input
                                        name="extraPrice"
                                        type="number"
                                        rows="1"
                                        value={patch.extra_price}
                                        className="inputField"
                                        style={{maxWidth: "200px"}}
                                        onChange={(e) => patchHandler("extra_price", e.target.value === "" ? 0 : parseFloat(e.target.value))}
                                    />
                                </Col>
                            </Row>
                        </Col>
                            <Col>
                            <Label>Kommentar til ordrebekreftelse:</Label>
                            <Input
                                name="our_comment"
                                type="textarea"
                                placeholder="Kommentar..."
                                rows="4"
                                defaultValue={comment_for_invoice}
                                className="inputField"
                                onChange={(e) => patch.comment_for_invoice = e.target.value}
                                invalid={false}
                                required
                            />
                            <Row style={{marginTop: "25px"}}>
                                <Col>
                                    <FormGroup>
                                        <Label>Avslag</Label>
                                        <br />
                                        <div style={{ display: "inline-flex" }}>
                                            <Input
                                                name="discount"
                                                type="number"
                                                placeholder="Prosent"
                                                rows="1"
                                                className="inputField"
                                                value={patch.discount_percentage}
                                                onChange={(e) => patchHandler("discount_percentage", e.target.value === "" ? 0 : parseFloat(e.target.value))}
                                            />
                                            <p style={{ marginTop: "6px", marginLeft: "6px" }}>%</p>
                                        </div>
                                    </FormGroup>

                                </Col>
                                <Col style={{position: "relative"}}>

                                </Col>
                            </Row>
                        </Col>
                        </Row>
                        <Row style={{}}>
                            <Col>
                                Hvis du ønsker å redigere andre ting enn dette, bør du slette pakken og opprette en ny.
                            </Col>
                            <Col style={{position: "relative"}}>
                                <Button className="button darkButton btn btn-secondary" style={{ position: "absolute", right: "12px", bottom: "12px" }} onClick={() => {
                                    savePackage();
                                }}>Lagre</Button>
                            </Col>
                        </Row>
                    </div>
                    :
                    <Row>
                        <Col>
                            <p><b>Solgt for pris:</b> {sold_for_price} kr</p>
                            <p><b>Pakkepris:</b> {package_type_price} kr</p>
                            <p><b>Ekstra timer lagt til:</b> {additional_hours_added === 1 ? "1 time" : additional_hours_added + " timer"}</p>
                            <p><b>Avslag:</b> {discount_percentage} %</p>
                            <p><b>Ekstra pris:</b> {extra_price} kr</p>
                        </Col>
                        <Col>
                            <p><b>Beskrivelse:</b></p>
                            <p style={{marginLeft: "5px"}}>{description}</p>
                            <p><b>Kommentar til ordrebekreftelse:</b></p>
                            <p style={{marginLeft: "5px"}}>{comment_for_invoice}</p>
                        </Col>
                    </Row>
            }
        </div>
    );
}

export default PackageDropdown;