import React, { useState } from "react";
import { Button, Card, CardBody, FormGroup, Input, Label } from "reactstrap";
import { useDispatch } from "react-redux";
import { resetPassword } from "../../../redux/actions/resetPassword";

const ResetPassword = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);

  const changeHandler = (e) => {
    setEmail(e.target.value);
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const handleReset = () => {
    if (validateEmail(email)) {
      setInvalidEmail(false);

      dispatch(
        resetPassword({
          email: email,
        })
      );
      setSubmitted(true);
    } else {
      setInvalidEmail(true);
      setSubmitted(false);
    }
  };

  return (
    <React.Fragment>
      <div className="text-center mt-4">
        <h1 className="h2">Reset password</h1>
        <p className="lead">Enter your email to reset your password.</p>
      </div>

      <Card>
        <CardBody>
          <div className="m-sm-4">
            <div>
              <FormGroup>
                <Label>Email</Label>
                <Input
                  bsSize="lg"
                  type="email"
                  name="email"
                  placeholder="Enter your email"
                  onChange={changeHandler}
                />
              </FormGroup>
              <div className="text-center mt-3">
                <Button color="primary" size="lg" onClick={handleReset}>
                  Reset password
                </Button>
              </div>
            </div>
            {submitted ? (
              <div style={{ marginTop: "20px" }}>
                A reset password link has been sent to this email if the user
                exists.
              </div>
            ) : (
              <div />
            )}
            {invalidEmail ? (
              <div style={{ marginTop: "20px", color: "red" }}>
                Enter a valid email.
              </div>
            ) : (
              <div />
            )}
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default ResetPassword;
