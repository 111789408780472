import axiosInstance from "../../axios";
import axios from "axios";
import {
  CONFIRM_RESET_PASSWORD_FAIL,
  CONFIRM_RESET_PASSWORD_SUCCESS,
  CONFIRM_RESET_PASSWORD_REQUEST,
} from "../constants";

export const confirmResetPassword = (props) => async (dispatch) => {
  dispatch({ type: CONFIRM_RESET_PASSWORD_REQUEST, clear: true });
  try {
    const { data } = await axios
      .post(`${process.env.REACT_APP_API_URL}user/password-reset/confirm/`, {
        password: props.password,
        token: props.token,
      })
      .then((response) => {
        return response;
      });

    return data;

    dispatch({
      type: CONFIRM_RESET_PASSWORD_SUCCESS,
      payload: "",
    });
  } catch (error) {
    dispatch({
      type: CONFIRM_RESET_PASSWORD_FAIL,
      errors: error.response ? error : error.response.data,
    });
    return error.response.data;
  }
};
