import axiosInstance, {baseURL} from "../axios";


const PaginatedService = (function paginatedService() {
    const getPage = async (arg, thunkAPI) => {
        try {
            if(arg.link) {
                const response = await axiosInstance.get(
                    "/" + arg.base_endpoint + arg.link + arg.search + (arg.filter ? "&" + arg.filter : "")
                );
                return {...response.data, page: arg.page}
            }else{
                const response = await axiosInstance.get(
                    "/" + arg.base_endpoint + "?limit=" + arg.pageSize + "&offset=" + arg.pageSize*arg.page + "&search=" + arg.search + (arg.filter ? "&" + arg.filter : "")
                );
                return {...response.data, page: arg.page}
            }
        } catch (err) {
            if (err.response) {
                return thunkAPI.rejectWithValue({status: err.response.status, message: err.response.data})
            }
            return thunkAPI.rejectWithValue({status: "", message: err.message})
        }
    }

    const renderFulfilled = (state, action) => {
        state.page = action.payload.results
        state.pageIndex = action.payload.page
        state.count = action.payload['count']
        state.nextPage = (
            action.payload['next'] ?
                action.payload['next'].replace("http://", "").replace(baseURL.replace("http://", "").replace("https://", ""), "") :
                null
        )
        state.previousPage = (
            action.payload.previous ?
                action.payload.previous.replace("http://", "").replace(baseURL.replace("http://", "").replace("https://", ""), "") :
                null
        )
    }

    return {
        getPage,
        renderFulfilled
    };
}())

export default PaginatedService