// Employee type
export const MANAGEMENT_TYPE = "employee";

// Auth groups
export const ECONOMY_TEAM = "Economy Team";
export const SALES_TEAM = "Sales Team";
export const MARKETING_TEAM = "Marketing Team";
export const HUMAN_RESOURCES_TEAM = "Human Resources Team";
export const ALL_EMPLOYEE = "All Employee Access";

// Dashboard constants
export const MAIN_DASHBOARD = {name: "Hoveddashbord", metaBaseId: 37};
export const MARKETING_DASHBOARD = {name: "Markedsføringsdashbord", metaBaseId: 38};
export const SALES_DASHBOARD = {name:"Salgsdashbord", metaBaseId: 40}

export const ManagementPaths = {
    home: "/employee/dashboard",
    newPackage: "/sales/new-package",
    orderConfirmations: "/sales/order-confirmations",
    teacherMatching: "/sales/teacher-matching",
    teacherOverview: "/employee/teacher-overview",
    studentOverview: "/sales/student-overview",
    invoiceReceiverOverview: "/sales/invoice-receiver-overview",
    packageOverview: "/sales/package-overview",
    invoiceOverview: "/economy/invoice/overview",
    salaryOverview: "/economy/salary-overview"
}