export const dateFormatter = (date) => {
    return (date.getDate() < 10 ? "0" : "") +
        date.getDate() + "." +
        (date.getMonth() + 1 < 10 ? "0" : "") +
        (date.getMonth() + 1) + "." +
        date.getFullYear();
};

// formats a time
export const timeFormatter = (time) => {
    return (time.getHours() < 10 ? "0" : "") +
        time.getHours() + ":" +
        (time.getMinutes() < 10 ? "0" : "") +
        time.getMinutes();
};
