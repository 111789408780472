import React, {useState} from "react";
import {
    Card, CardBody, CardHeader, Col, Row
} from "reactstrap";
import Pil_ned from "../../../../../assets/img/RH/Pil_ned.png";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const FileTreeNode = (props) => {
    const [showChildren, setShowChildren] = useState(false);

    const toggleShowChildren = () => {
        setShowChildren(!showChildren);
    }

    const [color, setColor] = React.useState("white");
//rgb(219, 220, 222)
    return (
        <Card style={{ boxShadow: "0 0 0 0", paddingLeft: "0px", paddingRight: 0, paddingBottom: 0, marginRight: 0, marginBottom: 0, marginTop: "15px", maxWidth: "600px", borderColor: "black", border: 1}}>
            <CardHeader onMouseEnter={() => setColor(props.onMouseHoverColor)}
                        onMouseLeave={() => setColor("white")} style={{borderRadius: "25px", backgroundColor: props.headerColor, color: color, cursor: "pointer", maxWidth: "800px"}} onClick={toggleShowChildren}>
                <Row style={{padding: 0, marginBottom: 0}}>
                    <Col style={{minWidth: "75%"}}>
                        <p lang="no" className="textOverflow" style={{padding: 0, marginBottom: 0}}>{props.tree.name}</p>
                    </Col>
                    <Col style={{minWidth: "80px", textAlign: "right", padding: "0"}}>
                        {
                            (showChildren ? (
                                <div>Lukk <FontAwesomeIcon icon={faAngleUp} /></div>
                            ) : (
                                <div>Se mer <FontAwesomeIcon icon={faAngleDown} /></div>
                            ))
                        }


                    </Col>
                </Row>

            </CardHeader>
            {
                showChildren ? (
                    <CardBody style={{padding: 18, paddingRight: 0, paddingBottom: 0, marginBottom: 10, borderLeft: "ridge", borderColor: "#e0e0e0", borderWidth: 1}}>
                        {
                            (props.tree.documents.length > 0 && showChildren) ?
                                props.tree.documents.map((file, key) => {
                                    return (
                                        <div key={key} style={{margin: "auto", marginLeft: 5, marginBottom: 20, display: "flex" }}>
                                            <a
                                              target="blank"
                                              href={file.file_uri}
                                              style={{
                                                  borderStyle: "solid",
                                                  borderWidth: "1px",
                                                  borderColor: "black",
                                                  color: "black",
                                                  borderRadius: "15px",
                                                  padding: 8,
                                                  width: "100%"
                                              }}>
                                                {file.document_name}
                                            </a>
                                        </div>
                                    )
                                }) :
                                (
                                    (props.tree.children.length <= 0 && showChildren) ?
                                        (
                                            <div style={{marginLeft: "5%"}}>
                                                <p>Ingen filer å vise</p>
                                            </div>
                                        )
                                        :
                                        (
                                            <div/>
                                        )
                                )
                        }
                        {showChildren ? (
                                <div style={{marginTop: "10px"}}>
                                    {props.tree.children.map((child, key) => <FileTreeNode key={key} onMouseHoverColor={"rgb(219, 220, 222)"} headerColor={"#898d92"} tree={child} depth={props.depth + 1} />)}
                                </div>
                            )
                            :
                            (
                                <div/>
                            )}

                    </CardBody>

                ) : null
            }

        </Card>
    )
}

export default FileTreeNode;