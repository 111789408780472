import axiosInstance from "../../axios";
import { GET_PACKAGE_TYPES_FAIL, GET_PACKAGE_TYPES_REQUEST, GET_PACKAGE_TYPES_SUCCESS } from "../constants";

export const getPackageTypes = (date) => async (dispatch) => {
  try {
    dispatch({ type: GET_PACKAGE_TYPES_REQUEST });
    const { data } = await axiosInstance.post("package/package-type-overview/",
        {date:date}
        );
    dispatch({ type: GET_PACKAGE_TYPES_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_PACKAGE_TYPES_FAIL,
      payload: error.response && error.response.data.message ?
          error.response.data.message :
          error.message
    });
  }
};
