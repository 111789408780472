import axiosInstance from "../../axios";
import {
    GET_INVOICES_FAIL,
    GET_INVOICES_SUCCESS,
    GET_INVOICES_REQUEST
} from "../constants";

export const getInvoices = (startDate, endDate) => async (dispatch) => {
  try {
    dispatch({ type: GET_INVOICES_REQUEST, clear: true });
    const { data } = await axiosInstance.post("/payment/list-invoices/", {
                            start_date: startDate,
                            end_date: endDate,
                            sent: false
                        });
    dispatch({ type: GET_INVOICES_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_INVOICES_FAIL,
      error:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};