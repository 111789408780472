import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import axiosInstance from "../../axios";
import {returnError} from "../../utils/errorHelper";

const initialState = {}

export const getColor = (event) => {
    return event.event_type.label  === "Aktivitet" ?  "#4cc029" : "#3B82EC"
}

const formatDate = (d) => encodeURIComponent(d.toISOString())

const createFormattedEvents = (e, arg) => {
    let events = []

    e.start = new Date(e.start)
    e.end = new Date(e.end)
    e.event_type = e.event_type_label
    e.color = getColor(e)
    if (arg.canChange === false) e.disabled = true

    if (!e.recurrences) {
        let {event_type_label: _, ...rest} = e
        events.push(rest)
        return events
    }

    // Adding necessary property expected by RRuleGenerator component
    if (!e.recurrences.includes("INTERVAL")) {
        e.recurrences = e.recurrences.concat(";INTERVAL=1")
    }

    e.child_ids = []

    let {event_type_label: _, ...rest} = e
    events.push(rest)

    let counter = 1
    e.occurrence_start_date_times.forEach((start) => {
        let startDate = new Date(start)
        if (startDate.getTime() !== e.start.getTime()) {
            let childId = `${e.event_id.toString()}.${counter.toString()}`
            e.child_ids.push(childId)
            events.push({
                ...e,
                child_ids: [],
                event_id: childId,  // Creating unique id
                start: startDate,
                end: new Date(startDate.getTime() + e.duration * 60000),
                parent_id: e.event_id
            })
            counter++
        }
    })
    return events
}


export const getUserEvents = createAsyncThunk(
    "schedule/getUserEvents",
    async (arg, thunkAPI) => {
        try {
            const response = await axiosInstance.get(
                `/schedule/events?user_id=${arg.id}&start=${formatDate(arg.start)}&end=${formatDate(arg.end)}`
            )
            let totalEvents = []
            response.data.forEach((e) => {
                totalEvents = totalEvents.concat(createFormattedEvents(e, arg))
            })
            console.log("Events: ", totalEvents)
            return totalEvents
        } catch (err) {
            return returnError(err, thunkAPI)
        }
    }
)

export const createUserEvent = createAsyncThunk(
    "schedule/createUserEvent",
    async (arg, thunkAPI) => {
        try {
            const response =  await axiosInstance.post(
                `/schedule/event?user_id=${arg.event.user}&start=${formatDate(arg.range.start)}&end=${formatDate(arg.range.end)}`,
                arg.event
            )
            let createdEvents = createFormattedEvents(response.data, arg)
            console.log("Created Events", createdEvents)
            return createdEvents
        } catch (err) {
            return returnError(err, thunkAPI)
        }
    }
)

export const patchUserEvent = createAsyncThunk(
    "schedule/patchUserEvent",
    async (arg, thunkAPI) => {
        try {
            await axiosInstance.patch(`/schedule/event/${arg.eventId}`, arg.eventPatch)
            return arg
        } catch (err) {
            return returnError(err, thunkAPI)
        }
    }
)

export const deleteUserEvent = createAsyncThunk(
    "schedule/deleteUserEvent",
    async (arg, thunkAPI) => {
        try {
            await axiosInstance.delete("/schedule/event/" + arg.eventId)
            return { remoteId: arg.eventId }
        } catch (err) {
            return returnError(err, thunkAPI)
        }
    }
)

export const getEventTypes = createAsyncThunk(
    "schedule/getEventTypes",
    async (arg, thunkAPI) => {
        try {
            const response = await axiosInstance.get("/schedule/event-types/")
            return response.data
        } catch (err) {
            return returnError(err, thunkAPI)
        }
    }
)

export const scheduleSlice = createSlice({
    name: "schedule",
    initialState,
    reducers: {},
})

export default scheduleSlice.reducer
