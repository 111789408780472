import axiosInstance from "../../axios";
import {
  GET_STUDENTS_FAIL,
  GET_STUDENTS_REQUEST,
  GET_STUDENTS_SUCCESS,
} from "../constants";
export const getStudents = () => async (dispatch) => {
  try {
    dispatch({ type: GET_STUDENTS_REQUEST, clear: true });

    const { data } = await axiosInstance.get("/user/teacher/get-students/");

    dispatch({ type: GET_STUDENTS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_STUDENTS_FAIL,
      error:
          error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
    });
  }
};

export const getStudentsAsSales = () => async (dispatch) => {
  try{
    const { data } = await axiosInstance.get("/user/student/list_students/");
    return data;
  }catch (error){
    dispatch({
      type: GET_STUDENTS_FAIL,
      error:
          error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
    });
  }
}

export const getStudentDropdown = (id) => async (dispatch) => {
  try{
    const { data } = await axiosInstance.get("/user/student/" + id + "/dropdown_detail/");
    return data;
  }catch (error){
    dispatch({
      type: GET_STUDENTS_FAIL,
      error:
          error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
    });
  }
}
