import React, {useEffect, useRef, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Input,
    Col,
    Row,
    Button,
    Spinner,
    CustomInput,
} from "reactstrap";
import Loader from "../Loader";
import TableContainerSubrow from "./TableContainerSubrow";
import TableContainer from "./TableContainer";
import "./style.css";

//Returns an overview with backend pagination
//the reducer must have the fields: page, count, nextPage, previousPage defined, the get functions must be dispatches
// that gets correct page, baseEndpoint must be same as the endpoint used by the redux functionality
const PaginatedTable = ( {columns, renderRowSubComponent, reducer, api, baseEndpoint, removeExpandedId, addExpandedId, filter} ) => {

    const dispatch = useDispatch();

    const didMount = useRef(false);

    const data = useSelector((state) => state[reducer]);

    const [searchString, setSearchString] = useState("");

    const [pageSize, setPageSize] = useState(10);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        dispatch(api({
            search: searchString,
            link: null,
            pageSize: 10,
            page: 0,
            base_endpoint: baseEndpoint,
            filter: filter
        }))
    }, [filter])


    const canNextPage = () => {
        if(data.nextPage){
            return true
        }
        return false;
    }
    const canPreviousPage = () => {
        if(data.previousPage){
            return true;
        }
        return false;
    }
    const nextPage = () => {
        // dispatch go to next page
        if(data.nextPage) {
            setLoading(true);
            dispatch(api({
                search: "",
                link: data.nextPage.replace(baseEndpoint, ""),
                pageSize: null,
                page: data.pageIndex + 1,
                base_endpoint: baseEndpoint,
                filter: filter
            }));
        };
    }
    const previousPage = () => {
        // dispatch get previous page
        if (data.previousPage) {
            setLoading(true);
            dispatch(api({
                search: "",
                link: data.previousPage.replace(baseEndpoint, ""),
                pageSize: null,
                page: data.pageIndex - 1,
                base_endpoint: baseEndpoint,
                filter: filter
            }));
        }
    }
    const gotoPage = (page) => {
        // dispatch get specific page
        setLoading(true);
        dispatch(api({
            search: searchString,
            link: null,
            pageSize: pageSize,
            page: page,
            base_endpoint: baseEndpoint,
            filter: filter
        }));
    }

    const onChangeInSelect = event => {
        setPageSize(Number(event.target.value))
        setLoading(true);
        dispatch(api({
            search: searchString,
            link: null,
            pageSize: event.target.value,
            page: 0,
            base_endpoint: baseEndpoint,
            filter: filter
        }));
    }
    const onChangeInInput = event => {
        let page = event.target.value ? Number(event.target.value) - 1 : 0;
        if(page < 0) page = 0;
        if(page > (pageCount() - 1)) page = pageCount() - 1

        gotoPage(page)
    }

    const pageIndex = () => {
        return data.pageIndex;
    };

    const pageCount = () => {
        if (data['count'] % pageSize === 0) return Math.floor(data['count'] / pageSize);
        const res = Math.floor(data['count'] / pageSize) + 1;
        if (isNaN(res)) return 0;
        else return res;
    };

    // as soon as dispatch is defined, get the first page
    useEffect(() => {
        dispatch(api({
            search: searchString,
            link: null,
            pageSize: 10,
            page: 0,
            base_endpoint: baseEndpoint,
            filter: filter
        }))
    }, [dispatch]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if(didMount.current){
                dispatch(api({
                    search: searchString,
                    link: null,
                    pageSize: 10,
                    page: 0,
                    base_endpoint: baseEndpoint
                }))
            }else{
                didMount.current = true;
            }
        }, 500)

        return () => clearTimeout(delayDebounceFn)
    }, [searchString])

    useEffect(() => {
        setLoading(false);
    }, [data])

    return (

        data && typeof data !== 'undefined' ?
            <>
                <Input
                    name="fName"
                    placeholder="Søk"
                    rows="1"
                    className="inputField"
                    onChange={(e) => {
                        setSearchString(e.target.value);
                    }}
                    style={{maxWidth: "450px", width: "100%", marginBottom: "25px"}}
                />
                {
                    !data.page ?
                        <Loader></Loader>
                        :
                        (
                            renderRowSubComponent ?
                                addExpandedId === undefined || removeExpandedId === undefined ?
                                    <TableContainerSubrow
                                        style={{maxWidth: "100%"}}
                                        columns={columns}
                                        data={data.page}
                                        expandedIds={data.expandedPageRowIds}
                                        renderRowSubComponent={renderRowSubComponent}
                                        pageSizeInput={pageSize} />
                                    :
                                    <TableContainerSubrow
                                        style={{maxWidth: "100%"}}
                                        columns={columns}
                                        data={data.page}
                                        expandedIds={data.expandedPageRowIds}
                                        renderRowSubComponent={renderRowSubComponent}
                                        addExpanded={(id) => dispatch(addExpandedId({id: id}))}
                                        removeExpanded={(id) => dispatch(removeExpandedId({id: id}))}
                                        pageSizeInput={pageSize}
                                    />
                                :
                            <TableContainer
                                columns={columns}
                                data={data.page}
                                pageSizeInput={pageSize}
                            />
                        )
                }
                {
                    loading ?
                        <Row className="justify-content-center align-self-center"><Spinner color="primary" style={{align: "center"}} /></Row> : <div></div>
                }

                <Row style={{ maxWidth: 1000, margin: "0 auto", marginTop: "25px", textAlign: "center" }}>
                    <Col md={3} style={{paddingTop: "25px"}}>
                        <Button
                            onClick={() => gotoPage(0)}
                            disabled={!canPreviousPage()}
                        >
                            {"<<"}
                        </Button>
                        <Button
                            onClick={previousPage}
                            disabled={!canPreviousPage()}
                        >
                            {"<"}
                        </Button>
                    </Col>
                    <Col md={2} style={{paddingTop: "15px"}}>
                        Page{" "}
                        <strong>
                            {pageIndex() + 1} of {pageCount()}
                        </strong>
                    </Col>
                    <Col md={2} style={{paddingTop: "15px"}}>
                        <Input
                            type="number"
                            min={1}
                            style={{ width: 70 }}
                            max={pageCount()}
                            defaultValue={pageIndex() + 1}
                            onChange={onChangeInInput}
                        />
                    </Col>
                    <Col md={2} style={{paddingTop: "15px"}}>
                        <CustomInput id={0} type="select" value={pageSize} onChange={onChangeInSelect}>
                            >
                            {[10, 20, 30].map(pageSize => (
                                <option key={pageSize} value={pageSize}>
                                    Show {pageSize}
                                </option>
                            ))}
                        </CustomInput>
                    </Col>
                    <Col md={3} style={{paddingTop: "25px"}}>
                        <Button
                            onClick={nextPage}
                            disabled={!canNextPage()}
                        >
                            {">"}
                        </Button>
                        <Button
                            onClick={() => gotoPage(pageCount() - 1)}
                            disabled={!canNextPage()}
                        >
                            {">>"}
                        </Button>
                    </Col>
                </Row>
            </>
            :
            <></>


    );


};

export default PaginatedTable