import React from "react";
import { Doughnut } from "react-chartjs-2";


const DoughnutChart = (labels, categories) => {
  const data = {
    labels: labels,
    datasets: categories,
  };

  const options = {
    maintainAspectRatio: false,
    cutoutPercentage: 85,
    legend: {
      display: false,
    },
  };

  return (
    <div className="chart chart-sm">
      <Doughnut data={data} options={options} />
    </div>
  );
};

export default DoughnutChart;
