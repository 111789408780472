import axiosInstance from "../../axios";
import {
  ADD_STUDENT_FAIL,
  ADD_STUDENT_REQUEST,
  ADD_STUDENT_SUCCESS,
} from "../constants";
export const postStudent = (props) => async (dispatch) => {
  try {
    dispatch({ type: ADD_STUDENT_REQUEST, clear: true });
    const student = props.user.fields;
    const { data } = await axiosInstance.post("/user/student/", {
      email: student.email,
      password: "Passpass123", // TODO: Make it a bit more "random" and send to student on email
      first_name: student.first_name,
      last_name: student.last_name,
      phone_number: student.phone_number,
      birth_date:
        student.birthYear + "-" + student.birthMonth + "-" + student.birthDay,
      address: student.address,
      post_code: student.post_code,
      post_code_area: student.post_code_area,
      heard_about_us: student.heard_about_us,
      city: student.city,
      subjects: props.subjects,
      school: student.school,
      other_comments: student.other,
      is_active: true,
    });
    dispatch({ type: ADD_STUDENT_SUCCESS, payload: data });
  } catch (error) {
    if (typeof error.response.data === "string") {
      dispatch({
        type: ADD_STUDENT_FAIL,
        error:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    } else if ("email" in error.response.data) {
      dispatch({
        type: ADD_STUDENT_FAIL,
        emailError: error.response.data.email[0],
      });
    } else {
      dispatch({
        type: ADD_STUDENT_FAIL,
        error:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  }
};
