import React, {useEffect, useMemo, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, CardHeader, CardTitle, Container, FormGroup, Label, CardText, Input, Col, Row, Modal, ModalBody, Button } from "reactstrap";

import {getSubjects} from "../../../../../redux/actions/teacherMatchingActions";
import TableContainer from "../../components/TableContainer";
import Select from 'react-select';
import {
    getActivityCategories,
    getTeacherOverview,
    patchTeacher,
    submitNewTeacher
} from "../../../../../redux/actions/teacherOverviewActions";
import arrowDown from "../../../../../assets/img/RH/Pil_ned.png";
import {getCities} from "../../../../../redux/actions/getCities";
import Loader from "../../../../../components/Loader";
import {getAllStudents} from "../../../../../redux/actions/getAllStudents";
import MessageModal from "../../../../../components/MessageModal";
import {GET_ALL_STUDENTS_PAGE_SUCCESS, GET_ALL_TEACHERS_PAGE_SUCCESS} from "../../../../../redux/constants";
import PaginatedTable from "../../../../../components/PaginatedTable/PaginatedTable";
import {fetchTeacherPage, teacherPageBaseUrl} from "../../../../../features/teacher/teacherSlice";

// Page that displays all students of a teacher
const TeacherOverview = () => {

    const dispatch = useDispatch();
    const teachers = useSelector((state) => state.teacherOverview.teachers);
    const [loading, setLoading] = useState(true);
    //const loader = useSelector(state => state.teacherMatching.loading);

    const [subjectOptions, setSubjectOptions] = useState([]);
    const [cityOptions, setCityOptions] = useState([]);
    const [ActivityCategoryOptions, setActivityCategoryOptions] = useState(null);

    const [studentOptions, setStudentOptions] = useState([]);

    const [editTeacher, setEditTeacher] = useState(false);

    const [editTeacherError] = useState([]);

    const [showAll, setShowAll] = useState(false)
    const [filter, setFilter] = useState("is_active=1")

    useEffect(() => {
        if(showAll){
            setFilter("")
        }else{
            setFilter("is_active=1")
        }
    }, [showAll]);

    const [addNewTeacher, setAddNewTeacher] = useState(false);
    const [newTeacher] = useState({
        first_name: "",
        last_name: "",
        email: "",
        subjects: [],
        city: null,
    });

    const initialTeacherPatch = {
        id: null,
        patch: {
        },
        subjects: [],
        students: []
    }

    const [teacherPatch, setTeacherPatch] = useState(initialTeacherPatch);

    const initialNewTeacherError = {
        email: [],
    }

    const [newTeacherError, setNewTeacherError] = useState(initialNewTeacherError);

    const [teacherView, setTeacherView] = useState(teachers);

    // initialize teacherlist and dropdown options
    useEffect(() => {
        dispatch(getSubjects()).then(data => {
            setSubjectOptions(data.map(subject=>{
                return {value: subject.subject_name, label: subject.subject_name, id: subject.id};
            }))
        });
        dispatch(getCities()).then(data => {
            setCityOptions(data.map(city => {
                return {value: city.city_name, label: city.city_name, id: city.id};
            }));
        });
        dispatch(getActivityCategories()).then(data => {
            setActivityCategoryOptions(data.map(category => {
                return {value: category.name, label: category.name, id: category.id};
            }));

            setLoading(false);
        });
        dispatch(getAllStudents()).then((data) => {
            setStudentOptions(
                data.map((student) => {
                  return {value: student.id, label: student.first_name + " " + student.last_name};
                })
            );
        });
    }, [dispatch]);

    // the dropdown element for the table
    const renderRowSubComponent = (row) => {
        const {
            id,
            email,
            first_name,
            last_name,
            city,
            city_name,
            age,
            gender,
            slack,
            our_comments,
            other_comments,
            activity_categories,
            students,
            subjects,
            field_of_study_name,
            police_report_file,
            diploma_file,
            verified_police_attestation,
            is_active,
            visma_employment_id,
            tablet_status,
            tablet_status_name,
        } = row.original

        // current data and variables for submitting new data
        const currentSubjects = subjects.map(subject=>{
            const subject_name = subjectOptions.find(x => x.id === subject).value;
            return {value: subject_name, label: subject_name, id: subject};
        });
        const currentActivityCategories = activity_categories.map(category => {
            const category_name = ActivityCategoryOptions.find(x => x.id === category).value;
            return {value: category_name, label: category_name, id: category};
        })
        let currentCity = undefined;
        if(city){
            currentCity = {value: cityOptions.find(x => x.id === city).value, label: cityOptions.find(x => x.id === city).value, id: city}
        }
        let patch = {};
        let newSubjects = currentSubjects.map(s => s.id);
        let verifyPoliceAttestation = verified_police_attestation;
        let setActive = is_active;
        let newStudents = students.map(s => s.id);
        let newActivityCategories = currentActivityCategories.map(c => c.id);

        return (
            <Card style={{ margin: "0 auto" }}>
                {editTeacher ?
                    <CardBody>
                        <CardTitle>
                            <strong> Rediger Lærer </strong>
                        </CardTitle>
                        <Row>
                            <Col xs="12" sm="5">
                                <FormGroup>
                                    <Label>Fornavn</Label>
                                    <Input
                                        name="fName"
                                        placeholder="Ola"
                                        rows="1"
                                        defaultValue={first_name}
                                        className="inputField"
                                        onChange={(e) => patch.first_name = e.target.value}
                                        invalid={false}
                                        required
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs="12" sm="7">
                                <FormGroup>
                                    <Label>Etternavn</Label>
                                    <Input
                                        name="lName"
                                        placeholder="Nordmann"
                                        defaultValue={last_name}
                                        rows="1"
                                        className="inputField"
                                        onChange={(e) => patch.last_name = e.target.value}
                                        invalid={false}
                                        required
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Label>Legg til/fjern elever:</Label>
                                <Select
                                    defaultValue={
                                        students.map((student) => {
                                            return {
                                                value: student.id,
                                                label: student.first_name + " " + student.last_name
                                            }
                                        })
                                    }
                                    isMulti
                                    name="colors"
                                    options={
                                        studentOptions.filter(
                                            student => !students.map(student => student.id).includes(student.value)
                                        )
                                    }
                                    className="basic-multi-select"
                                    menuPortalTarget={document.body}
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                    classNamePrefix="select"
                                    style={{ width: "20px", marginBottom: "20px" }}
                                    onChange={(e)=> {
                                        newStudents = e.map(student => {
                                            return student.value;
                                        });
                                    }}
                                />

                            </Col>
                        </Row>
                        <Row style={{marginBottom: "20px"}}>
                            <Col>
                                <p>Legg til fag:</p>
                                <Select
                                    defaultValue={currentSubjects}
                                    isMulti
                                    name="colors"
                                    options={subjectOptions}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    style={{width: "50px", marginBottom: "20px"}}
                                    onChange={(e)=> {
                                        if(e){
                                            newSubjects = e.map(subject => {
                                                return subject.id;
                                            });
                                        }else {
                                            newSubjects = [];
                                        }
                                    }}
                                />
                            </Col>
                            <Col>
                                <p>Velg by:</p>
                                <Select
                                    defaultValue={currentCity !== null ? currentCity : ""}
                                    name="colors"
                                    options={cityOptions}
                                    className="basic-select"
                                    classNamePrefix="select"
                                    style={{width: "50px", marginBottom: "40px"}}
                                    onChange={(e)=> {
                                        if(e){
                                            patch.city = e.id;
                                        }else {
                                            patch.city = null;
                                        }
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row style={{marginBottom: "20px"}}>
                            <Col>
                                <p>Legg til undervisningstyper:</p>
                                <Select
                                    defaultValue={currentActivityCategories}
                                    isMulti
                                    name="colors"
                                    options={ActivityCategoryOptions}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    style={{width: "50px", marginBottom: "20px", marginTop: "20px"}}
                                    onChange={(e)=> {
                                        if(e){
                                            newActivityCategories = e.map(category => {
                                                return category.id;
                                            });
                                        }else {
                                            newActivityCategories = [];
                                        }
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row style={{marginBottom: "20px"}}>
                            <Col>
                                <FormGroup>
                                    <Label>Visma id</Label>
                                    <Input
                                        name="visma_e_id"
                                        placeholder=""
                                        rows="1"
                                        defaultValue={visma_employment_id}
                                        className="inputField"
                                        onChange={(e) => patch.visma_employment_id = e.target.value}
                                        invalid={false}
                                        required
                                    />
                                </FormGroup>
                                <FormGroup style={{marginTop: "20px"}}>
                                    <Label>Vår kommentar</Label>
                                    <Input
                                        name="our_comment"
                                        type="textarea"
                                        placeholder="Kommentar"
                                        rows="1"
                                        defaultValue={our_comments}
                                        className="inputField"
                                        onChange={(e) => patch.our_comments = e.target.value}
                                        invalid={false}
                                        required
                                    />
                                </FormGroup>
                            </Col>
                            <Col>
                                <p>Nettbrett status:</p>
                                <Select
                                    defaultValue={tablet_status !== null ? {value: tablet_status, label: tablet_status_name} : ""}
                                    name="colors"
                                    options={[
                                        {value: "OWNS_TABLET", label: "Har eget"},
                                        {value: "NO_TABLET", label: "Har ikke"},
                                        {value: "BORROWED_TABLET", label: "Låner et av våres"}
                                    ]}
                                    className="basic-select"
                                    classNamePrefix="select"
                                    style={{width: "50px", marginBottom: "40px"}}
                                    onChange={(e)=> {
                                        if(e){
                                            patch.tablet_status = e.value;
                                        }else {
                                            patch.tablet_status = null;
                                        }
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>

                        </Row>
                        <Row style={{marginTop: "20px", marginLeft: "15px"}}>
                            <label>
                                {verified_police_attestation ?
                                    <input style={{marginRight: "10px"}} id="verifyPoliceAttest" type="checkbox" onChange={(e) => {
                                        verifyPoliceAttestation = !verifyPoliceAttestation;
                                        patch.verified_police_attestation = verifyPoliceAttestation;
                                    }} defaultChecked={true} />
                                :
                                    <input style={{marginRight: "10px"}} id="verifyPoliceAttest" type="checkbox" onChange={(e) => {
                                        verifyPoliceAttestation = !verifyPoliceAttestation;
                                        patch.verified_police_attestation = verifyPoliceAttestation;
                                    }}/>
                                }

                                Godkjenn politiattest.

                            </label>
                        </Row>
                        <Row style={{marginTop: "20px", marginLeft: "15px"}}>
                            <label>
                                {is_active ?
                                    <input style={{marginRight: "10px"}} id="setActive" type="checkbox" onChange={(e) => {
                                        setActive = !setActive;
                                        patch.is_active = setActive;
                                    }} defaultChecked={true} />
                                    :
                                    <input style={{marginRight: "10px"}} id="setActive" type="checkbox" onChange={(e) => {
                                        setActive = !setActive;
                                        patch.is_active = setActive;
                                    }}/>
                                }

                                Angi om læreren er aktiv.

                            </label>
                        </Row>
                        <Row>
                            <Col/>
                            <Col style={{float: "right", marginTop: "10px"}}>
                                <Row style={{float: "right", marginBottom: "10px"}}>
                                    <Button className="button darkButton saveButton" onClick={() => {
                                        teacherPatch.id = id;
                                        teacherPatch.patch = patch;
                                        teacherPatch.subjects = newSubjects;
                                        teacherPatch.activity_categories = newActivityCategories;
                                        teacherPatch.students = newStudents;
                                        updateTeacher();
                                    }}>Send inn</Button>
                                </Row>

                            </Col>
                        </Row>
                        <Row style={{float: "right"}}><Button className="button redButton" onClick={() => setEditTeacher(false)}>Avbryt</Button></Row>
                    </CardBody>
                :
                    <CardBody>
                        <CardTitle style={{textAlign: "left"}}>
                        <strong> {first_name} {last_name} </strong>
                        </CardTitle>
                        <CardText style={{textAlign: "left"}}>
                            { age ? age + " år" : "Alder ikke oppgitt"} - {gender ? gender : "Kjønn ikke oppgitt"} - {currentCity ? currentCity.value : "By ikke oppgitt"}
                        </CardText>
                        <Row>
                            <Col style={{textAlign: "left"}}>
                                <div>Studieretning - {field_of_study_name}</div>
                                <div>Epost - {email}</div>
                                <div>Slack - {slack ? slack : "Ikke oppgitt"}</div>
                                <div>Visma id - {visma_employment_id ? visma_employment_id : "Ikke oppgitt"}</div>
                                <div>Vitnemål - {police_report_file ? <a target="blank" href={police_report_file}>
                                    Åpne fil
                                </a> : "Ikke oppgitt"}</div>
                                <div>Politiattest - {diploma_file ? <a target="blank" href={diploma_file}>
                                    Åpne fil
                                </a> : "Ikke oppgitt"}</div>
                                <div>Nettbrett - {tablet_status_name ? tablet_status_name : "Ikke oppgitt"}</div>
                                <div>Aktiv - {is_active ? "Ja" : "Nei"}</div>
                                <div style={{marginTop: "10px"}}>
                                    <h5>Vår kommentar:</h5>
                                    <p>
                                        {our_comments ? our_comments : "Ingen kommentar."}
                                    </p>
                                </div>
                                <div style={{marginTop: "10px"}}>
                                    <h5>Andre kommentarer:</h5>
                                    <p>
                                        {other_comments ? other_comments : "Ingen andre kommentarer."}
                                    </p>
                                </div>

                            </Col>
                            <Col>
                                <Row style={{textAlign: "left", marginBottom: "10px"}}>
                                    <div>
                                        <h5>Studenter:</h5>
                                        {students.map((student, key) => {
                                            return <div key={key}>{student.first_name} {student.last_name}</div>
                                        })}
                                    </div>

                                </Row>
                                <Row style={{textAlign: "left", marginBottom: "10px"}}>
                                    <div>
                                        <h5>Fag:</h5>
                                        {currentSubjects.map((subject, key) => {
                                            return <div key={key}>{subject.value}</div>
                                        })}
                                    </div>
                                </Row>
                                <Row style={{textAlign: "left"}}>
                                    <div>
                                        <h5>Undervisningstyper:</h5>
                                        {currentActivityCategories.map((category, key) => {
                                            return <div key={key}>{category.value}</div>
                                        })}
                                    </div>
                                </Row>
                            </Col>
                        </Row>
                        <Row style={{float: "right"}}><Button className="button darkButton" onClick={() => setEditTeacher(true)}>Rediger</Button></Row>
                    </CardBody>
                }
            </Card>
        )
    }

    const columns = useMemo(
        () => [
            {
                Header: () => null,
                id: 'expander', // 'id' is required
                Cell: ({ row }) => (
                    <span>
            {row.isExpanded ? '△' : '▽'}
          </span>
                )
            },
            {
                Header: "Fornavn",
                accessor: "first_name",
            },
            {
                Header: "Etternavn",
                accessor: "last_name",
            },
            {
                Header: "Epost",
                accessor: "email",
            },
            {
                Header: "Telefon",
                accessor: "phone_number"
            },
            {
                Header: "By",
                accessor: d => (<p>{cityOptions.find(x => x.id === d.city) ? cityOptions.find(x => x.id === d.city).value : "Ikke oppgitt"}</p>)
            },
            {
                id: "verified_police_attestation",
                Header: "Godkjent Politiattest",
                accessor: d => (<p>{d.verified_police_attestation ? "Ja" : "Nei"}</p>)
            },
        ],
        [cityOptions]
    )

    const updateTeacher = () => {
        dispatch(patchTeacher(teacherPatch)).then(response => {
            if (response) {
                for (var key in response.data) {
                    if (response.data.hasOwnProperty(key)) {
                        editTeacherError.push(key + ": " + response.data[key])
                    }
                }
                setErrorMessageIsOpen(true);
            }else {
                dispatch(getTeacherOverview()).then(data=>{
                    setTeacherView(data);
                    setMessageInfo(
                        {
                            header: "Vellykket",
                            message: "Læreren ble oppdatert"
                        }
                    );
                    toggleMessageIsOpen();
                });
                setEditTeacher(false);
            }


        })
        setTeacherPatch(initialTeacherPatch);
    }

    const submitTeacher = () => {
        setNewTeacherError(initialNewTeacherError);
        //console.log("submitting teacher: ", newTeacher);
        dispatch(submitNewTeacher(newTeacher)).then(response => {
            // response is undefined unless there is an error therefore we need this check
            if(response){
                if(response.status === 400){
                    //console.log("staus yes big error", response.data);
                    setNewTeacherError(response.data);
                }
            }else{
                dispatch(getTeacherOverview()).then(data=>{
                    setTeacherView(data);
                });
                setMessageIsOpen(true);
            }
        });
    }

    // modal functionality
    const [messageIsOpen, setMessageIsOpen] = useState(false);
    const [errorMessageIsOpen, setErrorMessageIsOpen] = useState(false);

    const [messageInfo, setMessageInfo] = useState({});
    const [successMessageIsOpen, setSuccessMessageIsOpen] = useState(false);
    const toggleMessageIsOpen = () => setSuccessMessageIsOpen((prevState) => !prevState);


    // render component
    return (
        <div>
            {MessageModal({ ...messageInfo, toggle: toggleMessageIsOpen, isOpen: successMessageIsOpen })}

                    <div className="title" style={{marginBottom: "40px"}}>Læreroversikt</div>
                    <Card style={{width: "50%", marginBottom: "40px", borderRadius: "25px"}}>
                        <CardHeader onClick={() => setAddNewTeacher(!addNewTeacher)} style={{borderRadius: "25px"}}><Row>
                            <Col style={{fontSize: "30px"}}>
                                Opprett ny lærer
                            </Col>
                            <Col style={{textAlign: "right"}}>
                                { addNewTeacher ? (
                                    <img src={arrowDown} className="rotate180" alt="arrowDown"
                                         style={{ textAlign: "right", width: 20, cursor: "pointer" }}
                                         onClick={() => setAddNewTeacher(false)}
                                    />
                                ) : (
                                    <img src={arrowDown} alt="arrowDown"
                                         style={{ width: 20, cursor: "pointer" }}
                                         onClick={() => setAddNewTeacher(true)}
                                    />
                                )}

                            </Col>

                        </Row></CardHeader>
                        {addNewTeacher ?
                            <CardBody>
                                <Row>
                                    <Col xs="12" sm="5">
                                        <FormGroup>
                                            <Label>Fornavn</Label>
                                            <Input
                                                name="fName"
                                                placeholder="Ola"
                                                rows="1"
                                                defaultValue={newTeacher.first_name}
                                                className="inputField"
                                                onChange={(e) => newTeacher.first_name = e.target.value}
                                                invalid={false}
                                                required
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col xs="12" sm="7">
                                        <FormGroup>
                                            <Label>Etternavn</Label>
                                            <Input
                                                name="lName"
                                                placeholder="Nordmann"
                                                defaultValue={newTeacher.last_name}
                                                rows="1"
                                                className="inputField"
                                                onChange={(e) => newTeacher.last_name = e.target.value}
                                                invalid={false}
                                                required
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label>Epost</Label>
                                            <Input
                                                name="email"
                                                placeholder="teacher@teacher.teacher"
                                                defaultValue={newTeacher.email}
                                                rows="1"
                                                className="inputField"
                                                onChange={(e) => newTeacher.email = e.target.value}
                                                invalid={false}
                                                required
                                            />
                                            {newTeacherError.email.map((el, key) => {
                                                return <p style={{color: "red"}} key={key}>{el}</p>;
                                            })}
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <p>Legg til fag:</p>
                                        <Select
                                            defaultValue={[]}
                                            isMulti
                                            name="colors"
                                            options={subjectOptions}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            style={{width: "50px", marginBottom: "20px"}}
                                            menuPortalTarget={document.body}
                                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                            onChange={(e)=> {
                                                if(e){
                                                    //newTeacher.subjects = e;
                                                    newTeacher.subjects = e.map(subject => {
                                                        return subject.id;
                                                    });
                                                    //console.log(newTeacher);
                                                }else {
                                                    newTeacher.subjects = [];
                                                }
                                            }}
                                        />
                                    </Col>
                                    <Col>
                                        <p>Velg by:</p>
                                        <Select
                                            defaultValue={""}
                                            name="colors"
                                            options={cityOptions}
                                            className="basic-select"
                                            classNamePrefix="select"
                                            style={{width: "50px", marginBottom: "20px"}}
                                            onChange={(e)=> {
                                                //console.log(e);
                                                if(e){
                                                    newTeacher.city = e.id;
                                                }else {
                                                    newTeacher.city = null;
                                                }
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col/>
                                    <Col style={{float: "right", marginTop: "10px"}}>
                                        <Button style={{float: "right"}} className="button darkButton saveButton" onClick={submitTeacher}>Send inn</Button>
                                    </Col>
                                </Row>
                            </CardBody>
                            :
                            <div/>
                        }
                        <Modal isOpen={messageIsOpen} onClick={() => setMessageIsOpen(false)}>
                            <div
                                id="modalHeader"
                                className="fullFlexCentre"
                            >
                                <div className="title whiteText">Ny lærer har blitt opprettet</div>
                            </div>
                            <ModalBody>
                                <div>Du kan nå finne den nye læreren i listen under.</div>
                                <Button className="button darkButton" style={{float: "center", margin: "auto", marginTop: "20px"}} onClick={() => {
                                    setMessageIsOpen(false);
                                }}>Ok</Button>
                            </ModalBody>
                        </Modal>

                        <Modal isOpen={errorMessageIsOpen} onClick={() => setErrorMessageIsOpen(false)}>
                            <div
                                id="modalHeader"
                                className="fullFlexCentre"
                            >
                                <div className="title whiteText">Feil</div>
                            </div>
                            <ModalBody>
                                <div>Det skjedde en feil under oppdatering av lærerbrukeren: </div>
                                {editTeacherError.map((e, key) => {
                                    return <div key={key}>{e}</div>
                                })}
                                <Button className="button darkButton" style={{float: "center", margin: "auto", marginTop: "20px"}} onClick={() => {
                                    setErrorMessageIsOpen(false);
                                }}>Ok</Button>
                            </ModalBody>
                        </Modal>
                    </Card>
                    {!loading ?
                        <>
                            <div>
                                <label>
                                    <input
                                        style={{ marginRight: "10px" }}
                                        id="filterOnIsActive"
                                        type="checkbox"
                                        onChange={(e) => {
                                            setShowAll(!showAll)
                                        }}
                                    />
                                    Vis inaktive lærere
                                </label>
                            </div>
                            <PaginatedTable
                                className="tableContainer"
                                columns={columns}
                                reducer='teachers'
                                baseEndpoint={teacherPageBaseUrl}
                                api={fetchTeacherPage}
                                renderRowSubComponent={renderRowSubComponent}
                                filter={filter}
                            />
                        </>
                        : <Loader/>}



        </div>
    );
};

export default TeacherOverview;
