import { baseURL } from "../../axios";
import axios from "axios";
import {
  LOG_IN_REQUEST,
  LOG_IN_SUCCESS,
  LOG_IN_FAIL,
  LOG_OUT_SUCCESS,
  LOG_OUT_FAIL,
} from "../constants";
import TokenService from "../../services/TokenService";

export const logIn = (props) => async (dispatch) => {
  try {
    dispatch({ type: LOG_IN_REQUEST, clear: true });

    const { data } = await axios.post(baseURL + "user/login/", {
      email: props.email,
      password: props.password,
    });

    TokenService.setToken(data)

    dispatch({
      type: LOG_IN_SUCCESS,
      payload: {
        user: data.user,
        type: data.type,
      },
    });

    return true;
  } catch (error) {

    dispatch({
      type: LOG_IN_FAIL
    });

    return error;
  }
};

export const logOut = () => async (dispatch) => {
  try {
    // Deauthenticates refresh token so the user cant get new authenticated
    // access tokens

    let refresh_token = localStorage.getItem("refresh_token");

    TokenService.clearToken();

    const {data} = await axios.post(baseURL + "user/logout/blacklist/", {
      refresh_token: refresh_token
    });

    dispatch({
      type: LOG_OUT_SUCCESS,
      payload: "",
    });
  } catch (error) {
    dispatch({
      type: LOG_OUT_FAIL,
      error:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
