import axiosInstance from "../../axios";
import { GET_STUDENT_FAIL, GET_STUDENT_REQUEST, GET_STUDENT_SUCCESS } from "../constants";

export const getStudent = (id) => async (dispatch) => {
  try {
    dispatch({ type: GET_STUDENT_REQUEST, clear: true });
    const { data } = await axiosInstance.get("/user/student/" + id + "/");
    dispatch({ type: GET_STUDENT_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: GET_STUDENT_FAIL, error: error.response && error.response.data.message ? error.response.data.message : error.message });
  }
};
