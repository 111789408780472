import {
    GET_SUBJECTS_SUCCESS,
    GET_TEACHER_OVERVIEW_SUCCESS,
    GET_ACTIVITY_CATEGORIES_SUCCESS,
    GET_ALL_TEACHERS_PAGE_SUCCESS
} from "../constants";
import {act} from "react-dom/test-utils";
import {pageState} from "./PageState";

const initialState = {
    loading: false,
    teachers: [],
    subjects: [],
    activity_categories: [],
    count: null,
    page: [],
    pageIndex: 0,
    nextPage: null,
    previousPage: null,
};

export const teacherOverviewReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_TEACHERS_PAGE_SUCCESS:
            return pageState(state, action);

        case GET_TEACHER_OVERVIEW_SUCCESS:
            return { ...state, loading: false, teacherMatching: action.payload, actionSucceeded: true };

        case GET_SUBJECTS_SUCCESS:
            return { ...state, loading: false, subjects: action.payload, actionSucceeded: true };

        case GET_ACTIVITY_CATEGORIES_SUCCESS:
            return { ...state, loading: false, activity_categories: action.payload, actionSucceeded: true };

        default:
            return state;
    }
};
