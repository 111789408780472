import React from "react";
import {Button} from "reactstrap";
import {useHistory} from "react-router-dom";

const Page404 = () => {

    const history = useHistory();

    return (
        <div className="text-center">
            <h1 className="display-1 font-weight-bold">404</h1>
            <p className="h1">Page not found.</p>
            <p className="h2 font-weight-normal mt-3 mb-4">
                The page you are looking for might have been removed.
            </p>
            <Button color="primary" size="lg" onClick={history.goBack}>
                Return to website
            </Button>
        </div>
    );
}



export default Page404;
