import {
  GET_MYPACKAGE_FAIL,
  GET_MYPACKAGE_REQUEST,
  GET_MYPACKAGE_SUCCESS,
  GET_PACKAGE_TYPES_FAIL,
  GET_PACKAGE_TYPES_REQUEST,
  GET_PACKAGE_TYPES_SUCCESS,
  EDIT_PACKAGE_TYPE_FAIL,
  EDIT_PACKAGE_TYPE_REQUEST,
  EDIT_PACKAGE_TYPE_SUCCESS,
  ADD_PACKAGE_STUDENT_FAIL,
  ADD_PACKAGE_STUDENT_REQUEST,
  ADD_PACKAGE_STUDENT_SUCCESS,
  GET_PACKAGE_TYPE_HISTORY_FAIL,
  GET_PACKAGE_TYPE_HISTORY_REQUEST,
  GET_PACKAGE_TYPE_HISTORY_SUCCESS,
  GET_ORDER_CONFIRMATIONS_SUCCESS,
  GET_ORDER_CONFIRMATIONS_REQUEST,
  GET_ORDER_CONFIRMATIONS_FAIL,
  GET_PACKAGE_PAGE_REQUEST,
  GET_PACKAGE_PAGE_SUCCESS,
  GET_PACKAGE_PAGE_FAIL,
  EDIT_PACKAGE_REQUEST,
  EDIT_PACKAGE_SUCCESS,
  EDIT_PACKAGE_FAIL,
  DELETE_PACKAGE_REQUEST,
  DELETE_PACKAGE_SUCCESS,
  DELETE_PACKAGE_FAIL
} from "../constants";
import {act} from "react-dom/test-utils";
import {baseURL} from "../../axios";

const initialState = {
  loading: false,
  allPackages: [],
  packageTypes: [],
  currentPackageTypeHistory: {price:[{start_date:"", price:0}]},
  orderConfirmations: [],
  count: null,
  page: [],
  pageIndex: 0,
  nextPage: null,
  previousPage: null,
  error: false,
  edited: false,
  editReturnMessage: "",
  newOrderconfirmation: false,
};

export const packageReducer = (state = initialState, action) => {

  switch (action.type) {
    case EDIT_PACKAGE_REQUEST:
      return { ...state, edited: true}

    case EDIT_PACKAGE_SUCCESS:
      return { ...state,
        edited: false,
        newOrderconfirmation: action.newOrderconfirmation,
        error: false,
        editReturnMessage: (action.newOrderconfirmation ? "Redigeringen av pakken var vellykket. En ny ordrebekreftelse har blitt laget, du kan se og sende den på ordrebekreftelser siden." : "Redigeringen av pakken var vellykket." ),
        page: state.page.map(pack => {
          if(pack.id === action.payload.id){
            return action.payload
          }
          return pack;
        }) }

    case EDIT_PACKAGE_FAIL:
      return { ...state, edited: false, error: true, editReturnMessage: "Noe gikk galt."}

    case DELETE_PACKAGE_REQUEST:
      return { ...state, edited: true }

    case DELETE_PACKAGE_SUCCESS:
      return { ...state, edited: false, error: false, editReturnMessage: "Pakken ble slettet", page: state.page.filter(( obj ) => {
          return obj.id !== action.payload.id;
        }) }

    case DELETE_PACKAGE_FAIL:
      return { ...state, edited: false, error: true, editReturnMessage: "Noe gikk galt."}

    case GET_PACKAGE_PAGE_REQUEST:
      return { ...state};

    case GET_PACKAGE_PAGE_SUCCESS:
      return { ...state,
        page: action.payload.results,
        pageIndex: action.page,
        count: action.payload['count'],
        nextPage: (action.payload['next'] ? action.payload['next'].replace("http://", "").replace(baseURL.replace("http://", "").replace("https://", ""), "") : null),
        previousPage: (action.payload.previous ? action.payload.previous.replace("http://", "").replace(baseURL.replace("http://", "").replace("https://", ""), "") : null)}

    case GET_MYPACKAGE_REQUEST:
      return { ...state, loading: true, allPackages: [] };

    case GET_MYPACKAGE_SUCCESS:
      return { ...state, loading: false, allPackages: action.payload };

    case GET_MYPACKAGE_FAIL:
      return { ...state, loading: false };

    case GET_PACKAGE_TYPES_REQUEST:
      return { ...state, loading: true };

    case GET_PACKAGE_TYPES_SUCCESS:
      return { ...state, loading: false, packageTypes: action.payload };

    case GET_PACKAGE_TYPES_FAIL:
      return { ...state, loading: false };

    case GET_PACKAGE_TYPE_HISTORY_REQUEST:
      return { ...state, loading: true };

    case GET_PACKAGE_TYPE_HISTORY_SUCCESS:
      return { ...state, loading: false, currentPackageTypeHistory: action.payload };

    case GET_PACKAGE_TYPE_HISTORY_FAIL:
      return { ...state, loading: false, error: action.payload };

    case EDIT_PACKAGE_TYPE_REQUEST:
      return { ...state, loading: true };

    case EDIT_PACKAGE_TYPE_SUCCESS:
      return { ...state, loading: false };

    case EDIT_PACKAGE_TYPE_FAIL:
      return { ...state, loading: false };

    case ADD_PACKAGE_STUDENT_REQUEST:
      return { ...state, loading: true };

    case ADD_PACKAGE_STUDENT_SUCCESS:
      return { ...state, loading: false, actionSucceeded: true };

    case ADD_PACKAGE_STUDENT_FAIL:
      return { ...state, loading: false, actionSucceeded: false };

    case GET_ORDER_CONFIRMATIONS_REQUEST:
      return { ...state, loading: true };

    case GET_ORDER_CONFIRMATIONS_SUCCESS:
      return { ...state, loading: false, orderConfirmations: action.payload, actionSucceeded: true };

    case GET_ORDER_CONFIRMATIONS_FAIL:
      return { ...state, loading: false, actionSucceeded: false };



    default:
      return state;
  }
};
