import axiosInstance from "../../axios";
import {
  EDIT_TEACHER_DETAILS_REQUEST,
  EDIT_TEACHER_DETAILS_SUCCESS,
  EDIT_TEACHER_DETAILS_FAIL,
} from "../constants";

export const editTeacherDetails = (updatedData) => async (dispatch) => {
  try {
    dispatch({ type: EDIT_TEACHER_DETAILS_REQUEST, clear: true });
    const { data } = await axiosInstance.patch(
      "user/teacher/update/profile-side/",
      updatedData
    );
    dispatch({
      type: EDIT_TEACHER_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (error.response.status){
      return error.response;
    }
    dispatch({
      type: EDIT_TEACHER_DETAILS_FAIL,
      error:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
