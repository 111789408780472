import React, {useState, useEffect} from "react";
import { Card, CardHeader, CardBody, Button, ButtonGroup} from "reactstrap";
import InvoiceTable from "../../components/invoiceTable";
import {getInvoices} from "../../../../../redux/actions/getInvoices";

import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../../../components/Loader";
import axiosInstance from "../../../../../axios";

const InvoiceOverview = () => {

    const packages = "packages";
    const custom = "custom";
    const travel = "travel";

    const getDate = () => {
        const date = new Date();
        date.setDate(date.getDate() + 1);
        const day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
        const month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
        const year = date.getFullYear()
        return year + "-" + month + "-" + day
    }

    const [view, setView] = useState(packages);
    const [startDate, setStartDate] = useState("2021-01-01");
    const [chosenInvoices, setChosenInvoices] = useState({packages: {}, custom: {}, travel: {}});
    const [endDate, setEndDate] = useState(getDate());
    const [invoiceType, setInvoiceType] = useState([]);

    const dispatch = useDispatch();

    const allInvoices = useSelector((state) => state.invoices);
    const {loading, invoices} = allInvoices;

    //In order for the table to fill up
    useEffect(() => {
        if (view === packages) {
            setInvoiceType(invoices.package_invoices);
        } else if (view === custom) {
            setInvoiceType(invoices.custom_activity_category_invoices);
        } else {
            setInvoiceType(invoices.travel_invoices);
        }
    });

    useEffect(() => {
            dispatch(getInvoices(startDate, endDate));
    }, [dispatch, startDate, endDate]);

    useEffect(() => {
        switch (view) {
            case packages:
                setInvoiceType(invoices.package_invoices);
                break;
            case custom:
                setInvoiceType(invoices.custom_activity_category_invoices);
                break;
            case travel:
                setInvoiceType(invoices.travel_invoices);
                break;
        }
    }, [view]);

    const toggleInvoice = (id, listOfPackages) => {
        const invoices = chosenInvoices;
        invoices[view].hasOwnProperty(id) ? delete invoices[view][id] : invoices[view][id] = listOfPackages
        setChosenInvoices(invoices)
        console.log(chosenInvoices)
    };

    const getMarkedInvoices = () => {
        const activityPackages = Object.values(chosenInvoices[packages]).flat();
        const customActivities = Object.values(chosenInvoices[custom]).flat();
        const travelActivities = Object.values(chosenInvoices[travel]).flat();
        return [travelActivities, customActivities, activityPackages]
    }

    const markInvoices = async () => {
        try {
            const [travelActivities, customActivities, activityPackages] = getMarkedInvoices();

            await axiosInstance.post("/payment/mark-invoices/", {
                travel_activities: travelActivities,
                custom_activities: customActivities,
                packages: activityPackages
            }).then(() => {
                setChosenInvoices({packages: {}, custom: {}, travel: {}});
                dispatch(getInvoices(startDate, endDate));
                setView(packages);
            })

        } catch (_) {
            window.alert("Noe gikk galt");
        }
    };

    const changeStartDate = (newStartDate) => {
        if (newStartDate.match(/^\d{4}-\d{2}-\d{2}$/)) {
            setStartDate(newStartDate);
        };

    };

    const changeEndDate = (newEndDate) => {
         if (newEndDate.match(/^\d{4}-\d{2}-\d{2}$/)) {
             setEndDate(newEndDate);
         };
    };

    return (
        <div>
            <Card>
                <CardHeader style={{ paddingBottom: "0" }}>
                    <div className="title">Fakturaoversikt</div>
                </CardHeader>
                <CardBody>
                    <>Startdato: </>
                    <input type="date"
                           style={{marginRight: "20px"}}
                           onChange={(value) => changeStartDate(value.target.value.toString())}
                           defaultValue={startDate}
                    />

                    <>Sluttdato: </>
                    <input type="date"
                           onChange={(value) => changeEndDate(value.target.value.toString())}
                           defaultValue={endDate}
                    />
                    <Button style={{
                        borderRadius: "20px", border: "none", float: "right", marginBottom: "10px",
                        backgroundColor: "#CDE1FF", color: "#313131", padding: "15px", marginRight: "35px"
                        }}
                        onClick={() => {if (window.confirm('Er du sikker på at du ville markere valgte fakturaer som fakturert?')) markInvoices()}}
                    >
                        Marker valgte som fakturert
                    </Button>
                    <ButtonGroup style={{width: "100%", height: "5vw", marginBottom: "20px"}}>
                      <Button onClick={() => setView(packages)}
                            style={view === packages ?
                              {width: "100%", borderRadius: "0px", backgroundColor: '#1a3457', borderColor: "#1a3457"}
                            : {width: "100%", borderRadius: "0px", backgroundColor: '#1a3457b3', borderColor: "white"}}>
                        PAKKER
                      </Button>
                      <Button onClick={() => setView(custom)}
                            style={view === custom ?
                              {width: "100%", borderRadius: "0px", backgroundColor: '#1a3457', borderColor: "#1a3457"}
                            : {width: "100%", borderRadius: "0px", backgroundColor: '#1a3457b3', borderColor: "white"}}>
                        SPESIALKUNDER
                      </Button>
                      <Button onClick={() => setView(travel)}
                            style={view === travel ?
                              {width: "100%", borderRadius: "0px", backgroundColor: '#1a3457', borderColor: "#1a3457"}
                            : {width: "100%", borderRadius: "0px", backgroundColor: '#1a3457b3', borderColor: "white"}}>
                        REISETILLEGG
                      </Button>
                    </ButtonGroup>

                    {loading ? <Loader/> :
                        <InvoiceTable invoiceList={invoiceType} view={view}
                                      toggle={(invoice, packages) => toggleInvoice(invoice, packages)}
                                      chosen={chosenInvoices}
                        />
                    }

                    <div style={{display: "flex", flexFlow: "row wrap", alignItems: "center", marginTop:"20px"
                        , paddingLeft: "100px"}}>
                        <svg width="65" height="65" viewBox="0 0 65 65">
                            <rect width="65" height="65" fill="#c4ecff"/>
                        </svg>
                        <div> = med delbetalinger</div>
                        <svg style={{marginLeft: "100px"}} width="65" height="65" viewBox="0 0 65 65">
                            <rect width="65" height="65" fill="#e0ffa4"/>
                        </svg>
                        <div>= uten delbetalinger</div>
                    </div>

                </CardBody>
            </Card>
        </div>
    )

}

export default InvoiceOverview;