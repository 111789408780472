import axios from "axios";
import TokenService from "./services/TokenService"
import { logOut } from "./redux/actions/auth";
import store from "./redux/store"

export const baseURL = process.env.REACT_APP_API_URL;

const axiosInstance = axios.create({
  baseURL: baseURL,
  timeout: 500000000,
  "Content-Type": "application/json",
  accept: "application/json",
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = TokenService.getAccessToken();
    if (token) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `JWT ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const originalRequest = error.config;
    const valid = TokenService.getRefreshTokenValidity();

    // if refresh token is expired, log user out
    if (!valid) {
      store.dispatch(logOut());
    }
    if (error.response) {
        if (error.response.status === 401 && !originalRequest.retry) {
            originalRequest.retry = true;
            return axiosInstance({
                url: "/user/token/refresh/",
                method: "post",
                data: {
                    refresh: TokenService.getRefreshToken(),
                },
            }).then((res) => {
                if (res.status === 200) {
                    TokenService.setToken(res.data);

                    /*
                    Update axiosInstance and originalRequest with new tokens and
                    send original request again
                    */
                    axiosInstance.defaults.headers.Authorization = `JWT ${TokenService.getAccessToken()}`;
                    originalRequest.headers.Authorization = `JWT ${TokenService.getAccessToken()}`;
                    return axiosInstance(originalRequest);
                }
                return null;
            });
        }
    }
    return Promise.reject(error);
  },
);

export default axiosInstance;
