import {
    GET_INVOICES_FAIL,
    GET_INVOICES_SUCCESS,
    GET_INVOICES_REQUEST
} from "../constants";

const initialState = {
    loading: false,
    invoices: {
        "package_invoices": [],
        "custom_activity_category_invoices": [],
        "travel_invoices": []
    }
};

export const invoiceReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_INVOICES_REQUEST:
            return {
                ...state,
                loading: true,
                invoices: state.invoices
            };

        case GET_INVOICES_SUCCESS:
            return {
                ...state,
                loading: false,
                invoices: action.payload
            };

        case GET_INVOICES_FAIL:
            return {
                ...state,
                loading: false
            };
        /*
        case MARK_INVOICES_REQUEST:
            return {
                ...state,
                loading: true,
                invoices: state.invoices
            };

        case MARK_INVOICES_SUCCESS:
            return {
                ...state,
                loading: false,
                invoices: action.payload
            };

        case MARK_INVOICES_FAIL:
            return {
                ...state,
                loading: false
            };

         */
        default:
            return state;
    }

}