import React, { useEffect, useState } from "react";
import "./style.css"
import {Frame} from "./Frame.jsx";

const PackageProgress = (props) => {
    return (
        <div className="package-progress-container" style={{background: props.props && props.props.color ? props.props.color : "#4CC029B2"}}>
            <Frame className="package-progress" >

            </Frame>
            <div className="fill" style={{height: props.props && props.props.progress ? props.props.progress : "0%"}}>  </div>

            <Frame></Frame>
        </div>
    )
}

// progress is a number between 30 and 92

// 92 minus percentage of 62

export default PackageProgress;