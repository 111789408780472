import axiosInstance from "../../axios";
import {
    ADD_SUBJECT_TO_STUDENT_FAIL, ADD_SUBJECT_TO_STUDENT_REQUEST,
    ADD_SUBJECT_TO_STUDENT_SUCCESS,
} from "../constants";

export const postAddSubjectToStudent = (subject_ids, student_id) => async (dispatch) => {
    try {
        dispatch({ type: ADD_SUBJECT_TO_STUDENT_REQUEST, clear: true });
        const { data } = await axiosInstance.post(
            "/user/student/add_subjects/" + student_id, {
                subjects: subject_ids
            }
        );
        dispatch({type: ADD_SUBJECT_TO_STUDENT_SUCCESS, payload: data});
    } catch (error) {
        dispatch({
            type: ADD_SUBJECT_TO_STUDENT_FAIL,
            error: error.response && error.response.data.message ?
                error.response.data.message :
                error.message,
    });

    }
}