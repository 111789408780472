import axiosInstance from "../../axios";
import { GET_SCHOOLS_FAIL, GET_SCHOOLS_REQUEST, GET_SCHOOLS_SUCCESS } from "../constants";

export const getSchools = () => async (dispatch) => {
  try {
    dispatch({ type: GET_SCHOOLS_REQUEST, clear: true });
    const { data } = await axiosInstance.get("/school/get-schools/");
    dispatch({ type: GET_SCHOOLS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: GET_SCHOOLS_FAIL, error: error.response && error.response.data.message ? error.response.data.message : error.message });
  }
};
