import axiosInstance from "../../axios";
import {
    GET_INVOICE_RECEIVER_SEARCH_SUCCESS,
    GET_INVOICE_RECEIVER_SEARCH_FAIL,
    GET_INVOICE_RECEIVER_SEARCH_REQUEST,
    PATCH_INVOICE_RECEIVER_SUCCESS,
    PATCH_INVOICE_RECEIVER_FAIL,
    PATCH_INVOICE_RECEIVER_REQUEST,
} from "../constants";

export const getInvoiceReceiversSearch = (search_string) => async (dispatch) => {
    try {
        dispatch({ type: GET_INVOICE_RECEIVER_SEARCH_REQUEST, clear: true });
        const { data } = await axiosInstance.get("/user/invoice-receiver/list/?search=" + search_string);

        dispatch({ type: GET_INVOICE_RECEIVER_SEARCH_SUCCESS, payload: data });

    } catch (error) {
        dispatch({
            type: GET_INVOICE_RECEIVER_SEARCH_FAIL,
            error:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const addStudentToInvoiceReceiver = (invoice_receiver_id, patch) => async (dispatch) => {
    try {
        dispatch({ type: PATCH_INVOICE_RECEIVER_REQUEST });
        const { data } = await axiosInstance.patch("/user/invoice-receiver/" + invoice_receiver_id, patch);

        dispatch({ type: PATCH_INVOICE_RECEIVER_SUCCESS});

    } catch (error) {
        dispatch({
            type: PATCH_INVOICE_RECEIVER_FAIL,
            error:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};