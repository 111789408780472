
const intersect = (list1, list2) => {
    return list1.filter(
        s1 => list2.map(s2 => s2.id).includes(s1.id)
    )
}

export const getSubjectIntersection = (
    teacherSubjects, allStudents, selectedStudentIds
) => {
    // Add the subjects from the selected students to the subjectList, if
    // the subject also is a subject of the teacher
    let subjectList = []
    selectedStudentIds.forEach(
          selected_id => subjectList.push(
              allStudents.find(
                  student => parseInt(student.id) === parseInt(selected_id)
              ).subjects.filter(
                  subject => teacherSubjects.some(
                      s => s.id === subject.id
                  )
              )
          )
    )
    // Subject list is a list of lists. Reduce that by intersection
    return subjectList.reduce(intersect)
}

export const getCommonSubjects = (teacherSubjects, studentSubjects) => {
    return studentSubjects.filter(
                    s_subject => teacherSubjects.some(
                        t_subject => t_subject.id === s_subject.id
                    )
    )
}
