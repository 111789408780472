import axiosInstance from "../../axios";
import {
  GET_TEACHER_FAIL,
  GET_TEACHER_REQUEST,
  GET_TEACHER_SUCCESS,
} from "../constants";
export const getTeacher = () => async (dispatch) => {
  try {
    dispatch({ type: GET_TEACHER_REQUEST, clear: true });
    const { data } = await axiosInstance.get("/user/get-teacher/");
    dispatch({ type: GET_TEACHER_SUCCESS, payload: data });
    return data;
  } catch (error) {
    dispatch({
      type: GET_TEACHER_FAIL,
      error:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
