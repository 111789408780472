import React, {Fragment} from 'react';
import { useTable, useSortBy, useFilters, useExpanded, usePagination } from "react-table"
import { Table } from 'reactstrap';
import { DefaultColumnFilter } from '../../modules/authenticated/Management/components/filters';
import {removeExpandedPageRowId} from "../../features/invoiceReceiver/invoiceReceiverSlice";

const TableContainer = ({ columns, data, renderRowSubComponent, expandedIds, addExpanded, removeExpanded}) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows, //-> we change 'rows' to 'page'
        prepareRow,
        visibleColumns,
    } = useTable(
        {
            columns,
            data,
            defaultColumn: { Filter: DefaultColumnFilter },
        },
        useFilters,
        useSortBy,
        useExpanded,
        usePagination,
    );

    return (
        <Fragment>
            <Table className="lessonTable" bordered hover {...getTableProps()}>
                <thead>
                {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                            <th {...column.getHeaderProps()}>
                                <div>
                                    {column.render('Header')}
                                </div>
                            </th>
                        ))}
                    </tr>
                ))}
                </thead>

                <tbody {...getTableBodyProps()}>
                {rows.map(row => {
                    if (expandedIds !== undefined) {
                        if (expandedIds.includes(row.original.id)) {
                            row.isExpanded = true
                        }
                    }
                    prepareRow(row);
                    return (
                        <Fragment key={row.getRowProps().key} >
                            <tr onClick={() => {
                                if (row.isExpanded && removeExpanded !== undefined) {
                                    removeExpanded(row.original.id)
                                } else if( addExpanded !== undefined){
                                    addExpanded(row.original.id)
                                }
                                row.toggleRowExpanded()
                            }} style={{cursor: "pointer"}}>
                                {row.cells.map((cell) => {
                                    return (
                                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                    );
                                })}
                            </tr>
                            {row.isExpanded && (
                                <tr>
                                    <td colSpan={visibleColumns.length}>
                                        {renderRowSubComponent(row)}
                                    </td>
                                </tr>
                            )}
                        </Fragment>
                    );
                })}
                </tbody>
            </Table>
        </Fragment>
    );
};

export default TableContainer;