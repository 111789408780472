import validator from "validator";

export const initialInvoiceUserState = {
    fields: {
      billing_first_name: "",
      billing_last_name: "",
      billing_phone_number: "",
      billing_email: "",
      billing_address: "",
      billing_post_code: "",
      billing_post_code_area: "",
      self_as_student: false,
      pays_for_students: []
    },
    errors: {
      billing_first_name: "",
      billing_last_name: "",
      billing_phone_number: "",
      billing_email: "",
      billing_address: "",
      billing_post_code: "",
      billing_post_code_area: "",
      self_as_student: false,
      pays_for_students: []
    },
  };

  //Handles changes to the form data related to the invoice reciever
export const invoiceHandler = (field, value, setInvoice) => {
    setInvoice((prevState) => ({
      ...prevState,
      fields: {
        ...prevState.fields,
        [field]: value,
      },
      errors: {
        ...prevState.errors,
        [field]: "",
      },
    }));
  };

export const validateInvoiceInput = (invoice, setInvoice) => {
  let valid = true;

  if (invoice.fields.billing_first_name === "") {
    setInvoice((prevState) => ({
      ...prevState,
      errors: {
        ...prevState.errors,
        billing_first_name: "Må fylles ut",
      },
    }));
    valid = false;
  }
  if (invoice.fields.billing_last_name === "") {
    setInvoice((prevState) => ({
      ...prevState,
      errors: {
        ...prevState.errors,
        billing_last_name: "Må fylles ut",
      },
    }));
    valid = false;
  }
  if (invoice.fields.billing_phone_number.length !== 8 || !validator.isInt(invoice.fields.billing_phone_number)) {
    setInvoice((prevState) => ({
      ...prevState,
      errors: {
        ...prevState.errors,
        billing_phone_number: "Ugyldig nummer",
      },
    }));
    valid = false;
  }
  if (!validator.isEmail(invoice.fields.billing_email)) {
    setInvoice((prevState) => ({
      ...prevState,
      errors: {
        ...prevState.errors,
        billing_email: "Må fylles ut",
      },
    }));
    valid = false;
  }
  if (!/(([\wæøåÆØÅ]+)[ -])+\d*\w?/.test(invoice.fields.billing_address)) {
    setInvoice((prevState) => ({
      ...prevState,
      errors: {
        ...prevState.errors,
        billing_address: "Ugyldig adresse",
      },
    }));
    valid = false;
  }
  if (invoice.fields.billing_post_code.length !== 4 || !validator.isInt(invoice.fields.billing_post_code)) {
    setInvoice((prevState) => ({
      ...prevState,
      errors: {
        ...prevState.errors,
        billing_post_code: "Ugyldig postnummer",
      },
    }));
    valid = false;
  }
  if (!/([\wæøåÆØÅ]+ )*[\wæøåÆØÅ]+/.test(invoice.fields.billing_post_code_area)) {
    setInvoice((prevState) => ({
      ...prevState,
      errors: {
        ...prevState.errors,
        billing_post_code_area: "Ugyldig poststed",
      },
    }));
    valid = false;
  }
  return valid;

}
