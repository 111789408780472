import React from "react";
import { Row } from "reactstrap";
import StudentRow from "./StudentRow";
import {dateFormatter, timeFormatter} from "../../../../utils/formattingHelpers";

// Returns the a table displaying students of a teacher with the next lesson

const TeacherStudentTable = (userActivities, students) => {
    const getLessonTableRows = () => {

        // makes a list of upcoming events
        let events = [...userActivities];
        let upcomingEvents = events.filter((activity) => {
            const today = new Date().getTime();
            const compare = new Date(activity.start_date_time).getTime();
            return today < compare;
        })

        // adds the next lesson for each student to each student
        students.forEach((student, key) => {
            const studentLessons = upcomingEvents.filter((event) => {
                const stud = event.student_details;
                // a check to see if the student is part of the lesson, filters out those they are not part of.
                const student_name = student.first_name + " " + student.last_name;
                return Object.keys(stud).some(function(k) {
                    return stud[k].name === student_name;
                });
            });

            // sorts on next date and picks first
            let sorted = [...studentLessons];
            sorted.sort((a, b) => (
                new Date(a.start_date_time) > new Date(b.start_date_time)
            ) ? 1 : -1 );
            // checks if there is an upcoming lesson and sets the values to "-" if not
            if(sorted[0]){
                const nextLesson = sorted[0];
                student.start_date_time = nextLesson.start_date_time;
                student.next_lesson_subject = nextLesson.subject_details;
            } else {
                student.start_date_time = "-";
                student.next_lesson_subject = "-";
            }
        });


        return students.map((student, key) => {
            let date = new Date(student.start_date_time);
            return StudentRow({
                key: key,
                student: student.first_name + " " + student.last_name,
                date: student.start_date_time === "-" ?
                    student.start_date_time :
                    `${dateFormatter(date)}, kl: ${timeFormatter(date)}`,
                subject: student.next_lesson_subject
            });
        });
    };

    return (
        <Row style={{ width: "100%", marginTop: "40px" }}>
            <div style={{ overflowX: "auto", width: "100%" }}>
                <table className="lessonTable" style={{ width: "100%" }}>
                    <thead>
                    <tr>
                        <th>Elev</th>
                        <th>Neste Time</th>
                        <th>Fag</th>
                    </tr>
                    </thead>
                    <tbody>{getLessonTableRows()}</tbody>
                </table>
            </div>
        </Row>
    );
};

export default TeacherStudentTable;
