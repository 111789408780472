import {
  LOG_IN_REQUEST,
  LOG_IN_SUCCESS,
  LOG_IN_FAIL,
  LOG_OUT_SUCCESS,
  LOG_OUT_FAIL,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAIL,
  EDIT_TEACHER_SUCCESS,
  EDIT_TEACHER_REQUEST,
  EDIT_TEACHER_FAIL,
} from "../constants";
import store from "../store";
const initialState = {
  currentUser: null,
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOG_IN_REQUEST:
      return { ...state, loading: true, currentUser: null };

    case LOG_IN_SUCCESS:
      return { ...state, loading: false, currentUser: action.payload, actionSucceeded: true };

    case LOG_IN_FAIL:
      return { ...state, loading: false, actionSucceeded: false };

    case LOG_OUT_SUCCESS:
      //store.currentUser = null;
      return { ...state, loading: false, currentUser: null };

    case LOG_OUT_FAIL:
      return { ...state, loading: false };

    default:
      return state;
  }
};

// export const editTeacherReducer = (state = initialState, action) => {
//   console.log(state);
//   console.log(action);
// };
