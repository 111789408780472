import React from "react";
import finished from "../../../../assets/img/RH/finished.png";
import missingSummary from "../../../../assets/img/RH/missingSummary.png";
import planned from "../../../../assets/img/RH/planned.png";
import edit from "../../../../assets/img/RH/Rediger.png";
import { Link } from "react-router-dom";
import Badge from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faCircle } from '@fortawesome/free-solid-svg-icons';

//Returns a row for the tables that display students in teacher profile,
const StudentRow = (props) => {

    return (
        <tr key={props.key}>
            <td>{props.student}</td>

            <td>
                <div>
                    {props.date}
                </div>
            </td>
            <td>{props.subject}</td>
        </tr>
    );
};

export default StudentRow;
