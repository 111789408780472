import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Button
} from "reactstrap";

import OrderConfirmationTable from "./OrderConfirmationTable";
import {
  getOrderConfirmations,
  deleteOrderConfirmation,
  sendOrderConfirmation,
} from "../../../../../redux/actions/orderConfirmationActions";
import logo from "../../../../../assets/img/RH/Realfagshjelpen_Logo_PNG_Original.png";
import ConfirmDeleteModal from "../../components/ConfirmDeleteModal";
import axiosInstance from "../../../../../axios";
import Loader from "../../../../../components/Loader";

// Page that displays all students of a teacher
const OrderConfirmations = () => {
  const dispatch = useDispatch();

  const userState = useSelector((state) => state.currentUser);
  const user = userState.currentUser.user;
  const userLoading = userState.loading;

  const orderConfirmationState = useSelector((state) => state.userPackages);
  const orderConfirmationList = orderConfirmationState.orderConfirmations;
  const orderConfirmationLoading = orderConfirmationState.loading;


  const [error, setError] = useState("initial")

  const deletePackage = async (id) => {
    try {
      await axiosInstance.delete(
        "/package/package/" + id + "/"
      );
    } catch (error) {
      setError(error.response.data)
    }
  };

  const [showModal, setShowModal] = useState(false);

  const [viewedObject, setViewedObject] = useState({
    id: null,
    package: {
      name: "",
      total_hours: 0,
      comment_for_invoice: "",
      students: [
        {
          contact_person: {
            first_name: "",
            last_name: "",
          },
        },
      ],
    },
  });

  useEffect(() => {
    dispatch(getOrderConfirmations());
  }, [dispatch]);

  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [showConfirmDeletePackageModal, setShowConfirmDeletePackageModal] = useState(false);
  const [objectToDelete, setObjectToDelete] = useState(null);

  const handleDelete = (key) => {
    setShowConfirmDeleteModal(true);
    setObjectToDelete(key);
  };

  const handleDeletePackage = (packageID) => {
    setShowConfirmDeletePackageModal(true);
    setObjectToDelete(packageID);
  }

  const handleConfirmDelete = async () => {
    await dispatch(deleteOrderConfirmation(objectToDelete));
    setShowConfirmDeleteModal(false);
  };

  const handleConfirmDeletePackage = async () => {
    await deletePackage(objectToDelete);
    setShowConfirmDeletePackageModal(false);
  };

  // reloads page if either deletion of package or deletion of order confirmation is successful
  // in order to reload table
  useEffect(()=> {

    if (!showConfirmDeletePackageModal && !showConfirmDeleteModal && error === "") {
      window.location.reload(false);
    }
    // Prevents infinite reloads on first render
    if (error === "initial") setError("");
  }, [showConfirmDeletePackageModal, showConfirmDeleteModal])

  const handleView = (key) => {
    setViewedObject(orderConfirmationList.find((x) => x.id === key));
    setShowModal(true);
  };

  const handleSend = () => {
    dispatch(sendOrderConfirmation(viewedObject.id));
    setShowModal(false);
    orderConfirmationList.filter((oc) => oc.id !== viewedObject.id);
    //window.location.reload();
  };

  const bg = {
    overlay: {
      background: "lightblue",
    },
  };

  return (

    <div>
      <div className="title mb-3" >Ordrebekreftelser</div>
      {orderConfirmationLoading || userLoading ? <Loader/> : ""}
      <div style={orderConfirmationLoading || userLoading ? {visibility: "hidden"}: {}}>
          {OrderConfirmationTable(
            orderConfirmationList,
            handleDelete,
            handleView,
            handleDeletePackage
          )}
      </div>
      <Modal
        isOpen={showModal}
        toggle={() => setShowModal(false)}
        className="modal-dialog modal-lg"
        styles={bg}
      >
        <ModalHeader>
          <header style={{ fontSize: "35px" }}>
            <h1>Ordrebekreftelsen vil bli sendt slik</h1>
            <p style={{color: "var(--red2)"}}>NB: Du vil stå som veileder på forhåndsvisningen selv om du
              ikke er veileder for øyeblikket. <br/> Når du sender bekreftelsen blir du satt til elevens veileder.</p>
          </header>
        </ModalHeader>
        <ModalBody
          style={{
            marginLeft: "50px",
            marginRight: "50px",
            marginBottom: "50px",
            align: "center",
          }}
        >
          <Row style={{ align: "middle" }}>
            <Card
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                marginBottom: "100px",
              }}
            >
              {viewedObject ? (
                viewedObject.order_confirmation_purpose !== "NEW_PACKAGE" ? (
                  <p style={{ marginLeft: "10px", marginRight: "10px" }}>
                    <br />
                    Vi har registrert endringer på din bestilling. Dette er de
                    nye detaljene.
                    <br />
                    <br />
                    {viewedObject.package.name}:{" "}
                    {viewedObject.package.total_hours} Timer
                    <br />
                    Pakkepris: {viewedObject.package.price} Kr
                    {viewedObject.package.number_of_payments > 1 ? (
                      <div>
                        <br />
                        Antall Betalinger:{" "}
                        {viewedObject.package.number_of_payments} <br />
                        Gebyr for delbetaling:{" "}
                        {viewedObject.package.number_of_payments - 1} * 150 kr ={" "}
                        {(viewedObject.package.number_of_payments - 1) * 150} kr{" "}
                        <br />
                        Totalt:{" "}
                        {viewedObject.package.price +
                          (viewedObject.package.number_of_payments - 1) *
                            150}{" "}
                        kr
                        <br />
                      </div>
                    ) : (
                      <div>
                        <br />
                      </div>
                    )}
                    Avdeling:{" "}
                    {viewedObject.package.students[0].city ?
                        viewedObject.package.students[0].city :
                        " Avdeling ikke bestemt"}
                    <br />
                    <br />
                    Kommentar: {viewedObject.package.comment_for_invoice}
                    <br />
                    <br />
                    Elevens navn:{" "}
                    {viewedObject.package.students.map((student, key) => {
                      return (
                        <div key={key}>
                          {student.first_name} {student.last_name}
                        </div>
                      );
                    })}
                    <br />
                    <br />
                    Din veileder er:
                    <br />
                    {user.first_name} {user.last_name}
                    <br />
                    {user.email}
                    <br />
                    {user.phone_number}
                    <br />
                    <br />
                    Vi ser fram til gode undervisningstimer!
                    <br />
                    <br />
                    Ved administrative henvendelser ber vi om at veileder
                    kontaktes.
                    <br />
                    Vedlagt ligger inngått avtale og informasjon om angrerett.
                    <br />
                    Dette er ikke en faktura. Faktura med betalingsinformasjon
                    kommer i begynnelsen av neste måned.
                    <br />
                    <br />
                    <br />
                    <img src={logo} style={{ width: "50%" }} />
                    <br />
                    Realfagskunnskap videreformidlet av Norges flinkeste
                    studenter
                    <br />
                  </p>
                ) : (
                  <p style={{ marginLeft: "10px", marginRight: "10px" }}>
                    <br />
                    Velkommen som kunde hos Realfagshjelpen. Vi har registrert
                    følgende bestilling.
                    <br />
                    <br />
                    {viewedObject.package.name}:{" "}
                    {viewedObject.package.total_hours} Timer
                    <br />
                    Pakkepris: {viewedObject.package.price} Kr
                    {viewedObject.package.number_of_payments > 1 ? (
                      <div>
                        <br />
                        Antall Betalinger:{" "}
                        {viewedObject.package.number_of_payments} <br />
                        Gebyr for delbetaling:{" "}
                        {viewedObject.package.number_of_payments - 1} * 150 kr ={" "}
                        {(viewedObject.package.number_of_payments - 1) * 150} kr{" "}
                        <br />
                        Totalt:{" "}
                        {viewedObject.package.price +
                          (viewedObject.package.number_of_payments - 1) *
                            150}{" "}
                        kr
                        <br />
                        <br />
                      </div>
                    ) : (
                      <div>
                        <br />
                      </div>
                    )}
                    Avdeling:{" "}
                    {viewedObject.package.students[0].city ?
                        viewedObject.package.students[0].city :
                         " Avdeling ikke bestemt"}
                    <br />
                    <br />
                    Kommentar: {viewedObject.package.comment_for_invoice}
                    <br />
                    <br />
                    Elevens navn:{" "}
                    {viewedObject.package.students.map((student, key) => {
                      return (
                        <div key={key}>
                          {student.first_name} {student.last_name}
                        </div>
                      );
                    })}
                    <br />
                    <br />
                    Din veileder er:
                    <br />
                    {user.first_name} {user.last_name}
                    <br />
                    {user.email}
                    <br />
                    {user.phone_number}
                    <br />
                    <br />
                    Vi ser Fram til gode undervisningstimer!
                    <br />
                    <br />
                    Ved administrative henvendelser ber vi om at veileder
                    kontaktes.
                    <br />
                    Vedlagt ligger inngått avtale og informasjon om angrerett.
                    <br />
                    Dette er ikke en faktura. Faktura med betalingsinformasjon
                    kommer i begynnelsen av neste måned.
                    <br />
                    <br />
                    <br />
                    <img src={logo} style={{ width: "50%" }} />
                    <br />
                    Realfagskunnskap videreformidlet av Norges flinkeste
                    studenter
                    <br />
                  </p>
                )
              ) : (
                <div />
              )}
            </Card>
          </Row>
          <Row style={{ marginLeft: "auto", marginRight: "auto" }}>
            <Col>
              <button
                style={{ marginLeft: "auto", marginRight: "auto" }}
                className="button darkButton"
                onClick={() => {
                  setShowModal(false);
                }}
              >
                Tilbake
              </button>
            </Col>
            <Col>
              <button
                style={{ marginLeft: "auto", marginRight: "auto" }}
                className="button darkButton"
                onClick={handleSend}
              >
                Send
              </button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
      <ConfirmDeleteModal
          handleConfirmDelete={handleConfirmDelete}
          setShowConfirmDeleteModal={setShowConfirmDeleteModal}
          show={showConfirmDeleteModal}
          text={"Er du sikker på at du vil slette denne ordrebekreftelsen?"}
      />
      <ConfirmDeleteModal
          handleConfirmDelete={handleConfirmDeletePackage}
          setShowConfirmDeleteModal={setShowConfirmDeletePackageModal}
          show={showConfirmDeletePackageModal}
          text={"Er du sikker på at du vil slette denne pakken?"}
      />
      <Modal isOpen={error !== "" && error !== "initial"}>
        <ModalHeader style={{align: "middle", justifyContent: "center"}}>
          Følgende feil oppstod:
        </ModalHeader>
        <ModalBody>
          <Row style={{align: "middle", justifyContent: "center", marginBottom: "10px"}}>
            <div style={{textAlign:"center"}}>
              {error}
            </div>
          </Row>
          <Row style={{align: "middle", justifyContent: "center"}}>
            <Button onClick={() => setError("")} style={{align: "middle"}}
            className="button darkButton">
              OK
            </Button>
          </Row>
        </ModalBody>
      </Modal>
    </div>

  );
};

export default OrderConfirmations;
