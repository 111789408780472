import React from "react";
import { Modal, ModalBody, Button } from "reactstrap";
import {useHistory} from "react-router-dom";

const MessageModal = ({ toggle, isOpen, header, message, link, error }) => {
  if (error) {
      if (message === undefined) {
    message = (
      <>
        Handlingen du utførte gikk ikke gjennom.
        <br />
        <br />
        Prøv igjen eller kontakt IT
      </>
    ) };
    header = "Beklager!";
    link = "";
  };

  const history = useHistory();

  const refresh = () => {
      history.push(link);
      window.location.reload();

  }

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => {
        if (link === "") toggle();
      }}
      size="sm"
    >
      <div
        id={error ? "failModalHeader" : "modalHeader"}
        className="fullFlexCentre"
      >
        <div className="title whiteText">{header}</div>
      </div>
      <ModalBody>
        <p style={{borderBottom:"0.5px solid black", padding: "20px", textAlign: "center"}}>{message}</p>
        {link === "" ? (
          <Button className="button darkButton" style={{ marginRight: "5px", float: "right" }}
                  onClick={toggle}
          >
            Ok
          </Button>
        ) : (
            <Button onClick={() => refresh()} className="button darkButton"
                    style={{ marginRight: "5px", float: "right" }}
            >
              Ok
            </Button>
        )}
      </ModalBody>
    </Modal>
  );
};

export default MessageModal;
