import { createSlice, createAsyncThunk} from "@reduxjs/toolkit"
import PaginatedService from "../../services/PaginatedService";
import axiosInstance from "../../axios";
import {returnError} from "../../utils/errorHelper";

const initialState = {
    loading: 'idle',    //  'idle' | 'pending' | 'succeeded' | 'failed'
    pageDetail: [],
    expandedPageRowIds: [],
    count: null,
    page: [],
    pageIndex: 0,
    nextPage: null,
    previousPage: null,
}

// API call for invoice receiver pagination
export const invoiceReceiverPageBaseUrl = "user/invoice-receiver/list/"
export const fetchInvoiceReceiverPage = createAsyncThunk(
    "invoiceReceiver/fetchInvoiceReceiverPage", PaginatedService.getPage
)

// API Calls for Invoice Receiver CRUD
export const getInvoiceReceiver = createAsyncThunk(
    "invoiceReceiver/getInvoiceReceiver",
    async (arg, thunkAPI) => {
        try {
            const response = await axiosInstance.get(
                "/user/invoice-receiver/" + arg.id
            )
            return response.data
        } catch (err) {
            return returnError(err, thunkAPI)
        }
    }
)
export const patchInvoiceReceiver = createAsyncThunk(
    "invoiceReceiver/patchInvoiceReceiver",
    async (arg, thunkAPI) => {
        try {
            const response = await axiosInstance.patch(
                "/user/invoice-receiver/" + arg.id, arg.data  // Arg is the invoice receiver data
            )
            return arg // Returning id and patched data so that we can update the state
        } catch (err) {
            return returnError(err, thunkAPI)
        }
    }
)

const invoiceReceiverSlice = createSlice({
    name: "invoiceReceiver",
    initialState,
    reducers: {
        addExpandedPageRowId(state, action) {
            state.expandedPageRowIds.push(action.payload.id)
        },
        removeExpandedPageRowId(state, action) {
            state.expandedPageRowIds = state.expandedPageRowIds.filter(i => i !== action.payload.id)
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchInvoiceReceiverPage.pending, (state, action) => { // Pagination
            state.loading = "pending"
        }).addCase(fetchInvoiceReceiverPage.fulfilled, (state, action) => {
            PaginatedService.renderFulfilled(state, action)
            state.pageDetail = initialState.pageDetail
            state.expandedPageRowIds = initialState.expandedPageRowIds
            state.loading = "success"
        }).addCase(fetchInvoiceReceiverPage.rejected, (state, action) => {
            state.loading = "failed"
        }).addCase(getInvoiceReceiver.pending, (state, action) => { // Get
            state.loading = "pending"
        }).addCase(getInvoiceReceiver.fulfilled, (state, action) => {
            state.pageDetail.push(action.payload)
            state.loading = "success"
        }).addCase(getInvoiceReceiver.rejected, (state, action) => {
            state.loading = "failed"
        }).addCase(patchInvoiceReceiver.pending, (state, action) => {  // Patch
            state.loading = "pending"
        }).addCase(patchInvoiceReceiver.fulfilled, (state, action) => {

            // We have successfully patched an invoice receiver, we now
            //  need to update it in the page and page detail
            for (const key in action.payload.data) {
                let i = state.page.findIndex(obj => {
                    return obj.id === action.payload.id
                })
                if (i !== -1 && Object.hasOwn(state.page[i], key)) {
                    state.page[i][key] = action.payload.data[key]
                }
                let j = state.pageDetail.findIndex(obj => {
                    return obj.id === action.payload.id
                })
                if (j !== -1 && Object.hasOwn(state.pageDetail[j], key)) {
                    state.pageDetail[j][key] = action.payload.data[key]
                }
            }
            state.loading = "success"
        }).addCase(patchInvoiceReceiver.rejected, (state, action) => {
            state.loading = "failed"
        })
    }
})

export const { addExpandedPageRowId, removeExpandedPageRowId } = invoiceReceiverSlice.actions
export default invoiceReceiverSlice.reducer