import React from 'react';
import TeacherOverview from "./pages/TeacherOverview";
import NewPackage from "./pages/NewPackage";
import OrderConfirmations from "./pages/OrderConfirmations";
import TeacherMatching from "./pages/TeacherMatching";
import SalesPackageOverview from "./pages/SalesPackageOverview";
import SalaryLevel from "./pages/SalaryLevel/SalaryLevel";
import PackageTypeOverview from "./pages/PackageType/PackageTypeOverview";
import AllStudentsOverview from "./pages/AllStudentsOverview";
import Dashboard from "./pages/Dashboard";
import InvoiceOverview from "./pages/InvoiceOverview";
import SalaryOverview from "./pages/SalaryOverview";
import {
  ECONOMY_TEAM,
  HUMAN_RESOURCES_TEAM,
  MANAGEMENT_TYPE,
  ManagementPaths,
  SALES_TEAM
} from "./managementConstants";
import InvoiceReceiverOverviewPage from "./pages/InvoiceReceiverOverview/InvoiceReceiverOverviewPage";
import { FaPeopleArrows, FaFileInvoiceDollar } from "react-icons/fa";
import { AiOutlineDashboard, AiFillDashboard } from 'react-icons/ai'
import { FiPackage } from 'react-icons/fi';
import { IoSchool } from 'react-icons/io5';
import { BsBagCheckFill, BsBagPlusFill, BsFillPersonLinesFill, BsPersonPlusFill } from 'react-icons/bs';
import { GiMoneyStack } from "react-icons/gi";

export const managementRoutes = [
  // Routes for all employees
  {
    path: ManagementPaths.home,
    name: "Dashbord",
    showOnSidebar: true,
    icon: AiFillDashboard,
    component: Dashboard,
    authCheck: (userState) => userState.type === MANAGEMENT_TYPE,
  },
  // Sales Routes (and HR)
  {
    path: ManagementPaths.newPackage,
    name: "Ny pakke på elev",
    showOnSidebar: true,
    icon: BsBagPlusFill,
    component: NewPackage,
    authCheck: (userState) => userState.user.groups.includes(SALES_TEAM),
  },
  {
    path: ManagementPaths.orderConfirmations,
    name: "Ordrebekreftelser",
    showOnSidebar: true,
    icon: BsBagCheckFill,
    component: OrderConfirmations,
    authCheck: (userState) => userState.user.groups.includes(SALES_TEAM),
  },
  {
    path: ManagementPaths.teacherMatching,
    name: "Lærermatching",
    showOnSidebar: true,
    icon: FaPeopleArrows,
    component: TeacherMatching,
    authCheck: (userState) => userState.user.groups.includes(SALES_TEAM),
  },
  {
    path: ManagementPaths.teacherOverview,
    name: "Læreroversikt",
    showOnSidebar: true,
    icon: IoSchool,
    component: TeacherOverview,
    authCheck: (userState) =>
        userState.user.groups.includes(SALES_TEAM) ||
        userState.user.groups.includes(HUMAN_RESOURCES_TEAM),
  },
  {
    path: ManagementPaths.studentOverview,
    name: "Studentoversikt",
    showOnSidebar: true,
    icon: BsFillPersonLinesFill,
    component: AllStudentsOverview,
    authCheck: (userState) =>
        userState.user.groups.includes(SALES_TEAM) ||
        userState.user.groups.includes(HUMAN_RESOURCES_TEAM),
  },
  {
    path: ManagementPaths.invoiceReceiverOverview,
    name: "Fakturamottakere",
    showOnSidebar: true,
    icon: BsFillPersonLinesFill,
    component: InvoiceReceiverOverviewPage,
    authCheck: (userState) =>
        userState.user.groups.includes(SALES_TEAM)
  },
  {
    path: ManagementPaths.packageOverview,
    name: "Pakkeoversikt",
    showOnSidebar: true,
    icon: FiPackage,
    component: SalesPackageOverview,
    authCheck: (userState) => userState.user.groups.includes(SALES_TEAM),
  },
  // Economy routes
  {
    path: ManagementPaths.invoiceOverview,
    name: "Fakturaoversikt",
    showOnSidebar: true,
    icon: FaFileInvoiceDollar,
    component: InvoiceOverview,
    authCheck: (userState) => userState.user.groups.includes(ECONOMY_TEAM),
  },
  {
    path: ManagementPaths.salaryOverview,
    name: "Betal lønn",
    showOnSidebar: true,
    icon: GiMoneyStack,
    component: SalaryOverview,
    authCheck: (userState) => userState.user.groups.includes(ECONOMY_TEAM),
  },
]