import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Col,
  Form,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import SubjectSelector from "./SubjectSelector";
import { postAddSubjectToStudent } from "../../../../redux/actions/subjectActions";

function RegisterNewCourseModal(
  student,
  subjects,
  studentSubjects,
  teacherSubjects,
  disabled
) {
  const dispatch = useDispatch();
  const [selectedSubjects, setSubjects] = useState([]);
  const [modal, setModal] = useState(false);
  const [saveInfo, setSaveInfo] = useState(false);
  const toggle = () => setModal(!modal);
  const toggleSaveInfo = () => setSaveInfo(!saveInfo);

  const saveToDB = () => {
    // Here you should do API calls to save selectedSubjects
    setSaveInfo(true);
    dispatch(
      postAddSubjectToStudent(
        selectedSubjects.map((subject) => subject.id),
        student.id
      )
    );
    setModal(false);
  };

  const updateSubjects = (selected) => {
    setSubjects(selected);
  };

  function courses_added() {
    return "" + selectedSubjects.map((x) => " " + x.subject_name);
  }

  /*function checkUnteachableCourses() {
    console.log(teacherSubjects.map((x) => x.id));
    selectedSubjects
      .map((x) => x.id)
      .forEach((sub) => {
        
        console.log(teacherSubjects.map((x) => x.id).includes(sub));
        if (teacherSubjects.map((x) => x.id).includes(sub)) {
          setHasUnteachableCourses(true);
          return;
        }
      });
    
    setHasUnteachableCourses(false);
  }*/

  const clickOk = () => {
    toggleSaveInfo();
    {window.location.reload(false)}
  }

  return (
    <div>
      <Button
        className="button darkButton"
        key="Registrer nytt fag på elev"
        text="Registrer nytt fag på elev"
        value="Registrer nytt fag på elev"
        onClick={toggle}
        id="registerStudentButton"
        disabled={student.id == null || disabled}
        style={{
          minHeight: "27px",
          height: "fit-content",
        }}
      >
        Registrer nytt fag på elev
      </Button>
      <Modal isOpen={modal} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle} id="modalHeader">
          <Label className="title whiteText">
            Legg til fag på {student.name}:
          </Label>
        </ModalHeader>
        <ModalBody>
            {SubjectSelector(
              subjects,
              studentSubjects,
              teacherSubjects,
              updateSubjects
            )}
            <Row>
              <Col>
                <Button
                  className="button darkButton saveButton"
                  style={{ marginRight: "0px" }}
                  onClick={(e) => saveToDB()}
                >
                  Lagre
                </Button>
              </Col>
            </Row>
        </ModalBody>
      </Modal>
      <Modal isOpen={saveInfo} toggle={toggleSaveInfo}>
        <ModalHeader toggle={toggle} id="modalHeader">
          <Label className="title whiteText">Lagret!</Label>
        </ModalHeader>
        <ModalBody>
          <p>
            {" "}
            Du har lagt til{courses_added()}. Dersom du har lagt til fag du ikke
            kan undervise i må du kontakte HR om dette.{" "}
          </p>
          <Button
            className="button mediumButton"
            onClick={() => clickOk()}
            style={{ marginRight: "5px" }}
          >
            OK
          </Button>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default RegisterNewCourseModal;
