import TeacherDashboard from "./pages/TeacherDashboard";
import InitialUpdateTeacher from "./pages/InitialUpdateTeacher";
import ActivityForm from "./pages/ActivityForm";
import StudentOverview from "./pages/StudentOverview/StudentOverview";
import IndividualStudentOverview from "./pages/StudentOverview/IndividualStudentOverview";
import AddStudentForm from "./pages/AddStudentForm";
import TeacherResources from "./pages/TeacherResources";
import Profile from "./pages/TeacherProfile";
import {TEACHER_TYPE, TeacherPaths} from "./teacherConstants";

import { BsPersonPlusFill, BsPersonLinesFill, BsPlusCircleFill } from 'react-icons/bs';
import {GiTeacher} from "react-icons/gi";
import {AiOutlinePlus} from "react-icons/ai"
import {FaToolbox, FaChalkboardTeacher} from "react-icons/fa"
import {IoCalendarNumber} from "react-icons/io5"
import TeacherSchedulePage from "./pages/Schedule/TeacherSchedulePage";

export const teacherRoutes = [
  {
    path: TeacherPaths.home,
    name: "Mine aktiviteter",
    showOnSidebar: true,
    icon: FaChalkboardTeacher,
    component: TeacherDashboard,
    authCheck: (userState) =>
        userState.type === TEACHER_TYPE
  },
  {
    path: TeacherPaths.updateProfile,
    name: "Oppdater profil",
    showOnSidebar: false,
    component: InitialUpdateTeacher,
    authCheck: (userState) => 
        userState.type === TEACHER_TYPE
    
  },
  {
    path: TeacherPaths.newActivity,
    name: "Ny aktivitet",
    showOnSidebar: true,
    icon: AiOutlinePlus,
    component: ActivityForm,
    authCheck: (userState) => 
        userState.type === TEACHER_TYPE
    
  },
  {
    path: TeacherPaths.editActivity,
    name: "Elev sin økt",
    showOnSidebar: false,
    component: ActivityForm,
    authCheck: (userState) => 
        userState.type === TEACHER_TYPE
    
  },
  {
    path: TeacherPaths.studentOverview,
    name: "Mine elever",
    showOnSidebar: true,
    icon: BsPersonLinesFill,
    component: StudentOverview,
    authCheck: (userState) => 
        userState.type === TEACHER_TYPE
  },
  {
    // NB: It is important that this is before 'Individuell elev'
    // otherwise the ':id' will match with the 'new/' part of this url
    path: TeacherPaths.newStudent,
    name: "Ny elev",
    showOnSidebar: true,
    icon: BsPersonPlusFill,
    component: AddStudentForm,
    authCheck: (userState) => 
        userState.type === TEACHER_TYPE
    
  },
  {
    path: TeacherPaths.individualStudentOverview,
    name: "Individuell elev",
    showOnSidebar: false,
    component: IndividualStudentOverview,
    authCheck: (userState) => 
        userState.type === TEACHER_TYPE
    
  },
  {
      path: TeacherPaths.resources,
      name: "Ressurser",
      showOnSidebar: true,
      icon: FaToolbox,
      component: TeacherResources,
      authCheck: (userState) => 
        userState.type === TEACHER_TYPE
      
  },
  {
      path: TeacherPaths.profile,
      name: "Profil",
      showOnSidebar: false,
      component: Profile,
      authCheck: (userState) => 
       userState.type === TEACHER_TYPE
    },
  {
    path: TeacherPaths.schedule,
    name: "Kalender",
    showOnSidebar: true,
    icon: IoCalendarNumber,
    component: TeacherSchedulePage,
    authCheck: (userState) =>
        userState.type === TEACHER_TYPE
  }
];
