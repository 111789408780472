import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Progress,
  Row,
} from "reactstrap";
import { getMyPackages } from "../../../../../redux/actions/getMyPackages";
import PackageProgress from "./PackageProgress";
import {Frame} from "./Frame";
import PackageInfoCard from "./PackageInfoCard";
import packageInfoCard from "./PackageInfoCard";
import ContactPersonCard from "./ContactPersonCard";
import {getContactinfo} from "../../../../../redux/actions/getContactinfo";

const PackageOverview = () => {


  /* Determines if the page should show the inactive packages or not */
  const [inactivePackages, setState] = useState(false);

  const stateHandler = () => {
    setState((prevState) => !prevState);
  };

  const dispatch = useDispatch();

    const contactEmployees = useSelector((state) => state.employees);
    useEffect(() => {
        dispatch(getContactinfo());
    }, [dispatch]);

  /* Gets the info about the packages */
  const packages = useSelector(
    (inactivePackages) => inactivePackages.userPackages
  );
  const { loading, allPackages } = packages;

  useEffect(() => {
    dispatch(getMyPackages());
  }, [dispatch]);

  /* Shows the active packages with the correct design */
  const getStartedPackageContainer = (name, hours, remaining) => {
    if (remaining > 0 && remaining < hours) {
      return (
        <Card
          className="lightInfobox m-3"
          style={{
            borderWidth: "0.75em",
            borderRadius: "0.5rem",
            width: 300,
            height: 250
          }}
        >
          <CardBody
            className="d-flex"
            style={{flex: 1, flexDirection: "column", justifyContent: "center", alignItems: "center"}}
          >


              <Row
              className="secondTitle"
              style={{flex: 1, display: "flex", justifyContent: "center"}}
            >
              {name}
            </Row>
            <div  className="secondTitle mb-2">({hours} timer)</div>
            <Row style={{flex: 2, justifyContent: "center"}}>
            <Row style={{ marginBottom: "10px", textAlign: "center" }}>
            <div>
              <div style={{ marginBottom: "5px" }}>
                Gjennomført: {hours - remaining}
              </div>
              <Progress
                color="success"
                value={((hours - remaining) / hours) * 100}
              />
              <div style={{ marginTop: "5px" }}>
                Resterende: {remaining}
              </div>
            </div>
          </Row>
          </Row>
          </CardBody>
        </Card>
      );
    }
  };


  const getNotStartedPackageContainer = (name, hours, remaining) => {
    if (hours - remaining === 0) {
      return (
        <Card
          className="mediumInfobox m-3"
          style={{
            borderWidth: "0.75em",
            borderRadius: "0.5rem",
            width: 300,
            height: 250
          }}
        >
          <CardBody className="d-flex" style={{flexDirection: "column"}}>
             <div
               style={{
                 display: "flex",
                 justifyContent: "center",
                 alignItems: "center",
                 flexDirection: "column",
                 flex: 1
               }}
             >
               <div  className="secondTitle">{name}</div>
               <div  className="secondTitle">({hours} timer)</div>
               <p>Ikke påbegynt</p>
          </div>
          </CardBody>
        </Card>
      );
    }
  };

  /* Shows the inactive packages with the right design */
  const getInactivePackageContainer = (is_expired, name, hours, remaining, expiration_date, sold_date) => {
    if (remaining == 0 || remaining == null) {
      return (
        <Card
          className="darkInfobox m-3"
          style={{
            borderWidth: "0.75em",
            borderRadius: "0.5rem",
            width: 300,
            height: 250
          }}
        >
          <CardBody
            className="d-flex"
            style={{
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <div
              className="secondTitle"
              style={{ textAlign: "center"}}
            >
              {name} <br /> ({hours} timer) <br/>
              <p>Fullført!</p>
            </div>
          </CardBody>
        </Card>
      );
    }
    if (is_expired) {
      return (
        <Card
          className="redInfobox m-3"
          style={{
            borderWidth: "0.75em",
            borderRadius: "0.5rem",
            width: 300,
            height: 250,
            backgroundColor: "rgba(222, 31, 31, 0.05)"
          }}
        >
          <CardBody
            className="d-flex"
            style={{
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <div
              className="secondTitle"
              style={{ textAlign: "center"}}
            >
              {name} <br /> ({hours} timer) <br/>
              <p>Utløpt pakke: <br/>Solgt: {sold_date} <br/>Utløpt: {expiration_date} </p>
            </div>
          </CardBody>
        </Card>
      );
    }
  };

  /* Regulates whether or not the inactive packages should be shown */
  const showInactivePackages = () => {
    stateHandler();
  };


  const getActivePackages = () => {
    return allPackages.filter(p => (!p.is_expired && (p.remaining_hours > 0) && (p.remaining_hours <= p.hours_in_package))).map((x, key) =>
          <PackageInfoCard packageDetails={x} key={key}></PackageInfoCard>
        )
  };

  const getNotStartedPackages = () => {
    return allPackages.filter(p => !p.is_expired).map((x) =>
      getNotStartedPackageContainer(
        x.name,
        x.hours_in_package + x.additional_hours_added,
        x.remaining_hours
      )
    );
  };

  const getInactivePackage = () => {
    return (
      <>
        <Row className="secondTitle mb-2" style={{ paddingLeft: "22.5px" }}>
          Inaktive pakker
        </Row>
          {allPackages.filter(p => p.is_expired).map((x) =>
              <PackageInfoCard packageDetails={x}></PackageInfoCard>
          )}
          {allPackages.filter(p => !p.is_expired && p.remaining_hours == 0 || p.remaining_hours == null).map((x) =>
              <PackageInfoCard packageDetails={x}></PackageInfoCard>
          )}

      </>
    );
  };

  return (
    <div>
      <h1 className="title mb-3"> Mine Pakker</h1>
        <div className="package-overview">
            <div className="package-title">
                Aktive pakker
            </div>
            <div className="package-column">
                {getActivePackages()}
                { inactivePackages ?
                    getInactivePackage()
                    : <></>
                }

                <Button
                    className="button darkButton saveButton "
                    style={{ width: "300px" }}
                    onClick={showInactivePackages}
                >
                    {inactivePackages ? (
                        <p style={{ marginBottom: "0px", padding: "0px" }}>
                            Skjul tidligere pakker
                        </p>
                    ) : (
                        <p style={{ marginBottom: "0px", padding: "0px" }}>
                            Se tidligere pakker
                        </p>
                    )}
                </Button>

            </div>
            <div className="contact-column">
                {
                    contactEmployees.supervisor ?
                        <ContactPersonCard contact={ contactEmployees.supervisor }></ContactPersonCard>
                        :
                        <ContactPersonCard></ContactPersonCard>
                }
            </div>
        </div>


    </div>
  );
};

export default PackageOverview;
