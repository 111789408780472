import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Loader from "../../../../../components/Loader";
import FileTreeNode from "./FileTreeNode";
import {getTeacherResources} from "../../../../../redux/actions/teacherResourcesActions";

const TeacherResources = () => {
    const dispatch = useDispatch();

    const [fileTree, setFileTree] = useState(undefined);

    useEffect(() => {
        dispatch(getTeacherResources()).then(
            data => setFileTree(data)
        );

    }, [dispatch]);

    const renderRoot = root => {
        return (
            <div>
                {root.map((node, key) => <FileTreeNode key={key} onMouseHoverColor={"#509a2b"} headerColor={"#196600"} tree={node} depth={0} />)}
            </div>
        )
    }

    return (
        fileTree ?
            <div className="page-container">
                <h1>Lærerressurser</h1>
                {renderRoot(fileTree)}
            </div>
            :
            <Loader/>
    )
}

export default TeacherResources;