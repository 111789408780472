import React from "react";

import ScrollToTop from "./components/ScrollToTop";
import AuthenticatedRoutes from "./modules/authenticated/routes";
import UnauthenticatedRoutes from "./modules/unauthenticated/routes";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import Page404 from "./modules/unauthenticated/pages/Page404";

import "./stylesheets/style.css";
import "./stylesheets/teacherTableStyle.css";
import "./stylesheets/economyTableStyle.css";
import PrivateRoute from "./modules/authenticated/PrivateRoute";

const App = () => (
  <Router>
        <ScrollToTop>
            <Switch>
                <PrivateRoute
                    exact={true}
                    path="/"
                />
                {AuthenticatedRoutes()}
                {UnauthenticatedRoutes()}
                <Route component={Page404} />
            </Switch>
        </ScrollToTop>
    </Router>
);

export default App;
