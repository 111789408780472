import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, CardBody, CardHeader, Col, Input, Label, Row } from "reactstrap";
import { postStudent } from "../../../../../redux/actions/addStudent";
import RadGrid from "../../components/RadioGrid";
import Rad from "../../components/RadioWithInnerText";
import { getSchools } from "../../../../../redux/actions/getSchools";
import { getTeacher } from "../../../../../redux/actions/getTeacher";
import { getCities } from "../../../../../redux/actions/getCities";
import SubjectSelector from "../../components/SubjectSelector";
import Loader from "../../../../../components/Loader";
import MessageModal from "../../../../../components/MessageModal";
import {createInvoiceReceiver} from "../../../../../redux/actions/createInvoiceReceiver";
import {
  initialInvoiceUserState,
  invoiceHandler,
  validateInvoiceInput
} from "../../utils/addInvoiceReceiverHelpers";
import validator from "validator";
import {resetPassword} from "../../../../../redux/actions/resetPassword";

//Page that displays a form for adding a new student
const AddStudentForm = () => {
  const selectedSubjectsState = { subjects: [], errors: "" };

  const initialUserState = {
    fields: {
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
      birthDay: "",
      birthMonth: "",
      birthYear: "",
      address: "",
      post_code: "",
      post_code_area: "",
      city: "",
      heard_about_us: "",
      education_stage: "",
      otherSubject: "",
      school: "",
      other: "",
    },

    errors: {
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
      birthDay: "",
      birthMonth: "",
      birthYear: "",
      address: "",
      post_code: "",
      post_code_area: "",
      city: "",
      heard_about_us: "",
      education_stage: "",
      otherSubject: "",
      school: "",
      other: "",
    },
  };

  const dispatch = useDispatch();
  const citiesObject = useSelector((state) => state.cities);
  const teacherObject = useSelector((state) => state.teacher);
  const schoolsObject = useSelector((state) => state.schools);
  const invoiceObject = useSelector((state) => state.invoiceReceivers);
  const [cityLoading, cities] = [citiesObject.loading, citiesObject.cities];
  const [teacherLoading, teacher] = [teacherObject.loading, teacherObject.currentTeacher];
  const [schoolLoading, schools] = [schoolsObject.loading, schoolsObject.schools];

  const studentObject = useSelector(state=>state.students);
  const submitLoading = studentObject.loading;
  const subLoading = invoiceObject.loading;
  const {actionSucceeded} = studentObject;
  const studentError = studentObject.emailError;
  const invoiceError = invoiceObject.emailError;


  useEffect(() => {
    dispatch(getCities());
    dispatch(getSchools());
    dispatch(getTeacher());
  }, [dispatch]);

  const student = "Eleven er også fakturamottaker";
  const different = "Eleven er ikke fakturamottaker"
  const later = "Opprett fakturamottaker senere"

  //Sets the initial state of the component using hooks
  const [user, setUser] = useState(initialUserState);
  const [invoice, setInvoice] = useState(initialInvoiceUserState);
  const [selectedSubjects, setSelectedSubjects] = useState(selectedSubjectsState);
  const [view, setView] = useState("")

  //Sets the student as the invoice receiver if that is intended
  useEffect(() => {
    if (view === student) {
      invoice.fields.self_as_student = true;
    } else {
      invoice.fields.self_as_student = false;
    };
  }, [view])

  const selectedSubjectsHandler = (subjects) => {
    setSelectedSubjects((prevState) => ({
      ...prevState,
      subjects: subjects,
      errors: "",
    }));
  };

  //Returns a select with schools
  const getSchoolOptions = () => {
    return schools.sort((a, b) => a.school_name.localeCompare(b.school_name)).map((school, key) => (
      <option key={key} value={school.id}>
        {" "}
        {school.school_name}
      </option>
    )).sort();
  };

  //Returns a grid of radio buttons corresponding to cities
  const getCitySelector = () => {
    return RadGrid(
      cities.map((city, key) =>
        Rad(
          key,
          "city",
          city.city_name,
          city.id,
          false,
          () => {
            userHandler("city", city.id);
          },
          false,
          user.fields.city,
          "medium"
        )
      )
    );
  };

  //Handles changes to the form data related to the student
  const userHandler = (field, value) => {
    setUser((prevState) => ({
      ...prevState,
      fields: {
        ...prevState.fields,
        [field]: value,
      },
      errors:
        field === "birthDay" || field === "birthMonth" || field === "birthYear"
          ? {
              ...prevState.errors,
              birthYear: "",
              birthMonth: "",
              birthDay: "",
            }
          : {
              ...prevState.errors,
              [field]: "",
            },
    }));
  };

  const newlyAddedStudent = studentObject.newlyAddedStudent;

  //Returns true if the form data is valid and sets errors where it is invalid
  const validateInput = () => {
    let valid = true;

    if (user.fields.first_name === "") {
      setUser((prevState) => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          first_name: "Må fylles ut",
        },
      }));
      valid = false;
    }
    if (user.fields.last_name === "") {
      setUser((prevState) => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          last_name: "Må fylles ut",
        },
      }));
      valid = false;
    }
    if (/[^a-zæøåA-ZÆØÅ -]/.test(user.fields.first_name)) {
      setUser((prevState) => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          first_name: "Må være bokstaver",
        },
      }));
      valid = false;
    }

    if (/[^a-zæøåA-ZÆØÅ -]/.test(user.fields.last_name)) {
      setUser((prevState) => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          last_name: "Må være bokstaver",
        },
      }));
      valid = false;
    }

    if (!validator.isEmail(user.fields.email)) {
      setUser((prevState) => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          email: "Ugyldig epostadresse",
        },
      }));
      valid = false;
    }

    if (user.fields.phone_number.length !== 8 || !validator.isInt(user.fields.phone_number)) {
      setUser((prevState) => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          phone_number: "Ugyldig nummer",
        },
      }));
      valid = false;
    }
    if (!validator.isDate(user.fields.birthYear + "/" + user.fields.birthMonth + "/" + user.fields.birthDay)) {
      setUser((prevState) => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          birthDay: "Ugyldig dato",
          birthMonth: "Ugyldig dato",
          birthYear: "Ugyldig dato",
        },
      }));
      valid = false;
    }
    if (user.fields.city === "") {
      setUser((prevState) => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          city: "By ikke valgt",
        },
      }));
      valid = false;
    }
    if (user.fields.heard_about_us === "") {
      setUser((prevState) => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          heard_about_us: "Må fylles ut",
        },
      }));
      valid = false;
    }

    if (selectedSubjects.subjects.length === 0 && user.fields.otherSubject === "") {
      setSelectedSubjects((prevState) => ({
        ...prevState,
        errors: "Vennligst velg minst ett fag eller spesifiser",
      }));
      valid = false;
    }

    if (user.fields.school === "notASchool") {
      setUser((prevState) => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          school: "Må velges",
        },
      }));
      valid = false;
    }
    if (!/(([\wæøåÆØÅ]+)[ -])+\d*\w?/.test(user.fields.address)) {
      setUser((prevState) => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          address: "Ugyldig adresse",
        },
      }));
      valid = false;
    }
    if (user.fields.post_code.length !== 4 || !validator.isInt(user.fields.post_code)) {
      setUser((prevState) => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          post_code: "Ugyldig postnummer",
        },
      }));
      valid = false;
    }
    if (!/([\wæøåÆØÅ]+ )*[\wæøåÆØÅ]+/.test(user.fields.post_code_area)) {
      setUser((prevState) => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          post_code_area: "Ugyldig poststed",
        },
      }));
      valid = false;
    }

    return valid;
  };

   const registerStudent = () => {

    let valid = validateInput();
    let validInvoice = validateInvoiceInput(invoice, setInvoice);
    if (!valid || (!validInvoice && view === different)) {
      displayFailed("Ugyldig input. Hverken fakturamottaker eller elev ble opprettet!");
      toggleMessageIsOpen();
    }
    else {
      if (user.fields.school === "Annet") {
        user.fields.school = "";
      }
      dispatch(postStudent({ user: user, subjects: selectedSubjects.subjects.map((sub) => sub.id) }));
      setSubmitted(true);
    }
  };

  useEffect(() => {
    if (view !== later && newlyAddedStudent !== null && view !== "") {
      invoice.fields.pays_for_students.push(newlyAddedStudent.id);
      dispatch(createInvoiceReceiver({invoice: invoice}));
      setSubmitted(true);
    }
  }, [newlyAddedStudent]);
  
  const [submitted, setSubmitted] = useState(false)
  const [messageInfo, setMessageInfo] = useState({});
  const [messageIsOpen, setMessageIsOpen] = useState(false);
  const toggleMessageIsOpen = () => setMessageIsOpen((prevState) => !prevState);

  useEffect(() => {
    if (!submitLoading && submitted) {

      if (actionSucceeded) {
        if (view === later) {
          displaySuccess("Eleven ble opprettet! " +
            "For å legge til fakturamottaker kan du gå til Mine Elever -> elevens navn og trykke på “Legg til " +
            "fakturamottaker”");
        } else {
          displaySuccess("Eleven ble opprettet med fakturamottaker!");
        }
        toggleMessageIsOpen();
        setSubmitted(false);
      }

      else if (studentError !== "") {
        displayFailed("For student: " + studentError);
        toggleMessageIsOpen();
        setSubmitted(false);
      } else {
        setSubmitted(true);
      }


    }
  }, [submitLoading]);

    useEffect(() => {

    if (!subLoading && submitted) {

      if (invoiceError === "" && view === student) {
        displaySuccess("Eleven ble opprettet med seg selv som fakturamottaker!");
        toggleMessageIsOpen();
      }
      else if (invoiceError !== "" && view === different) {
        displaySuccess( "Eleven ble opprettet, " +
            "men følgende problem oppstod med fakturamottaker: “" + invoiceError +
            "” \nFor å legge til fakturamottaker kan du gå til Mine Elever -> elevens navn og trykke på “Legg til " +
            "fakturamottaker”", "Suksess med elev, men poblem med fakturamottaker");
        toggleMessageIsOpen();
      }

      setSubmitted(false);
    }
  }, [subLoading]);


  const displayFailed = (message) => setMessageInfo({ error: true, message: message});

  const displaySuccess = (message, header = "Vellykket!") => setMessageInfo({header: header,
    message: message, link: "/teacher/students/"})

  return (
    <div>
      {MessageModal({ ...messageInfo, toggle: toggleMessageIsOpen, isOpen: messageIsOpen })}
      {cityLoading || schoolLoading || teacherLoading ? <Loader/> : ""}
      <Card className={cityLoading || schoolLoading || teacherLoading ? "invisible" : ""}>
        <CardHeader>
          <div className="title">Ny elev</div>
        </CardHeader>
        <CardBody>

            <div className="secondTitle">Elev</div>
            <Row>
              <Col xs="12" sm="5">

                  <Label>Fornavn</Label>
                  <Input
                    name="fName"
                    placeholder="Ola"
                    rows="1"
                    className="inputField"
                    onChange={(e) => userHandler("first_name", e.target.value)}
                    invalid={user.errors.first_name !== ""}
                    required
                  />
                  {user.errors.first_name === "" ? "" : <p style={{ color: "red" }}>{user.errors.first_name}</p>}

              </Col>
              <Col xs="12" sm="7">

                  <Label>Etternavn</Label>
                  <Input
                    name="lName"
                    placeholder="Nordmann"
                    rows="1"
                    className="inputField"
                    onChange={(e) => userHandler("last_name", e.target.value)}
                    invalid={user.errors.last_name !== ""}
                    required
                  />
                  {user.errors.last_name === "" ? "" : <p style={{ color: "red" }}>{user.errors.last_name}</p>}

              </Col>
            </Row>
            <Row>
              <Col xs="12" sm="5">

                  <label>Fødselsdato</label>
                  <div className="dateContainer">
                    <Input
                      name="bDay"
                      rows="1"
                      type="number"
                      className="inputField dayNmonthField"
                      onChange={(e) => userHandler("birthDay", e.target.value)}
                      invalid={user.errors.birthDay !== ""}
                      placeholder="DD"
                      required
                    />
                    <Input
                      name="bMonth"
                      rows="1"
                      type="number"
                      className="inputField dayNmonthField"
                      onChange={(e) => userHandler("birthMonth", e.target.value)}
                      invalid={user.errors.birthMonth !== ""}
                      placeholder="MM"
                      required
                    />
                    <Input
                      name="bYear"
                      rows="1"
                      type="number"
                      className="inputField"
                      id="yearField"
                      onChange={(e) => userHandler("birthYear", e.target.value)}
                      invalid={user.errors.birthYear !== ""}
                      placeholder="YYYY"
                      required
                    />
                  </div>
                  {user.errors.birthDay === "" && user.errors.birthMonth === "" && user.errors.birthYear === "" ? (
                    ""
                  ) : (
                    <p style={{ color: "red" }}>{user.errors.birthDay}</p>
                  )}

              </Col>
              <Col xs="12" sm="7">

                  <Label>Telefonnummer</Label>
                  <Input
                    type="number"
                    name="phone"
                    rows="1"
                    onChange={(e) => userHandler("phone_number", e.target.value)}
                    invalid={user.errors.phone_number !== ""}
                    className="inputField"
                    required
                  />
                  {user.errors.phone_number === "" ? "" : <p style={{ color: "red" }}>{user.errors.phone_number}</p>}

              </Col>
            </Row>
            <Row>
              <Col xs="12" sm="12" xl="7">

                  <Label>Adresse</Label>
                  <Input
                    name="address"
                    rows="1"
                    className="inputField"
                    onChange={(e) => userHandler("address", e.target.value)}
                    invalid={user.errors.address !== ""}
                    required
                  />
                  {user.errors.address === "" ? "" : <p style={{ color: "red" }}>{user.errors.address}</p>}

              </Col>
              <Col xs="12" sm="5" xl="2">

                  <Label>Postnummer</Label>
                  <Input
                    name="post_code"
                    rows="1"
                    className="inputField"
                    onChange={(e) => userHandler("post_code", e.target.value)}
                    invalid={user.errors.post_code !== ""}
                    required
                  />
                  {user.errors.post_code === "" ? "" : <p style={{ color: "red" }}>{user.errors.post_code}</p>}

              </Col>
              <Col xs="12" sm="7" xl="3">

                  <Label>Poststed</Label>
                  <Input
                    name="post_code_area"
                    rows="1"
                    className="inputField"
                    onChange={(e) => userHandler("post_code_area", e.target.value)}
                    invalid={user.errors.post_code_area !== ""}
                    required
                  />
                  {user.errors.post_code_area === "" ? "" : <p style={{ color: "red" }}>{user.errors.post_code_area}</p>}

              </Col>
            </Row>
            <Row>
              <Col xs="12" sm="12" md="12" lg="6">

                  <Label>E-post</Label>
                  <Input
                    name="email"
                    rows="1"
                    onChange={(e) => userHandler("email", e.target.value)}
                    invalid={user.errors.email !== ""}
                    className="inputField"
                    required
                  />
                  {user.errors.email === "" ? "" : <p style={{ color: "red" }}>{user.errors.email}</p>}

              </Col>
            </Row>
            <Row>
              <Col>

                  <Label>Hvor hørte eleven først om Realfagshjelpen?</Label>
                  <Input
                    name="by"
                    rows="1"
                    className="inputField"
                    required
                    invalid={user.errors.heard_about_us !== ""}
                    onChange={(e) => userHandler("heard_about_us", e.target.value)}
                  />
                  {user.errors.heard_about_us === "" ? "" : <p style={{ color: "red" }}>{user.errors.heard_about_us}</p>}

              </Col>
            </Row>
            <Row>
              <Col>

                  <Label>I hvilken by foregår undervisningen?</Label>
                  {getCitySelector()}
                  {user.errors.city === "" ? "" : <p style={{ color: "red" }}>{user.errors.city}</p>}

              </Col>
            </Row>
            {SubjectSelector([], [], teacher.subjects, (selected) => {
              selectedSubjectsHandler(selected);
            })}
            <Col>{selectedSubjects.errors === "" ? "" : <p style={{ color: "red" }}>{selectedSubjects.errors}</p>}</Col>
            <Row style={{marginTop: "20px"}}>
              <Col>

                  <Label>Hvilken skole går eleven på? (Velg "Annet" om du ikke finner skolen i listen)</Label>
                  <Input
                    type="select"
                    id="level"
                    className="inputField"
                    onChange={(e) => userHandler("school", e.target.value)}
                    invalid={!(user.errors.school === "")}
                  >
                    <option value="notASchool">Velg</option>
                    <option value="Annet">Annet</option>
                    {getSchoolOptions()}
                  </Input>
                  {user.errors.school === "" ? "" : <p style={{ color: "red" }}>{user.errors.school}</p>}

              </Col>
            </Row>
            <Row >
              <Col>

                {user.fields.school === "Annet" ?
                    <Label style={{color: "green"}}>Du kan trygt opprette eleven med "Annet" valgt som elevens skole. Bare gi beskjed til drift om at skolen ikke finnes, så legger vi den til i ettertid.</Label> : <div/>}

              </Col>
            </Row>
            <Row style={{display:"flex"}}>
              <Col xs="12" >
                <div className="secondTitle" style={{marginBottom: "20px", marginTop: "20px"}}>
                  Velg alternativ for fakturamottaker
                </div>
              </Col>
              <Col className="fullFlexCentre.col-md-4" style={{marginBottom: "10px"}}>
                <Input type="button" className="button mediumButton" value={student} onClick={() => setView(student)}
                     style={view === student ? {whiteSpace: "normal",width: "250px", backgroundColor: "#2a9d8f85"} : {whiteSpace: "normal",width: "250px"}}/>
              </Col>
              <Col className="fullFlexCentre.col-md-4" style={{marginBottom: "10px"}}>
                <Input type="button" className="button mediumButton" value={different} onClick={() => setView(different)}
                       style={view === different ? {whiteSpace: "normal",width: "250px", backgroundColor: "#2a9d8f85"} : {whiteSpace: "normal",width: "250px"}}/>
              </Col>
              {/*
              <Col className="fullFlexCentre.col-md-4" style={{marginBottom: "10px"}}>
                <Input type="button" className="button mediumButton" value={later} onClick={() => setView(later)}
                       style={view === later ? {whiteSpace: "normal",width: "250px", backgroundColor: "#2a9d8f85"} : {whiteSpace: "normal",width: "250px"}}/>
              </Col>
              */}
            </Row>
            {view === different ?
                <Col style={{marginTop: "20px"}}>
            <Row>
              <Col xs="12" sm="5">

                  <Label>Fornavn</Label>
                  <Input
                    name="fName"
                    placeholder="Ola"
                    rows="1"
                    className="inputField"
                    onChange={(e) => invoiceHandler("billing_first_name", e.target.value, setInvoice)}
                    invalid={invoice.errors.billing_first_name !== ""}
                    required
                  />
                  {invoice.errors.billing_first_name === "" ? "" : <p style={{ color: "red" }}>{invoice.errors.billing_first_name}</p>}

              </Col>
              <Col xs="12" sm="7">

                  <Label>Etternavn</Label>
                  <Input
                    name="lName"
                    placeholder="Nordmann"
                    rows="1"
                    className="inputField"
                    onChange={(e) => invoiceHandler("billing_last_name", e.target.value, setInvoice)}
                    invalid={invoice.errors.billing_last_name !== ""}
                    required
                  />
                  {invoice.errors.billing_last_name === "" ? "" : <p style={{ color: "red" }}>{invoice.errors.billing_last_name}</p>}

              </Col>
            </Row>
            <Row>
              <Col xs="12" md="5">

                  <Label>Telefonnummer</Label>
                  <Input
                    name="phoneFac"
                    rows="1"
                    className="inputField"
                    onChange={(e) => invoiceHandler("billing_phone_number", e.target.value, setInvoice)}
                    invalid={invoice.errors.billing_phone_number !== ""}
                    required
                  />
                  {invoice.errors.billing_phone_number === "" ? "" : <p style={{ color: "red" }}>{invoice.errors.billing_phone_number}</p>}

              </Col>
              <Col xs="12" md="7">

                  <Label>E-post</Label>
                  <Input
                    name="emailFac"
                    rows="1"
                    className="inputField"
                    onChange={(e) => invoiceHandler("billing_email", e.target.value, setInvoice)}
                    invalid={invoice.errors.billing_email !== ""}
                    required
                  />
                  {invoice.errors.billing_email === "" ? "" : <p style={{ color: "red" }}>{invoice.errors.billing_email}</p>}

              </Col>
            </Row>
            <Row>
              <Col xs="12" sm="12" xl="7">

                  <Label>Adresse</Label>
                  <Input
                    name="billing_address"
                    rows="1"
                    className="inputField"
                    onChange={(e) => invoiceHandler("billing_address", e.target.value, setInvoice)}
                    invalid={invoice.errors.billing_address !== ""}
                    required
                  />
                  {invoice.errors.billing_address === "" ? "" : <p style={{ color: "red" }}>{invoice.errors.billing_address}</p>}

              </Col>
              <Col xs="12" sm="5" xl="2">

                  <Label>Postnummer</Label>
                  <Input
                    name="billing_post_code"
                    rows="1"
                    className="inputField"
                    onChange={(e) => invoiceHandler("billing_post_code", e.target.value, setInvoice)}
                    invalid={invoice.errors.billing_post_code !== ""}
                    required
                  />
                  {invoice.errors.billing_post_code === "" ? "" : <p style={{ color: "red" }}>{invoice.errors.billing_post_code}</p>}

              </Col>
              <Col xs="12" sm="7" xl="3">

                  <Label>Poststed</Label>
                  <Input
                    name="billing_post_code_area"
                    rows="1"
                    className="inputField"
                    onChange={(e) => invoiceHandler("billing_post_code_area", e.target.value, setInvoice)}
                    invalid={invoice.errors.billing_post_code_area !== ""}
                    required
                  />
                  {invoice.errors.billing_post_code_area === "" ? "" : <p style={{ color: "red" }}>{invoice.errors.billing_post_code_area}</p>}

              </Col>
            </Row>
                </Col>
                : <div/>
            }
            {view !== "" ?
              <Row>
                <Col>
                <div style={{fontSize: "24px", marginTop: "20px"}}>Andre kommentarer</div>

                  <Input
                    name="comments"
                    type="textarea"
                    outline="true"
                    className="inputField"
                    style={{ height: "200px" }}
                    onChange={(e) => userHandler("other", e.target.value)}
                  />{" "}

                  </Col>
              </Row>

            : <div/>}
            {view !== "" ?
            <Row>
              <Col>
                <Button className="button darkButton saveButton" onClick={() => registerStudent()}>
                  Opprett elev
                </Button>
              </Col>
            </Row>
                :<div/>}

        </CardBody>
      </Card>
    </div>
  );
};

export default AddStudentForm;
