// Sidebar
export const SIDEBAR_VISIBILITY_TOGGLE = "SIDEBAR_VISIBILITY_TOGGLE";
export const SIDEBAR_VISIBILITY_SHOW = "SIDEBAR_VISIBILITY_SHOW";
export const SIDEBAR_VISIBILITY_HIDE = "SIDEBAR_VISIBILITY_HIDE";
export const SIDEBAR_STICKY_TOGGLE = "SIDEBAR_STICKY_TOGGLE";
export const SIDEBAR_STICKY_ENABLE = "SIDEBAR_STICKY_ENABLE";
export const SIDEBAR_STICKY_DISABLE = "SIDEBAR_STICKY_DISABLE";

// Layout
export const LAYOUT_BOXED_TOGGLE = "LAYOUT_BOXED_TOGGLE";
export const LAYOUT_BOXED_ENABLE = "LAYOUT_BOXED_ENABLE";
export const LAYOUT_BOXED_DISABLE = "LAYOUT_BOXED_DISABLE";

// Theme
export const THEME_TOGGLE = "THEME_TOGGLE";

// Tablets
export const TABLETS_LIST_REQUEST = "TABLETS_LIST_REQUEST";
export const TABLETS_LIST_SUCCESS = "TABLETS_LIST_SUCCESS";
export const TABLETS_LIST_FAIL = "TABLETS_LIST_FAIL";

//Applicants
export const APPLICANTS_LIST_REQUEST = "APPLICANTS_LIST_REQUEST";
export const APPLICANTS_LIST_SUCCESS = "APPLICANTS_LIST_SUCCESS";
export const APPLICANTS_LIST_FAIL = "APPLICANTS_LIST_FAIL";

//Application
export const APPLICATION_CREATE_REQUEST = "APPLICATION_CREATE_REQUEST";
export const APPLICATION_CREATE_SUCCESS = "APPLICATION_CREATE_SUCCESS";
export const APPLICATION_CREATE_FAIL = "APPLICATION_CREATE_FAIL";

//Sessions
export const INCREMENT_MINUTE = "INCREMENT_MINUTE";
//auth
export const LOG_IN_REQUEST = "LOG_IN";
export const LOG_IN_SUCCESS = "LOG_IN_SUCCESS";
export const LOG_IN_FAIL = "LOG_IN_FAIL";
export const LOG_OUT_SUCCESS = "LOG_OUT_SUCCESS";
export const LOG_OUT_FAIL = "LOG_OUT_FAIL";
export const EDIT_REQUEST = "EDIT_REQUEST";

//addStudent
export const ADD_STUDENT_REQUEST = "ADD_STUDENT_REQUEST";
export const ADD_STUDENT_SUCCESS = "ADD_STUDENT_SUCCESS";
export const ADD_STUDENT_FAIL = "ADD_STUDENT_FAIL ";

//getActivities
export const GET_ACTIVITY_REQUEST = "GET_ACTIVITY_REQUEST";
export const GET_ACTIVITY_SUCCESS = "GET_ACTIVITY_SUCCESS";
export const GET_ACTIVITY_FAIL = "GET_ACTIVITY_FAIL";

export const GET_USER_ACTIVITIES_PAGE_SUCCESS = "GET_USER_ACTIVITIES_PAGE_SUCCESS";


//editActivities
export const EDIT_ACTIVITY_REQUEST = "EDIT_ACTIVITY_REQUEST";
export const EDIT_ACTIVITY_SUCCESS = "EDIT_ACTIVITY_SUCCESS";
export const EDIT_ACTIVITY_FAIL = "EDIT_ACTIVITY_FAIL";

//deleteActivities
export const DELETE_ACTIVITY_REQUEST = "DELETE_ACTIVITY_REQUEST";
export const DELETE_ACTIVITY_SUCCESS = "DELETE_ACTIVITY_SUCCESS";
export const DELETE_ACTIVITY_FAIL = "DELETE_ACTIVITY_FAIL";

//getStudents
export const GET_STUDENTS_REQUEST = "GET_STUDENTS_REQUEST";
export const GET_STUDENTS_SUCCESS = "GET_STUDENTS_SUCCESS";
export const GET_STUDENTS_FAIL = "GET_STUDENTS_FAIL";
//getStudent
export const GET_STUDENT_REQUEST = "GET_STUDENT_REQUEST";
export const GET_STUDENT_SUCCESS = "GET_STUDENT_SUCCESS";
export const GET_STUDENT_FAIL = "GET_STUDENT_FAIL";
//addActivity
export const ADD_ACTIVITY_REQUEST = "ADD_ACTIVITY_REQUEST";
export const ADD_ACTIVITY_SUCCESS = "ADD_ACTIVITY_SUCCESS";
export const ADD_ACTIVITY_FAIL = "ADD_ACTIVITY_FAIL ";
//editTeacher
export const EDIT_TEACHER_REQUEST = "EDIT_TEACHER_REQUEST ";
export const EDIT_TEACHER_SUCCESS = "EDIT_TEACHER_SUCCESS ";
export const EDIT_TEACHER_FAIL = "EDIT_TEACHER_FAIL ";
//getCurrentStudent
export const GET_COMMON_STUDENT_SUBJECT_REQUEST =
  "GET_COMMON_STUDENT_SUBJECT_REQUEST";
export const GET_COMMON_STUDENT_SUBJECT_SUCCESS =
  "GET_COMMON_STUDENT_SUBJECT_SUCCESS";
export const GET_COMMON_STUDENT_SUBJECT_FAIL =
  "GET_COMMON_STUDENT_SUBJECT_FAIL";
export const GET_STUDENT_SUBJECT_REQUEST = "GET_STUDENT_SUBJECT_REQUEST";
export const GET_STUDENT_SUBJECT_SUCCESS = "GET_STUDENT_SUBJECT_SUCCESS";
export const GET_STUDENT_SUBJECT_FAIL = "GET_STUDENT_SUBJECT_FAIL";
//getSubjectTree
export const GET_SUBJECT_TREE_REQUEST = "GET_SUBJECT_TREE_REQUEST";
export const GET_SUBJECT_TREE_SUCCESS = "GET_SUBJECT_TREE_SUCCESS";
export const GET_SUBJECT_TREE_FAIL = "GET_SUBJECT_TREE_FAIL";

//getTeacherSubjects
export const GET_SUBJECTS_FAIL = "GET_SUBJECTS_FAIL";
export const GET_SUBJECTS_REQUEST = "GET_SUBJECTS_REQUEST";
export const GET_SUBJECTS_SUCCESS = "GET_SUBJECTS_SUCCESS";

//PackageOverview
export const GET_MYPACKAGE_FAIL = "GET_MYPACKAGE_FAIL";
export const GET_MYPACKAGE_REQUEST = "GET_MYPACKAGE_REQUEST";
export const GET_MYPACKAGE_SUCCESS = "GET_MYPACKAGE_SUCCESS";

export const GET_PACKAGE_PAGE_REQUEST = "GET_PACKAGE_PAGE_REQUEST";
export const GET_PACKAGE_PAGE_SUCCESS = "GET_PACKAGE_PAGE_SUCCESS";
export const GET_PACKAGE_PAGE_FAIL = "GET_PACKAGE_PAGE_FAIL";

export const DELETE_PACKAGE_REQUEST = "DELETE_PACKAGE_REQUEST";
export const DELETE_PACKAGE_SUCCESS = "DELETE_PACKAGE_SUCCESS";
export const DELETE_PACKAGE_FAIL = "DELETE_PACKAGE_FAIL";

export const EDIT_PACKAGE_REQUEST = "EDIT_PACKAGE_REQUEST";
export const EDIT_PACKAGE_SUCCESS = "EDIT_PACKAGE_SUCCESS";
export const EDIT_PACKAGE_FAIL = "EDIT_PACKAGE_FAIL";

//PackageTypes
export const GET_PACKAGE_TYPES_FAIL = "GET_PACKAGE_TYPES_FAIL";
export const GET_PACKAGE_TYPES_REQUEST = "GET_PACKAGE_TYPES_REQUEST";
export const GET_PACKAGE_TYPES_SUCCESS = "GET_PACKAGE_TYPES_SUCCESS";

//PackageTypes
export const GET_PACKAGE_TYPE_HISTORY_FAIL = "GET_PACKAGE_TYPE_HISTORY_FAIL";
export const GET_PACKAGE_TYPE_HISTORY_REQUEST =
  "GET_PACKAGE_TYPE_HISTORY_REQUEST";
export const GET_PACKAGE_TYPE_HISTORY_SUCCESS =
  "GET_PACKAGE_TYPE_HISTORY_SUCCESS";

export const EDIT_TEACHER_DETAILS_REQUEST = "EDIT_TEACHER_DETAILS_REQUEST ";
export const EDIT_TEACHER_DETAILS_SUCCESS = "EDIT_TEACHER_DETAILS_SUCCESS ";
export const EDIT_TEACHER_DETAILS_FAIL = "EDIT_TEACHER_DETAILS_FAIL ";

// editTeacherPreference
export const EDIT_TEACHER_PREFERENCE_REQUEST = "EDIT_TEACHER_PREFERENCE_REQUEST ";
export const EDIT_TEACHER_PREFERENCE_SUCCESS = "EDIT_TEACHER_PREFERENCE_SUCCESS ";
export const EDIT_TEACHER_PREFERENCE_FAIL = "EDIT_TEACHER_PREFERENCE_FAIL ";
//editTeacherPreferredSubject
export const EDIT_TEACHER_PREFERRED_SUBJECT_REQUEST = "EDIT_TEACHER_PREFERRED_SUBJECT_REQUEST ";
export const EDIT_TEACHER_PREFERRED_SUBJECT_SUCCESS = "EDIT_TEACHER_PREFERRED_SUBJECT_SUCCESS ";
export const EDIT_TEACHER_PREFERRED_SUBJECT_FAIL = "EDIT_TEACHER_PREFERRED_SUBJECT_FAIL ";
//editPackageType
export const EDIT_PACKAGE_TYPE_REQUEST = "EDIT_PACKAGE_TYPE_REQUEST ";
export const EDIT_PACKAGE_TYPE_SUCCESS = "EDIT_PACKAGE_TYPE_SUCCESS ";
export const EDIT_PACKAGE_TYPE_FAIL = "EDIT_PACKAGE_TYPE_FAIL ";
//getTeacher
export const GET_TEACHER_REQUEST = "GET_TEACHER_REQUEST ";
export const GET_TEACHER_SUCCESS = "GET_TEACHER_SUCCESS ";
export const GET_TEACHER_FAIL = "GET_TEACHER_FAIL ";
//getCities
export const GET_CITIES_REQUEST = "GET_CITIES_REQUEST ";
export const GET_CITIES_SUCCESS = "GET_CITIES_SUCCESS ";
export const GET_CITIES_FAIL = "GET_CITIES_FAIL ";
//fieldsOfStudy
export const GET_FIELDSOFSTUDY_REQUEST = "GET_FIELDSOFSTUDY_REQUEST ";
export const GET_FIELDSOFSTUDY_SUCCESS = "GET_FIELDSOFSTUDY_SUCCESS ";
export const GET_FIELDSOFSTUDY_FAIL = "GET_FIELDSOFSTUDY_FAIL ";

//getContactinfo
export const GET_CONTACT_FAIL = "GET_CONTACT_FAIL";
export const GET_CONTACT_REQUEST = "GET_CONTACT_REQUEST";
export const GET_CONTACT_SUCCESS = "GET_CONTACT_SUCCESS";

//getSalaries
export const GET_SALARIES_FAIL = "GET_SALARIES_FAIL";
export const GET_SALARIES_REQUEST = "GET_SALARIES_REQUEST";
export const GET_SALARIES_SUCCESS = "GET_SALARIES_SUCCESS";

//getSalaries
export const GET_SALARY_LEVEL_TYPE_DETAILS_FAIL =
  "GET_SALARY_LEVEL_TYPE_DETAILS_FAIL";
export const GET_SALARY_LEVEL_TYPE_DETAILS_REQUEST =
  "GET_SALARY_LEVEL_TYPE_DETAILS_REQUEST";
export const GET_SALARY_LEVEL_TYPE_DETAILS_SUCCESS =
  "GET_SALARY_LEVEL_TYPE_DETAILS_SUCCESS";

//getTeacherSalaries
export const GET_TEACHER_SALARIES_FAIL = "GET_TEACHER_SALARIES_FAIL";
export const GET_TEACHER_SALARIES_REQUEST = "GET_TEACHER_SALARIES_REQUEST";
export const GET_TEACHER_SALARIES_SUCCESS = "GET_TEACHER_SALARIES_SUCCESS";

//addSalarylevel
export const ADD_SALARY_FAIL = "ADD_SALARY_FAIL";
export const ADD_SALARY_REQUEST = "ADD_SALARY_REQUEST";
export const ADD_SALARY_SUCCESS = "ADD_SALARY_SUCCESS";

//editSalarylevel
export const EDIT_SALARY_FAIL = "EDIT_SALARY_FAIL";
export const EDIT_SALARY_REQUEST = "EDIT_SALARY_REQUEST";
export const EDIT_SALARY_SUCCESS = "EDIT_SALARY_SUCCESS";

//error handling
export const SET_ERROR = "SET_ERROR";

//getTeacherSalaries
export const GET_TEACHER_SALARY_FAIL = "GET_TEACHER_SALARY_FAIL";
export const GET_TEACHER_SALARY_REQUEST = "GET_TEACHER_SALARY_REQUEST";
export const GET_TEACHER_SALARY_SUCCESS = "GET_TEACHER_SALARY_SUCCESS";

//getSchools
export const GET_SCHOOLS_FAIL = "GET_SCHOOLS_FAIL";
export const GET_SCHOOLS_REQUEST = "GET_SCHOOLS_REQUEST";
export const GET_SCHOOLS_SUCCESS = "GET_SCHOOLS_SUCCESS";

//getTeacherSalaries
export const MARK_TEACHERS_AS_PAID_FAIL = "MARK_TEACHERS_AS_PAID_FAIL";
export const MARK_TEACHERS_AS_PAID_REQUEST = "MARK_TEACHERS_AS_PAID_REQUEST";
export const MARK_TEACHERS_AS_PAID_SUCCESS = "MARK_TEACHERS_AS_PAID_SUCCESS";

//addPackageStudent
export const ADD_PACKAGE_STUDENT_FAIL = "ADD_PACKAGE_STUDENT_FAIL";
export const ADD_PACKAGE_STUDENT_REQUEST = "ADD_PACKAGE_STUDENT_REQUEST";
export const ADD_PACKAGE_STUDENT_SUCCESS = "ADD_PACKAGE_STUDENT_SUCCESS";

//change password
export const CHANGE_PASSWORD_REQUEST ="CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_SUCCESS ="CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAIL ="CHANGE_PASSWORD_FAIL";

// Subjects
export const ADD_SUBJECT_TO_STUDENT_FAIL = "ADD_SUBJECT_TO_STUDENT_FAIL"
export const ADD_SUBJECT_TO_STUDENT_SUCCESS = "ADD_SUBJECT_TO_STUDENT_SUCCESS"
export const ADD_SUBJECT_TO_STUDENT_REQUEST = "ADD_SUBJECT_TO_STUDENT_REQUEST"

// Get all students
export const GET_ALL_STUDENTS_FAIL = "GET_ALL_STUDENTS_FAIL";
export const GET_ALL_STUDENTS_SUCCESS = "GET_ALL_STUDENTS_SUCCESS";
export const GET_ALL_STUDENTS_REQUEST = "GET_ALL_STUDENTS_REQUEST";

export const GET_ALL_STUDENTS_PAGE_SUCCESS = "GET_ALL_STUDENTS_PAGE_SUCCESS";

// confirmResetPassword
export const CONFIRM_RESET_PASSWORD_FAIL = "CONFIRM_RESET_PASSWORD_FAIL";
export const CONFIRM_RESET_PASSWORD_SUCCESS = "CONFIRM_RESET_PASSWORD_SUCCESS";
export const CONFIRM_RESET_PASSWORD_REQUEST = "CONFIRM_RESET_PASSWORD_REQUEST";

// resetPassword
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";

// get Order confirmations
export const GET_ORDER_CONFIRMATIONS_FAIL = "GET_ORDER_CONFIRMATIONS_FAIL";
export const GET_ORDER_CONFIRMATIONS_REQUEST = "GET_ORDER_CONFIRMATIONS_REQUEST";
export const GET_ORDER_CONFIRMATIONS_SUCCESS = "GET_ORDER_CONFIRMATIONS_SUCCESS";

//get Invoice Receivers
export const GET_INVOICE_RECEIVERS_FAIL = "GET_INVOICE_RECEIVERS_FAIL";
export const GET_INVOICE_RECEIVERS_REQUEST = "GET_INVOICE_RECEIVERS_REQUEST";
export const GET_INVOICE_RECEIVERS_SUCCESS = "GET_INVOICE_RECEIVERS_SUCCESS";

export const GET_INVOICE_RECEIVER_SEARCH_FAIL = "GET_INVOICE_RECEIVER_SEARCH_FAIL";
export const GET_INVOICE_RECEIVER_SEARCH_REQUEST = "GET_INVOICE_RECEIVER_SEARCH_REQUEST";
export const GET_INVOICE_RECEIVER_SEARCH_SUCCESS = "GET_INVOICE_RECEIVER_SEARCH_SUCCESS";

export const PATCH_INVOICE_RECEIVER_FAIL = "PATCH_INVOICE_RECEIVER_SEARCH_FAIL";
export const PATCH_INVOICE_RECEIVER_REQUEST = "PATCH_INVOICE_RECEIVER_SEARCH_REQUEST";
export const PATCH_INVOICE_RECEIVER_SUCCESS = "PATCH_INVOICE_RECEIVER_SEARCH_SUCCESS";

// getInvoices
export const GET_INVOICES_FAIL = "GET_INVOICES_FAIL";
export const GET_INVOICES_SUCCESS = "GET_INVOICES_SUCCESS";
export const GET_INVOICES_REQUEST = "GET_INVOICES_REQUEST";

// markInvoices
export const MARK_INVOICES_FAIL = "MARK_INVOICES_FAIL";
export const MARK_INVOICES_SUCCESS = "MARK_INVOICES_SUCCESS";
export const MARK_INVOICES_REQUEST = "MARK_INVOICES_REQUEST";

// createInvoiceReceiver
export const CREATE_INVOICE_RECEIVER_FAIL = "CREATE_INVOICE_RECEIVER_FAIL";
export const CREATE_INVOICE_RECEIVER_SUCCESS = "CREATE_INVOICE_RECEIVER_SUCCESS";
export const CREATE_INVOICE_RECEIVER_REQUEST = "CREATE_INVOICE_RECEIVER_REQUEST";
// teacherMatching
export const GET_TEACHER_MATCHING_FAIL = "GET_TEACHER_MATCHING_FAIL";
export const GET_TEACHER_MATCHING_REQUEST = "GET_TEACHER_MATCHING_REQUEST";
export const GET_TEACHER_MATCHING_SUCCESS = "GET_TEACHER_MATCHING_SUCCESS";

//teacherOverview
export const GET_TEACHER_OVERVIEW_FAIL = "GET_TEACHER_OVERVIEW_FAIL";
export const GET_TEACHER_OVERVIEW_REQUEST = "GET_TEACHER_OVERVIEW_REQUEST";
export const GET_TEACHER_OVERVIEW_SUCCESS = "GET_TEACHER_OVERVIEW_SUCCESS";

export const GET_ALL_TEACHERS_PAGE_SUCCESS = "GET_ALL_TEACHERS_PAGE_SUCCESS";

// activity category
export const GET_ACTIVITY_CATEGORIES_SUCCESS = "GET_ACTIVITY_CATEGORIES_SUCCESS";

// metabase
export const GET_METABASE_FAIL = "GET_METABASE_FAIL";

export const GET_TEACHER_RESOURCES_SUCCESS = "GET_TEACHER_RESOURCES_SUCCESS";
export const GET_TEACHER_RESOURCES_FAIL = "GET_TEACHER_RESOURCES_FAIL";
export const GET_TEACHER_RESOURCES_REQUEST = "GET_TEACHER_RESOURCES_REQUEST";

export const CREATE_TEACHER_RESOURCES_SUCCESS = "CREATE_TEACHER_RESOURCES_SUCCESS";
export const CREATE_TEACHER_RESOURCES_FAIL = "CREATE_TEACHER_RESOURCES_FAIL";
export const CREATE_TEACHER_RESOURCES_REQUEST = "CREATE_TEACHER_RESOURCES_REQUEST";

